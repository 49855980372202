import React, { Component } from "react";
import styles from "./UserChat.module.scss";
import Icon from "../../Icon/Icon";
import SEND_ICON from "../../../assets/sendIcon.svg";
import KEEP_ALIVE_ON from "../../../assets/keepaliveon.svg";
import KEEP_ALIVE_OFF from "../../../assets/keepaliveoff.svg";
import RETRY_MESSAGE from "../../../assets/retry-message.svg";
import IconChatNotification from "../../../assets/icon-chat-notification_white.svg";
import SOS from "../../../assets/sos.svg";
import {
  CONTAINER,
  VEHICLE_CHAT_ENDPOINT,
  STOP,
  MAX_LENGTH_MESSAGE,
  START,
  LENGHT_MESSAGE_SIZE,
  LENGHT_MESSAGE_SIZE_OVER_MAX,
} from "../../../Util/constants";
import Controller from "../../../Controller";
import moment from "moment";
import { httpRequest } from "../../../RequestManager/HttpRequest";
import {
  getWsAuthToken,
  chatMessages,
  resendExpiredChatMessage,
} from "../../../RequestManager/GqlBuilderAccount";
import Swal from "sweetalert2";

const INNER_TEXT = "inner-text";
const ARROW_DOWN = "arrow-down";
const MESSAGE = "message";
const UPDATE = "update";
const ERROR = "error";
export default class UserChat extends Component {
  messageError = LENGHT_MESSAGE_SIZE;
  scrollRef = React.createRef();
  todayDate = new Date();
  timer_map = null;
  textArea = React.createRef();
  minTimeOut = 500;
  colorStatus = {
    0: "#000000",
    1: "#00b6c9",
    2: "#000000",
    3: "#000000",
    5: "#999",
  };
  iconStatus = {
    0: "check", ///sent
    1: "check-double", ///seen
    2: "check-double", //Received
    3: "times",
    5: "times", //fail
  };
  arrowElement = null;
  MAX_SIZE = 10;
  element = "";
  spanOver = "";
  spanValid = "";
  msgFullValue = "";
  webChatSocket = null;
  wsAuthToken = null;
  lookupId = "";
  count = null;
  offset = 1;
  limit = 20;
  isFirstload = true;
  preview = "Type Message";
  state = {
    showAvatar: false,
    opCode: {},
    value: "",
    loading: true,
    chat: [],
    keepAlive: false,
  };

  componentDidMount() {
    this.lookupId = this.props.vehicle.lookupId;

    this.arrowElement = document.getElementById(ARROW_DOWN);
    this.MAX_SIZE = Controller.getMsgChatLenght();
    if (this.props.phoneMode) {
      window.addEventListener(
        "resize",
        (e) => {
          if (window.innerHeight > 600) {
            this.onBlurTextAreaHandler();
          } else {
            this.onFouceTextAreaHandler();
          }
        },
        false
      );
    }
    this.getHistoryChatHandler();
  }
  componentWillUnmount() {
    this.closeChatMessageHandler();
  }

  getHistoryChatHandler = () => {
    if (this.count !== null && this.count !== 0) {
      this.offset = this.offset + this.limit;
    }
    if (this.count !== null && this.count !== 0 && this.offset >= this.count)
      return;
    this.props.contralHttpInterval(STOP);
    httpRequest(chatMessages, {
      vehicleLookupId: this.lookupId,
      limit: this.limit,
      reverseAfterLimit: true,
      offset: this.offset,
    })
      .then((res) => {
        this.setDataHandler(res.data.chatMessages);
      })
      .catch((err) => {});
  };
  checkDateSeparatorHandler = (index, item) => {
    if (index == 0) {
      return !moment(this.state.chat[index].createdAt).isSame(
        this.todayDate,
        "day"
      );
    } else {
      return !moment(item.createdAt).isSame(
        this.state.chat[index - 1].createdAt,
        "day"
      );
    }
  };
  closeChatMessageHandler = () => {
    if (this.webChatSocket !== null) {
      this.webChatSocket.close(1000);
      this.webChatSocket = null;
    }
  };
  scrolleToBottomChatHandler = () => {
    let elmnt = document.getElementById(CONTAINER);
    let elmArrow = document.getElementById(ARROW_DOWN);
    if (elmnt != null) {
      elmnt.scroll({ top: elmnt.scrollHeight, behavior: "smooth" });
      this.setVisibilityArrowHandler(false);
    }
  };
  onKeyDownHandler = (event) => {
    if (event.key === "Enter" && !event.altKey) {
      event.preventDefault();
      this.onClickMessageHandler();
    } else if (event.altKey && event.key === "Enter") {
      this.setState({ value: this.state.value + "\n" });
    }
  };
  fireSwalHandler = (title, text, type) => {
    Swal.fire({
      title: title,
      text: text,
      type: type,
      confirmButtonText: "Done",
    }).then((results) => {
      if (type === "success") {
      } else if (type === "warning") {
        this.setState({ loading: false });
      }
    });
  };
  setDataHandler = (data) => {
    this.count = data.count;
    this.setState(
      { chat: [...data.objects, ...this.state.chat], loading: false },
      () => {
        if (this.isFirstload) {
          this.scrolleToBottomChatHandler();
          this.isFirstload = false;
        } else if (!this.isFirstload) {
          document.getElementById(this.limit.toString()).scrollIntoView();
        }
        if (this.webChatSocket !== null) {
          this.timer_map = setTimeout(() => {
            this.props.contralHttpInterval(START);
          }, 1000);
        }
        if (this.webChatSocket == null) {
          this.getChatTokenHandler();
        }
      }
    );
  };
  onClickAvatarHandler = () => {
    this.setState({ showAvatar: !this.state.showAvatar });
  };
  onClickCloseChatHandler = () => {
    this.closeChatMessageHandler();
    this.props.closechat();
  };
  getChatTokenHandler = () => {
    httpRequest(getWsAuthToken, {})
      .then((res) => {
        this.wsAuthToken = res.data.generateWsToken;
        this.lookupId = this.props.vehicle.lookupId;
        this.setState({ loading: false }, () => {
          this.props.contralHttpInterval(START);
          this.connectToSocketChatHandler();
        });
      })
      .catch((err) => {});
  };
  onScrollLoadChatsHandler = (event) => {
    event.preventDefault();
    if (document.getElementById(CONTAINER).scrollTop == 0) {
      if (
        this.count !== null &&
        this.count !== 0 &&
        this.offset + this.limit >= this.count
      )
        return;
      this.setState({ loading: true }, () => {
        this.setVisibilityArrowHandler(true);
        if (this.timer_map !== null) {
          clearTimeout(this.timer_map);
        }
        this.getHistoryChatHandler();
      });
    } else {
      if (
        document.getElementById(CONTAINER).offsetHeight +
          document.getElementById(CONTAINER).scrollTop >=
        document.getElementById(CONTAINER).scrollHeight
      ) {
        this.setVisibilityArrowHandler(false);
      } else {
        this.setVisibilityArrowHandler(true);
      }
    }
  };
  onClickRetryMsgHandler = (event, item) => {
    event.preventDefault();
    this.setState({ loading: true }, () => {
      this.props.contralHttpInterval(STOP);
      httpRequest(resendExpiredChatMessage, { messageLookupId: item.lookupId })
        .then((res) => {
          this.props.contralHttpInterval(START);
          if (res.errors !== undefined) {
            this.fireSwalHandler(
              "warning",
              "Chat message cannot be re-sent, as it is either still sending or has been received",
              "warning"
            );
          } else {
            this.setState({ loading: false });
          }
        })
        .catch((err) => {});
    });
  };
  connectToSocketChatHandler = () => {
    var webSocket = new WebSocket(
      `${VEHICLE_CHAT_ENDPOINT}/${this.lookupId}/?token=${this.wsAuthToken}`
    );

    webSocket.onopen = () => {
      this.webChatSocket = webSocket;

      webSocket.onerror = (event) => {
        this.reconnectToSocketHandler();
      };
      webSocket.onmessage = (event) => {
        this.setChatMessageHandler(JSON.parse(event.data));
      };
    };
  };
  setVisibilityArrowHandler = (show) => {
    if (!show) {
      this.arrowElement.style.visibility = "hidden";
    } else {
      this.arrowElement.style.visibility = "visible";
    }
  };
  onWeelHandler = () => {
    if (
      document.getElementById(CONTAINER).scrollTop == 0 &&
      this.count !== null &&
      this.count !== 0 &&
      this.offset + this.limit < this.count
    ) {
      this.setState({ loading: true }, () => {
        if (this.timer_map !== null) {
          clearTimeout(this.timer_map);
        }
        this.getHistoryChatHandler();
      });
    }
  };
  reconnectToSocketHandler = () => {
    this.closeChatMessageHandler();

    setTimeout(() => {
      if (this.minTimeOut * 2 < 10000) {
        this.minTimeOut = this.minTimeOut * 2;
      }
      this.props.contralHttpInterval(STOP);
      this.getChatTokenHandler();
    }, this.minTimeOut);
  };
  setChatMessageHandler = (data) => {
    if (data == null || data.errors !== undefined) return;
    let temp = [data];
    if (data.action == MESSAGE) {
      this.minTimeOut = 500;
      this.setState({ chat: [...this.state.chat, ...temp] }, () => {
        this.scrolleToBottomChatHandler();
      });
    } else if (data.action == UPDATE) {
      this.minTimeOut = 500;
      let copyOpcode = { ...this.state.opCode };
      copyOpcode[data.lookupId] = { ...data };
      this.setState({ opCode: copyOpcode }, () => {});
    } else if (data.action == ERROR) {
      this.reconnectToSocketHandler();
    }
  };
  onClickMessageHandler = () => {
    if (
      this.state.value.toString().trim() === "" ||
      this.webChatSocket == null ||
      this.getSizeOfBytpeSizeOfBroadcastmessageHandler(this.state.value) >
        MAX_LENGTH_MESSAGE
    )
      return;
    else {
      this.webChatSocket.send(JSON.stringify({ body: this.state.value }));
      this.count = this.count + 1;
      this.setState({ value: "" }, () => {
        this.onBlurTextAreaHandler();
      });
    }
  };

  getStatusIconHandler = (lookupId, status) => {
    if (this.state.opCode[lookupId] !== undefined) {
      return (
        <>
          <span
            className={styles.statusIcon}
            style={{
              color: this.colorStatus[this.state.opCode[lookupId].status],
              visibility:
                this.colorStatus[this.state.opCode[lookupId].status] == 5
                  ? "hidden"
                  : "visible",
            }}
          >
            <Icon
              defination={{
                prefix: "far",
                iconName: this.iconStatus[this.state.opCode[lookupId].status],
              }}
            />
          </span>
        </>
      );
    } else {
      return (
        <>
          <span
            className={styles.statusIcon}
            style={{
              color: this.colorStatus[status],
              visibility: status == 5 ? "hidden" : "visible",
            }}
          >
            <Icon
              defination={{
                prefix: "far",
                iconName: this.iconStatus[status],
              }}
            />
          </span>
        </>
      );
    }
  };
  onFouceTextAreaHandler = () => {
    if (this.props.phoneMode) {
      this.textArea.current.style.position = "fixed";
      this.textArea.current.style.marginBottom = "0";
    }
  };
  onBlurTextAreaHandler = () => {
    if (this.props.phoneMode) {
      this.textArea.current.style.position = "absolute";
      this.textArea.current.style.marginBottom = "10rem";
    }
  };
  checkErrorMsgHandler = (item) => {
    if (
      this.state.opCode[item.lookupId] !== undefined &&
      this.state.opCode[item.lookupId].status == 3
    )
      return true;
    if (this.state.opCode[item.lookupId] == undefined && item.status == 3)
      return true;
    return false;
  };
  checkMessagePreveHandler = (index, item) => {
    if (index == 0) return true;
    if (item.direction == 5) return false;
    if (this.state.chat[index - 1].direction == item.direction) return false;
    return true;
  };
  getSizeOfBytpeSizeOfBroadcastmessageHandler = () => {
    var byteSize = new Blob([this.state.value]).size;
    return byteSize;
  };
  onChangeHandler = (event) => {
    if (
      this.getSizeOfBytpeSizeOfBroadcastmessageHandler(event.target.value) >
      MAX_LENGTH_MESSAGE
    ) {
      this.messageError = LENGHT_MESSAGE_SIZE_OVER_MAX;
    } else {
      this.messageError = LENGHT_MESSAGE_SIZE;
    }
    this.setState({ value: event.target.value }, () => {});
  };
  render() {
    var byteSize = new Blob([this.state.value]).size;
    return (
      <>
        <span
          className={styles.spinnerLoader}
          style={{ display: this.state.loading ? "block" : "none" }}
        >
          <Icon
            defination={{ prefix: "fal", iconName: "spinner" }}
            isSpinner={true}
          />
        </span>
        <div
          className={
            this.state.showAvatar
              ? styles.avatarPreviewOpen
              : styles.avatarPreviewclose
          }
        >
          <span
            className={styles.iconCloseAvatar}
            onClick={this.onClickAvatarHandler}
          >
            <Icon defination={{ prefix: "fas", iconName: "times" }} />
          </span>
          <img
            alt="vehicle-picture"
            src={this.props.vehicle.avatar}
            className={styles.fullAvatarImg}
          />
        </div>
        <div className={styles.UserChats}>
          <div className={styles.titleWrapper}>
            <p className={styles.title}>
              <span className={styles.iconChat}>
                {this.props.vehicle.avatar !== null &&
                this.props.vehicle.avatar !== "" ? (
                  <img
                    className={styles.imgAvatar}
                    src={this.props.vehicle.avatar}
                    alt="vehicle-picture"
                    onClick={this.onClickAvatarHandler}
                  />
                ) : (
                  <img
                    className={styles.imgIconChatWhite}
                    src={IconChatNotification}
                    alt="icon-chat"
                  />
                )}
              </span>
              <span>live chat</span>&nbsp;&nbsp;
            </p>
            <span
              className={styles.icon}
              onClick={this.onClickCloseChatHandler}
            >
              <span className={styles.keepAlive}>
                <img
                  src={
                    this.props.vehicle.terminal.isAlive
                      ? KEEP_ALIVE_ON
                      : KEEP_ALIVE_OFF
                  }
                  className={styles.imgkeepalive}
                  alt="keep-alive-icon"
                />
                &nbsp;
              </span>
              <span className={styles.extermalId}>{this.props.extermalId}</span>
              <Icon defination={{ prefix: "fas", iconName: "arrow-right" }} />
            </span>
          </div>

          <div
            className={styles.container}
            id={CONTAINER}
            onWheel={(e) => this.onWeelHandler(e)}
            onTouchEnd={(e) => this.onWeelHandler(e)}
            ref={this.scrollRef}
            style={{
              height: Controller.getIsUser() ? "70vh" : "",
              maxHeight: Controller.getIsUser() ? "unset" : "",
              opacity: this.state.showAvatar ? 0.4 : 1,
            }}
            onScroll={(e) => this.onScrollLoadChatsHandler(e)}
          >
            {this.state.chat.map((item, index) => {
              return (
                <div className={styles.msg} key={index} id={index + 1}>
                  <div
                    className={styles.dateDivider}
                    style={{
                      display: this.checkDateSeparatorHandler(index, item)
                        ? "flex"
                        : "none",
                    }}
                  >
                    <span className={styles.date}>
                      {moment(item.createdAt).format("LL")}
                    </span>
                  </div>

                  <div
                    style={{
                      background:
                        item.direction == 2 && this.checkErrorMsgHandler(item)
                          ? "rgba(255, 182, 55, .7)"
                          : item.direction == 5
                          ? "#F7B5B5"
                          : "",
                    }}
                    className={
                      item.direction == 2 || item.direction == 3
                        ? `${styles.bubble} ${styles.alt}`
                        : styles.bubble
                    }
                  >
                    <button
                      className={styles.iconRetry}
                      onClick={(e) => this.onClickRetryMsgHandler(e, item)}
                      onTouchEnd={(e) => this.onClickRetryMsgHandler(e, item)}
                      style={{
                        display:
                          item.direction == 2 && this.checkErrorMsgHandler(item)
                            ? "flex"
                            : "none",
                      }}
                    >
                      <img alt="retry-message-icon" src={RETRY_MESSAGE} />
                      <span className={styles.textretry}>Retry</span>
                    </button>
                    <div className={styles.txt}>
                      <span className={styles.message}>
                        <span
                          style={{
                            display:
                              item.direction == 5 || item.direction == 6
                                ? "inline-block"
                                : "none",
                          }}
                        >
                          {item.direction == 5 && (
                            <img src={SOS} alt="sos-icon" />
                          )}
                          {item.direction == 6 && (
                            <span>
                              <Icon
                                defination={{
                                  iconName: "megaphone",
                                  prefix: "fas",
                                }}
                              />&nbsp;
                            </span>
                          )}
                        </span>
                        <span>{item.body}</span>
                      </span>
                      <span className={styles.timestamp}>
                        <span
                          style={{ marginRight: ".2rem", fontWeight: "bold" }}
                        >
                          {item.direction == 3 || item.direction == 4
                            ? moment(item.createdAt).format("LLL")
                            : moment(item.createdAt).format("HH:mm")}
                        </span>
                        {item.direction !== 1 &&
                          this.getStatusIconHandler(item.lookupId, item.status)}
                      </span>
                    </div>
                    {this.checkMessagePreveHandler(index, item) && (
                      <div
                        className={
                          item.direction == 2 || item.direction == 3
                            ? item.direction == 2 &&
                              this.checkErrorMsgHandler(item)
                              ? `${styles.bubbleArrow} ${styles.altfailed}`
                              : `${styles.bubbleArrow} ${styles.alt}`
                            : styles.bubbleArrow
                        }
                      ></div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
          <div
            className={styles.downArrow}
            id={ARROW_DOWN}
            onClick={this.scrolleToBottomChatHandler}
            style={{ visibility: "hidden" }}
          >
            <Icon
              defination={{
                prefix: "fas",
                iconName: "angle-double-down",
              }}
            />
          </div>
        </div>

        {!Controller.getIsUser() && (
          <div
            className={styles.wInputContainer}
            ref={this.textArea}
            onKeyPress={this.onKeyDownHandler}
          >
            <span className={styles.msCount}>Message length</span>
            <div
              className={
                byteSize > this.MAX_SIZE
                  ? `${styles.totalCount} ${styles.glow}`
                  : styles.totalCount
              }
              id="BOX"
            >
              <span>
                {<span>{byteSize}</span>}/{this.MAX_SIZE}
              </span>
            </div>
            <p
              className={styles.msgLength}
              style={{
                visibility: byteSize > this.MAX_SIZE ? "visible" : "hidden",
              }}
            >
              <strong>{this.messageError}</strong>
            </p>

            <textarea
              className={styles.wInputText}
              value={this.state.value}
              dir="auto"
              onKeyDown={this.onKeyDownHandler}
              placeholder={this.preview}
              id={INNER_TEXT}
              onChange={(e) => this.onChangeHandler(e)}
            />

            <span className={styles.sendMsgBtn}>
              <img
                src={SEND_ICON}
                onClick={this.onClickMessageHandler}
                alt="send message icon"
                className={styles.sendMsgBtn}
              />
            </span>
          </div>
        )}
      </>
    );
  }
}
