import React, { Component } from "react";
import styles from "./GenericMarker.module.scss";
import ALERT from "../../../assets/alert-vehicle.svg";
import ONLINE from "../../../assets/online-vehicle.svg";
import OFFLINE from "../../../assets/offline-vehicle.svg";

const decodeMarkerType = (details, isOnline) => {
  if (
    details.sosAlert !== null ||
    details.newMessageAlert != null ||
    details.showVehicleOutsideFenceAnimation ||
    details.exitFenceAlert != null
  ) {
    return ALERT;
  } else if (isOnline) {
    return ONLINE;
  } else {
    return OFFLINE;
  }
};
const GenericMarker = (props) => {
  const { details, heading, isOnline } = props;
  return (
    <div className={styles.custommarker}>
      <img
      className={styles.icon}
        onClick={() => props.markerClick(details)}
        style={{
          transform: `rotate(${heading != null ? heading : 0}deg)`,
        }}
        alt="vihicle-icon"
        src={decodeMarkerType(details, isOnline)}
      />
    </div>
  );
};
export default GenericMarker;
