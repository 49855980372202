// export const getLocalStorageInfo = () => {
//     let data = localStorage.getItem("info");
//     if (data === null) {
//       return null;
//     } else {
//       let token = JSON.parse(data).token;
//       return token;
//     }
//   };
//   export const storeInLocalStorage = data => {
//     localStorage.setItem("info", data);
//   };
//   export const removeInfoLocalStorage = () => {
//     localStorage.removeItem("info");
//   };
export const getAuthToken = () => {
  let data = localStorage.getItem("info");
  if (data === null) {
    return null;
  } else {
    return JSON.parse(data).token;
  }
};
export const getRefreshToken = () => {
  let data = localStorage.getItem("info");

  if (data === null) {
    return null;
  } else {
    return JSON.parse(data).refreshToken;
  }
};
export const setAuthToken = (data) => {
  localStorage.setItem("info", data);
};
export const removeAuthToken = () => {
  localStorage.removeItem("info");
};
export const getVersionNo = () => {
  let ver_no = localStorage.getItem("version_no");
  if (ver_no == undefined || ver_no==null) return "";
  else return ver_no;
};
export const storeVersionNo = (data) => {
  localStorage.setItem("version_no", data);
};