import React, { Component } from "react";

import SpinnerLoader from "../SpinnerLoader/SpinnerLoader";
import FormButtons from "../FormButtons/FormButtons";
import {
  getVehicles,
  createGroup,
  getGroupDue,
  updateGroup,
} from "../../RequestManager/GqlBuilderAccount";
import styles from "./CreateGroup.module.scss";
import Icon from "../Icon/Icon";
import Button from "../Button/Button";
import Controller from "../../Controller";
import {
  CANCEL,
  CLOSE,
  SUBMIT,
  EDIT_MODE,
  CREATE_MODE,
  VIEW_MODE,
  OTHER_ALIAS_NAME,
} from "../../Util/constants";
import Input from "../Input/Input";
import SearchBar from "../GoogleMap/SearchBar/SearchBar";
import SearchBox from "../SearchBox/SearchBox";
import { httpRequest } from "../../RequestManager/HttpRequest";
import Swal from "sweetalert2/dist/sweetalert2.js";

export default class CreateGroup extends Component {
  state = {
    loading: true,
    isMouted: false,
    isValidForm: true,
    address: {
      type: "address",
      address: "",
      country: "",
      error: false,
      size: 250,
      label: "Address",
      errMsg: "Invalid address",
      lat: "",
      lng: "",
    },

    createGroup: {
      groupName: {
        label: "Group name",
        value: "",
        error: false,
        name: "groupName",
        type: "text",
        errMsg: "Invalid Group name",
      },
    },
    searchVehicle: "",
    selectedVehicles: {},
    vehiclesList: {},
    originalVehiclesList: {},
    originalVehiclesSelected: {},
  };
  onscrollHandler = (event) => {
    var obj = event.target;

    if (obj.scrollTop === obj.scrollHeight - obj.offsetHeight) {
      this.setState({ loading: true });
    }
  };
  componentDidMount() {
    let location = { ...this.state.address };
    if (
      (this.props.mode === EDIT_MODE || this.props.mode === VIEW_MODE) &&
      this.props.data.location !== null
    ) {
      location.address = this.props.data.location.address;
      location.country = this.props.data.location.country;
      location.lat = this.props.data.location.latitude;
      location.lng = this.props.data.location.longitude;
      this.setState({ address: location }, () => {});
    }
    this.createHttpForInitDataHandler();
  }
  createHttpForInitDataHandler = () => {
    let params = {
      search: "",
      offset: 0,
      limit: 0,
      display: "groupassign",
    };
    let query = this.props.mode === CREATE_MODE ? getVehicles : getGroupDue;
    let dataParam =
      this.props.mode === CREATE_MODE
        ? params
        : { lookup_id: this.props.data.lookupId };
    httpRequest(query, dataParam)
      .then((res) => {
        if (this.props.mode == CREATE_MODE) {
          this.intiallizeDataHandler({ data: res.data.vehicles });
        } else {
          this.intiallizeDataHandler({
            data: res.data.vehicleDualReturn.available,
            selected: res.data.vehicleDualReturn.selected,
          });
        }
      })
      .catch((err) => {});
  };
  convertaArrToObjHandler = (data) => {
    let vehicle = {};
    data.map((item) => {
      return (vehicle[item.vin.toString()] = { ...item });
    });

    return vehicle;
  };

  intiallizeDataHandler = (dataObj) => {
    let copyInputs = { ...this.state.createGroup };
    let copyLocation = { ...this.state.address };

    if (this.props.mode === CREATE_MODE) {
      let obj = this.convertaArrToObjHandler(dataObj.data.vehicles);
      this.setState({
        selectedVehicles: {},
        vehiclesList: obj,
        loading: false,
        originalVehiclesList: JSON.parse(JSON.stringify(obj)),
      });
    } else {
      let data = this.props.data;

      let copyInputs = { ...this.state.createGroup };
      let location = { ...this.state.address };
      copyInputs.groupName.value = data.name;
      let objAvailable = this.convertaArrToObjHandler(dataObj.data);
      let objSelected = this.convertaArrToObjHandler(dataObj.selected);

      if (this.props.data.location !== null) {
        location.address = data.location.address;
        location.lat = data.location.latitude;
        location.lng = data.location.longitude;
        location.country = data.location.country;
      }
      this.setState(
        {
          selectedVehicles: objSelected,
          vehiclesList: objAvailable,
          loading: false,
          address: location,
          createGroup: copyInputs,
          originalVehiclesList: JSON.parse(JSON.stringify(objAvailable)),
          originalVehiclesSelected: JSON.parse(JSON.stringify(objSelected)),
        },
        () => {}
      );
    }

    this.setState(
      {
        createGroup: copyInputs,
        address: copyLocation,
      },
      () => {}
    );
  };
  buildInputsElementsHandler = () => {
    const { createGroup } = this.state;
    return (
      <>
        <Input
          type={createGroup.groupName.type}
          name={createGroup.groupName.name}
          key={createGroup.groupName.name}
          value={createGroup.groupName.value}
          placeholder={createGroup.groupName.label}
          label={createGroup.groupName.label}
          error={createGroup.groupName.error}
          errMsg={createGroup.groupName.errMsg}
          inputWrapper={styles.inputWrapper}
          change={(e) => this.onChangeHandler(e)}
          phoneMode={this.props.phoneMode}
          disabled={this.props.mode == VIEW_MODE}
        />
        {this.props.mode != VIEW_MODE ? (
          <SearchBar
            label={"Location"}
            address={this.state.address.address}
            formatAddress={this.formatAddressHandler}
            searchWrapper={styles.inputWrapper}
            error={this.state.address.error}
            disabled={this.props.mode == VIEW_MODE}
            phoneMode={this.props.phoneMode}
          />
        ) : (
          <Input
            label={"Location"}
            error={""}
            errMsg={""}
            value={this.state.address.address}
            name={"address"}
            inputWrapper={styles.inputWrapper}
            disabled={true}
            phoneMode={this.props.phoneMode}
          />
        )}
      </>
    );
  };
  onClickAddItem = (item) => {
    let vehiclesListCopy = JSON.parse(JSON.stringify(this.state.vehiclesList));
    delete vehiclesListCopy[item.vin];
    let org_available = JSON.parse(
      JSON.stringify(this.state.originalVehiclesList)
    );

    delete org_available[item.vin];

    let selectedVehiclesCopy = JSON.parse(
      JSON.stringify(this.state.selectedVehicles)
    );
    selectedVehiclesCopy[item.vin] = { ...item };
    this.setState({
      vehiclesList: vehiclesListCopy,
      selectedVehicles: selectedVehiclesCopy,
      originalVehiclesList: org_available,
    });
  };
  onClickRemoveItem = (item, index) => {
    let vehiclesListCopy = JSON.parse(JSON.stringify(this.state.vehiclesList));
    vehiclesListCopy[item.vin] = { ...item };
    let org_available = JSON.parse(
      JSON.stringify(this.state.originalVehiclesList)
    );
    org_available[item.vin] = { ...item };
    let selectedVehiclesCopy = JSON.parse(
      JSON.stringify(this.state.selectedVehicles)
    );
    delete selectedVehiclesCopy[item.vin];
    this.setState({
      vehiclesList: vehiclesListCopy,
      selectedVehicles: selectedVehiclesCopy,
      originalVehiclesList: org_available,
    });
  };
  onClickSearchHandler = () => {
    let search = this.state.searchVehicle;
    if (search.trim() === "") {
      this.setState({
        searchVehicle: "",
        vehiclesList: JSON.parse(
          JSON.stringify(this.state.originalVehiclesList)
        ),
      });
    } else {
      let arrMatch = {};
      Object.values(this.state.originalVehiclesList).map((vehicle) => {
        //loop and push all matched devices
        try {
          if (
            vehicle.vin.indexOf(search) !== -1 || vehicle.vClass.displayName.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
            vehicle.name.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
            vehicle.licensePlate.number.toLowerCase().indexOf(search.toLowerCase()) !== -1
          )
            arrMatch[vehicle.vin] = vehicle;
        } catch (error) {}
      });
      this.setState({
        vehiclesList: arrMatch,
      });
    }
  };
  onChangeHandler = (event) => {
    if (this.state.loading) return;
    let copyinputs = { ...this.state.createGroup };
    copyinputs[event.target.name].value = event.target.value;
    copyinputs[event.target.name].error = false;

    this.setState({ createGroup: copyinputs });
  };
  formatAddressHandler = (address) => {
    let copyAddress = { ...this.state.address };
    if (address === -1 || address.status !== "OK") {
      copyAddress.address = "";
      copyAddress.country = "";
      this.setState({ address: copyAddress }, () => {});
      return;
    } else {
      let arr = address.results[0].address_components;
      copyAddress.address = address.results[0].formatted_address;
      copyAddress.lat = address.results[0].geometry.location.lat;
      copyAddress.lng = address.results[0].geometry.location.lng;
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].types[0] === "country") {
          copyAddress.country = arr[i].short_name;
          copyAddress.error = false;
          break;
        }
      }
      copyAddress.error = false;
      this.setState({ address: copyAddress }, () => {});
    }
  };
  createLookArrforCreateGroup = () => {
    let data = this.state.selectedVehicles;
    let arr = [];
    for (let key in data) {
      arr.push(data[key].lookupId);
    }
    return arr;
  };
  creatObjForServerHandler = () => {
    if (this.props.mode == CREATE_MODE) {
      return {
        name: this.state.createGroup.groupName.value.trim(),
        accountLookupId: Controller.getAccountId(),
        vehicles: this.createLookArrforCreateGroup(),
        location: {
          address: this.state.address.address,
          country: this.state.address.country,
          latitude: this.state.address.lat.toFixed(4).toString(),
          longitude: this.state.address.lng.toFixed(4).toString(),
          postCode: "",
        },
      };
    }
  };

  onSearchChangeHandler = (event) => {
    if (event.target.value.trim() === "") {
      this.setState({
        searchVehicle: event.target.value,
        vehiclesList: JSON.parse(
          JSON.stringify(this.state.originalVehiclesList)
        ),
      });
    } else {
      this.setState({ searchVehicle: event.target.value }, () => {
        this.onClickSearchHandler();
      });
    }
  };
  validCreateGroupHandler = () => {
    let isValid = true;
    let copyGroup = { ...this.state.createGroup };
    let location = { ...this.state.address };
    if (copyGroup.groupName.value.trim() === "") {
      isValid = false;
      copyGroup.groupName.error = true;
      isValid = false;
    }
    if (location.address === "") {
      location.error = true;
      isValid = false;
    }
    this.setState({ createGroup: copyGroup, address: location });
    return isValid;
  };
  onChangeSearchHandler = (event) => {
    if (event.target.value.trim() === "") {
      this.setState({
        searchVehicle: event.target.value,
        vehiclesList: this.searchListHandler(),
      });
    } else {
      this.setState({ searchVehicle: event.target.value }, () => {
        this.onClickSearchHandler();
      });
    }
  };
  onKeyDownHandler = (e) => {
    if (e.key === "Enter") {
      this.onClickSearchHandler();
    }
  };
  searchListHandler = () => {
    let list = JSON.parse(JSON.stringify(this.state.originalVehiclesList));
    let temp = {};
    Object.values(list).map((item) => {
      if (this.state.selectedVehicles[item.vin] == undefined)
        return (temp[item.vin] = { ...item });
    });
    return temp;
  };
  onClickClearHandler = () => {
    this.setState({
      searchVehicle: "",
      vehiclesList: this.searchListHandler(),
    });
  };
  fireSwalHandler = (title, text, type) => {
    Swal.fire({
      title: title,
      text: text,
      type: type,
    }).then((res) => {
      if (type === "success") {
        this.props.reloadPage();
      }
    });
  };
  checkRespondServerHandler = (res) => {
    if (res.errors !== undefined) {
      this.fireSwalHandler(
        "Warning",
        res.errors[0].message.replace(/_/g, " "),
        "warning"
      );
      this.setState({ loading: false });
    } else {
      this.fireSwalHandler(
        "Success",
        this.props.mode === EDIT_MODE
          ? "Group was updated"
          : "Group was created",
        "success"
      );
    }
  };
  createArrResponsHandler = () => {
    let remove = [];
    let assign = [];
    let org_select = { ...this.state.originalVehiclesSelected };
    let org_available = { ...this.state.originalVehiclesList };
    let selectedList = { ...this.state.selectedVehicles };
    for (let key in org_available) {
      if (org_select[key] !== undefined && selectedList[key] === undefined) {
        remove.push(org_available[key].lookupId);
      }
    }
    for (let key in selectedList) {
      if (org_select[key] == undefined) {
        assign.push(selectedList[key].lookupId);
      }
    }
    return {
      remove: remove,
      assign: assign,
    };
  };
  creatEditObjHandler = () => {
    let data = this.createArrResponsHandler();
    return {
      accountLookupId: Controller.getAccountId(),
      updated: data.assign,
      removed: data.remove,
      name: this.state.createGroup.groupName.value,
      location: {
        address: this.state.address.address,
        country: this.state.address.country,
        longitude: this.state.address.lng,
        latitude: this.state.address.lat,
        postCode: "",
      },
    };
  };
  onClickSubmitHadler = () => {
    this.setState({ loading: true }, () => {
      if (this.validCreateGroupHandler()) {
        if (this.props.mode === CREATE_MODE) {
          let data = {};
          data.group = this.creatObjForServerHandler();
          httpRequest(createGroup, data)
            .then((res) => {
              this.checkRespondServerHandler(res);
            })
            .catch((err) => {});
        } else {
          let obj = {};
          obj.group = this.creatEditObjHandler();
          obj.lookupId = this.props.data.lookupId;
          httpRequest(updateGroup, obj)
            .then((res) => {
              this.checkRespondServerHandler(res);
            })
            .catch((err) => {});
        }
      } else {
        this.setState({ loading: false });
      }
    });
  };
  render() {
    return (
      <div className={styles.CreateGroups}>
        <h3 className={styles.header}>
          <span className={styles.headerIcon}>
            <Icon defination={{ prefix: "fal", iconName: "user" }} />
          </span>
          {this.props.mode} Group
        </h3>
        <div className={styles.breakLine}></div>
        <p
          className={styles.stickError}
          style={{
            visibility: !this.state.isValidForm ? "visible" : "hidden",
          }}
        >
          Please enter missing information
        </p>
        <div
          className={styles.infoWrapper}
          style={{ opacity: this.state.loading ? 0.6 : 1 }}
        >
          {/* <div className={styles.formWrapper}> */}
            <div className={styles.form}>
              {this.buildInputsElementsHandler()}
            {/* </div> */}
          </div>
          <div className={styles.categoryWrapper}>
            {this.props.mode !== VIEW_MODE && (
              <div className={styles.listWrapper}>
                <SearchBox
                  name="searchVehicles"
                  title={`Search Vehicles (${
                    Object.values(this.state.vehiclesList).length
                  })`}
                  placeholder="Search Vehicles"
                  errmsg={""}
                  change={(e) => this.onSearchChangeHandler(e)}
                  click={this.onClickSearchHandler}
                  value={this.state.searchVehicle}
                  onKeyDown={this.onKeyDownHandler}
                  clickClear={this.onClickClearHandler}
                />

                <div className={styles.itemsWrapper} id="vechile-list">
                  {Object.values(this.state.vehiclesList).length == 0 && (
                    <p className={styles.noDataMSg}>No Vehicles</p>
                  )}
                  {Object.values(this.state.vehiclesList).map((item, index) => {
                    return (
                      <div
                        id={item.id}
                        key={index}
                        className={styles.item}
                        style={{ opacity: 1 }}
                      >
                        <div className={styles.itemInfoWrapper}>
                          <div className={styles.dataWrapper}>
                            <span className={styles.dataLabel}>
                              License No.:{" "}
                            </span>
                            <span>
                              {item.licensePlate != null
                                ? item.licensePlate.number
                                : "N/A"}
                            </span>
                          </div>
                          <div className={styles.dataWrapper}>
                            <span className={styles.dataLabel}>Name: </span>
                            <span>{item.name}</span>
                          </div>
                          <div className={styles.dataWrapper}>
                            <span className={styles.dataLabel}>Type: </span>
                            <span>{item.vClass.displayName.toLowerCase()=='other'? OTHER_ALIAS_NAME:item.vClass.displayName}</span>
                          </div>
                        </div>
                        {this.props.mode !== VIEW_MODE && (
                          <div
                            className={styles.addIconWrapper}
                            onClick={() => this.onClickAddItem(item)}
                          >
                            <span className={styles.addIcon}>
                              <Icon
                                defination={{ prefix: "fal", iconName: "plus" }}
                              />
                            </span>
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            )}

            <div className={styles.listWrapper}>
              <p className={styles.subtitle}>
                Selected Vehicles (
                {Object.values(this.state.selectedVehicles).length})
              </p>
              <div className={styles.itemsWrapper}>
                {Object.values(this.state.selectedVehicles).length == 0 && (
                  <p className={styles.noDataMSg}>No Selected Vehicles</p>
                )}
                {Object.values(this.state.selectedVehicles).map(
                  (item, index) => {
                    return (
                      <div
                        id={item.id}
                        key={index}
                        className={styles.item}
                        style={{ opacity: 1 }}
                      >
                        <div className={styles.itemInfoWrapper}>
                          <div className={styles.dataWrapper}>
                            <span className={styles.dataLabel}>
                              License No.:
                            </span>
                            <span>
                              {item.licensePlate != null
                                ? item.licensePlate.number
                                : "N/A"}
                            </span>
                          </div>
                          <div className={styles.dataWrapper}>
                            <span className={styles.dataLabel}>Name: </span>
                            <span>{item.name}</span>
                          </div>
                          <div className={styles.dataWrapper}>
                            <span className={styles.dataLabel}>Type: </span>
                            <span>{item.vClass.displayName.toLowerCase()=='other'? OTHER_ALIAS_NAME:item.vClass.displayName}</span>
                          </div>
                        </div>
                        {this.props.mode !== VIEW_MODE && (
                          <div
                            className={styles.addIconWrapper}
                            onClick={() => this.onClickRemoveItem(item)}
                          >
                            <span className={styles.addIcon}>
                              <Icon
                                defination={{
                                  prefix: "fal",
                                  iconName: "minus",
                                }}
                              />
                            </span>
                          </div>
                        )}
                      </div>
                    );
                  }
                )}
              </div>
            </div>
          </div>
        </div>
        <div className={styles.btnWrapper}>
          {this.props.mode == VIEW_MODE ? (
            <Button
              click={this.props.close}
              overrideStyles={{
                marginRight: "1rem",
              }}
              text={CLOSE}
            />
          ) : (
            <>
              <FormButtons
                text={CANCEL}
                click={this.props.close}
                disabled={this.state.loading}
                overrideStyles={{ width: "14.2rem" }}
              />
              <FormButtons
                text={SUBMIT}
                click={this.onClickSubmitHadler}
                disabled={this.state.loading}
                overrideStyles={{ width: "14.2rem" }}
              />
            </>
          )}
        </div>
        <div
          className={styles.spinnerWrapper}
          style={{ display: this.state.loading ? "block" : "none" }}
        >
          <SpinnerLoader />
        </div>
      </div>
    );
  }
}
