import React, { Component, Fragment } from "react";
import styles from "./Header.module.scss";

import HeaderMobileLogo from "../../assets/mobile/hiWayLogoGrey.svg";
import Icon from "../../Component/Icon/Icon";
import * as route from "../../Util/routes";
import {
  removeAuthToken,
  getVersionNo,
} from "../../Store/localStorageManager/localStorageManager";
import { withRouter } from "react-router-dom";
import HiwayLogo from "../../assets/headerHiwayLogo.svg";
import Controller from "../../Controller";
import {
  USERS_OVERLAY,
  UPDATE_ACCOUNT_MODEL,
  GROUPS_OVERLAY,
  VEHICLES_OVERLAY,
  UPDATE_VERSION,
  CLEAR_HISTORY,
} from "../../Util/constants";
class Header extends Component {
  timer = null;
  systemVerionNumber = getVersionNo();
  state = {
    value: "",
    openMenu: true,
    showSearch: false,
    userName: "",
    lastName: "",
    avatar: "",
    email: "",

    expandSide: {
      terminal: false,
      user: false,
      vehicle: false,
      group: false,
    },
  };
  componentDidMount() {
    let user = Controller.getBasicInfo();
    if (user == null) return;
  }

  onChangeHandler = (event) => {
    clearTimeout(this.timer);
    this.setState({ value: event.target.value }, () => {
      if (this.state.value.trim().length >= 1) {
        this.timer = setTimeout(() => {
          this.props.setSearchKey(this.state.value.trim());
        }, 800);
      } else if (this.state.value == "") {
        this.props.setSearchKey("");
      }
    });
  };
  onKeyDownHandler = (e) => {
    if (e.key === "Enter") {
      this.props.setSearchKey(this.state.value.trim());
    }
  };
  onFocusHandler = () => {
    if (this.state.value !== "" && this.props.totalResults > 0) {
      this.props.openSearchList();
    }
  };
  onClickOpenMenuHandler = () => {
    this.setState({ openMenu: !this.state.openMenu });
  };
  onClickSearchMobileHandler = () => {
    this.setState({ showSearch: !this.state.showSearch });
  };
  logoutHandler = () => {
    if (this.props.activeLink === UPDATE_VERSION) return;
    removeAuthToken();
    this.props.setAuthuntication();
    this.props.history.push(route.LOGIN);
  };
  getAvatarImage = () => {
    if (
      this.props.accountDetails.avatar === "" ||
      this.props.accountDetails.avatar == undefined ||
      this.props.accountDetails.avatar == null
    ) {
      return (
        <span className={styles.iconAvatar}>
          <Icon defination={{ prefix: "far", iconName: "user-circle" }} />
        </span>
      );
    } else {
      return (
        <>
          <img
            src={this.props.accountDetails.avatar}
            className={styles.imgAvatar}
            alt="User"
          />
        </>
      );
    }
  };
  onClickClearHistoryHandler = () => {
    this.props.openModel(CLEAR_HISTORY);
  };
  onClickExpandHandler = (type) => {
    let copyExapnd = { ...this.state.expandSide };
    copyExapnd[type] = !copyExapnd[type];
    this.setState({ expandSide: copyExapnd });
  };

  openModelHandler = (type) => {
    this.props.openModel(type);
    this.props.closeHeader();
  };

  clickOnLogHandler = () => {
    this.props.clickOnLogo();
  };
  render() {
    return (
      <Fragment>
        <div
          className={styles.Headers}
          style={{ display: this.props.showHeader ? "none" : "" }}
        >
          <div className={styles.logowrapper}>
            <span className={styles.menuIcon} onClick={this.props.closeHeader}>
              {this.props.headerMenu ? (
                <Icon defination={{ prefix: "fal", iconName: "times" }} />
              ) : (
                <Icon defination={{ prefix: "fas", iconName: "bars" }} />
              )}
            </span>
            <span className={styles.logoDesktop}>
              <img
                onClick={this.clickOnLogHandler}
                src={HiwayLogo}
                className={styles.imgLogo}
                alt="hiway-header-logo"
              />
              <span
                className={styles.versionNumber}
                // onClick={this.props.onClickshowRelaeseNote}
              >
                {this.systemVerionNumber!=null ?this.systemVerionNumber:""}
              </span>
            </span>
            <div className={styles.searchWrapperDesk}>
              <input
                className={styles.inputHeaderSearchDesktop}
                value={this.state.value}
                placeholder="Search vehicle"
                type="search"
                onChange={(e) => this.onChangeHandler(e)}
                onKeyDown={(e) => this.onKeyDownHandler(e)}
                onFocus={this.onFocusHandler}
                autoComplete="off"
                onBlur={this.onBlurHandler}
                name="globalSearch"
                disabled={this.props.disableHeaderSearch}
              />
              <span className={styles.IconSearcDesk}>
                <Icon defination={{ prefix: "far", iconName: "search" }} />
              </span>
              <div className={styles.dropdownMenu}>
                <span className={styles.logoutIcon}>
                  <strong>
                    {this.props.accountDetails.name &&
                      this.props.accountDetails.name.charAt(0)}
                  </strong>
                </span>
                <ul>
                  <li>
                    <ul
                      style={{ height: Controller.getIsUser() ? "12rem" : "" }}
                    >
                      <div className={styles.generalInfo}>
                        {this.getAvatarImage()}
                        <div className={styles.infoUser}>
                          <span>
                            {this.props.accountDetails.name !== "" &&
                            this.props.accountDetails.name !== undefined
                              ? `${this.props.accountDetails.name}`
                              : "N/A"}
                          </span>
                          <span className={styles.accountEmail}>
                            {this.props.accountDetails.email}
                          </span>
                        </div>
                      </div>
                      <hr className={styles.breakLine} />
                      <div className={styles.actionLinkWrapper}>
                        <div
                          className={styles.myAccount}
                          onClick={() =>
                            this.props.openModel(UPDATE_ACCOUNT_MODEL)
                          }
                        >
                          My Account
                        </div>
                        {!Controller.getIsUser() && (
                          <span
                            className={styles.item}
                            onClick={this.onClickClearHistoryHandler}
                          >
                            {CLEAR_HISTORY}
                          </span>
                        )}
                        <span
                          className={styles.item}
                          onClick={this.logoutHandler}
                        >
                          Logout
                        </span>
                      </div>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>

            <div className={styles.mobileLogoWrapper}>
              {!this.state.showSearch ? (
                <span className={styles.logoMobile}>
                  <img
                    src={HeaderMobileLogo}
                    onClick={this.clickOnLogHandler}
                    className={styles.imgLogo}
                    alt="one-track-header-logo"
                  />
                  <span
                    className={styles.versionNumber}
                    // onClick={this.props.onClickshowRelaeseNote}
                  >
                    {this.systemVerionNumber!=null?this.systemVerionNumber:""}
                  </span>
                </span>
              ) : (
                <div className={styles.inputMobileSearchWrapper}>
                  <input
                    value={this.state.value}
                    className={styles.inputMobileSearch}
                    type="search"
                    style={{ position: this.state.position }}
                    autoComplete="off"
                    placeholder="Search vehicle"
                    onFocus={this.onFocusHandler}
                    onChange={(e) => this.onChangeHandler(e)}
                  />
                </div>
              )}
            </div>
            <span
              className={styles.searchIcon}
              onClick={this.onClickSearchMobileHandler}
            >
              <Icon defination={{ prefix: "far", iconName: "search" }} />
            </span>
          </div>
        </div>
        <div
          className={
            this.props.headerMenu
              ? styles.menuHeaderWrapper
              : styles.menuHeaderWrapperClose
          }
        >
          <span
            className={styles.headerMenuTitle}
            style={{ visibility: this.props.headerMenu ? "visible" : "hidden" }}
            onClick={this.props.closeHeader}
          >
            <span className={styles.textTilte}>
              <Icon defination={{ prefix: "fal", iconName: "cars" }} />{" "}
              <span className={styles.textMenu}>MENU</span>
            </span>
          </span>

          <ul
            className={styles.mainLinksWrapper}
            style={{ visibility: this.props.headerMenu ? "visible" : "hidden" }}
          >
            <li
              className={
                this.props.activeLink == VEHICLES_OVERLAY
                  ? `${styles.singleLink} ${styles.activeLink}`
                  : styles.singleLink
              }
              onClick={() => this.openModelHandler(VEHICLES_OVERLAY)}
            >
              Vehicles
            </li>
            <li
              onClick={() => this.openModelHandler(GROUPS_OVERLAY)}
              className={
                this.props.activeLink == GROUPS_OVERLAY
                  ? `${styles.singleLink} ${styles.activeLink}`
                  : styles.singleLink
              }
            >
              Groups
            </li>
            <li
              className={
                this.props.activeLink == USERS_OVERLAY
                  ? `${styles.singleLink} ${styles.activeLink}`
                  : styles.singleLink
              }
              onClick={() => this.openModelHandler(USERS_OVERLAY)}
            >
              Users
            </li>

            <li
              className={styles.singleLink}
              onClick={() => this.openModelHandler(UPDATE_ACCOUNT_MODEL)}
              style={{ display: this.props.phoneMode ? "block" : "none" }}
            >
              My Account
            </li>
            <li
              className={styles.singleLink}
              onClick={this.onClickClearHistoryHandler}
              style={{ display: this.props.phoneMode ? "block" : "none" }}
            >
              {CLEAR_HISTORY}
            </li>
            <li className={styles.singleLink} onClick={this.logoutHandler}>
              Logout
            </li>
          </ul>
        </div>
      </Fragment>
    );
  }
}
export default withRouter(Header);
