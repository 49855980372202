import React, { Component } from "react";
import MapButton from "../MapButton/MapButton";
import styles from "./Controls.module.scss";
import IconChatNotification from "../../../assets/icon-chat-notification.svg";
import IconOutOfZone from "../../../assets/icon-out-of-zone-notification.svg";
import IconSOS from "../../../assets/icon-sos-notification.svg";
import VehicleFilters from "../VehicleFilters/VehicleFilters";
import CenterIcon from "../../../assets/center.svg";
import Swal from "sweetalert2";
import moment from "moment";
import KEEP_ALIVE_ON from "../../../assets/keepaliveon.svg";
import KEEP_ALIVE_OFF from "../../../assets/keepaliveoff.svg";
import SEND_ICON from "../../../assets/sendIcon.svg";
import LOCATION_ICON from "../../../assets/locationIcon.svg";
import TOGGLE_ON_ICON from "../../../assets/toggleOnIcon.svg";
import TOGGLE_OFF_ICON from "../../../assets/toggleOffIcon.svg";
import EYE_SLASH_ICON from "../../../assets/eyeSlashIcon.svg";
import EYE_ICON from "../../../assets/eye.svg";
import DOWNLOAD_ICON from "../../../assets/downloadIcon.svg";
import ART_PALETTE_ICON from "../../../assets/artPaletteIcon.svg";
import CAR_ICON from "../../../assets/carIcon.svg";
import EDIT_ICON from "../../../assets/editIcon.svg";
import TRASH_ICON from "../../../assets/trashIcon.svg";
import OFFLINE_ICON_LEGEND from "../../../assets/offline-legend.svg";
import ONLINE_ICON_LEGEND from "../../../assets/online-legend.svg";
import Controller from "../../../Controller";
import {
  MAX_LENGTH_MESSAGE,
  LICENSE_NO,
  EDIT_FENCING,
  SHOW_FENCING,
  UPLOAD_KLM,
  DELETE_FENCING,
  DRAW_FENCING,
  CIRCLE,
  POLYLINE,
  RECTANGLE,
  BROADCAST_CONTIANER,
  DEFALUT_BORDER_COLOR,
  DEFALUT_FILL_COLOR,
  DRAW_FENCING_RECTANGLE,
  DRAW_FENCING_CIRCEL,
  DRAW_FENCING_POINTS,
  NOTIFICATION_CONTAINER,
  STOP,
  START,
  HIDE_FENCING,
  RELOAD_FENCING_LIST,
  UPDATE_FENCING_LIST,
  GRAPHQL_ENDPOINT,
  OVERVIEW,
  TERMINAL_ID,
  TYPE,
  VEHICLES_OF_FENCE,
  EDIT_FENCE_DETAILS,
  DOWNLOAD_FENCING,
  TOGGLE_FENCE_ACTIVATION,
  OPEN_FENCING_MENU,
  CLEAR_ALL_ACTION_BOXES,
  VEHICLE_TYPES,
  LENGHT_MESSAGE_SIZE,
  LENGHT_MESSAGE_SIZE_OVER_MAX,
} from "../../../Util/constants";
import Icon from "../../Icon/Icon";
import Input from "../../Input/Input";
import UploadFile from "../../UploadFile/UploadFile";
import {
  createFencing,
  updateFencing,
  deleteFencing,
  userNotifications,
  markVehicleAsRead,
  createBroadcastMessage,
  broadcastMessages,
  getWsAuthToken,
  importFencing,
  toggleFenceActivation,
} from "../../../RequestManager/GqlBuilderAccount";
import { httpRequest } from "../../../RequestManager/HttpRequest";
import {
  converStringToJson,
  randomID,
  trimStringAtEnd,
} from "../../../Util/globalFunc";
import SOS from "../../../assets/sos.svg";
import ALERT from "../../../assets/alertOutOfVencing.svg";
import NO_ALERT_ZONE from "../../../assets/no-alert-zone.svg";
import NOTIFICATION from "../../../assets/notification.svg";
import NO_SOS_SENT from "../../../assets/no-sos.svg";
import validator from "validator";
const INNER_TEXT_BOARDCAST = "inner-text-broadcast";
const SOS_NOTIFICATION = "sosNotifications";
const FENCE_NOTIFICATION = "fenceNotifications";
const CHAT_NOTIFICCATION = "chatNotifications";
const ALL_COUNT_NOTIFICATION = "notificationsCount";
class Controls extends Component {
  preview = "Type Message";
  messageError = LENGHT_MESSAGE_SIZE;
  lookupIdChat = {};
  notificationType = null;
  tap = 0;
  indexTap = 0;
  itemTap = {};
  tapTimer = null;
  timer_map_notification = null;
  timer_map_broadcast = null;
  timer = null;
  searchTimer = null;
  scrollTop = 0;
  container = React.createRef();
  elem = null;
  minTimeoutNotification = 500;
  minTimeoutBroadcast = 500;
  st = 0;
  tempHiddenFencing = null;
  unreadNotifications = {
    [SOS_NOTIFICATION]: Controller.getUnreadSosCountNotification(),
    [FENCE_NOTIFICATION]: Controller.getUnreadFencingCountNotification(),
    [CHAT_NOTIFICCATION]: Controller.getUnreadChatCountNotification(),
    unreadNotifications: Controller.getUnreadCountNotification(),
  };
  counters = {
    [SOS_NOTIFICATION]: null,
    [FENCE_NOTIFICATION]: null,
    [CHAT_NOTIFICCATION]: null,
    [ALL_COUNT_NOTIFICATION]: null,
  };
  offsetsNotifications = {
    [SOS_NOTIFICATION]: 1,
    [FENCE_NOTIFICATION]: 1,
    [CHAT_NOTIFICCATION]: 1,
  };
  textArea = React.createRef();
  colorStatus = {
    0: "#000000",
    1: "#00b6c9",
    2: "#000000",
    3: "#000000",
  };
  notificationTypedecode = {
    [SOS_NOTIFICATION]: "SOS",
    [FENCE_NOTIFICATION]: "OUT_OF_FENCE",
    [CHAT_NOTIFICCATION]: "NEW_MESSAGE",
  };
  iconStatus = {
    0: "check", ///sent
    1: "check-double", ///seen
    2: "check-double", //recived
    3: "times", //fail
  };
  titleNotificationandImg = {
    [SOS_NOTIFICATION]: {
      img: IconSOS,
      title: "SOS Notification",
    },
    [FENCE_NOTIFICATION]: {
      title: "SOS Notification",
      img: IconOutOfZone,
    },
    [CHAT_NOTIFICCATION]: {
      title: "Chat Notification",
      img: IconChatNotification,
    },
  };
  MAX_SIZE = Controller.getMsgChatLenght();
  wsNotificationToken = Controller.getWsTokenNotification();
  wsBroadcastToken = Controller.getWsTokenBroardcast();
  webSocketNotification = null;
  webSocketBroardcast = null;
  count = null;
  offset = 1;
  limit = 10;
  offsetBroadcast = 1;
  countBroadcast = null;
  shouldScrole = false;
  isNotificationInit = false;
  shouldScroleBroadcast = false;
  isBroadcastInit = false;
  isIphone = false;
  iconNotifiction = {
    2: SOS,
    3: ALERT,
    4: NOTIFICATION,
  };

  circle = {
    center: {
      latitude: "",
      longitude: "",
    },
    radius: "",
  };

  rectangle = {
    north: "",
    south: "",
    east: "",
    west: "",
  };
  defaultFencingObj = [
    {
      name: "",
      createdAt: "",
      updatedAt: "",
      color: DEFALUT_FILL_COLOR,
      drawn: false,
      coordinates: [],
    },
  ];
  _refId = "";
  //lookupIdFencing = "";
  _drawFencingUuid = null;
  // msgFullValue = "";
  state = {
    menuNotification: false,
    viewNotification: SOS_NOTIFICATION,
    [SOS_NOTIFICATION]: [],
    [CHAT_NOTIFICCATION]: [],
    [FENCE_NOTIFICATION]: [],
    overviewArray: [],
    originalOverviewList: [],
    agrOverview: {
      onlineCount: 0,
      offlineCount: 0,
      aliveCount: 0,
      deadCount: 0,
      sosCount: 0,
    },

    showOverview: false,
    updated: {},
    value: "",
    // unreadNotificationsCount: Controller.getUnreadCountNotification(),
    notifications: [],
    broadcasts: [],
    broadcastStatus: {},
    showBoardcasts: false,
    showNotification: false,
    typeOfDraw: POLYLINE,
    showFencing: false,
    //showClusterVehicles: false,
    details: {},
    showCords: false,
    showFilters: false,
    showVehicleDetails: true,
    groupFilters: [],
    excludeList: [],
    showLicense: false,
    disableControls: false,
    action: "",
    drawFencingPoints: null,
    loading: false,
    deletedPoints: {},
    // previewKml: false,
    //showFencingListOnMap: true, //boolean to show fencing on map or not by default its show
    uploadFile: {
      value: "",
      error: false,
      errMsg: "select file to upload",
      label: "",
      placeholder: "Select KML",
      fileName: "",
    },
    fileName: {
      value: "",
      error: false,
      errMsg: "Invalid.Enter a name",
      label: "Permitted Zone name",
      placeholder: "Enter Permitted Zone name",
      name: "fileName",
    },
    fillColor: {
      value: DEFALUT_FILL_COLOR,
      error: false,
      errMsg: "select file to upload",
      label: "Fill color",
      placeholder: "Enter Permitted Zone name",
      name: "fillColor",
    },

    lat: {
      value: "",
      error: false,
      errMsg: "Invalid lat or long",
      label: "Latitude",
      placeholder: "Enter latitude",
      name: "lat",
    },
    lng: {
      value: "",
      error: false,
      errMsg: "",
      label: "Longitude",
      placeholder: "Enter longitude ",
      name: "lng",
    },
    currentFencingList: [],
    hasfencing: Controller.checkHasFencing(),
    vehiclesInCluster: [],
    showClusterVehicles: false,
    openedFencingUuId: null,
    loadingFenceVehicles: false,
    vehiclesOfFence: [],
    hiddenFencingList: [],
    searchKey: "",
  };

  componentDidMount() {
    if (this.props.refFiltersControls !== undefined) {
      this.props.refFiltersControls(this.setDataHandler);
    }
    if (this.props.refDisableControls !== undefined) {
      this.props.refDisableControls(this.disableControlsHandler);
    }
    if (this.props.refActionControls !== undefined) {
      this.props.refActionControls(this.setActionsDataHandler);
    }
    if (this.props.updateOverView !== undefined) {
      this.props.updateOverView(this.updateOverviewArrayHandler);
    }
    if (this.props.updateVehiclesInCluster !== undefined) {
      this.props.updateVehiclesInCluster(
        this.updateVehiclesInClusterArrayHandler
      );
    }
    if (this.props.refFencingDataControl != undefined) {
      this.props.refFencingDataControl(this.fenceDataHandler);
    }
    var userAgent = navigator.userAgent;
    this.isIphone = /iPhone/.test(userAgent) && !window.MSStream;
    if (this.props.phoneMode && !this.isIphone) {
      window.addEventListener(
        "resize",
        (e) => {
          if (window.innerHeight > 600) {
            this.onBlurTextAreaHandler();
          } else {
            this.onFouceTextAreaHandler();
          }
        },
        false
      );
    }
    this.connectToWsNotificationTokenHandler();
    this.connectToWsBroardcastTokenHandler();
  }

  disableControlsHandler = (data) => {
    this.setState({ disableControls: data }, () => {});
  };

  setDataHandler = (data) => {
    this.setState({ groupFilters: data });
  };
  ////////////////////////////Fence ///////////////////////////////////////////////////////////
  fenceDataHandler = (data, action) => {
    if (action == CLEAR_ALL_ACTION_BOXES) {
      this.setState({
        showFencing: false,
        openedFencingUuId: "",
        hiddenFencingList: [],
        currentFencingList: [],
        showFilters: false,
        showBoardcasts: false,
        value: "",
        showOverview: false,
        showClusterVehicles: false,
        action: "",
        menuNotification: false,
        showNotification: false,
      });
    } else if (action == OPEN_FENCING_MENU) {
      let currentFencingList = Object.values(
        this.props.getAccountFencingList()
      );
      let hiddenFencingList = this.getInactiveFencing(currentFencingList);
      this.setState(
        {
          showFencing: true,
          openedFencingUuId: data.uuid,
          hiddenFencingList,
          currentFencingList,
          showFilters: false,
          showBoardcasts: false,
          value: "",
          showOverview: false,
          showClusterVehicles: false,
          action: "",
          menuNotification: false,
          showNotification: false,
        },
        () => {
          var elmnt = document.getElementById(data.uuid); //uuid
          if (elmnt !== undefined && elmnt !== null) {
            elmnt.scrollIntoView(true);
          }
        }
      );
    } else if (action == VEHICLES_OF_FENCE) {
      this.setState({ vehiclesOfFence: data, loadingFenceVehicles: false });
    }
  };
  openFenceActions = (uuid = "") => {
    this.setState({ openedFencingUuId: uuid, action: "" }, () => {
      this.props.highlightSingleFence(uuid);
    });
  };
  /////////////////////////////////////Notification////////////////////////////////////////////
  openNotificationMenuhandler = () => {
    this.setState({ menuNotification: !this.state.menuNotification });
  };
  onClickshowNotificationHandler = (type, close = null) => {
    if (this.state.loading) return;
    this.setState(
      {
        showNotification: close == null ? true : false,
        showFencing: false,
        viewNotification: type,
        showFilters: false,
        showBoardcasts: false,
        value: "",
        showOverview: false,
        showClusterVehicles: false,
        action: "",
        menuNotification: false,
      },
      () => {
        if (!this.isNotificationInit && this.state.showNotification) {
          this.isNotificationInit = true;
          this.elem = document.getElementById(NOTIFICATION_CONTAINER);
         
          this.getHistoryNotificationHandler(true);
        }
      }
    );
  };
  onScrollLoadNotificationsHandler = () => {
    if (this.elem == null) {
      this.elem = document.getElementById(NOTIFICATION_CONTAINER);
    
    }

    if (
      Math.abs(
        this.elem.scrollHeight - this.elem.clientHeight - this.elem.scrollTop
      ) < 1
    ) {
      if (
        this.counters[this.state.viewNotification] !== null &&
        this.counters[this.state.viewNotification] !== 0 &&
        this.offsetsNotifications[this.state.viewNotification] + this.limit >=
          this.counters[this.state.viewNotification] &&
        !this.state.loading
      )
        return;
      this.setState({ loading: true }, () => {
        if (this.timer_map_notification != null) {
          clearTimeout(this.timer_map_notification);
        }
        this.getHistoryNotificationHandler();
      });
    }
  };

  onClickNotificationHandler = (item, index) => {
    if (this.state.updated[item.lookupId] == undefined) {
      let copyNotifications = [...this.state[this.state.viewNotification]];
      if (!copyNotifications[index].read) {
        copyNotifications[index].read = true;
        this.unreadNotifications.unreadNotifications =
          this.unreadNotifications.unreadNotifications - 1;
        this.unreadNotifications[this.state.viewNotification] =
          this.unreadNotifications[this.state.viewNotification] - 1;
        this.setState(
          { loading: true, [this.state.viewNotification]: copyNotifications },
          () => {
            this.props.contralHttpInterval(STOP);
            httpRequest(markVehicleAsRead, {
              notificationLookupIds: [item.lookupId],
            })
              .then((res) => {
                this.setState(
                  {
                    loading: false,
                    showNotification: false,
                    menuNotification: false,
                  },
                  () => {
                    this.props.contralHttpInterval(START);
                    this.props.mapToNotificationAlert(item);
                  }
                );
              })
              .catch((res) => {});
          }
        );
      } else {
        this.props.mapToNotificationAlert(item);
      }
    } else {
      this.props.mapToNotificationAlert(item);
    }
  };
  scrolleToBottomNotificationHandler = (element) => {
    let elmnt = document.getElementById(element);
    if (elmnt != null && element == BROADCAST_CONTIANER) {
      elmnt.scroll({ top: elmnt.scrollHeight, behavior: "smooth" });
    } else if (elmnt !== null && element == NOTIFICATION_CONTAINER) {
      elmnt.scroll({ top: 0, behavior: "smooth" });
    }
  };
  connectToWsNotificationTokenHandler = () => {
    var webSocket = new WebSocket(GRAPHQL_ENDPOINT, "graphql-ws");
    webSocket.onopen = () => {
      this.webSocketNotification = webSocket;
      this.props.updateWebSocket(
        this.webSocketNotification,
        NOTIFICATION_CONTAINER
      );
      webSocket.send(
        JSON.stringify({
          type: START,
          id: 1,
          payload: {
            query:
              "subscription ( $wsAuthToken: String) {\n\tuserNotificationsSubscription(wsAuthToken: $wsAuthToken\n\t) {\n\t\tnotificationsCount\n\t\tchatUnreadNotificationsCount\nsosUnreadNotificationsCount\nfenceUnreadNotificationsCount\nunreadNotificationsCount\n\t\tupdated\nnotification {gpsFixStatus\nunreadNotificationsOfSameTypeAndVehicleForUser\n\n\t\t\tlookupId\n\t\t\tcreatedAt\n\t\t\tread\n\t\t\treadDate\n\t\t\ttitle\n\t\t\tdescription\n\t\t\tpriority\n\t\t\tnotificationType\n\t\t\tuser {\n\t\t\t\tlookupId\n\t\t\t}\n\t\t\tlongitude\nlatitude\nvehicle{lookupId\n licensePlate{\nnumber\n}\n\t\t\t\tname\n\t\t\t\tvin\n\t\t\t\tterminal {\n\t\t\t\t\tlookupId\n\t\t\t\t}\n\t\t\t\tlastValidHeading {\n\t\t\t\t\theading\n\t\t\t\t}\n\t\t\t\tlastValidPosition {\n\t\t\t\t\tlatitude\n\t\t\t\t\tlongitude\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n}\n",
            variables: {
              wsAuthToken: this.wsNotificationToken,
            },
          },
        })
      );
      webSocket.onerror = (event) => {
        this.reconnectNotificationOrbroadcastSocketHandler(
          NOTIFICATION_CONTAINER
        );
      };
      webSocket.onmessage = (event) => {
        this.setNotificationHandler(JSON.parse(event.data));
      };
    };
  };
  checkTheLookUpIdOfNotificationExistHandler = (data) => {
    let isfound = false;
    let copyNotifications = [...this.state[CHAT_NOTIFICCATION]];
    for (let i = 0; i < copyNotifications.length; i++) {
      if (
        copyNotifications[i].notificationType == 4 &&
        copyNotifications[i].vehicle.lookupId == data.vehicle.lookupId
      ) {
        copyNotifications.splice(i, 1);
        let temp = [data];
        copyNotifications = [...temp, ...copyNotifications];
        isfound = true;
        return { isfound: isfound, notifications: copyNotifications };
      }
    }
    return { isfound: isfound, notifications: copyNotifications };
  };
  setNotificationHandler = (data) => {
    if (
      data.type !== "complete" &&
      data !== null &&
      data.errors == undefined &&
      data.payload !== null &&
      data.payload.data !== null &&
      data.payload.data.userNotificationsSubscription != null &&
      data.payload.errors == undefined &&
      this.wsNotificationToken !== null
    ) {
      this.minTimeoutNotification = 500;
      let obj = data.payload.data.userNotificationsSubscription.notification;
      let updated = data.payload.data.userNotificationsSubscription.updated;
      let temp = [obj];
      this.unreadNotifications.unreadNotifications =
        data.payload.data.userNotificationsSubscription
          .unreadNotificationsCount !== null
          ? data.payload.data.userNotificationsSubscription
              .unreadNotificationsCount
          : this.unreadNotifications.unreadNotifications;
      if (!updated) {
        if (obj.notificationType == 2 || obj.notificationType == 3) {
          if (obj.notificationType == 3) {
            this.unreadNotifications[FENCE_NOTIFICATION] =
              data.payload.data.userNotificationsSubscription
                .fenceUnreadNotificationsCount !== null
                ? data.payload.data.userNotificationsSubscription
                    .fenceUnreadNotificationsCount
                : this.unreadNotifications[FENCE_NOTIFICATION];

            this.setState(
              {
                [FENCE_NOTIFICATION]: [
                  ...temp,
                  ...this.state[FENCE_NOTIFICATION],
                ],
              },
              () => {
                this.counters[FENCE_NOTIFICATION] =
                  this.counters[FENCE_NOTIFICATION] == null
                    ? 1
                    : this.counters[FENCE_NOTIFICATION] + 1;
                this.offsetsNotifications[FENCE_NOTIFICATION] =
                  this.offsetsNotifications[FENCE_NOTIFICATION] + 1;
                this.scrolleToBottomNotificationHandler(NOTIFICATION_CONTAINER);
              }
            );
          } else if (obj.notificationType == 2) {
            this.unreadNotifications[SOS_NOTIFICATION] =
              data.payload.data.userNotificationsSubscription
                .sosUnreadNotificationsCount !== null
                ? data.payload.data.userNotificationsSubscription
                    .sosUnreadNotificationsCount
                : this.unreadNotifications[SOS_NOTIFICATION];
            this.setState(
              {
                [SOS_NOTIFICATION]: [...temp, ...this.state[SOS_NOTIFICATION]],
              },
              () => {
                this.counters[SOS_NOTIFICATION] =
                  this.counters[SOS_NOTIFICATION] == null
                    ? 1
                    : this.counters[SOS_NOTIFICATION] + 1;
                this.offsetsNotifications[SOS_NOTIFICATION] =
                  this.offsetsNotifications[SOS_NOTIFICATION] + 1;
                this.scrolleToBottomNotificationHandler(NOTIFICATION_CONTAINER);
              }
            );
          }
        } else {
          this.lookupIdChat[obj.vehicle.lookupId] = { ...obj };
          let res = this.checkTheLookUpIdOfNotificationExistHandler(obj);
          this.unreadNotifications[CHAT_NOTIFICCATION] =
            data.payload.data.userNotificationsSubscription
              .chatUnreadNotificationsCount !== null
              ? data.payload.data.userNotificationsSubscription
                  .chatUnreadNotificationsCount
              : this.unreadNotifications[CHAT_NOTIFICCATION];
          if (res.isfound) {
            this.setState({
              [CHAT_NOTIFICCATION]: res.notifications,
            });
          } else {
            this.counters[CHAT_NOTIFICCATION] =
              this.counters[CHAT_NOTIFICCATION] == null
                ? 1
                : this.counters[CHAT_NOTIFICCATION] + 1;
            this.offsetsNotifications[CHAT_NOTIFICCATION] =
              this.offsetsNotifications[CHAT_NOTIFICCATION] + 1;
            this.setState(
              {
                [CHAT_NOTIFICCATION]: [
                  ...temp,
                  ...this.state[CHAT_NOTIFICCATION],
                ],
              },
              () => {
                this.scrolleToBottomNotificationHandler(NOTIFICATION_CONTAINER);
              }
            );
          }
        }
      } else {
        let copyUpdated = { ...this.state.updated };
        copyUpdated[obj.lookupId] = { ...obj };
        this.setState({
          updated: copyUpdated,
        });
      }
    } else if (data.type !== "complete" && data.payload.errors !== undefined) {
      this.reconnectNotificationOrbroadcastSocketHandler(
        NOTIFICATION_CONTAINER
      );
    }
  };
  getHistoryNotificationHandler = (isInit = null) => {
    if (
      this.counters[this.state.viewNotification] !== null &&
      this.counters[this.state.viewNotification] !== 0 &&
      isInit == null
    ) {
      this.offsetsNotifications[this.state.viewNotification] =
        this.offsetsNotifications[this.state.viewNotification] + this.limit;
      this.offset = this.offsetsNotifications[this.state.viewNotification];
      this.notificationType =
        this.notificationTypedecode[this.state.viewNotification];
    }
    if (
      this.counters[this.state.viewNotification] !== null &&
      this.counters[this.state.viewNotification] !== 0 &&
      this.offsetsNotifications[this.state.viewNotification] >=
        this.counters[this.state.viewNotification] &&
      isInit == null
    )
      return;

    this.setState({ loading: true }, () => {
      this.props.contralHttpInterval(STOP);
      httpRequest(userNotifications, {
        limit: this.limit,
        offset: this.offset,
        excludeRead: false,
        onlyLatestChat: true,
        notificationType: this.notificationType,
      })
        .then((res) => {
          this.setDataNotificationHandler(res.data, isInit);
        })
        .catch((err) => {});
    });
  };
  initDataCountNotificationhandler = (data) => {
    this.counters[SOS_NOTIFICATION] = data.sosNotificationsCount;
    this.counters[FENCE_NOTIFICATION] = data.fenceNotificationsCount;
    this.counters[CHAT_NOTIFICCATION] = data.chatNotificationsCount;
    this.counters[ALL_COUNT_NOTIFICATION] = data.notificationsCount;
  };
  checkDataChatExistInArrayHandler = (arr) => {
    if (arr == null) return [];
    if (!Object.keys(this.lookupIdChat).length) return arr;
    let arrTemp = JSON.parse(JSON.stringify(arr));
    for (let key in this.lookupIdChat) {
      for (let i = 0; i < arrTemp.length; i++) {
        if (key == arrTemp[i].vehicle.lookupId) {
          arrTemp.splice(i, 1);
          break;
        }
      }
    }

    return arrTemp;
  };
  setDataNotificationHandler = (data, isInit) => {
    let chatMessages = [];
    if (isInit) {
      this.initDataCountNotificationhandler(data.userNotifications);
      chatMessages = this.checkDataChatExistInArrayHandler(
        data.userNotifications.chatNotifications
      );
    }
    if (isInit) {
      chatMessages = [...this.state.chatNotifications, ...chatMessages];
    } else {
      chatMessages =
        data.userNotifications.chatNotifications != null
          ? [
              ...this.state.chatNotifications,
              ...data.userNotifications.chatNotifications,
            ]
          : this.state.chatNotifications;
    }
    this.setState(
      {
        chatNotifications: chatMessages,
        fenceNotifications:
          data.userNotifications.fenceNotifications != null
            ? [
                ...this.state.fenceNotifications,
                ...data.userNotifications.fenceNotifications,
              ]
            : this.state.fenceNotifications,
        sosNotifications:
          data.userNotifications.sosNotifications != null
            ? [
                ...this.state.sosNotifications,
                ...data.userNotifications.sosNotifications,
              ]
            : this.state.sosNotifications,
        loading: false,
      },
      () => {
        this.timer_map_notification = setTimeout(() => {
          this.props.contralHttpInterval(START);
        }, 1000);

        if (this.offset > 1) {
          let elm = document.getElementById(this.offset.toString());
        
          if (elm !== null) {
            elm.scrollIntoView({ behavior: "smooth" });
          }
        }
      }
    );
  };
  onWeelNotificationHandler = (event) => {
    if (this.props.phoneMode) return;
    if (this.elem == null) {
      this.elem = document.getElementById(NOTIFICATION_CONTAINER);
    }
    if (
      Math.abs(
        this.elem.scrollHeight - this.elem.clientHeight - this.elem.scrollTop <
          1
      ) &&
      this.counters[this.state.viewNotification] !== null &&
      this.counters[this.state.viewNotification] !== 0 &&
      this.offsetsNotifications[this.state.viewNotification] + this.limit <
        this.counters[this.state.viewNotification] &&
      !this.state.loading
    ) {
      this.setState({ loading: true }, () => {
        if (this.timer_map_notification !== null) {
          clearTimeout(this.timer_map_notification);
        }

        this.getHistoryNotificationHandler();
      });
    }
  };
  onTouchMoveHandler = (event) => {
    // event.preventDefault();
    if (this.elem == null) {
      this.elem = document.getElementById(NOTIFICATION_CONTAINER);
    }
    if (
      Math.abs(
        this.elem.scrollHeight - this.elem.clientHeight - this.elem.scrollTop
      ) < 1
    ) {
      if (
        this.counters[this.state.viewNotification] !== null &&
        this.counters[this.state.viewNotification] !== 0 &&
        this.offsetsNotifications[this.state.viewNotification] + this.limit <
          this.counters[this.state.viewNotification] &&
        !this.state.loading
      ) {
        clearTimeout(this.timer);
        this.setState({ loading: true }, () => {
          if (this.timer_map_notification !== null) {
            clearTimeout(this.timer_map_notification);
          }
          this.getHistoryNotificationHandler();
        });
      }
    } else if (this.state.loading) {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.onTouchMoveHandler();
      }, 100);
    }
  };
  ///////////////////////////////////////////////////Notification////////////////////////////////////////////
  ////////////////////////////////////////////////////Broadcast//////////////////////////////////////////////
  onWeelBroadcastHandler = (event) => {
    if (
      document.getElementById(BROADCAST_CONTIANER).scrollTop == 0 &&
      this.countBroadcast !== null &&
      this.countBroadcast !== 0 &&
      this.offsetBroadcast + this.limit < this.countBroadcast &&
      !this.state.loading
    ) {
      this.setState({ loading: true }, () => {
        if (this.timer_map_broadcast !== null) {
          clearTimeout(this.timer_map_broadcast);
        }
        this.getHistoryBroadcastHandler();
      });
    }
  };
  onFouceTextAreaHandler = () => {
    if (this.props.phoneMode && !this.isIphone) {
      this.textArea.current.style.top = "85%";
      this.textArea.current.style.bottom = "unset";
      this.textArea.current.style.position = "fixed";
      this.textArea.current.style.width = "75vw";
    }
  };
  onBlurTextAreaHandler = () => {
    if (this.props.phoneMode && !this.isIphone) {
      // this.textArea.current.style.width = "75vw";
      this.textArea.current.style.position = "absolute";
      this.textArea.current.style.top = "unset";
      this.textArea.current.style.bottom = "0";
      // this.textArea.current.style.width = "";
    }
  };
  onScrollLoadBroadcastHandler = () => {
    if (document.getElementById(BROADCAST_CONTIANER).scrollTop == 0) {
      if (
        this.countBroadcast !== null &&
        this.countBroadcast !== 0 &&
        this.offsetBroadcast + this.limit >= this.countBroadcast &&
        !this.state.loading
      )
        return;
      this.setState({ loading: true }, () => {
        if (this.timer_map_broadcast !== null) {
          clearTimeout(this.timer_map_broadcast);
        }
        this.getHistoryBroadcastHandler();
      });
    }
  };
  getHistoryBroadcastHandler = (isInit = null) => {
    if (
      this.countBroadcast !== null &&
      this.countBroadcast !== 0 &&
      isInit == null
    ) {
      this.offsetBroadcast = this.offsetBroadcast + this.limit;
    }
    if (
      this.countBroadcast !== null &&
      this.countBroadcast !== 0 &&
      this.offsetBroadcast >= this.countBroadcast &&
      isInit == null
    )
      return;
    this.setState({ loading: true }, () => {
      this.props.contralHttpInterval(STOP);
      httpRequest(broadcastMessages, {
        offset: this.offsetBroadcast,
        limit: this.limit,
        reverseAfterLimit: true,
      })
        .then((res) => {
          this.timer_map_broadcast = setTimeout(() => {
            this.props.contralHttpInterval(START);
          }, 1000);

          this.setBroadcastDataHandler(res.data);
        })
        .catch((err) => {});
    });
  };
  setBroadcastDataHandler = (data) => {
    this.countBroadcast = data.broadcastMessages.count;
    let objs = data.broadcastMessages.objs;
    this.setState(
      {
        broadcasts: [...objs, ...this.state.broadcasts],
        loading: false,
      },
      () => {
        if (!this.shouldScroleBroadcast) {
          this.shouldScroleBroadcast = true;
          this.scrolleToBottomNotificationHandler(BROADCAST_CONTIANER);
        } else if (this.shouldScroleBroadcast) {
          document.getElementById(this.limit.toString()).scrollIntoView();
        }
      }
    );
  };
  onClickMessageBroadcastHandler = () => {
    if (
      this.state.value == "" ||
      this.state.loading ||
      this.getSizeOfBytpeSizeOfBroadcastmessageHandler(this.state.value) >
        MAX_LENGTH_MESSAGE
    )
      return;
    else {
      this.setState({ loading: true }, () => {
        this.props.contralHttpInterval(STOP);
        httpRequest(createBroadcastMessage, { body: this.state.value })
          .then((res) => {
            if (res.errors != undefined) {
              this.fireSwalHandler(
                "info",
                res.errors[0].message.replace(/_/g, " "),
                "info"
              );
              this.onBlurTextAreaHandler();
            } else {
              this.props.contralHttpInterval(START);
              this.onBlurTextAreaHandler();
              this.setState({ value: "", loading: false });
            }
          })
          .catch((err) => {});
      });
    }
  };
  onKeyDownHandler = (event) => {
    if (event.key === "Enter" && !event.altKey) {
      event.preventDefault();
      this.onClickMessageBroadcastHandler();
    } else if (event.altKey && event.key === "Enter") {
      this.setState({ value: this.state.value + "\n" });
    }
  };
  onChangeBroadcastMsgHandler = (event) => {
    if (
      this.getSizeOfBytpeSizeOfBroadcastmessageHandler(event.target.value) >
      MAX_LENGTH_MESSAGE
    ) {
      this.messageError = LENGHT_MESSAGE_SIZE_OVER_MAX;
    } else {
      this.messageError = LENGHT_MESSAGE_SIZE;
    }
    this.setState({ value: event.target.value });
  };
  onClickshowBroadcastHandler = () => {
    this.setState(
      {
        showNotification: false,
        showFencing: false,
        showFilters: false,
        showBoardcasts: !this.state.showBoardcasts,
        value: "",
        showOverview: false,
        showClusterVehicles: false,
        action: "",
      },
      () => {
        if (!this.isBroadcastInit) {
          this.isBroadcastInit = true;
          this.getHistoryBroadcastHandler(false);
        }
        if (this.state.showBoardcasts && this.isBroadcastInit) {
          setTimeout(() => {
            this.scrolleToBottomNotificationHandler(BROADCAST_CONTIANER);
          }, 200);
        }
      }
    );
  };
  connectToWsBroardcastTokenHandler = () => {
    var webSocket = new WebSocket(GRAPHQL_ENDPOINT, "graphql-ws");
    webSocket.onopen = () => {
      this.webSocketBroardcast = webSocket;
      this.props.updateWebSocket(this.webSocketBroardcast, BROADCAST_CONTIANER);
      webSocket.send(
        JSON.stringify({
          type: START,
          id: 1,
          payload: {
            query:
              "subscription broadcastSubscription($wsAuthToken: String) {\n\tbroadcastSubscription(wsAuthToken: $wsAuthToken) {\n\t\tbroadcastObj {\n\t\t\tlookupId\n\t\t\tcreatedAt\n\t\t\tstatus\n\t\t\tbody\n\t\t}\n\t\tupdate {\n\t\t\tlookupId\n\t\t\tstatus\n\t\t\tseenAt\n\t\t\treceivedAt\n\t\t}\n\t}\n}\n",
            variables: {
              wsAuthToken: this.wsBroadcastToken,
            },
          },
        })
      );
      webSocket.onerror = (event) => {
        this.reconnectNotificationOrbroadcastSocketHandler(BROADCAST_CONTIANER);
      };
      webSocket.onmessage = (event) => {
        this.setBroadcastHandler(JSON.parse(event.data));
      };
    };
  };
  checkDateSeparatorHandler = (index, item) => {
    if (index == 0) {
      return !moment(this.state.broadcasts[index].createdAt).isSame(
        this.todayDate,
        "day"
      );
    } else {
      return !moment(item.createdAt).isSame(
        this.state.broadcasts[index - 1].createdAt,
        "day"
      );
    }
  };
  closeSocketHandler = (socket) => {
    if (socket == NOTIFICATION_CONTAINER) {
      if (this.webSocketNotification !== null) {
        this.webSocketNotification.close(1000);
        this.webSocketNotification = null;
      }
    } else if (socket == BROADCAST_CONTIANER) {
      if (this.webSocketBroardcast !== null) {
        this.webSocketBroardcast.close(1000);
        this.webSocketBroardcast = null;
      }
    }
  };
  reconnectNotificationOrbroadcastSocketHandler = (type) => {
    setTimeout(() => {
      this.closeSocketHandler(type);
      if (type == NOTIFICATION_CONTAINER) {
        if (this.minTimeoutNotification * 2 < 10000) {
          this.minTimeoutNotification = this.minTimeoutNotification * 2;
        }
      } else if (type == BROADCAST_CONTIANER) {
        if (this.minTimeoutBroadcast * 2 < 10000) {
          this.minTimeoutBroadcast = this.minTimeoutBroadcast * 2;
        }
        this.minTimeoutBroadcast = this.minTimeoutBroadcast * 2;
      }
      this.props.contralHttpInterval(STOP);
      httpRequest(getWsAuthToken, {})
        .then((res) => {
          this.props.contralHttpInterval(START);
          if (type == NOTIFICATION_CONTAINER) {
            this.wsNotificationToken = res.data.generateWsToken;
            this.connectToWsNotificationTokenHandler();
          } else if (type == BROADCAST_CONTIANER) {
            this.wsBroadcastToken = res.data.generateWsToken;
            this.connectToWsBroardcastTokenHandler();
          }
        })
        .catch((err) => {});
    }, this.minTimeoutNotification);
  };
  setBroadcastHandler = (data) => {
    if (
      data.type !== "complete" &&
      data !== null &&
      data.payload !== null &&
      data.errors == undefined &&
      data.payload.data !== null &&
      data.payload.errors == undefined &&
      this.webSocketBroardcast !== null
    ) {
      this.minTimeoutBroadcast = 500;
      let obj = data.payload.data.broadcastSubscription;
      let copyBoardcast = [...this.state.broadcasts];
      if (obj.broadcastObj !== null) {
        let temp = [obj.broadcastObj];
        this.setState({ broadcasts: [...copyBoardcast, ...temp] }, () => {
          this.offsetBroadcast = this.offsetBroadcast + 1;
          this.countBroadcast =
            this.countBroadcast == null ? 1 : this.countBroadcast + 1;
          this.scrolleToBottomNotificationHandler(BROADCAST_CONTIANER);
        });
      } else if (obj.update !== null) {
        let copystatus = { ...this.state.broadcastStatus };
        copystatus[obj.update.lookupId] = { ...obj.update };
        this.setState({ broadcastStatus: copystatus });
      }
    } else if (data.type !== "complete" && data.payload.errors !== undefined) {
      this.reconnectNotificationOrbroadcastSocketHandler(BROADCAST_CONTIANER);
    } else if (data.errors !== undefined && !Controller.getIsUser()) {
      this.fireSwalHandler(
        "info",
        data.errors[0].message.replace(/_/g, " "),
        "info"
      );
    }
  };
  //////////////////////////////////////////////////////////Broadcast/////////////////////////////////////////
  setActionsDataHandler = (info) => {
    //[key, data]
    if (info.key == CLEAR_ALL_ACTION_BOXES) {
      this.setState({
        showFencing: false,
        openedFencingUuId: "",
        hiddenFencingList: [],
        currentFencingList: [],
        showFilters: false,
        showBoardcasts: false,
        value: "",
        showOverview: false,
        showClusterVehicles: false,
        action: "",
        menuNotification: false,
        showNotification: false,
      });
    } else if (info.key == DRAW_FENCING_POINTS) {
      if (info.uuid != undefined) {
        //////WHAT is this !!!!
        this._drawFencingUuid = info.uuid;
      }

      this.setState({ [info.key]: info.data });
    } else if (info.key == DRAW_FENCING_RECTANGLE) {
      ////NEED to clear the draw
      this.rectangle = { ...info.data };
    } else if (info.key == DRAW_FENCING_CIRCEL) {
      this.circle.center.latitude = info.data.center.lat;
      this.circle.center.longitude = info.data.center.lng;
      this.circle.radius = info.data.radius;
    }
  };
  showFilters = () => {
    if (!this.state.showFilters) {
      this.props.refFiltersControls(this.setDataHandler);
    }
    this.setState(
      {
        showFilters: !this.state.showFilters,
        showFencing: false,
        showNotification: false,
        showBoardcasts: false,
        value: "",
        showOverview: false,
        showClusterVehicles: false,
        menuNotification: false,
      },
      () => {
        this.props.triggerFilter(this.state.showFilters);
      }
    );
  };
  showVehicleDetails = () => {
    this.setState({ showVehicleDetails: !this.state.showVehicleDetails });
  };
  onTouchStartHandler = (item, index) => {
    if (this.tapTimer == null) {
      this.tapTimer = setTimeout(() => {
        this.tapTimer = null;
        if (this.tap >= 2) {
          let obj = { ...this.itemTap };
          this.onClickNotificationHandler(obj, this.indexTap);
          this.tap = 0;
          this.itemTap = {};
          this.indexTap = 0;
        } else {
          this.tap = 0;
          this.itemTap = {};
          this.indexTap = 0;
        }
      }, 300);
    }
    if (
      Object.keys(this.itemTap).length &&
      this.itemTap.lookupId == item.lookupId
    ) {
      this.tap = this.tap + 1;
      this.indexTap = index;
    } else {
      this.itemTap = item;
      this.tap = 1;
      this.indexTap = index;
    }
  };
  //filter check actions
  ischecked = (item) => {
    const index = this.state.excludeList.indexOf(item.lookupId);
    if (index > -1) return false;
    else return true;
  };
  onClickShowDisplayCordHandler = () => {
    this.setState({ showCords: !this.state.showCords });
  };
  fireSwalHandler = (title, text, type, reloadFencingList = false) => {
    Swal.fire({
      title: title,
      text: text,
      type: type,
      confirmButtonText: "Done",
    }).then((results) => {
      let hiddenFencingList = [...this.state.hiddenFencingList];

      if (type === "success") {
        if (this.tempHiddenFencing != null) {
          let index = hiddenFencingList.indexOf(this.tempHiddenFencing.uuid);
          if (this.tempHiddenFencing.status) {
            if (index != -1) hiddenFencingList.splice(index, 1);
          } else {
            if (index == -1)
              hiddenFencingList.push(this.tempHiddenFencing.uuid);
          }
          this.tempHiddenFencing = null;
        }
        this.setState(
          {
            loading: false,
            action: "",
            hasfencing: reloadFencingList ? false : this.state.hasfencing,
            hiddenFencingList,
          },
          () => {
            reloadFencingList &&
              this.props.updateFencingDataAndClearTempFencing(
                RELOAD_FENCING_LIST
              );
          }
        );
      } else if (type === "warning" || type == "info") {
        this.setState({ loading: false });
      }
    });
  };
  covertePointsForGoogleMapHandler = (data, type) => {
    let temp = [];
    let temp2 = [];
    if (type == UPLOAD_KLM) {
      for (let i = 0; i < data.length; i++) {
        temp.push({
          lat: parseFloat(data[i].latitude),
          lng: parseFloat(data[i].longitude),
        });
      }
      return temp;
    } else if (type == SHOW_FENCING || type == EDIT_FENCING) {
      if (parseInt(data.type) == 1) {
        temp2 = converStringToJson(data.line);
        for (let j = 0; j < temp2.length; j++) {
          temp.push({
            lat: parseFloat(temp2[j].latitude),
            lng: parseFloat(temp2[j].longitude),
          });
        }
      } else if (parseInt(data.type) == 2) {
        let obj = converStringToJson(data.circle);
        return {
          center: {
            lat: parseFloat(obj.center.latitude),
            lng: parseFloat(obj.center.longitude),
          },
          radius: parseFloat(obj.radius),
        };
      } else if (parseInt((data.type = 3))) {
        let obj = converStringToJson(data.rectangle);
        return {
          north: parseFloat(obj.north),
          south: parseFloat(obj.south),
          east: parseFloat(obj.east),
          west: parseFloat(obj.west),
        };
      }

      return temp;
    }
  };
  parseDocument(file, callBack) {
    let fileReader = new FileReader();
    fileReader.onload = async (e) => {
      let result = await this.extractGoogleCoords(e.target.result);

      callBack(result);
    };
    fileReader.readAsText(file);
  }
  async extractGoogleCoords(plainText) {
    let parser = new DOMParser();
    let xmlDoc = parser.parseFromString(plainText, "text/xml");
    let googlePolygons = [];

    if (xmlDoc.documentElement.nodeName == "kml") {
      if (xmlDoc.getElementsByTagName("Placemark").length == 0) {
        this.fireSwalHandler(
          "Warning",
          "Not valid File, please check tag Placemark",
          "warning"
        );
        return googlePolygons;
      }
      for (const item of xmlDoc.getElementsByTagName("Placemark")) {
        let temp = item.getElementsByTagName("coordinates");

        let cordStr = temp[0].innerHTML.split(" ");

        for (let i = 0; i < cordStr.length; i++) {
          let cord = cordStr[i].trim().split(",");

          if (!isNaN(parseFloat(cord[1])) && !isNaN(parseFloat(cord[0]))) {
            googlePolygons.push({
              latitude: parseFloat(cord[1]),
              longitude: parseFloat(cord[0]),
            });
          }
        }
      }
      return googlePolygons;
    } else {
      this.fireSwalHandler("Warning", "KML file is not valid", "warning");
      return googlePolygons;
    }
  }
  /////////////////////////////////////////Zones or fencing ///////////////////////////////////////////////
  checkFileValidHandler = (file) => {
    if (file.length) return true;
    return false;
  };

  /**
   * show or hide the fencing block on main map
   */
  getInactiveFencing = (currentFencingList) => {
    let list = [];
    currentFencingList.map((item) => {
      if (!item.isActive) list.push(item.uuid);
    });
    return list;
  };
  onClickFencingHandler = () => {
    //to show fencing block
    if (!this.state.showFencing) {
      this.props.refFiltersControls(this.setDataHandler); ///?????????
    }
    let { fileName, fillColor, lat, lng, uploadFile } = {
      ...this.state,
    };
    fileName.value = "";
    fileName.error = false;
    fillColor.value = DEFALUT_FILL_COLOR;
    lat.value = "";
    lat.error = false;
    lng.value = "";
    lng.error = false;
    uploadFile.fileName = "";
    uploadFile.value = "";
    uploadFile.error = false;
    let currentFencingList = Object.values(this.props.getAccountFencingList());
    let hiddenFencingList = this.getInactiveFencing(currentFencingList);
    this.setState(
      {
        showNotification: false,
        showBoardcasts: false,
        showFilters: false,
        showFencing: !this.state.showFencing,
        action: "",
        showCords: false,
        fillColor,
        fileName,
        typeOfDraw: POLYLINE,
        drawFencingPoints: null,
        value: "",
        showOverview: false,
        showClusterVehicles: false,
        menuNotification: false,
        hiddenFencingList,
      },
      () => {
        this.props.setActionType(""); //celar the action type
      }
    );
  };
  /**
   * Show or hide single fencing (polygon) from map
   * @param {*} uuid
   */
  onClickShowOrHideSingleFencingHandler = (uuid) => {
    let hiddenFencingList = [...this.state.hiddenFencingList];
    let index = hiddenFencingList.indexOf(uuid);
    if (index != -1) {
      hiddenFencingList.splice(index, 1);
    } else {
      hiddenFencingList.push(uuid);
    }
    this.setState(
      {
        hiddenFencingList,
      },
      () => {
        this.props.onClickShowOrHideSingleFencing(hiddenFencingList);
      }
    );
  };
  /**
   * activate or deactivate the fencing
   * @param {} currentFencingList
   */
  onClickToggleFencingHandler = (currentFencingList) => {
    this.setState({ loading: true }, () => {
      this.props.contralHttpInterval(STOP);
      let isActive = currentFencingList.isActive;
      httpRequest(toggleFenceActivation, {
        fenceLookupId: currentFencingList.lookupId,
        activationStatus: !isActive,
      })
        .then((res) => {
          this.props.contralHttpInterval(START);
          if (res.errors !== undefined) {
            this.fireSwalHandler(
              "Warning",
              res.errors[0].message.replace(/_/g, " "),
              "warning"
            );
          } else {
            this.tempHiddenFencing = {
              status: !isActive,
              uuid: currentFencingList.uuid,
            };
            this.fireSwalHandler(
              "Success",
              `Zone is ${isActive ? "disabled" : "enabled"}`,
              "success",
              true
            );
          }
        })
        .catch((err) => {});
    });
  };
  /**
   * delete the single fencing
   * @param {*} lookupIdFencing
   */
  onClickDeleteFencingHandler = (lookupIdFencing) => {
    this.setState({ loading: true }, () => {
      this.props.contralHttpInterval(STOP);
      httpRequest(deleteFencing, { lookupId: lookupIdFencing })
        .then((res) => {
          this.props.contralHttpInterval(START);
          if (res.errors !== undefined) {
            this.fireSwalHandler(
              "Warning",
              res.errors[0].message.replace(/_/g, " "),
              "warning"
            );
          } else {
            this.fireSwalHandler("Success", "Zone is deleted", "success", true);
          }
        })
        .catch((err) => {});
    });
  };
  /**
   * Download fencing kml file
   * @param {*} lookupId
   */
  onClickDownloadFencingHandler = (lookupId) => {
    this.setState({ loading: true }, () => {
      this.props.contralHttpInterval(STOP);
      httpRequest(importFencing, { lookupIds: [lookupId] }).then((res) => {
        var a = document.createElement("A");
        a.href = res.data.exportKmlFile.fileUrl;
        a.download = res.data.exportKmlFile.fileUrl;
        a.target = "_blank";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        this.setState({ loading: false }, () => {
          this.props.contralHttpInterval(START);
        });
      });
    });
  };
  /**
   * Close the particluar opened action and reset data to default
   */
  closeActionWindowHandler = () => {
    let { fileName, fillColor, lat, lng, uploadFile } = {
      ...this.state,
    };
    fileName.value = "";
    fileName.error = false;
    lat.value = "";
    lat.error = false;
    lng.value = "";
    lng.error = false;
    uploadFile.value = "";
    uploadFile.fileName = "";
    uploadFile.error = false;
    fillColor.value = DEFALUT_FILL_COLOR;
    this.setState(
      {
        action: "",
        drawFencingPoints: null,
        fileName,
        fillColor,
        uploadFile,
        typeOfDraw: POLYLINE,
      },
      () => {
        this.props.setActionType("");
      }
    );
  };
  /**
   * Handles diffenent fencing actions
   * @param {*} action
   * @param {*} uuid
   * @returns
   */
  onClickActionFencingHandler = (action, uuid = null) => {
    if (this.state.action == action) {
      if (
        action == EDIT_FENCE_DETAILS ||
        action == EDIT_FENCING ||
        action == VEHICLES_OF_FENCE
      ) {
        this.setState({ action: "" });
        return;
      } else return;
    }
    let { fileName, fillColor } = { ...this.state };
    let fencingList = this.props.getAccountFencingList();
    let currentFencingList = uuid != null ? fencingList[uuid] : {};
    if (action == TOGGLE_FENCE_ACTIVATION) {
      //show the swral for confirmation
      Swal.fire({
        title: "Are you sure?",
        text: `You are about to ${
          currentFencingList.isActive ? "disable" : "enable"
        } ${currentFencingList.name} zone.`,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#00a0dc",
        cancelButtonColor: "#ff1a1a",
        confirmButtonText: "Yes",
      }).then((res) => {
        if (res.dismiss !== "cancel" && res.dismiss !== "backdrop") {
          this.onClickToggleFencingHandler(currentFencingList);
        }
      });
    } else if (action == DELETE_FENCING) {
      //show the swral for confirmation      /
      Swal.fire({
        title: "Are you sure?",
        text: `You want to delete ${currentFencingList.name}.`,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#00a0dc",
        cancelButtonColor: "#ff1a1a",
        confirmButtonText: "Yes",
      }).then((res) => {
        if (res.dismiss !== "cancel" && res.dismiss !== "backdrop") {
          this.onClickDeleteFencingHandler(currentFencingList.lookupId);
        }
      });
    } else if (action == DOWNLOAD_FENCING) {
      //show the swral for confirmation
      Swal.fire({
        title: "Are you sure?",
        text: `You are about to download ${currentFencingList.name} kml.`,
        type: "info",
        showCancelButton: true,
        confirmButtonColor: "#00a0dc",
        cancelButtonColor: "#ff1a1a",
        confirmButtonText: "Yes",
      }).then((res) => {
        if (res.dismiss !== "cancel" && res.dismiss !== "backdrop") {
          this.onClickDownloadFencingHandler(currentFencingList.lookupId);
        }
      });
    } else if (action == VEHICLES_OF_FENCE) {
      this.setState({ loadingFenceVehicles: true, action }, () => {
        this.props.getVehiclesofFence(currentFencingList.lookupId);
      });
    } else {
      if (currentFencingList == undefined && action == EDIT_FENCING) return;
      if (action == EDIT_FENCING) {
        fillColor.value = currentFencingList.color;
      }
      if (action == EDIT_FENCE_DETAILS) {
        fileName.value = currentFencingList.name;
      }
      this.setState(
        {
          action: action,
          drawFencingPoints: null,
          fileName,
          fillColor,
        },
        () => {
          let actionData = null;
          if (action !== UPLOAD_KLM) {
            if (action == EDIT_FENCING) actionData = [currentFencingList];
            this.props.setActionType(action, actionData);
          } else {
            this.props.setActionType(UPLOAD_KLM);
          }
          if (action == DRAW_FENCING) {
            this.props.setDrawType(POLYLINE, {
              fillColor: this.state.fillColor.value,
              borderColor: this.state.fillColor.value,
            });
          }
        }
      );
    }
  };

  checkValidUploadKmlFileHandler = () => {
    let isValid = true;
    let copyfileUpaload = { ...this.state.uploadFile };
    let copyFileName = { ...this.state.fileName };
    if (copyfileUpaload.value == "" && this.state.action == UPLOAD_KLM) {
      isValid = false;
      copyfileUpaload.error = true;
    }
    if (copyFileName.value.trim() == "") {
      isValid = false;
      copyFileName.error = true;
    }
    if (this.state.action == DRAW_FENCING) {
      if (this.state.typeOfDraw == "") {
        isValid = false;
      } else if (
        this.state.typeOfDraw == POLYLINE &&
        (this.state.drawFencingPoints == null ||
          (this.state.drawFencingPoints !== null &&
            Object.keys(this.state.drawFencingPoints).length < 3))
      ) {
        isValid = false;
      } else if (
        this.state.typeOfDraw == CIRCLE &&
        (this.circle.center.lat == "" ||
          this.circle.center.lng == "" ||
          this.circle.radius == "")
      ) {
        isValid = false;
      } else if (
        this.state.typeOfDraw == RECTANGLE &&
        (this.rectangle.west == "" ||
          this.rectangle.east == "" ||
          this.rectangle.north == "" ||
          this.rectangle.south == "")
      ) {
        isValid = false;
      }
    }
    this.setState({ uploadFile: copyfileUpaload, fileName: copyFileName });
    return isValid;
  };
  createObjForServerHandler = (data, type, drawn) => {
    if (this.state.action == DRAW_FENCING || this.state.action == UPLOAD_KLM) {
      let obj = {
        fields: {
          name: this.state.fileName.value,
          color: this.state.fillColor.value,
          drawn: drawn,
          line: type == 1 ? data : [],
          circle: type == 2 ? data : {},
          rectangle: type == 3 ? data : {},
          type: type,
        },
      };
      return obj;
    } else if (this.state.action == EDIT_FENCING) {
      return {
        color: this.state.fillColor.value,
      };
    } else if (this.state.action == EDIT_FENCE_DETAILS) {
      return {
        name: this.state.fileName.value,
      };
    }
  };
  resetCircelAndRectengleHandler = () => {
    for (let key in this.rectangle) {
      this.rectangle[key] = "";
    }
    this.circle.center.latitude = "";
    this.circle.center.longitude = "";
    this.circle.radius = "";
  };
  resetObjFormHandler = () => {
    let uploadFileCopy = { ...this.state.uploadFile };
    this.resetCircelAndRectengleHandler();
    uploadFileCopy.value = "";
    uploadFileCopy.error = false;
    uploadFileCopy.fileName = "";
    let fileNameCopy = { ...this.state.fileName };
    fileNameCopy.value = "";
    fileNameCopy.error = "";

    let fillColor = { ...this.state.fillColor };
    fillColor.value = DEFALUT_FILL_COLOR;
    fillColor.error = "";
    return {
      uploadFile: uploadFileCopy,
      fileName: fileNameCopy,
      fillColor,
    };
  };
  onClickSubmitDrawHandler = () => {
    this.setState({ loading: true }, () => {
      if (this.checkValidUploadKmlFileHandler()) {
        let type =
          this.state.typeOfDraw == POLYLINE
            ? 1
            : this.state.typeOfDraw == CIRCLE
            ? 2
            : 3;
        let data = "";
        if (type == 1) {
          data = this.convertCordToArrayHandler();
        } else if (type == 2) {
          data = this.circle;
        } else {
          data = this.rectangle;
        }
        let fields = this.createObjForServerHandler(data, type, true);
        this.props.contralHttpInterval(STOP);
        httpRequest(createFencing, fields)
          .then((res) => {
            if (res.errors !== undefined) {
              this.fireSwalHandler(
                "Warning",
                res.errors[0].message.replace(/_/g, " "),
                "warning"
              );
            } else {
              let obj = this.resetObjFormHandler();
              this.setState(
                {
                  uploadFile: obj.uploadFile,
                  fileName: obj.fileName,
                  loading: false,
                  action: "",
                  typeOfDraw: "",
                  hasfencing: true,
                },
                () => {
                  this.props.updateFencingDataAndClearTempFencing(
                    RELOAD_FENCING_LIST
                  );
                  this.props.setActionType("");
                }
              );
            }
            this.props.contralHttpInterval(START);
          })
          .catch((err) => {});
      } else {
        this.setState({ loading: false });
      }
    });
  };

  onClickUploadKmlHandler = () => {
    this.setState({ loading: true }, () => {
      if (this.checkValidUploadKmlFileHandler()) {
        this.parseDocument(this.state.uploadFile.value, (data) => {
          let fields = this.createObjForServerHandler(data, 1, false);
          this.props.contralHttpInterval(STOP);
          httpRequest(createFencing, fields)
            .then((res) => {
              if (res.errors !== undefined) {
                this.fireSwalHandler(
                  "Warning",
                  res.errors[0].message.replace(/_/g, " "),
                  "warning"
                );
              } else {
                let obj = this.resetObjFormHandler();
                this.setState(
                  {
                    uploadFile: obj.uploadFile,
                    fileName: obj.fileName,
                    loading: false,
                    action: "",
                    hasfencing: true,
                  },
                  () => {
                    ////////CLEAR THE FENCING ////to DO
                    this.props.updateFencingDataAndClearTempFencing(
                      RELOAD_FENCING_LIST
                    );
                    this.props.setActionType("");
                  }
                );
              }
              this.props.contralHttpInterval(START);
            })
            .catch((err) => {});
        });
      } else {
        this.setState({ loading: false });
      }
    });
  };
  uploadFileHandler = (event) => {
    let uploadFile = { ...this.state.uploadFile };
    if (this.checkFileValidHandler(event.target.files)) {
      uploadFile.value = event.target.files[0];
      uploadFile.error = false;
      uploadFile.fileName = event.target.files[0].name;
    } else {
      uploadFile.error = true;
    }

    let openedFencingUuId = `KML${randomID()}`;
    this.setState(
      {
        uploadFile: uploadFile,

        openedFencingUuId,
      },
      () => {
        let copyfile = { ...this.state.uploadFile };
        if (!copyfile.error) {
          this.parseDocument(copyfile.value, (data) => {
            let arr = this.covertePointsForGoogleMapHandler(data, UPLOAD_KLM);
            //////call google here
            let fencing = {
              coordinates: arr,
              color: DEFALUT_FILL_COLOR,
              uuid: openedFencingUuId,
            };
            this.props.setActionType(this.state.action, [fencing]);
          });
        }
      }
    );
  };
  onClickTypeOfDrawHandler = (typeOfDraw) => {
    let { fillColor, lat, lng } = {
      ...this.state,
    };
    fillColor.value = DEFALUT_FILL_COLOR;
    lat.value = "";
    lat.error = false;
    lng.value = "";
    lng.error = false;
    this.setState({ typeOfDraw: typeOfDraw, fillColor, lat, lng }, () => {
      /////CALL DRAW
      if (typeOfDraw !== "") {
        this.props.setDrawType(typeOfDraw, {
          fillColor: this.state.fillColor.value,
        });
      } else {
        this.props.setActionType("");
      }
    });
  };
  onCheckHandler = (item) => {
    let excludeList = [...this.state.excludeList];
    const index = excludeList.indexOf(item.lookupId);
    if (index > -1) excludeList.splice(index, 1);
    else excludeList.push(item.lookupId);

    this.setState({ excludeList: excludeList }, () => {
      this.props.exludeGroups(this.state.excludeList);
    });
  };
  convertCordToArrayHandler = () => {
    let copyCords = { ...this.state.drawFencingPoints };
    let temp = [];
    for (let key in copyCords) {
      temp.push({
        latitude: copyCords[key].lat,
        longitude: copyCords[key].lng,
      });
    }
    return temp;
  };

  showLicenseClick = () => {
    this.setState({ showLicense: !this.state.showLicense }, () => {
      this.props.showLicenseClick();
    });
  };
  checkForValidation = () => {
    let { fileName } = {
      ...this.state,
    };
    let isValid = true;
    if (fileName.value.trim() == "") {
      fileName.error = true;
      isValid = false;
      this.setState({ fileName });
    }
    return isValid;
  };
  onClickEditFencingHandler = (fencingItem) => {
    let lookupId = fencingItem.lookupId;
    if (this.state.action == EDIT_FENCE_DETAILS) {
      if (!this.checkForValidation()) {
        return;
      }
    }
    this.setState({ loading: true }, () => {
      let fields = this.createObjForServerHandler([], fencingItem.type, true);
      this.props.contralHttpInterval(STOP);
      httpRequest(updateFencing, {
        fields,
        lookupId,
      })
        .then((res) => {
          if (res.errors !== undefined) {
            this.fireSwalHandler(
              "Warning",
              res.errors[0].message.replace(/_/g, " "),
              "warning"
            );
          } else {
            let obj = this.resetObjFormHandler();
            let { fileName, fillColor } = { ...this.state };
            fileName.value = "";
            fileName.error = false;
            fillColor.value = DEFALUT_FILL_COLOR;

            this.setState(
              {
                uploadFile: obj.uploadFile,
                fileName: obj.fileName,
                loading: false,
                action: "",
                fileName,
                fillColor,
              },
              () => {
                this.props.setActionType("");
                this.props.updateFencingDataAndClearTempFencing(
                  UPDATE_FENCING_LIST,
                  res.data.updateFencing.fencing
                );
              }
            );
          }
          this.props.contralHttpInterval(START);
        })
        .catch((err) => {});
    });
  };
  getStatusIconBroadcastHandler = (lookupId, status) => {
    if (this.state.broadcastStatus[lookupId] !== undefined) {
      return (
        <>
          <span
            className={styles.statusIcon}
            style={{
              color:
                this.colorStatus[this.state.broadcastStatus[lookupId].status],
            }}
          >
            <Icon
              defination={{
                prefix: "far",
                iconName:
                  this.iconStatus[this.state.broadcastStatus[lookupId].status],
              }}
            />
          </span>
        </>
      );
    } else {
      return (
        <>
          <span
            className={styles.statusIcon}
            style={{
              color: this.colorStatus[status],
            }}
          >
            <Icon
              defination={{
                prefix: "far",
                iconName: this.iconStatus[status],
              }}
            />
          </span>
        </>
      );
    }
  };

  onChangeHandler = (event) => {
    let copyObj = { ...this.state[event.target.name] };
    copyObj.error = false;
    copyObj.value = event.target.value;
    this.setState({ [event.target.name]: copyObj }, () => {
      if (event.target.name == "fillColor") {
        if (this.state.action == DRAW_FENCING && this.state.typeOfDraw !== "") {
          if (
            this.state.action == UPLOAD_KLM &&
            this.state.fileName.value == ""
          )
            return;
          this.props.updateFencingColorsAndData({
            uuid: "",
            borderColor: this.state.fillColor.value,
            color: this.state.fillColor.value,
          });
        }
        if (this.state.action == EDIT_FENCING) {
          this.props.updateFencingColorsAndData({
            uuid: this.state.openedFencingUuId,
            borderColor: this.state.fillColor.value,
            color: this.state.fillColor.value,
          });
        } else if (this.state.action == UPLOAD_KLM) {
          if (this.state.fileName.value == "") return;
          this.props.updateFencingColorsAndData({
            uuid: this.state.openedFencingUuId,
            borderColor: this.state.fillColor.value,
            color: this.state.fillColor.value,
          });
        }
      }
    });
  };
  onOpenOverViewHandler = (item) => {
    this.setState({ showOverview: false }, () => {
      this.props.mapToNotificationAlert(item, OVERVIEW);
    });
  };
  onClickAddLocationPointHandler = () => {
    if (this.state.typeOfDraw != POLYLINE) return;
    let { lat, lng, drawFencingPoints } = this.state;
    if (drawFencingPoints == null) {
      drawFencingPoints = {};
    }
    let latlng = {};
    if (
      validator.isLatLong(`${parseFloat(lat.value)}, ${parseFloat(lng.value)}`)
    ) {
      latlng = { lat: parseFloat(lat.value), lng: parseFloat(lng.value) };
      drawFencingPoints[`point${latlng.lat}-${latlng.lng}`] = {
        lat: latlng.lat,
        lng: latlng.lng,
      };
      lat.value = "";
      lat.error = false;
      lng.value = "";
      this.setState({ drawFencingPoints, lat, lng }, () => {
        this.props.updateDrawFencingPoints(latlng);
      });
    } else {
      //show error
      lat.error = true;
      this.setState({ lat });
    }
  };

  deletePointHandler = (key) => {
    let deletedPoints = { ...this.state.deletedPoints };
    let deletedBeams = Object.keys(deletedPoints);
    if (deletedBeams.includes(key)) {
      delete deletedPoints[key];
    } else {
      deletedPoints[key] = this.state.drawFencingPoints[key];
    }

    this.setState({ deletedPoints }, () => {});
  };
  isOnlineHandler = (item) => {
    if (item.terminal !== null && item.terminal.isOnline) {
      return true;
    }
    return false;
  };
  isTerminalAliveHandler = (item) => {
    if (item.terminal !== null && item.terminal.isAlive) {
      return true;
    }
    return false;
  };
  getDataClusterForMobileHandler = () => {
    if (this.state.vehiclesInCluster.length == 0)
      return <p className={styles.loadingDataMsg}>No Vehicles Found</p>;
    return this.state.vehiclesInCluster.map((item, index) => {
      return (
        <div
          className={styles.itemClusterWrapper}
          key={index}
          onClick={() => this.onOpenOverViewHandler(item)}
        >
          <span className={styles.itemClusterMobile}>
            <span className={styles.vehicleIcon}>
              {VEHICLE_TYPES[item.details.vClass.lookupId].icon}
            </span>{" "}
            <span className={styles.vehicleName}>
              {item.details.licensePlate.number}
            </span>
          </span>
          <span
            className={`${styles.itemClusterMobile} ${styles.itemInfoCluster}`}
          >
            <img
              alt="terminal-staus-icon"
              className={styles.imgVehicle}
              src={
                this.isOnlineHandler(item.details)
                  ? ONLINE_ICON_LEGEND
                  : OFFLINE_ICON_LEGEND
              }
            />

            <span>{item.details.terminal.externalId}</span>
          </span>
          <span className={styles.iconWrapperMobile}>
            <img
              className={styles.imgVehicle}
              style={{
                display:
                  item.details.sosAlert != null ? "inline-block" : "none",
              }}
              alt="alert-status-icon"
              src={
                item.details.sosAlert !== null && !item.details.sosAlert
                  ? NO_SOS_SENT
                  : SOS
              }
            />
            <img
              className={styles.imgVehicle}
              alt="alert-status-icon"
              src={
                item.details.vehicleIsOutsideFence != null &&
                item.details.vehicleIsOutsideFence
                  ? ALERT
                  : NO_ALERT_ZONE
              }
            />
            <img
              className={styles.imgVehicle}
              alt="alert-status-icon"
              style={{
                display:
                  item.details.newMessageAlert != null &&
                  item.details.newMessageAlert
                    ? "inline-block"
                    : "none",
              }}
              src={NOTIFICATION}
            />
          </span>
        </div>
      );
    });
  };
  clusterVehiclesDataHandler = () => {
    if (this.state.vehiclesInCluster.length == 0)
      return <p className={styles.loadingDataMsg}>No Vehicles Found</p>;
    return this.state.vehiclesInCluster.map((item, index) => {
      return (
        <div
          className={styles.tableRow}
          key={index}
          onClick={() => this.onOpenOverViewHandler(item)}
        >
          <div className={styles.textCellCluster}>
            {VEHICLE_TYPES[item.details.vClass.lookupId].icon}
          </div>
          <div className={`${styles.textCellCluster} ${styles.licenseNumber}`}>
            <span className={styles.toolTip}>
              {item.details.licensePlate.number}
            </span>
            <span>{item.details.licensePlate.number}</span>
          </div>
          <div className={`${styles.textCellCluster} ${styles.networkId}`}>
            <span className={styles.toolTip}>
              {item.details.terminal.externalId}
            </span>
            {item.details.terminal.externalId}
          </div>

          <div className={styles.textCellCluster}>
            <img
              alt="status-vehicle-icon"
              src={
                this.isOnlineHandler(item.details)
                  ? ONLINE_ICON_LEGEND
                  : OFFLINE_ICON_LEGEND
              }
              className={styles.imgVehicle}
            />
            {/* <span
              className={styles.deviceDote}
              style={{
                backgroundColor: this.isOnlineHandler(item.details)
                  ? "rgb(0, 181, 0)"
                  : "rgb(237, 31, 3)",
              }}
            ></span> */}
          </div>
          <div className={styles.textCellCluster}>
            <img
              className={styles.imgVehicle}
              style={{
                visibility:
                  item.details.sosAlert != null ? "visible" : "hidden",
              }}
              alt="alert-status-icon"
              src={
                item.details.sosAlert !== null && !item.details.sosAlert
                  ? NO_SOS_SENT
                  : SOS
              }
            />
          </div>
          <div className={styles.textCellCluster}>
            <img
              className={styles.imgVehicle}
              alt="alert-status-icon"
              src={
                item.details.vehicleIsOutsideFence != null &&
                item.details.vehicleIsOutsideFence
                  ? ALERT
                  : NO_ALERT_ZONE
              }
            />
          </div>
          <div className={styles.textCellCluster}>
            <img
              className={styles.imgVehicle}
              alt="alert-status-icon"
              style={{
                visibility:
                  item.details.newMessageAlert != null &&
                  item.details.newMessageAlert
                    ? "visible"
                    : "hidden",
              }}
              src={IconChatNotification}
            />
          </div>
        </div>
      );
    });
  };
  overviewDataHandler = () => {
    if (this.state.overviewArray.length == 0)
      return <p className={styles.loadingDataMsg}>No Data Found</p>;
    return this.state.overviewArray.map((item, index) => {
      return (
        <div
          className={styles.tableRow}
          key={index}
          onClick={() => this.onOpenOverViewHandler(item)}
        >
          <div className={styles.textCell}>
            {VEHICLE_TYPES[item.vClass.lookupId].icon}
          </div>
          <div className={styles.textCell}>{item.licensePlate.number}</div>
          <div className={styles.textCell}>{item.terminal.externalId}</div>
          <div className={styles.textCell}>
            <img
              src={
                this.isOnlineHandler(item)
                  ? ONLINE_ICON_LEGEND
                  : OFFLINE_ICON_LEGEND
              }
              alt="icons-staus-terminal"
              className={styles.imgVehicle}
            />
            {/* <span
              className={styles.deviceDote}
              style={{
                backgroundColor: this.isOnlineHandler(item)
                  ? "rgb(0, 181, 0)"
                  : "rgb(237, 31, 3)",
              }}
            ></span> */}
          </div>
          <div className={styles.textCell}>
            <img
              className={styles.imgVehicle}
              alt="keep-Alive-icon"
              src={
                this.isTerminalAliveHandler(item)
                  ? KEEP_ALIVE_ON
                  : KEEP_ALIVE_OFF
              }
            />
          </div>
          <div className={styles.textCell}>
            <img
              alt="sos-status-icon"
              src={item.sosAlert != null ? SOS : NO_SOS_SENT}
              className={styles.sosImg}
            />
          </div>
          <div className={styles.textCell}>
            <img
              className={styles.imgVehicle}
              alt="alert-status-icon"
              src={
                item.vehicleIsOutsideFence != null && item.vehicleIsOutsideFence
                  ? ALERT
                  : NO_ALERT_ZONE
              }
            />
          </div>
        </div>
      );
    });
  };
  clusterVehicleHeaderHandler = () => {
    return [
      TYPE,
      LICENSE_NO,
      TERMINAL_ID,
      <span className={styles.iconFont}>
        <Icon
          defination={{
            prefix: "far",
            iconName: "satellite",
          }}
        />
      </span>,
      <img
        style={{ width: "2.5rem" }}
        className={styles.imgVehicle}
        src={SOS}
        alt="sos-icon"
      />,
      <span className={styles.iconFont}>
        <img
          className={styles.imgVehicle}
          style={{ width: "2rem" }}
          alt="out-of-zone-icon"
          src={IconOutOfZone}
        />
      </span>,
      <img
        className={styles.imgVehicle}
        src={IconChatNotification}
        alt="alert-icon"
      />,
    ].map((item, index) => {
      return (
        <div className={styles.textCellCluster} key={index}>
          {item}
        </div>
      );
    });
  };
  overviewHeaderHandler = () => {
    return [
      TYPE,
      LICENSE_NO,
      TERMINAL_ID,
      <span className={styles.iconFont}>
        <Icon
          defination={{
            prefix: "far",
            iconName: "satellite",
          }}
        />
      </span>,
      <span className={styles.iconFont}>
        <img
          src={IconChatNotification}
          alt="chat-icon"
          className={styles.sosImg}
        />
      </span>,
      <span>
        <img src={SOS} alt="SOS-icon" className={styles.sosImg} />
      </span>,
      <span className={styles.iconFont}>
        <img
          src={IconOutOfZone}
          className={styles.sosImg}
          style={{ width: "2rem" }}
        />
      </span>,
    ].map((item, index) => {
      return (
        <div className={styles.textCell} key={index}>
          {item}
        </div>
      );
    });
  };

  updateOverviewArrayHandler = (arr, arg) => {
    let objCopy = { ...this.state.agrOverview };
    objCopy = arg;
    let arrMatch = [];
    let search = this.state.searchKey;
    if (search.trim() != "") {
      arr.map((item) => {
        //loop and push all matched devices
        try {
          if (
            item.licensePlate.number
              .toString()
              .toLowerCase()
              .indexOf(search) !== -1 ||
            item.terminal.externalId.toString().indexOf(search) !== -1
          ) {
            arrMatch.push(item);
          }
        } catch (error) {}
      });
    } else arrMatch = arr;
    this.setState(
      {
        overviewArray: arrMatch,
        originalOverviewList: arr,
        agrOverview: objCopy,
      },
      () => {}
    );
  };
  sortVehiclesHandler = (cluster) => {
    cluster.sort((a, b) => {
      return Number(b.details.isOnline) - Number(a.details.isOnline);
    });
  };
  updateVehiclesInClusterArrayHandler = (vehiclesInCluster = []) => {
    if (!this.state.showClusterVehicles) {
      this.sortVehiclesHandler(vehiclesInCluster);
    }

    this.setState({
      vehiclesInCluster,
      showClusterVehicles: !this.state.showClusterVehicles,
      showFilters: false,
      showFencing: false,
      showNotification: false,
      showBoardcasts: false,
      showOverview: false,
    });
  };

  onClickOverViewHandler = () => {
    this.setState({
      showNotification: false,
      showFencing: false,
      showFilters: false,
      showBoardcasts: false,
      value: "",
      showOverview: !this.state.showOverview,
      showClusterVehicles: false,
      action: "",
    });
  };
  /**
   * Multi zone code here
   */
  onOpenVehicleDetailsHandler = (item) => {
    if (item.terminal == null) return;
    this.setState({ showFencing: false, openedFencingUuId: "" }, () => {
      this.props.mapToNotificationAlert(item, OVERVIEW);
      this.props.highlightSingleFence("");
    });
  };
  disableZoneActionBtnStyles = (item) => {
    if (
      this.state.loading ||
      this.state.loadingFenceVehicles ||
      !item.isActive ||
      this.state.hiddenFencingList.indexOf(item.uuid) != -1
    ) {
      return {
        opacity: 0.6,
        cursor: "not-allowed !important",
      };
    } else {
      return {};
    }
  };
  getMultizoneHandler = () => {
    const { fileName, fillColor, lat, lng } = this.state;
    return (
      <>
        <div
          className={
            this.state.showFencing
              ? `${styles.multizoneWrapper} `
              : styles.closeNotification
          }
        >
          {this.state.showFencing && (
            <>
              <div
                className={
                  this.state.action == UPLOAD_KLM
                    ? `${styles.multizoneHeader} ${styles.createZoneActive}`
                    : styles.multizoneHeader
                }
              >
                {this.props.phoneMode ? (
                  <span className={styles.title} style={{ width: "90%" }}>
                    Permitted Zones
                  </span>
                ) : (
                  <span
                    className={styles.title}
                    style={{ width: "90%" }}
                    onClick={() => this.onClickActionFencingHandler(UPLOAD_KLM)}
                  >
                    Create New Zone
                  </span>
                )}
                <span
                  className={
                    this.state.action == UPLOAD_KLM
                      ? `${styles.iconClose} ${styles.iconCloseActive}`
                      : styles.iconClose
                  }
                  onClick={this.onClickFencingHandler}
                >
                  <span className={styles.toolTip}> Close</span>
                  <Icon
                    defination={{
                      prefix: "fas",
                      iconName: "times",
                    }}
                  />
                </span>
              </div>
              <div className={styles.multizoneContainer}>
                {Object.values(this.props.getAccountFencingList()).length ==
                  0 && <span className={styles.msgNoData}>No Zones Found</span>}
                {Object.values(this.props.getAccountFencingList()).map(
                  (item, index) => {
                    let isCircleZone = parseInt(item.type) == 2;
                    return (
                      <div
                        className={styles.singlezoneWrapper}
                        key={index}
                        id={item.uuid}
                      >
                        <div className={styles.singlezoneItemWrapper}>
                          <div
                            className={styles.singlezoneItem}
                            style={{
                              borderColor: item.color ? item.color : "",
                            }}
                          >
                            <div className={styles.zoneActionIconWrapper}>
                              <span
                                className={styles.zoneActionIcon}
                                style={
                                  this.state.loading ||
                                  this.state.loadingFenceVehicles ||
                                  this.state.hiddenFencingList.indexOf(
                                    item.uuid
                                  ) != -1
                                    ? {
                                        opacity: 0.6,
                                        cursor: "not-allowed !important",
                                      }
                                    : {}
                                }
                                onClick={() =>
                                  this.state.hiddenFencingList.indexOf(
                                    item.uuid
                                  ) == -1 &&
                                  this.props.boundToSingleFence(item.uuid)
                                }
                              >
                                <img
                                  src={LOCATION_ICON}
                                  alt="Move to Premitted Zone"
                                  className={styles.zoneActionImg}
                                />
                              </span>
                              <span className={styles.zoneActionIcon}>
                                <img
                                  src={
                                    this.state.hiddenFencingList.indexOf(
                                      item.uuid
                                    ) != -1
                                      ? EYE_ICON
                                      : EYE_SLASH_ICON
                                  }
                                  onClick={() =>
                                    this.onClickShowOrHideSingleFencingHandler(
                                      item.uuid
                                    )
                                  }
                                  alt={
                                    this.state.hiddenFencingList[item.uuid] !=
                                    -1
                                      ? HIDE_FENCING
                                      : SHOW_FENCING
                                  }
                                  className={styles.zoneActionImg}
                                />
                              </span>
                              {!this.props.phoneMode && (
                                <span
                                  className={styles.zoneActionIcon}
                                  onClick={() =>
                                    this.onClickActionFencingHandler(
                                      TOGGLE_FENCE_ACTIVATION,
                                      item.uuid
                                    )
                                  }
                                >
                                  <img
                                    src={
                                      item.isActive
                                        ? TOGGLE_ON_ICON
                                        : TOGGLE_OFF_ICON
                                    }
                                    alt="toggle icon"
                                    className={styles.zoneActionImg}
                                  />
                                </span>
                              )}
                              <span className={styles.zoneTitle}>
                                {item.name}
                              </span>
                              {!this.props.phoneMode && (
                                <span
                                  className={styles.zoneOpenIcon}
                                  onClick={() =>
                                    this.state.openedFencingUuId == item.uuid
                                      ? this.openFenceActions()
                                      : this.openFenceActions(item.uuid)
                                  }
                                >
                                  <Icon
                                    defination={{
                                      prefix: "far",
                                      iconName:
                                        this.state.openedFencingUuId ==
                                        item.uuid
                                          ? "chevron-up"
                                          : "chevron-right",
                                    }}
                                  />
                                </span>
                              )}
                            </div>
                          </div>
                          <div
                            className={styles.singlezoneItem}
                            style={{
                              display:
                                this.state.openedFencingUuId != item.uuid
                                  ? "none"
                                  : "",
                              borderColor: item.color ? item.color : "",
                            }}
                          >
                            <div
                              className={styles.zoneActionIconWrapper}
                              style={{ backgroundColor: "#F5F5F5" }}
                            >
                              <span
                                className={styles.zoneActionIcon}
                                style={{
                                  opacity: isCircleZone ? 0.6 : 1,
                                  cursor: isCircleZone ? "not-allowed" : "",
                                }}
                              >
                                <img
                                  src={DOWNLOAD_ICON}
                                  onClick={() =>
                                    !isCircleZone &&
                                    this.onClickActionFencingHandler(
                                      DOWNLOAD_FENCING,
                                      item.uuid
                                    )
                                  }
                                  alt="download icon"
                                  className={styles.zoneActionImg}
                                  style={{
                                    opacity: isCircleZone ? 0.6 : 1,
                                    cursor: isCircleZone ? "not-allowed" : "",
                                  }}
                                />
                              </span>
                              <span
                                className={styles.zoneActionIcon}
                                style={{
                                  backgroundColor:
                                    this.state.action == EDIT_FENCING
                                      ? "#fff"
                                      : "",
                                  ...this.disableZoneActionBtnStyles(item),
                                }}
                                onClick={() =>
                                  item.isActive &&
                                  this.onClickActionFencingHandler(
                                    EDIT_FENCING,
                                    item.uuid
                                  )
                                }
                              >
                                <img
                                  src={ART_PALETTE_ICON}
                                  alt="art palette icon"
                                  className={styles.zoneActionImg}
                                />
                              </span>
                              <span
                                className={styles.zoneActionIcon}
                                onClick={() =>
                                  this.onClickActionFencingHandler(
                                    VEHICLES_OF_FENCE,
                                    item.uuid
                                  )
                                }
                                style={{
                                  backgroundColor:
                                    this.state.action == VEHICLES_OF_FENCE
                                      ? "#fff"
                                      : "",
                                }}
                              >
                                <img
                                  src={CAR_ICON}
                                  alt="car icon"
                                  className={styles.zoneActionImg}
                                />
                              </span>
                              <span
                                className={styles.zoneActionIcon}
                                onClick={() =>
                                  item.isActive &&
                                  this.onClickActionFencingHandler(
                                    EDIT_FENCE_DETAILS,
                                    item.uuid
                                  )
                                }
                                style={{
                                  backgroundColor:
                                    this.state.action == EDIT_FENCE_DETAILS
                                      ? "#fff"
                                      : "",
                                  ...this.disableZoneActionBtnStyles(item),
                                }}
                              >
                                <img
                                  src={EDIT_ICON}
                                  alt="toggle icon"
                                  className={styles.zoneActionImg}
                                />
                              </span>
                              <span
                                className={styles.zoneActionIcon}
                                style={
                                  this.state.loading ||
                                  this.state.loadingFenceVehicles
                                    ? {
                                        opacity: 0.6,
                                        cursor: "not-allowed !important",
                                      }
                                    : {}
                                }
                                onClick={() =>
                                  this.onClickActionFencingHandler(
                                    DELETE_FENCING,
                                    item.uuid
                                  )
                                }
                              >
                                <img
                                  src={TRASH_ICON}
                                  alt="trash icon"
                                  className={styles.zoneActionImg}
                                />
                              </span>
                            </div>
                          </div>
                          {this.state.action == EDIT_FENCING &&
                            this.state.openedFencingUuId == item.uuid && (
                              <div
                                className={styles.singlezoneItemDetail}
                                style={{
                                  borderColor: item.color ? item.color : "",
                                }}
                              >
                                <div className={styles.dataInfoWrapper}>
                                  <Input
                                    label={fillColor.label}
                                    error={fillColor.error}
                                    change={(e) => this.onChangeHandler(e)}
                                    errMsg={fillColor.errMsg}
                                    value={fillColor.value}
                                    name={fillColor.name}
                                    inputWrapper={styles.inputWrapper}
                                    disabled={this.state.loading}
                                    phoneMode={true}
                                    type="color"
                                  />
                                  <div className={styles.btnWrapper}>
                                    <span
                                      className={`${styles.iconTypeWrapper} ${styles.editIconTypeWrapper}`}
                                      onClick={this.closeActionWindowHandler}
                                    >
                                      <span
                                        className={
                                          this.state.loading
                                            ? `${styles.iconTDisable} ${styles.editIconT}`
                                            : styles.editIconT
                                        }
                                      >
                                        <Icon
                                          defination={{
                                            prefix: "fas",
                                            iconName: "times",
                                          }}
                                        />
                                      </span>
                                    </span>
                                    <span
                                      className={`${styles.iconTypeWrapper} ${styles.editIconTypeWrapper}`}
                                      onClick={() =>
                                        this.onClickEditFencingHandler(item)
                                      }
                                    >
                                      <span
                                        className={
                                          this.state.loading
                                            ? `${styles.iconTDisable} ${styles.editIconT}`
                                            : styles.editIconT
                                        }
                                      >
                                        <Icon
                                          defination={{
                                            prefix: "fas",
                                            iconName: "check",
                                          }}
                                        />
                                      </span>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            )}
                          {this.state.action == EDIT_FENCE_DETAILS &&
                            this.state.openedFencingUuId == item.uuid && (
                              <div
                                className={styles.singlezoneItemDetail}
                                style={{
                                  borderColor: item.color ? item.color : "",
                                }}
                              >
                                <div className={styles.dataInfoWrapper}>
                                  <Input
                                    label={fileName.label}
                                    error={fileName.error}
                                    errMsg={fileName.errMsg}
                                    change={(e) => this.onChangeHandler(e)}
                                    value={fileName.value}
                                    name={fileName.name}
                                    placeholder="Enter a name"
                                    inputWrapper={styles.inputWrapper}
                                    disabled={this.state.loading}
                                    phoneMode={true}
                                  />
                                  <div className={styles.btnWrapper}>
                                    <span
                                      className={`${styles.iconTypeWrapper} ${styles.editIconTypeWrapper}`}
                                      onClick={this.closeActionWindowHandler}
                                    >
                                      <span
                                        className={
                                          this.state.loading
                                            ? `${styles.iconTDisable} ${styles.editIconT}`
                                            : styles.editIconT
                                        }
                                      >
                                        <Icon
                                          defination={{
                                            prefix: "fas",
                                            iconName: "times",
                                          }}
                                        />
                                      </span>
                                    </span>
                                    <span
                                      className={`${styles.iconTypeWrapper} ${styles.editIconTypeWrapper}`}
                                      onClick={() =>
                                        this.onClickEditFencingHandler(item)
                                      }
                                    >
                                      <span
                                        className={
                                          this.state.loading
                                            ? `${styles.iconTDisable} ${styles.editIconT}`
                                            : styles.editIconT
                                        }
                                      >
                                        <Icon
                                          defination={{
                                            prefix: "fas",
                                            iconName: "check",
                                          }}
                                        />
                                      </span>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            )}
                          {this.state.action == VEHICLES_OF_FENCE &&
                            this.state.openedFencingUuId == item.uuid && (
                              <div
                                className={styles.singlezoneItemDetail}
                                style={{
                                  borderColor: item.color ? item.color : "",
                                }}
                              >
                                <div className={styles.headerAndTableWrapper}>
                                  <>
                                    <div className={styles.subHeader}>
                                      <span className={styles.title}>
                                        Total:{" "}
                                        {this.state.vehiclesOfFence.length}
                                        <span
                                          className={styles.fenceVehiclesLoader}
                                          style={{
                                            display: this.state
                                              .loadingFenceVehicles
                                              ? "block"
                                              : "none",
                                          }}
                                        >
                                          <Icon
                                            defination={{
                                              prefix: "fal",
                                              iconName: "spinner",
                                            }}
                                            isSpinner={true}
                                          />
                                        </span>
                                      </span>
                                    </div>
                                    <div
                                      className={styles.tableWrapper}
                                      style={{
                                        display: !this.state
                                          .loadingFenceVehicles
                                          ? "block"
                                          : "none",
                                      }}
                                    >
                                      <div className={styles.table}>
                                        {this.zoneVehiclesDataHandler()}
                                      </div>
                                    </div>
                                  </>
                                  {/* )} */}
                                </div>
                              </div>
                            )}
                        </div>
                      </div>
                    );
                  }
                )}
              </div>{" "}
            </>
          )}
        </div>
        {this.state.showFencing &&
          (this.state.action == UPLOAD_KLM ||
            this.state.action == DRAW_FENCING) && (
            <div className={styles.createZoneWrapper}>
              <div className={styles.createZoneBtns}>
                <span
                  className={
                    this.state.action == DRAW_FENCING
                      ? `${styles.createZoneBtnDisabledKml} ${styles.createZoneBtn}`
                      : styles.createZoneBtn
                  }
                  onClick={() => this.onClickActionFencingHandler(UPLOAD_KLM)}
                >
                  {UPLOAD_KLM}
                </span>
                <span
                  className={
                    this.state.action == UPLOAD_KLM
                      ? `${styles.createZoneBtnDisabled} ${styles.createZoneBtn}`
                      : styles.createZoneBtn
                  }
                  onClick={() => this.onClickActionFencingHandler(DRAW_FENCING)}
                >
                  {DRAW_FENCING}
                </span>
              </div>
              {this.state.action == DRAW_FENCING && (
                <div className={styles.createZoneInfoWrapper}>
                  <div
                    className={styles.listWrapper}
                    style={{ display: this.state.showCords ? "block" : "none" }}
                  >
                    <span
                      className={styles.closeIcon}
                      onClick={
                        this.state.showCords
                          ? this.onClickShowDisplayCordHandler
                          : this.state.action !== ""
                          ? this.closeActionWindowHandler
                          : this.onClickFencingHandler
                      }
                    >
                      <Icon
                        defination={{
                          prefix: "far",
                          iconName: this.state.showCords ? "backward" : "times",
                        }}
                      />
                    </span>
                    {this.state.drawFencingPoints != null &&
                      Object.keys(this.state.drawFencingPoints).map(
                        (key, index) => {
                          return (
                            <div className={styles.listItem} key={index}>
                              <span className={styles.dataWrapper}>
                                Lat: {this.state.drawFencingPoints[key].lat},
                                Lng:
                                {this.state.drawFencingPoints[key].lng}
                              </span>
                            </div>
                          );
                        }
                      )}
                  </div>
                  <div
                    className={styles.titleTypeFencing}
                    style={{ height: this.state.uploadFile.error ? "" : 0 }}
                  >
                    <span
                      className={styles.errorMsg}
                      style={{
                        visibility: this.state.uploadFile.error
                          ? "visible"
                          : "hidden",
                      }}
                    >
                      Invalid.No zone entered
                    </span>
                  </div>
                  <div className={styles.btnDrawTypeWrapper}>
                    <span
                      className={styles.iconTypeWrapper}
                      onClick={() => this.onClickTypeOfDrawHandler(POLYLINE)}
                    >
                      <span
                        className={styles.iconT}
                        style={{
                          color:
                            this.state.typeOfDraw == POLYLINE ? "#00b6c9" : "",
                        }}
                      >
                        <Icon
                          defination={{
                            prefix: "fas",
                            iconName: "draw-polygon",
                          }}
                        />
                      </span>
                    </span>
                    <span
                      className={styles.iconTypeWrapper}
                      onClick={() => this.onClickTypeOfDrawHandler(CIRCLE)}
                    >
                      <span
                        className={styles.iconT}
                        style={{
                          color:
                            this.state.typeOfDraw == CIRCLE ? "#00b6c9" : "",
                        }}
                      >
                        <Icon
                          defination={{
                            prefix: "fas",
                            iconName: "draw-circle",
                          }}
                        />
                      </span>
                    </span>
                    <span
                      className={styles.iconTypeWrapper}
                      onClick={() => this.onClickTypeOfDrawHandler(RECTANGLE)}
                    >
                      <span
                        className={styles.iconT}
                        style={{
                          color:
                            this.state.typeOfDraw == RECTANGLE ? "#00b6c9" : "",
                        }}
                      >
                        <Icon
                          defination={{
                            prefix: "fas",
                            iconName: "draw-square",
                          }}
                        />
                      </span>
                    </span>
                  </div>

                  <Input
                    label={fileName.label}
                    error={fileName.error}
                    errMsg={fileName.errMsg}
                    change={(e) => this.onChangeHandler(e)}
                    value={fileName.value}
                    name={fileName.name}
                    placeholder="Enter a name"
                    inputWrapper={styles.inputWrapper}
                    disabled={this.state.loading}
                    phoneMode={true}
                  />
                  <Input
                    label={fillColor.label}
                    error={fillColor.error}
                    change={(e) => this.onChangeHandler(e)}
                    errMsg={fillColor.errMsg}
                    value={fillColor.value}
                    name={fillColor.name}
                    inputWrapper={styles.inputWrapper}
                    disabled={this.state.loading}
                    phoneMode={true}
                    type="color"
                  />

                  <div className={styles.positionWrapper}>
                    <Input
                      label={lat.label}
                      error={lat.error}
                      errMsg={lat.errMsg}
                      value={lat.value}
                      change={(e) => this.onChangeHandler(e)}
                      name={lat.name}
                      placeholder={lat.placeholder}
                      inputWrapper={styles.inputPosition}
                      disabled={
                        this.state.loading || this.state.typeOfDraw != POLYLINE
                      }
                      phoneMode={true}
                      type="number"
                    />
                    <Input
                      label={lng.label}
                      error={lng.error}
                      placeholder={lng.placeholder}
                      errMsg={lng.errMsg}
                      value={lng.value}
                      change={(e) => this.onChangeHandler(e)}
                      name={lng.name}
                      inputWrapper={styles.inputPosition}
                      disabled={
                        this.state.loading || this.state.typeOfDraw != POLYLINE
                      }
                      phoneMode={true}
                      type="number"
                    />
                    <button
                      disabled={
                        this.state.loading || this.state.typeOfDraw != POLYLINE
                      }
                      style={{
                        cursor:
                          this.state.typeOfDraw == POLYLINE
                            ? "pointer"
                            : "not-allowed",
                      }}
                      className={styles.btnAddLocation}
                      onClick={this.onClickAddLocationPointHandler}
                    >
                      <img
                        src={LOCATION_ICON}
                        disabled={
                          this.state.loading ||
                          this.state.typeOfDraw != POLYLINE
                        }
                        alt=""
                        className={styles.mapIcon}
                      />
                    </button>
                  </div>
                  <div className={styles.btnWrapper}>
                    <button
                      style={{
                        visibility:
                          this.state.typeOfDraw == POLYLINE &&
                          this.state.drawFencingPoints != null &&
                          Object.keys(this.state.drawFencingPoints).length
                            ? "visible"
                            : "hidden",
                      }}
                      className={styles.btnDrawLoaction}
                      onClick={this.onClickShowDisplayCordHandler}
                    >
                      <img
                        src={LOCATION_ICON}
                        alt=""
                        className={styles.mapIcon}
                      />
                    </button>

                    <span
                      className={`${styles.iconTypeWrapper} ${styles.actionIconTypeWrapper}`}
                      onClick={this.closeActionWindowHandler}
                    >
                      <span
                        className={
                          this.state.loading
                            ? `${styles.iconTDisable} ${styles.iconT}`
                            : styles.iconT
                        }
                      >
                        <Icon
                          defination={{
                            prefix: "fas",
                            iconName: "times",
                          }}
                        />
                      </span>
                    </span>
                    <span
                      className={`${styles.iconTypeWrapper} ${styles.actionIconTypeWrapper}`}
                      onClick={this.onClickSubmitDrawHandler}
                    >
                      <span
                        className={
                          this.state.loading
                            ? `${styles.iconTDisable} ${styles.iconT}`
                            : styles.iconT
                        }
                      >
                        <Icon
                          defination={{
                            prefix: "fas",
                            iconName: "check",
                          }}
                        />
                      </span>
                    </span>
                  </div>
                </div>
              )}
              {this.state.action == UPLOAD_KLM && (
                <div className={styles.createZoneInfoWrapper}>
                  <div
                    className={styles.errorMsg}
                    style={{
                      visibility: this.state.uploadFile.error
                        ? "visible"
                        : "hidden",
                    }}
                  >
                    <span>Invalid.upload a KML file</span>
                  </div>
                  <UploadFile
                    label="Upload KML"
                    inputWrapper={styles.uploadFileWrapper}
                    showLoader={this.state.loading}
                    error={this.state.uploadFile.error}
                    errMsg={""}
                    name="upload_image"
                    phoneMode={true}
                    acceptedFiles=".kml"
                    fileName={this.state.uploadFile.fileName}
                    placeholder={this.state.uploadFile.placeholder}
                    clickAction={(e) => this.uploadFileHandler(e)}
                    imgurl={
                      this.state.uploadFile.fileName != ""
                        ? URL.createObjectURL(this.state.uploadFile.value)
                        : ""
                    }
                    showPreview={false}
                  />
                  <Input
                    label={fileName.label}
                    error={fileName.error}
                    errMsg={fileName.errMsg}
                    change={(e) => this.onChangeHandler(e)}
                    value={fileName.value}
                    name={fileName.name}
                    placeholder="Enter a name"
                    inputWrapper={styles.inputWrapper}
                    disabled={this.state.loading}
                    phoneMode={true}
                  />
                  <Input
                    label={fillColor.label}
                    error={fillColor.error}
                    change={(e) => this.onChangeHandler(e)}
                    errMsg={fillColor.errMsg}
                    value={fillColor.value}
                    name={fillColor.name}
                    inputWrapper={styles.inputWrapper}
                    disabled={this.state.loading}
                    phoneMode={true}
                    type="color"
                  />

                  <div className={styles.btnWrapper}>
                    <span
                      className={`${styles.iconTypeWrapper} ${styles.actionIconTypeWrapper}`}
                      onClick={this.closeActionWindowHandler}
                    >
                      <span
                        className={
                          this.state.loading
                            ? `${styles.iconTDisable} ${styles.iconT}`
                            : styles.iconT
                        }
                      >
                        <Icon
                          defination={{
                            prefix: "fas",
                            iconName: "times",
                          }}
                        />
                      </span>
                    </span>
                    <span
                      className={`${styles.iconTypeWrapper} ${styles.actionIconTypeWrapper}`}
                      onClick={this.onClickUploadKmlHandler}
                    >
                      <span
                        className={
                          this.state.loading
                            ? `${styles.iconTDisable} ${styles.iconT}`
                            : styles.iconT
                        }
                      >
                        <Icon
                          defination={{
                            prefix: "fas",
                            iconName: "check",
                          }}
                        />
                      </span>
                    </span>
                  </div>
                </div>
              )}
            </div>
          )}
      </>
    );
  };
  zoneVehiclesDataHandler = () => {
    if (this.state.vehiclesOfFence.length == 0)
      return <p className={styles.loadingDataMsg}>No Vehicles Found</p>;
    return this.state.vehiclesOfFence.map((item, index) => {
      return (
        <div className={styles.tableRow} key={index}>
          <div className={styles.textCellZone}>
            {item.licensePlate != null ? item.licensePlate.number : "N/A"}
          </div>
          <div className={styles.textCellZone}>
            {VEHICLE_TYPES[item.vClass.lookupId].icon}
          </div>
          <div className={styles.textCellZone}>
            <span
              className={styles.deviceDote}
              style={{
                backgroundColor: this.isOnlineHandler(item)
                  ? "rgb(0, 181, 0)"
                  : "rgb(237, 31, 3)",
              }}
            ></span>
          </div>
          <div className={styles.textCellZone}>
            <img
              className={styles.imgVehicle}
              alt="alert-status-icon"
              src={ALERT}
            />
          </div>
          <div className={styles.textCellZone}>
            <span
              className={`${styles.infoIcon} ${styles.noTerminalTooltip}`}
              onClick={() => this.onOpenVehicleDetailsHandler(item)}
              style={item.terminal == null ? { cursor: "not-allowed" } : {}}
            >
              <span className={styles.smallTooltip}>No Terminal</span>
              <Icon
                defination={{
                  prefix: "fas",
                  iconName: "info-circle",
                }}
              />
            </span>
          </div>
        </div>
      );
    });
  };
  getClusterVehiclesListHandler = () => {
    return (
      <div
        className={
          !this.state.showClusterVehicles
            ? ` ${styles.closeNotification}`
            : styles.clusterVehiclesWrapper
        }
      >
        {this.state.showClusterVehicles && (
          <>
            <div className={styles.header}>
              <span className={styles.title}>
                <span
                  className={styles.closeIcon}
                  style={{ marginRight: ".2rem" }}
                >
                  <Icon
                    defination={{
                      prefix: "far",
                      iconName: "list-ul",
                    }}
                  />
                </span>
                Vehicles
              </span>
              <span
                className={styles.closeIcon}
                onClick={this.updateVehiclesInClusterArrayHandler}
              >
                <Icon
                  defination={{
                    prefix: "far",
                    iconName: "times",
                  }}
                />
              </span>
            </div>
          </>
        )}
        {!this.props.phoneMode && (
          <div className={styles.tableWrapper}>
            {this.state.showClusterVehicles && (
              <>
                <div className={`${styles.tableRow} ${styles.borderbottom} `}>
                  {this.clusterVehicleHeaderHandler()}
                </div>
                <div className={styles.table}>
                  {this.clusterVehiclesDataHandler()}
                </div>
              </>
            )}
          </div>
        )}
        {this.props.phoneMode && this.state.showClusterVehicles && (
          <div className={styles.tableWrapper}>
            {this.getDataClusterForMobileHandler()}
          </div>
        )}
      </div>
    );
  };

  onClickSearchHandler = (search) => {
    if (search === "") {
      return;
    } else {
      let arrMatch = [];
      this.state.originalOverviewList.map((item) => {
        //loop and push all matched devices
        try {
          if (
            item.licensePlate.number
              .toString()
              .toLowerCase()
              .indexOf(search) !== -1 ||
            item.terminal.externalId.toString().indexOf(search) !== -1
          ) {
            arrMatch.push(item);
          }
        } catch (error) {}
      });

      this.setState({
        overviewArray: arrMatch,
      });
    }
  };
  onChangeSearchHandler = (event) => {
    if (event.target.value.trim() === "") {
      this.setState({
        searchKey: event.target.value,
        overviewArray: JSON.parse(
          JSON.stringify(this.state.originalOverviewList)
        ),
      });
    } else {
      this.setState({ searchKey: event.target.value }, () => {
        this.onClickSearchHandler(this.state.searchKey.trim().toLowerCase());
      });
    }
  };
  getSizeOfBytpeSizeOfBroadcastmessageHandler = () => {
    var byteSize = new Blob([this.state.value]).size;
    return byteSize;
  };
  render() {
    return (
      <>
        <div className={styles.controlsWrapper}>
          <div>
            <MapButton
              id="fencing"
              label={""} //Permitted Zone
              disabled={this.state.disableControls || this.state.loading}
              btnAction={this.onClickFencingHandler}
              icon={{
                prefix: "fas",
                iconName: "border-outer",
              }}
              customClass={styles.btnScreen}
              iconClass={styles.iconMap}
              toolTip="Zone search"
              toolTipOverride={styles.toolTipOverride}
            />
          </div>
          {this.props.btnOptions.filtersBtn && (
            <MapButton
              id="filter"
              label={""} //Filter
              toolTip="Filter by groups"
              btnAction={this.showFilters}
              icon={{ prefix: "fas", iconName: "filter" }}
              showClose={false}
              customClass={styles.btnScreen}
              disabled={this.state.disableControls}
            />
          )}
          {this.props.btnOptions.centerBtn && (
            <MapButton
              id="Center"
              label={""} //Center
              btnAction={this.props.recenterMap}
              imageType={CenterIcon}
              showClose={false}
              customClass={styles.btnScreen}
              imageClass={styles.imageClass}
              toolTip="Re-Center map"
            />
          )}

          <button
            className={styles.NotificationMobileWrapper}
            disabled={this.state.disableControls || this.state.loading}
          >
            <span
              className={styles.textNotification}
              onClick={this.openNotificationMenuhandler}
            >
              <Icon defination={{ prefix: "fal", iconName: "bell" }} />
              &nbsp;({this.unreadNotifications.unreadNotifications})
            </span>
            <span
              className={styles.dorpDownListWrapper}
              style={{
                visibility: this.state.menuNotification ? "visible" : "hidden",
              }}
            >
              <span
                onClick={() =>
                  this.onClickshowNotificationHandler(SOS_NOTIFICATION)
                }
                className={styles.imgWrapperNotification}
              >
                <img
                  className={styles.imgNotification}
                  alt="sos-notification"
                  src={IconSOS}
                />
                <span>({this.unreadNotifications[SOS_NOTIFICATION]})</span>
              </span>
              <span className={styles.border}></span>
              <span
                onClick={() =>
                  this.onClickshowNotificationHandler(FENCE_NOTIFICATION)
                }
                className={styles.imgWrapperNotification}
              >
                <img
                  className={styles.imgNotification}
                  alt="zone-notification"
                  src={IconOutOfZone}
                />
                <span>({this.unreadNotifications[FENCE_NOTIFICATION]})</span>
              </span>

              <span className={styles.border}></span>
              <span
                onClick={() =>
                  this.onClickshowNotificationHandler(CHAT_NOTIFICCATION)
                }
                className={styles.imgWrapperNotification}
              >
                <img
                  className={styles.imgNotification}
                  alt="zone-notification"
                  src={IconChatNotification}
                />
                <span>({this.unreadNotifications[CHAT_NOTIFICCATION]})</span>
              </span>
            </span>
          </button>
          <MapButton
            id={SOS_NOTIFICATION}
            disabled={this.state.disableControls || this.state.loading}
            showClose={false}
            customClass={styles.btnNotification}
            btnAction={() =>
              this.onClickshowNotificationHandler(SOS_NOTIFICATION)
            }
            tool={styles.imgWrapperNotification}
            label={
              <span className={styles.imgWrapperNotification}>
                <img
                  style={{ width: "3rem" }}
                  className={styles.imgNotification}
                  src={IconSOS}
                  alt="sos-notification"
                />
                <span className={styles.textNotification}>
                  ({this.unreadNotifications[SOS_NOTIFICATION]})
                </span>
              </span>
            }
            customStyles={{
              width: "7rem",
            }}
            toolTip="SOS Notification"
          />
          <MapButton
            id={CHAT_NOTIFICCATION}
            showClose={false}
            btnAction={() =>
              this.onClickshowNotificationHandler(CHAT_NOTIFICCATION)
            }
            customClass={styles.btnNotification}
            tool={styles.imgWrapperNotification}
            label={
              <span className={styles.imgWrapperNotification}>
                <img
                  className={styles.imgNotification}
                  src={IconChatNotification}
                  alt="Msg-notification"
                />
                <span className={styles.textNotification}>
                  ({this.unreadNotifications[CHAT_NOTIFICCATION]})
                </span>
              </span>
            }
            disabled={this.state.disableControls || this.state.loading}
            customStyles={{
              width: "7rem",
            }}
            toolTip="Chat Notification"
          />
          <MapButton
            id={FENCE_NOTIFICATION}
            showClose={false}
            btnAction={() =>
              this.onClickshowNotificationHandler(FENCE_NOTIFICATION)
            }
            customClass={styles.btnNotification}
            tool={styles.imgWrapperNotification}
            label={
              <span className={styles.imgWrapperNotification}>
                <img
                  className={styles.imgNotification}
                  src={IconOutOfZone}
                  alt="out-of-zone-notification"
                />
                <span className={styles.textNotification}>
                  ({this.unreadNotifications[FENCE_NOTIFICATION]})
                </span>
              </span>
            }
            disabled={this.state.disableControls || this.state.loading}
            customStyles={{
              width: "7rem",
            }}
            toolTip="Out of zone Notification"
          />

          <MapButton
            id="Broadcast"
            label={""} //Broadcast
            btnAction={this.onClickshowBroadcastHandler}
            showClose={false}
            icon={{ prefix: "fas", iconName: "megaphone" }}
            disabled={this.state.disableControls || this.state.loading}
            customClass={styles.btnScreen}
            iconClass={styles.iconMap}
            toolTip="Broadcast Message"
          />
          <MapButton
            id="overview"
            label={""} //OVERVIEW
            btnAction={this.onClickOverViewHandler}
            showClose={false}
            icon={{ prefix: "fas", iconName: "list-ul" }}
            disabled={this.state.disableControls || this.state.loading}
            customClass={styles.btnScreen}
            iconClass={styles.iconMap}
            toolTip="Overview"
          />
        </div>
        <div className={styles.allBoxwrapperContainer}>
          <div className={styles.allBoxwrapper}>
            {this.getMultizoneHandler()}
            {this.getClusterVehiclesListHandler()}
            <div
              className={
                !this.state.showBoardcasts
                  ? ` ${styles.closeNotification}`
                  : styles.notificationWrapper
              }
            >
              {this.state.showBoardcasts && (
                <>
                  <div className={styles.header}>
                    <span className={styles.title}>
                      <span
                        className={styles.closeIcon}
                        style={{ marginRight: ".2rem" }}
                      >
                        <Icon
                          defination={{
                            prefix: "far",
                            iconName: "megaphone",
                          }}
                        />
                      </span>
                      Broadcast Messages
                    </span>
                    <span
                      className={styles.closeIcon}
                      onClick={this.onClickshowBroadcastHandler}
                    >
                      <Icon
                        defination={{
                          prefix: "far",
                          iconName: "times",
                        }}
                      />
                    </span>
                  </div>
                  <div
                    className={styles.containerBroadcast}
                    id={BROADCAST_CONTIANER}
                    style={{ height: Controller.getIsUser() ? "48rem" : "" }}
                    onWheel={this.onWeelBroadcastHandler}
                    onScroll={this.onScrollLoadBroadcastHandler}
                    onTouchEnd={(e) => this.onWeelBroadcastHandler(e)}
                  >
                    <span
                      className={styles.spinnerLoader}
                      style={{ display: this.state.loading ? "block" : "none" }}
                    >
                      <Icon
                        defination={{ prefix: "fal", iconName: "spinner" }}
                        isSpinner={true}
                      />
                    </span>
                    {this.state.broadcasts.map((item, index) => {
                      return (
                        <div className={styles.msg} key={index} id={index + 1}>
                          <div
                            className={styles.dateDivider}
                            style={{
                              display: this.checkDateSeparatorHandler(
                                index,
                                item
                              )
                                ? "flex"
                                : "none",
                            }}
                          >
                            <span className={styles.date}>
                              {moment(item.createdAt).format("LL")}
                            </span>
                          </div>
                          <div className={`${styles.bubble} ${styles.alt}`}>
                            {
                              <div className={styles.txt}>
                                <span className={styles.message}>
                                  {item.body}
                                </span>
                                <span className={styles.timestamp}>
                                  <span style={{ marginRight: ".2rem" }}>
                                    {moment(item.createdAt).format("HH:mm")}
                                  </span>
                                  {this.getStatusIconBroadcastHandler(
                                    item.lookupId,
                                    item.status
                                  )}
                                </span>
                              </div>
                            }
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  {!Controller.getIsUser() && (
                    <div
                      className={styles.wInputContainer}
                      onKeyPress={this.onKeyDownHandler}
                      ref={this.textArea}
                    >
                      <span className={styles.msCount}>Message length</span>
                      <div
                        className={
                          this.getSizeOfBytpeSizeOfBroadcastmessageHandler() >
                          this.MAX_SIZE
                            ? `${styles.totalCount} ${styles.glow}`
                            : styles.totalCount
                        }
                        id="BOX"
                      >
                        <span>
                          {
                            <span>
                              {this.getSizeOfBytpeSizeOfBroadcastmessageHandler()}
                            </span>
                          }
                          /{this.MAX_SIZE}
                        </span>
                      </div>
                      <textarea
                        className={styles.wInputText}
                        value={this.state.value}
                        placeholder={this.preview}
                        dir="auto"
                        id={INNER_TEXT_BOARDCAST}
                        onKeyDown={this.onKeyDownHandler}
                        onChange={(e) => this.onChangeBroadcastMsgHandler(e)}
                      />

                      <span className={styles.sendMsgBtn}>
                        <img
                          src={SEND_ICON}
                          onClick={this.onClickMessageBroadcastHandler}
                          alt="send message icon"
                          className={styles.sendMsgBtn}
                        />
                      </span>
                    </div>
                  )}
                  <p
                    className={styles.msgLength}
                    style={{
                      visibility:
                        this.getSizeOfBytpeSizeOfBroadcastmessageHandler() >
                        this.MAX_SIZE
                          ? "visible"
                          : "hidden",
                    }}
                  >
                    <strong>{this.messageError}</strong>
                  </p>
                </>
              )}
            </div>
            <div
              className={
                !this.state.showOverview
                  ? ` ${styles.closeNotification}`
                  : styles.overviewWrapper
              }
            >
              {this.state.showOverview && (
                <>
                  <div className={styles.header}>
                    <span className={styles.title}>
                      <span
                        className={styles.closeIcon}
                        style={{ marginRight: ".2rem" }}
                      >
                        <Icon
                          defination={{
                            prefix: "far",
                            iconName: "list-ul",
                          }}
                        />
                      </span>
                      {OVERVIEW}
                    </span>
                    <span
                      className={styles.closeIcon}
                      onClick={this.onClickOverViewHandler}
                    >
                      <Icon
                        defination={{
                          prefix: "far",
                          iconName: "times",
                        }}
                      />
                    </span>
                  </div>
                </>
              )}
              <div className={styles.tableWrapper}>
                {this.state.showOverview && (
                  <>
                    <div className={styles.searchDeviceWrapper}>
                      <span className={styles.iconSearch}>
                        <Icon
                          defination={{ prefix: "fas", iconName: "search" }}
                        />
                      </span>
                      <input
                        onChange={(e) => this.onChangeSearchHandler(e)}
                        placeholder="Search"
                        value={this.state.searchKey}
                        className={styles.searchInput}
                        type="search"
                      />
                    </div>
                    <div
                      className={`${styles.tableRow} ${styles.borderbottom} `}
                    >
                      {this.state.showOverview && this.overviewHeaderHandler()}
                    </div>
                    <div className={styles.table}>
                      {this.state.showOverview && this.overviewDataHandler()}
                    </div>
                  </>
                )}
              </div>
              {this.state.showOverview && (
                <div className={styles.aggregationSummary}>
                  <div className={styles.itemAgrWrapper}>
                    <img
                      src={ONLINE_ICON_LEGEND}
                      alt="online-icon"
                      className={styles.imgIcon}
                    />
                    &nbsp;
                    <span>{this.state.agrOverview.onlineCount}</span>{" "}
                  </div>
                  <div className={styles.itemAgrWrapper}>
                    <img
                      src={OFFLINE_ICON_LEGEND}
                      alt="offline-icon"
                      className={styles.imgIcon}
                    />
                    &nbsp;
                    <span>{this.state.agrOverview.offlineCount}</span>
                  </div>
                  <div className={styles.itemAgrWrapper}>
                    <span className={styles.imgCenter}>
                      <img
                        src={KEEP_ALIVE_ON}
                        alt="keep-alive-icon"
                        className={styles.imgIcon}
                      />
                    </span>
                    &nbsp;
                    <span>{this.state.agrOverview.aliveCount}</span>
                  </div>
                  <div className={styles.itemAgrWrapper}>
                    <span className={styles.imgCenter}>
                      <img
                        src={KEEP_ALIVE_OFF}
                        alt="keep-alive-icon"
                        className={styles.imgIcon}
                      />
                    </span>
                    &nbsp;
                    <span>{this.state.agrOverview.deadCount}</span>
                  </div>
                  <div className={styles.itemAgrWrapper}>
                    <span className={`${styles.imgCenter}`}>
                      <img src={SOS} alt="sos-icon" className={styles.sosImg} />
                    </span>
                    &nbsp;
                    <span>{this.state.agrOverview.sosCount}</span>
                  </div>
                </div>
              )}
            </div>
            <div
              className={
                !this.state.showNotification
                  ? ` ${styles.closeNotification}`
                  : styles.notificationWrapper
              }
              style={{ height: this.state.showFencing ? 0 : "" }}
            >
              {this.state.showNotification && (
                <div className={styles.header}>
                  <span className={styles.title}>
                    <span
                      className={styles.closeIcon}
                      style={{ marginRight: ".2rem" }}
                    >
                      <img
                        alt="Notification-icon"
                        src={
                          this.titleNotificationandImg[
                            this.state.viewNotification
                          ].img
                        }
                      />
                      {/* <Icon
                        defination={{
                          prefix: "far",
                          iconName: "bell",
                        }}
                      /> */}
                    </span>
                    {
                      this.titleNotificationandImg[this.state.viewNotification]
                        .title
                    }
                    <span className={styles.countMobileNotification}>
                      &nbsp;(
                      {this.unreadNotifications[this.state.viewNotification]}
                      &nbsp;Unread)
                    </span>
                  </span>
                  <span
                    className={styles.closeIcon}
                    onClick={() =>
                      this.onClickshowNotificationHandler(
                        this.state.viewNotification,
                        true
                      )
                    }
                  >
                    <Icon
                      defination={{
                        prefix: "far",
                        iconName: "times",
                      }}
                    />
                  </span>
                </div>
              )}
              <div
                className={styles.container}
                id={NOTIFICATION_CONTAINER}
                ref={this.container}
                onTouchEnd={(e) => this.onTouchMoveHandler(e)}
                onWheel={this.onWeelNotificationHandler}
                onScroll={this.onScrollLoadNotificationsHandler}
              >
                <span
                  className={styles.spinnerLoader}
                  style={{ display: this.state.loading ? "block" : "none" }}
                >
                  <Icon
                    defination={{ prefix: "fal", iconName: "spinner" }}
                    isSpinner={true}
                  />
                </span>
                {this.state[this.state.viewNotification].map((item, index) => {
                  return (
                    <div
                      key={index}
                      id={index + 1}
                      onClick={() =>
                        this.onClickNotificationHandler(item, index)
                      }
                      onTouchStart={() => this.onTouchStartHandler(item, index)}
                      className={styles.notification}
                      style={{
                        backgroundColor:
                          !item.read &&
                          this.state.updated[item.lookupId] == undefined
                            ? "#cbe2ea"
                            : "#fff",
                      }}
                    >
                      <div className={styles.msgWrapper}>
                        <span className={styles.iconMsg}>
                          <img
                            alt="notifiction-icon"
                            style={{
                              width:
                                this.props.phoneMode &&
                                item.notificationType == 2
                                  ? "5rem"
                                  : "",
                            }}
                            className={styles.imgIconPhone}
                            src={this.iconNotifiction[item.notificationType]}
                          />
                        </span>
                        <span className={styles.msgTextWrapper}>
                          <span className={styles.topLine}>
                            <span className={styles.vinNumber}>
                              <strong>License Number:</strong>&nbsp;
                              {item.vehicle.licensePlate.number}
                            </span>
                            <span className={styles.notificationDate}>
                              <strong>
                                {" "}
                                {moment(item.createdAt).format(
                                  "MM-DD-YYYY HH:mm:ss"
                                )}
                              </strong>
                            </span>
                          </span>
                          <span className={styles.dateMobile}>
                            <strong>
                              {" "}
                              {moment(item.createdAt).format(
                                "MM-DD-YYYY HH:mm:ss"
                              )}
                            </strong>
                          </span>
                          {item.notificationType !== 4 && (
                            <span className={styles.bottomLine}>
                              <span>
                                <strong>Position:</strong>&nbsp;
                              </span>
                              <span>
                                Lat&nbsp;
                                {parseFloat(item.latitude).toFixed(3)}
                                &#xb0;&nbsp;,&nbsp;
                              </span>
                              <span>
                                Long&nbsp;
                                {parseFloat(item.longitude).toFixed(3)}
                                &#xb0;
                              </span>
                              &nbsp;
                              <span
                                className={styles.gpsStatus}
                                style={{
                                  display:
                                    item.notificationType == 2
                                      ? "block"
                                      : "none",
                                }}
                              >
                                <span className={styles.toolTip}>
                                  {item.gpsFixStatus == 0
                                    ? "UNLOCK GPS"
                                    : "LOCK GPS"}
                                </span>
                                {
                                  <Icon
                                    defination={{
                                      prefix: "fas",
                                      iconName:
                                        item.gpsFixStatus !== null &&
                                        parseInt(item.gpsFixStatus) == 0
                                          ? "location-slash"
                                          : "location",
                                    }}
                                  />
                                }
                              </span>
                            </span>
                          )}
                          {item.notificationType == 4 && (
                            <span className={styles.bottomLine}>
                              <span className={styles.description}>
                                <span
                                  className={styles.plusIcon}
                                  style={{
                                    display:
                                      item.read &&
                                      item.unreadNotificationsOfSameTypeAndVehicleForUser <=
                                        1
                                        ? "none"
                                        : "inline",
                                  }}
                                >
                                  (
                                  <Icon
                                    defination={{
                                      prefix: "fas",
                                      iconName: "plus",
                                    }}
                                  />
                                  &nbsp;
                                  {
                                    item.unreadNotificationsOfSameTypeAndVehicleForUser
                                  }
                                  )
                                </span>
                                {trimStringAtEnd(
                                  item.description,
                                  this.props.phoneMode ? 30 : 45
                                )}
                              </span>
                            </span>
                          )}
                        </span>
                      </div>
                      <span className={styles.breakLineNotification}></span>
                    </div>
                  );
                })}
              </div>
            </div>
            <div
              style={{ height: this.state.showFencing ? 0 : "" }}
              className={
                this.state.showFilters
                  ? `${styles.filtersWrapper} ${styles.wrapperCloseFilter}`
                  : styles.filtersWrapper
              }
            >
              <VehicleFilters
                closeFilters={this.showFilters}
                show={this.state.showFilters}
                groupFilters={this.state.groupFilters}
                onCheck={this.onCheckHandler}
                ischecked={this.ischecked}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default Controls;
