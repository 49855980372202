import React, { Component } from "react";
import Icon from "../../Icon/Icon";
import styles from "./ZoomControls.module.scss";
export default class ZoomControls extends Component {
  render() {
    return (
      <div className={styles.controlWrappers}>
        <span className={styles.icon} onClick={this.props.zoomin}> 
          <Icon defination={{ prefix: "fas", iconName: "plus" }} />
        </span>
        <span className={styles.separator}></span>
        <span className={styles.icon} onClick={this.props.zoomout}>
          <Icon defination={{ prefix: "fas", iconName: "minus" }} />
        </span>
      </div>
    );
  }
}
