import React, { Component } from "react";
import styles from "./VersionUpdate.module.scss";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import Icon from "../Icon/Icon";
import { getVersionNo } from "../../Store/localStorageManager/localStorageManager";
class VersionUpdate extends Component {
  renderTimeHandler = (time) => {
    if (time.remainingTime == 0) {
      this.props.updateVersion();
    }
    return <span className={styles.time}>{time.remainingTime}</span>;
  };
  render() {
    return (
      <div className={styles.VersionUpdates}>
        <h3 className={styles.header}>
          <span className={styles.headerIcon}>
            <Icon
              defination={{
                prefix: "fal",
                iconName: "external-link-square-alt",
              }}
            />
          </span>
          Update Version
        </h3>
        <div className={styles.breakLine}></div>
        <p className={styles.msg}>
         <span>{`Update ${getVersionNo()} is Available.`}</span><span>{` Application will be updated in 10 seconds.`}</span>
        </p>
        <div className={styles.counterWrapper}>
          <CountdownCircleTimer
            isPlaying
            duration={10}
            onComplete={() => [true, 1000]}
            colors={[
              ["#646464", 0.33],
              ["#646464", 0.33],
              ["#646464", 0.33],
            ]}
          >
            {this.renderTimeHandler}
          </CountdownCircleTimer>
        </div>
      </div>
    );
  }
}

export default VersionUpdate;
