import React, { Component, Fragment } from "react";
import styles from "./Pagination.module.scss";
import Icon from "../Icon/Icon";
import { PAGE_LIMIT } from "../../Util/constants";
export default class Pagination extends Component {
  totalLoadedRecords = () => {
    let limit = PAGE_LIMIT;
    return this.props.totalRecordsPerPage + (this.props.pageNumber - 1) * limit;
  };

  getPaginationNumbers = () => {
    if (this.props.totalPages == 1) {
      return <span className={styles.activePage} style={{ cursor: this.props.disabled ? "not-allowed" : "" }}>1</span>;
    }
    if (this.props.totalPages >= 2) {
      let middleIndex;

      if (this.props.pageNumber < 2) {
        middleIndex = 2;
      } else if (
        this.props.pageNumber == this.props.totalPages &&
        this.props.pageNumber - 1 >= 2
      ) {
        middleIndex = this.props.pageNumber - 1;
      } else {
        middleIndex = this.props.pageNumber;
      }

      return (
        <>
          <span
            className={
              this.props.pageNumber === middleIndex - 1
                ? styles.activePage
                : styles.inactivePages
            }
            style={{ cursor: this.props.disabled ? "not-allowed" : "" }}
            onClick={
              !this.props.disabled
                ? () => this.props.setPage(middleIndex - 1)
                : () => {}
            }
          >
            {middleIndex - 1}
          </span>
          <span
            className={
              this.props.pageNumber == middleIndex
                ? styles.activePage
                : styles.inactivePages
            }
            style={{ cursor: this.props.disabled ? "not-allowed" : "" }}
            onClick={
              !this.props.disabled
                ? () => this.props.setPage(middleIndex)
                : () => {}
            }
          >
            {middleIndex}
          </span>
          {this.props.totalPages >= middleIndex + 1 && (
            <span
              className={
                this.props.pageNumber == middleIndex + 1
                  ? styles.activePage
                  : styles.inactivePages
              }
              style={{ cursor: this.props.disabled ? "not-allowed" : "" }}
              onClick={
                !this.props.disabled
                  ? () => this.props.setPage(middleIndex + 1)
                  : () => {}
              }
            >
              {middleIndex + 1}
            </span>
          )}
        </>
      );
    }
  };

  render() {
    const { pagination } = this.props.style;

    return (
      <div className={pagination} id="pagination">
        {!this.props.hideDetailText && (
          <p className={styles.amountText}>
            <strong>{`Showing  ${this.totalLoadedRecords()} of ${
              this.props.totalRecords
            } entries`}</strong>
          </p>
        )}
        <div className={styles.btnWrapper}>
          <button
            disabled={this.props.disabled || this.props.pageNumber == 1}
            className={`${styles.prev} ${styles.navigation}`}
            onClick={this.props.prev}
            style={{ cursor: this.props.disabled ? "not-allowed" : "" }}
          >
            <Icon
              defination={{ prefix: "fal", iconName: "angle-double-left" }}
            />
          </button>
          {this.getPaginationNumbers()}
          <button
            disabled={
              this.props.disabled ||
              this.props.pageNumber == this.props.totalPages
            }
            className={`${styles.next} ${styles.navigation}`}
            onClick={this.props.next}
            style={{ cursor: this.props.disabled ? "not-allowed" : "" }}
          >
            <Icon
              defination={{ prefix: "fal", iconName: "angle-double-right" }}
            />
          </button>
        </div>
      </div>
    );
  }
}
