import React, { Component } from "react";
// import validator from "validator";
import {
  CANCEL,
  SUBMIT,
  PICTURE,
  UPLOAD_PRESET,
  CLOUDINARY_UPLOAD_PRESET,
  FILE,
  KM_HR,
  MILES,
} from "../../Util/constants";
import FormButtons from "../FormButtons/FormButtons";
import Controller from "../../Controller";
import SearchBar from "../GoogleMap/SearchBar/SearchBar";
import Icon from "../Icon/Icon";
import Tabs from "../Tabs/Tabs";
import Input from "../Input/Input";
import SpinnerLoader from "../SpinnerLoader/SpinnerLoader";
import RadioButton from "../RadioButton/RadioButton";
import Telephone from "../Telephone/Telephone";
import UploadFile from "../UploadFile/UploadFile";
import styles from "./UpdateAccount.module.scss";
import Swal from "sweetalert2/dist/sweetalert2.js";
import {
  updateAccount,
  updateUser,
  integrationPartnersAndChatConfig,
  updateAccountChatConfig,
} from "../../RequestManager/GqlBuilderAccount";
import { httpRequest } from "../../RequestManager/HttpRequest";
import { getPartnerLookupId } from "../../Util/globalFunc";
import ImageCrop from "../ImageCrop/ImageCrop";
import Button from "../Button/Button";
import PreviewImage from "../PreviewImage/PreviewImage";
import CheckBox from "../CheckBox/CheckBox";
export default class UpdateAccount extends Component {
  avatar_url = "";
  _partnerLookupId = null;
  lookupId = null;
  selectedIntegrationPartner = null;
  state = {
    loading: false,
    isMouted: false,
    isValidForm: true,
    speed: KM_HR,
    activeTab: 0,
    croppedImageUrl: null,
    showPreviewImage: false,
    openImageCropModel: false,
    syncAccountDetailsWithPartner: true,
    selectedIntegrationPartner: null,
    address: {
      type: "address",
      address: "",
      country: "",
      error: false,
      size: 250,
      label: "Legal Address",
      errMsg: "Invalid address",
      lat: "",
      lng: "",
    },
    uploadFile: {
      value: "",
      error: false,
      errMsg: "select file to upload",
      label: "",
      placeholder: "Select picture",
      fileName: "",
    },
    updateAccount: {
      email: {
        label: "Email",
        placeholder: "Enter email",
        value: "",
        error: false,
        name: "email",
        type: "text",
        errMsg: "Invalid email",
        disabled: true,
      },
      companyName: {
        label: "Company name",
        value: "",
        error: false,
        name: "companyName",
        placeholder: "Enter company name",
        type: "text",
        errMsg: "Invalid company name",
      },
      phone: {
        label: "Telephone",
        value: "",
        error: false,
        name: "phone",
        type: "text",
        placeholder: "Enter phone ",
        errMsg: "Invalid telephone",
      },
      postcode: {
        label: "Postcode",
        value: "",
        error: false,
        name: "postcode",
        type: "text",
        placeholder: "Enter postcode ",
        errMsg: "Invalid postcode",
      },
      apiKey: {
        label: "API key",
        value: "",
        error: false,
        name: "apiKey",
        type: "text",
        errMsg: "invalid web token",
      },
    },
    newPassword: {
      value: "",
      error: false,
      placeholder: "Enter your new password",
      label: "new password",
      errMsg: "Enter password. Min length is 6 characters.",
      type: "password",
      disabled: false,
      size: 254,
      name: "newPassword",
    },
    currentPassword: {
      value: "",
      error: false,
      placeholder: "Enter your current password",
      label: "Current password",
      errMsg: "Enter current password.",
      type: "password",
      //updateKey: "phone",
      disabled: false,
      size: 254,
      name: "currentPassword",
    },
    accountChatConfiguration: {
      recommendedTextMsgBytes: {
        value: "",
        error: false,
        placeholder: "Enter text lenght.valid 1-65535 Btyes",
        label: "Recommended text message (Bytes)",
        errMsg: "Valid values 1-65535",
        type: "number",
        disabled: false,
        size: 254,
        name: "recommendedTextMsgBytes",
      },
      ackMessageTimeout: {
        value: "",
        error: false,
        placeholder: "Enter number.valid 20-25",
        label: "Ack message timeout (Seconds)",
        errMsg: "Valid values 20-25",
        type: "number",
        disabled: false,
        size: 254,
        name: "ackMessageTimeout",
      },
      transmitMaxRetries: {
        value: "",
        error: false,
        placeholder: "Enter number.valid 0-5",
        label: "Retrasmit max number",
        errMsg: "Valid values 0-5",
        type: "number",
        disabled: false,
        size: 254,
        name: "transmitMaxRetries",
      },

      keepaliveTimeout: {
        value: "",
        error: false,
        placeholder: "Enter number.valid 0-150",
        label: "Keepalive timeout (Seconds)",
        errMsg: "Valid values 0-150",
        type: "number",
        disabled: false,
        size: 254,
        name: "keepaliveTimeout",
      },
    },
  };
  componentDidMount() {
    this.lookupId = Controller.getAccountId();
    this.createHttpRequestHandler();
  }
  createHttpRequestHandler = () => {
    this.setState({ loading: true }, () => {
      httpRequest(integrationPartnersAndChatConfig, {
        lookupId: this.lookupId,
      }).then((res) => {
        this._partnerLookupId = getPartnerLookupId(
          res.data.integrationPartners.partners
        );
        this.initDataHandler(res.data.accountChatConfiguration);
      });
    });
  };
  initDataHandler = (config) => {
    let obj = { ...this.state.updateAccount };
    let location = { ...this.state.address };
    let chatConfigCopy = { ...this.state.accountChatConfiguration };
    let org_obj = Controller.getBasicInfo();
    for (let key in obj) {
      if (key !== "postcode") obj[key].value = org_obj[key];
    }
    if (org_obj.location !== null) {
      location.address = org_obj.location.address;
      location.lat = org_obj.location.latitude;
      location.lng = org_obj.location.longitude;
      obj.postcode.value =
        org_obj.location.postCode == null ? "" : org_obj.location.postCode;
    }
    obj.apiKey.value = org_obj.apiKey;
    if (config !== null) {
      for (let key in chatConfigCopy) {
        chatConfigCopy[key].value = config[key];
      }
    }
    this.setState(
      {
        speed: org_obj.speedUnit === 1 ? KM_HR : MILES,
        updateAccount: obj,
        loading: false,
        address: location,
        accountChatConfiguration: chatConfigCopy,
        croppedImageUrl: org_obj.avatar,
        syncAccountDetailsWithPartner: org_obj.syncAccountDetailsWithPartner,
        selectedIntegrationPartner: org_obj.selectedIntegrationPartner,
      },
      () => {}
    );
  };
  buildInputsElementsHandler = () => {
    let arr = Object.values(this.state.updateAccount);
    return arr.map((item, index) => {
      if (item.name !== "phone" && item.name != "apiKey") {
        return (
          <Input
            type={item.type}
            name={item.name}
            key={index}
            value={item.value}
            placeholder={item.label}
            label={item.label}
            error={item.error}
            errMsg={item.errMsg}
            inputWrapper={styles.inputWrapper}
            change={(e) => this.onChangeHandler(e)}
            phoneMode={this.props.phoneMode}
            disabled={item.name == "email"}
            isRegularText={false}
          />
        );
      }
      if (item.name == "apiKey") {
        return (
          <div className={styles.inputWrapper}>
            {this.state.selectedIntegrationPartner != null &&
              this.state.selectedIntegrationPartner != "" && (
                <button className={styles.integrationpartner}>
                  {this.state.selectedIntegrationPartner}
                </button>
              )}
            <Input
              type={item.type}
              name={item.name}
              key={index}
              value={item.value}
              placeholder={item.label}
              label={item.label}
              error={item.error}
              errMsg={item.errMsg}
              inputWrapper={styles.inputWrapper}
              change={(e) => this.onChangeHandler(e)}
              phoneMode={this.props.phoneMode}
              disabled={item.name == "email"}
              isRegularText={false}
            />
          </div>
        );
      } else {
        return (
          <Telephone
            type={item.type}
            name={item.name}
            key={index}
            value={item.value}
            placeholder={item.label}
            label={item.label}
            error={item.error}
            errMsg={item.errMsg}
            isRegularText={false}
            inputWrapper={styles.inputWrapper}
            phoneMode={this.props.phoneMode}
            change={(e) => this.onChangePhoneHandler(e)}
            defaultCountry={"us"} //for no value
          />
        );
      }
    });
  };
  getMarkerHandler = () => {
    return [{ lat: 51.5074, lng: 0.1278 }];
  };

  onChangeHandler = (event) => {
    if (this.state.loading) {
      return;
    }
    let copyInputs = { ...this.state.updateAccount };
    copyInputs[event.target.name].value = event.target.value;
    copyInputs[event.target.name].error = false;
    this.setState(
      { updateAccount: copyInputs, loading: false, isValidForm: true },
      () => {}
    );
  };
  onChangePhoneHandler = (value) => {
    if (this.state.loading) return;
    let copycreate = { ...this.state.updateAccount };
    copycreate.phone.value = value;
    copycreate.phone.error = false;
    this.setState({ createAccount: copycreate, errorMsg: "" });
  };
  formatAddressHandler = (address) => {
    let copyAddress = { ...this.state.address };
    if (address === -1 || address.status !== "OK") {
      copyAddress.address = "";
      copyAddress.country = "";
      this.setState({ address: copyAddress }, () => {});
      return;
    } else {
      let arr = address.results[0].address_components;
      copyAddress.address = address.results[0].formatted_address;
      copyAddress.lat = address.results[0].geometry.location.lat;
      copyAddress.lng = address.results[0].geometry.location.lng;
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].types[0] === "country") {
          copyAddress.country = arr[i].short_name;
          copyAddress.error = false;
          break;
        }
      }
      copyAddress.error = false;
      this.setState({ address: copyAddress }, () => {});
    }
  };
  uploadFileHandler = (event) => {
    let uploadFile = { ...this.state.uploadFile };

    if (this.checkFileValidHandler(event.target.files)) {
      uploadFile.value = event.target.files[0];
      uploadFile.error = false;
      uploadFile.fileName = event.target.files[0].name;
    } else {
      uploadFile.error = true;
    }
    this.setState({
      uploadFile: uploadFile,
      openImageCropModel: true,
      croppedImageUrl: null,
    });
  };
  checkFileValidHandler = (file) => {
    if (file.length) {
      let typeOfFile = ["png", "jpg", "jpeg"];
      let typeImage = file[0].type.split("/")[1].toLowerCase();
      return typeOfFile.includes(typeImage);
    } else {
      return false;
    }
  };
  setDataInController = () => {
    let obj = this.createObjForUpdateAccount();
    let data = Controller.getBasicInfo();
    data.companyName = obj.name;
    data.name = obj.name;
    data.avatar = this.avatar_url == "" ? data.avatar : this.avatar_url;
    data.phone = obj.phone;
    data.location = obj.location;
    data.apiKey = obj.apiKey;
    data.speedUnit = this.state.speed === KM_HR ? 1 : 2;
    data.syncAccountDetailsWithPartner = obj.syncAccountDetailsWithPartner;
    data.selectedIntegrationPartner = this.selectedIntegrationPartner;
    Controller.setAccountInfo(data);
    this.props.infoUpdated();
    this.props.openModel("");
  };
  createObjForUpdateAccount = () => {
    let copyAccount = { ...this.state.updateAccount };
    return {
      syncAccountDetailsWithPartner: this.state.syncAccountDetailsWithPartner,
      phone: copyAccount.phone.value,
      name: copyAccount.companyName.value,
      apiKey: copyAccount.apiKey.value,
      //partnerLookupId: this._partnerLookupId,
      speedUnit: this.state.speed === KM_HR ? "KMH" : "MPH",
      //logo:/c,
      location: {
        address: this.state.address.address,
        country: this.state.address.country,
        latitude: this.state.address.lat.toString(),
        longitude: this.state.address.lng.toString(),
        postCode: copyAccount.postcode.value,
      },
    };
  };
  fireSwalHandler = (title, text, type) => {
    Swal.fire({
      title: title,
      text: text,
      type: type,
    }).then((res) => {
      if (type === "success") {
        this.setDataInController();
      }
    });
  };
  checkRespondServerHandler = (res) => {
    if (res.data.updateAccount != undefined)
      this.selectedIntegrationPartner =
        res.data.updateAccount.account.selectedIntegrationPartner != null
          ? res.data.updateAccount.account.selectedIntegrationPartner.name
          : null;
    else {
      this.selectedIntegrationPartner =
        Controller.getBasicInfo().selectedIntegrationPartner;
    }
    if (res.errors !== undefined) {
      this.fireSwalHandler(
        "Warning",
        res.errors[0].message.replace(/_/g, " "),
        "warning"
      );
      this.setState({ loading: false });
    } else {
      this.fireSwalHandler("Success", "Account was updated", "success");
    }
  };
  updateAccountHandler = () => {
    if (this.state.loading) return;
    //login
    if (this.state.activeTab == 0) {
      this.updateAccountDetailsHandler();
    } else if (this.state.activeTab == 1) {
      this.updateCredentialsHandler();
    } else if (this.state.activeTab == 2) {
      this.updateAccountConfigChatHandler();
    }
  };
  validChatConfigDataHanlder = () => {
    let isValid = true;
    let copyAccountConfig = { ...this.state.accountChatConfiguration };
    for (let key in copyAccountConfig) {
      let val = parseInt(copyAccountConfig[key].value);
      if (key == "recommendedTextMsgBytes") {
        if (val < 1 || val > 65535) {
          isValid = false;
          copyAccountConfig[key].error = true;
        }
      } else if (key == "ackMessageTimeout") {
        if (val < 20 || val > 25) {
          isValid = false;
          copyAccountConfig[key].error = true;
        }
      } else if (key == "transmitMaxRetries") {
        if (val < 0 || val > 5) {
          isValid = false;
          copyAccountConfig[key].error = true;
        }
      } else if (key == "keepalivePeriod") {
        if (val < 1 || val > 50) {
          isValid = false;
          copyAccountConfig[key].error = true;
        }
      } else if (key == "keepaliveTimeout") {
        if (val < 0 || val > 150) {
          isValid = false;
          copyAccountConfig[key].error = true;
        }
      }
    }
    this.setState({ accountChatConfiguration: copyAccountConfig });
    return isValid;
  };
  createObjForConfigChatHandler = () => {
    const {
      recommendedTextMsgBytes,
      ackMessageTimeout,
      transmitMaxRetries,
      keepaliveTimeout,
    } = this.state.accountChatConfiguration;
    return {
      lookupId: this.lookupId,
      chatConfiguration: {
        recommendedTextMsgBytes: recommendedTextMsgBytes.value,
        ackMessageTimeout: ackMessageTimeout.value,
        transmitMaxRetries: transmitMaxRetries.value,
        keepaliveTimeout: keepaliveTimeout.value,
      },
    };
  };
  updateAccountConfigChatHandler = () => {
    this.setState({ loading: true }, () => {
      if (this.validChatConfigDataHanlder()) {
        let obj = this.createObjForConfigChatHandler();
        httpRequest(updateAccountChatConfig, obj)
          .then((res) => {
            if (res.errors !== undefined) {
              this.fireSwalHandler(
                "Warning",
                res.errors[0].message.replace(/_/g, " "),
                "warning"
              );
              this.setState({ loading: false });
            } else {
              Controller.setLengthMsgchat(
                this.state.accountChatConfiguration.recommendedTextMsgBytes
                  .value
              );
              this.fireSwalHandler(
                "Success",
                "Account chat configuration updated",
                "success"
              );
            }
          })
          .catch((err) => {});
      } else {
        this.setState({ loading: false });
      }
    });
  };
  checkValidCredentailsHandler = () => {
    let isValid = true;
    let pwdCopy = { ...this.state.newPassword };
    let currentPwdCopy = { ...this.state.currentPassword };
    if (currentPwdCopy.value == "") {
      currentPwdCopy.error = true;
      isValid = false;
    } else if (pwdCopy.value.length < 6 || pwdCopy.value == "") {
      pwdCopy.error = true;
      isValid = false;
    }
    this.setState({
      newPassword: pwdCopy,
      currentPassword: currentPwdCopy,
    });
    return isValid;
  };
  updateCredentialsHandler = () => {
    if (this.checkValidCredentailsHandler()) {
      let data = {
        lookupId: Controller.getUserId(),
        user: {
          newPassword: this.state.newPassword.value.trim(),
          oldPassword: this.state.currentPassword.value.trim(),
        },
      };
      this.setState({ loading: true }, () => {
        httpRequest(updateUser, data)
          .then((res) => {
            this.checkRespondServerHandler(res);
          })
          .catch((err) => {});
      });
    }
  };
  updateAccountDetailsHandler = () => {
    if (this.checkValidGeneralInfoHandler()) {
      var account = this.createObjForUpdateAccount();
      this.setState({ loading: true }, () => {
        if (this.state.uploadFile.value !== "") {
          let formData = new FormData();
          formData.append(FILE, this.state.croppedImageUrl);
          formData.append(UPLOAD_PRESET, CLOUDINARY_UPLOAD_PRESET);
          httpRequest({}, formData, PICTURE)
            .then((res) => {
              return Promise.resolve(res.data.secure_url);
            })
            .then((fileUrl) => {
              this.avatar_url = fileUrl;
              account.logo = fileUrl;
              let data = {
                account: account,
                lookupId: Controller.getAccountId(),
              };

              httpRequest(updateAccount, data)
                .then((res) => {
                  this.checkRespondServerHandler(res);
                })
                .catch((err) => {});
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          let data = {
            account: account,
            lookupId: Controller.getAccountId(),
          };
          httpRequest(updateAccount, data)
            .then((res) => {
              ////we need to get back the avatar to update the globle var
              this.checkRespondServerHandler(res);
            })
            .catch((err) => {});
        }
      });
    } else {
      this.setState({ loading: false });
    }
  };
  onChangeConfigHandler = (event) => {
    let copyObj = { ...this.state.accountChatConfiguration };
    copyObj[event.target.name].value = event.target.value;
    copyObj[event.target.name].error = false;
    this.setState({ accountChatConfiguration: copyObj });
  };
  checkValidGeneralInfoHandler = () => {
    let isValid = true;
    let copyAccount = { ...this.state.updateAccount };
    let addressCopy = { ...this.state.address };
    let passKey = ["email", "phone", "apiKey", "postcode"];

    for (let key in copyAccount) {
      if (!passKey.includes(key) && copyAccount[key].value.trim() === "") {
        copyAccount[key].error = true;
        isValid = false;
      } else if (
        key == "phone" &&
        (copyAccount[key].value.trim() == "" ||
          (copyAccount[key].value.trim() !== "" &&
            copyAccount[key].value.length < 7))
      ) {
        copyAccount[key].error = true;
        isValid = false;
      }
      //address validation
      if (addressCopy.address === "" || addressCopy.address.length > 250) {
        addressCopy.error = true;
        isValid = false;
      }
    }

    this.setState({
      updateAccount: copyAccount,
      address: addressCopy,
    });
    return isValid;
  };
  onClickIconPasswordHandler = (fieldName) => {
    let updatePassword = { ...this.state[fieldName] };
    updatePassword.type =
      updatePassword.type == "password" ? "text" : "password";
    this.setState({ [fieldName]: updatePassword });
  };
  onClickChangeTabHandler = (tab) => {
    if (!this.state.loading) {
      this.setState({ activeTab: tab });
    }
  };
  tabContentHeaderSelector = () => {
    switch (this.state.activeTab) {
      case 0:
        return "Details";
        break;
      case 1:
        return "Credentials";
        break;
    }
  };
  onClickToggleBtnHandler = (type) => {
    this.setState({ speed: type });
  };
  onCheckOfflineTimerHandler = () => {
    this.setState({
      syncAccountDetailsWithPartner: !this.state.syncAccountDetailsWithPartner,
    });
  };
  tabContentBuilder = () => {
    switch (this.state.activeTab) {
      case 0:
        return (
          <div className={styles.form}>
            {this.buildInputsElementsHandler()}

            <SearchBar
              label={"Location"}
              address={this.state.address.address}
              formatAddress={this.formatAddressHandler}
              searchWrapper={styles.inputWrapper}
              error={this.state.address.error}
              phoneMode={this.props.phoneMode}
            />
            <div className={styles.inputWrapper}>
              <label className={styles.titleSpeed}>Units</label>

              <div className={styles.radioWrapper}>
                <RadioButton
                  name={KM_HR}
                  label={KM_HR}
                  id={KM_HR}
                  // inputWrapper={styles.radioWrapper}
                  change={() => this.onClickToggleBtnHandler(KM_HR)}
                  checked={this.state.speed === KM_HR}
                  disabled={this.state.loading}
                />
                <RadioButton
                  name={MILES}
                  label={MILES}
                  id={MILES}
                  inputWrapper={styles.radio}
                  change={() => this.onClickToggleBtnHandler(MILES)}
                  checked={this.state.speed === MILES}
                  disabled={this.state.loading}
                />
              </div>
            </div>
            <div className={styles.checkboxMainWrapper}>
              <CheckBox
                labelMsg={"Terminal online timeout"}
                checkBoxWrapper={styles.checkboxWrapper}
                value={this.state.syncAccountDetailsWithPartner}
                click={this.onCheckOfflineTimerHandler}
                name={"syncAccountDetailsWithPartner"}
              />
            </div>
            <div className={styles.imageWrapperAction}>
              <UploadFile
                label="Upload"
                inputWrapper={styles.uploadFileWrapper}
                showLoader={this.state.loading}
                error={this.state.uploadFile.error}
                errMsg={this.state.uploadFile.errMsg}
                name="upload_image"
                acceptedFiles=".jpeg, .png, .jpg"
                fileName={this.state.uploadFile.fileName}
                placeholder={this.state.uploadFile.placeholder}
                clickAction={(e) => this.uploadFileHandler(e)}
                phoneMode={this.props.phoneMode}
              />
              <div className={styles.previewWrapper}>
                <Button
                  text="Preview Image"
                  overrideClass={styles.overrideClass}
                  click={this.showPreviewHandler}
                  disabled={Controller.getBasicInfo().avatar == null}
                />
              </div>
            </div>
          </div>
        );
        break;

      case 1:
        return (
          <form className={styles.form} autoComplete="off">
            <Input
              value={this.state.currentPassword.value}
              error={this.state.currentPassword.error}
              label={this.state.currentPassword.label}
              inputWrapper={styles.inputWrapper}
              placeholder={this.state.currentPassword.placeholder}
              change={(e) => this.onChangeNewPwdHandler(e)}
              errMsg={this.state.currentPassword.errMsg}
              type={this.state.currentPassword.type}
              name={this.state.currentPassword.name}
              phoneMode={this.props.phoneMode}
              iconDefination={{ prefix: "far", iconName: "eye-slash" }}
              iconAction={() =>
                this.onClickIconPasswordHandler("currentPassword")
              }
            />
            <Input
              value={this.state.newPassword.value}
              error={this.state.newPassword.error}
              label={this.state.newPassword.label}
              inputWrapper={styles.inputWrapper}
              placeholder={this.state.newPassword.placeholder}
              change={(e) => this.onChangeNewPwdHandler(e)}
              errMsg={this.state.newPassword.errMsg}
              type={this.state.newPassword.type}
              name={this.state.newPassword.name}
              phoneMode={this.props.phoneMode}
              iconDefination={{ prefix: "far", iconName: "eye-slash" }}
              iconAction={() => this.onClickIconPasswordHandler("newPassword")}
            />
          </form>
        );
        break;
      case 2:
        return (
          <form className={styles.form} autoComplete="off">
            <Input
              value={
                this.state.accountChatConfiguration.recommendedTextMsgBytes
                  .value
              }
              error={
                this.state.accountChatConfiguration.recommendedTextMsgBytes
                  .error
              }
              label={
                this.state.accountChatConfiguration.recommendedTextMsgBytes
                  .label
              }
              inputWrapper={styles.inputWrapper}
              min="1"
              max="65535"
              placeholder={
                this.state.accountChatConfiguration.recommendedTextMsgBytes
                  .placeholder
              }
              change={(e) => this.onChangeConfigHandler(e)}
              errMsg={
                this.state.accountChatConfiguration.recommendedTextMsgBytes
                  .errMsg
              }
              type={
                this.state.accountChatConfiguration.recommendedTextMsgBytes.type
              }
              name={
                this.state.accountChatConfiguration.recommendedTextMsgBytes.name
              }
              phoneMode={this.props.phoneMode}
            />
            <Input
              value={
                this.state.accountChatConfiguration.ackMessageTimeout.value
              }
              error={
                this.state.accountChatConfiguration.ackMessageTimeout.error
              }
              label={
                this.state.accountChatConfiguration.ackMessageTimeout.label
              }
              inputWrapper={styles.inputWrapper}
              min="20"
              max="65535"
              placeholder={
                this.state.accountChatConfiguration.ackMessageTimeout
                  .placeholder
              }
              change={(e) => this.onChangeConfigHandler(e)}
              errMsg={
                this.state.accountChatConfiguration.ackMessageTimeout.errMsg
              }
              type={this.state.accountChatConfiguration.ackMessageTimeout.type}
              name={this.state.accountChatConfiguration.ackMessageTimeout.name}
              phoneMode={this.props.phoneMode}
            />
            <Input
              value={
                this.state.accountChatConfiguration.transmitMaxRetries.value
              }
              error={
                this.state.accountChatConfiguration.transmitMaxRetries.error
              }
              label={
                this.state.accountChatConfiguration.transmitMaxRetries.label
              }
              inputWrapper={styles.inputWrapper}
              min="0"
              max="65535"
              placeholder={
                this.state.accountChatConfiguration.transmitMaxRetries
                  .placeholder
              }
              change={(e) => this.onChangeConfigHandler(e)}
              errMsg={
                this.state.accountChatConfiguration.transmitMaxRetries.errMsg
              }
              type={this.state.accountChatConfiguration.transmitMaxRetries.type}
              name={this.state.accountChatConfiguration.transmitMaxRetries.name}
              phoneMode={this.props.phoneMode}
            />

            <Input
              value={this.state.accountChatConfiguration.keepaliveTimeout.value}
              error={this.state.accountChatConfiguration.keepaliveTimeout.error}
              label={this.state.accountChatConfiguration.keepaliveTimeout.label}
              inputWrapper={styles.inputWrapper}
              min="0"
              max="150"
              placeholder={
                this.state.accountChatConfiguration.keepaliveTimeout.placeholder
              }
              change={(e) => this.onChangeConfigHandler(e)}
              errMsg={
                this.state.accountChatConfiguration.keepaliveTimeout.errMsg
              }
              type={this.state.accountChatConfiguration.keepaliveTimeout.type}
              name={this.state.accountChatConfiguration.keepaliveTimeout.name}
              phoneMode={this.props.phoneMode}
            />
          </form>
        );
    }
  };
  onChangeNewPwdHandler = (e) => {
    if (this.state.loading) {
      return;
    }
    let pwd = { ...this.state[e.target.name] };
    pwd.value = e.target.value;
    pwd.error = false;
    this.setState({ [e.target.name]: pwd }, () => {});
  };
  sendImageUrlHandler = (url) => {
    this.setState({ croppedImageUrl: url });
  };
  closePreviewHandler = () => {
    this.setState({ showPreviewImage: false, openImageCropModel: false });
  };
  showPreviewHandler = () => {
    this.setState({ showPreviewImage: true }, () => {});
  };
  cancelImageHandler = () => {
    let uploadFile = { ...this.state.uploadFile };
    uploadFile.value = "";
    uploadFile.error = false;
    uploadFile.fileName = "";
    this.setState({
      uploadFile: uploadFile,
      croppedImageUrl: null,
    });
  };
  render() {
    return (
      <div className={styles.UpdateAccounts}>
        <h3 className={styles.header}>
          <span className={styles.headerIcon}>
            <Icon defination={{ prefix: "fal", iconName: "user" }} />
          </span>
          Update Account
        </h3>
        <div className={styles.breakLine}></div>
        <p
          className={styles.stickError}
          style={{
            visibility: !this.state.isValidForm ? "visible" : "hidden",
          }}
        >
          Please enter missing information
        </p>
        <div
          className={styles.infoWrapper}
          style={{ opacity: this.state.loading ? 0.6 : 1 }}
        >
          <div
            className={
              (this.state.uploadFile.value != "" &&
                this.state.openImageCropModel) ||
              this.state.showPreviewImage
                ? styles.openWrapper
                : styles.closeWrapper
            }
          >
            {this.state.showPreviewImage ? (
              <PreviewImage
                previewImage={Controller.getBasicInfo().avatar}
                closePreview={this.closePreviewHandler}
              />
            ) : (
              <ImageCrop
                imageSrc={this.state.uploadFile.value}
                sendImageUrl={this.sendImageUrlHandler}
                previewImage={this.state.croppedImageUrl}
                closePreview={this.closePreviewHandler}
                mode={this.props.mode}
                cancel={this.cancelImageHandler}
              />
            )}
          </div>

          <div className={styles.formWrapper}>
            <Tabs
              click={(tab) => this.onClickChangeTabHandler(tab)}
              tabArray={[
                "Account Details",
                "Account Credentials",
                "Chat Configuration",
              ]}
              selected={this.state.activeTab}
            />
            {this.tabContentBuilder()}
          </div>
        </div>
        <div className={styles.btnWrapper}>
          <FormButtons
            text={CANCEL}
            click={() => this.props.openModel("")}
            disabled={this.state.loading}
            overrideStyles={{ width: "14.2rem" }}
          />
          <FormButtons
            text={SUBMIT}
            click={this.updateAccountHandler}
            disabled={this.state.loading}
            overrideStyles={{ width: "14.2rem" }}
          />
        </div>
        <div
          className={styles.spinnerWrapper}
          style={{ display: this.state.loading ? "block" : "none" }}
        >
          <SpinnerLoader />
        </div>
      </div>
    );
  }
}
