import axios from "axios";
import Config from "../config";
import { getAuthToken } from "../Store/localStorageManager/localStorageManager";
import { CLOUDINARY_URL } from "../Util/constants";
export const requestApi = (data, picture) => {
  if (picture === null) {
    let TOKEN = getAuthToken();
    return axios
      .request({
        method: "post",
        data: data,
        url: Config.api.API_URL,
        headers: {
          Authorization: TOKEN ? `JWT ${TOKEN}` : null,
        },
        params: data.params !== undefined ? data.params : null,
      })
      .then((res) => {
        return Promise.resolve(res);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  } else {
    return axios
      .request({
        url: CLOUDINARY_URL,
        method: "post",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: data,
      })
      .then((res) => {
        return Promise.resolve(res);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }
};
