import React, { Component } from "react";
import {
  CANCEL,
  SUBMIT,
  CREATE_TERMINAL_MODE,
  NEXT,
  CLOSE,
  CREATE_MODE,
  TERMINAL_ID,
  DEVICE_NAME,
  TERMINAL_TYPE
} from "../../Util/constants";
import Button from "../Button/Button";
import Icon from "../Icon/Icon";
import Input from "../Input/Input";
import SpinnerLoader from "../SpinnerLoader/SpinnerLoader";
import styles from "./CreateTerminal.module.scss";
import {
  fetchTerminalDetails,
  createTerminalManually,
  getTerminalById,
} from "../../RequestManager/GqlBuilderAccount";
import { httpRequest } from "../../RequestManager/HttpRequest";
import Swal from "sweetalert2/dist/sweetalert2.js";
import FormButtons from "../FormButtons/FormButtons";
import { getTypeOfTerminal } from "../../Util/globalFunc";
export default class ViewTerminal extends Component {
  state = {
    loading: false,
    isMouted: false,
    isValidForm: true,
    currentStage: 1,
    terminal: {
      externalId: {
        value: "",
        error: false,
        errMsg: "Invalid ID",
        label:TERMINAL_ID,
        name: "externalId",
        size: 250,
        placeholder: "Enter ID",
      },
      apiKey: {
        value: "",
        error: false,
        errMsg: "Invalid key",
        label: "Terminal API key",
        name: "apiKey",
        size: 250,
        placeholder: "Enter key",
      },     
      deviceName: {
        value: "",
        error: false,
        errMsg: "Invalid device Name",
        label: DEVICE_NAME,
        name: "deviceName",
        size: 250,
        placeholder: "Enter device name",
      },
      description: {
        value: "",
        error: false,
        errMsg: "Invalid description",
        label: "description",
        name: "description",
        size: 250,
        placeholder: "Enter description",
      },
    },
    networkConfig: {
      destIp: {
        name: "destIp",
        value: "",
        error: false,
        errMsg: "Not a valid destination IP",
        type: "text",
        updateKey: "destinationIp",
        label: "destination IP",
        size: 50,
      },
      destPort: {
        name: "destPort",
        value: "",
        error: false,
        errMsg: "Not a valid port destination",
        type: "text",
        updateKey: "destinationPort",
        label: "destination port",
        size: 50,
      },
      recvPort: {
        name: "recvPort",
        value: "",
        error: false,
        errMsg: "Not a valid port received",
        type: "text",
        updateKey: "receivedPort",
        label: "received port",
        size: 50,
      },
    },
    location: {
      lat: "",
      lng: "",
      error: false,
      address: "",
      locationError: false,
    },
    terminalType: {
      label: TERMINAL_TYPE,
      value: "",
      error: false,
      errMsg: "Invalid device type",
    },
    terminalData: null,
  };

  intiallizeDataHandler = (copyDevice) => {
    if (copyDevice == null) return;
    let copyInputs = { ...this.state.terminal };
    let networkInputs = { ...this.state.networkConfig };
    let copyTerminalType = { ...this.state.terminal_type };
    let location = { ...this.state.location };
    for (let key in copyInputs) {
      if (copyDevice[key] !== undefined && copyDevice[key] !== null) {
        copyInputs[key].value = copyDevice[key];
      }
    }
    for (let key in networkInputs) {
      if (copyDevice[key] !== undefined && copyDevice[key] !== null) {
        networkInputs[key].value = copyDevice[key];
      }
    }
    if (copyDevice.locationName !== null) {
      location.address = copyDevice.locationName;
      location.lat = copyDevice.latitude;
      location.lng = copyDevice.longitude;
      location.country = ""; //copyDevice.location.country;
    }
    copyTerminalType.value = copyDevice.terminalType;
    this.setState(
      {
        terminal: copyInputs,
        networkConfig: networkInputs,
        terminalType: copyTerminalType,
        location: location,
        loading: false,
        terminalData: copyDevice,
        currentStage: 2,
      },
      () => {}
    );
  };
  onChangeTypeTerminalhandler = (event) => {
    let terminalType = { ...this.state.terminalType };
    terminalType.value = event.target.value;
    this.setState({ terminalType: terminalType });
  };
  onChangeInputsHandler = (event) => {
    let copyTermianl = { ...this.state.terminal };
    copyTermianl[event.target.name].value = event.target.value;
    copyTermianl[event.target.name].error = false;
    this.setState({ terminal: copyTermianl });
  };
  getInputTerminalHandler = () => {
    return Object.values(this.state.terminal).map((item, index) => {
      return (
        <Input
          key={index}
          inputWrapper={styles.inputWrapper}
          name={item.name}
          value={
            item.value != ""
              ? item.value
              : this.state.currentStage == 2
              ? "N/A"
              : ""
          }
          placeholder={item.placeholder}
          label={item.label}
          phoneMode={this.props.phoneMode}
          change={(e) => this.onChangeInputsHandler(e)}
          disabled={index <= 1 && this.state.currentStage == 1 ? false : true}
          error={item.error}
          errMsg={item.errMsg}
        />
      );
    });
  };
  cancelHandler = () => {
    Swal.fire({
      title: "Are you sure?",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#00a0dc",
      cancelButtonColor: "#ff1a1a",
      confirmButtonText: "Yes",
    }).then((res) => {
      if (res.dismiss !== "cancel" && res.dismiss !== "backdrop") {
        this.props.close();
      } else {
      }
    });
  };
  oncloseHandler = () => {
    this.props.reloadPage();
  };
  getActionsHandler = () => {
    if (
      this.props.mode == CREATE_TERMINAL_MODE &&
      this.state.currentStage == 1
    ) {
      return (
        <div className={styles.btnWrapper}>
          <FormButtons
            text={CANCEL}
            click={this.props.close}
            disabled={this.state.loading}
            overrideStyles={{ width: "14.2rem" }}
          />
          <FormButtons
            text={NEXT}
            click={this.fetchTerminalDetailsHandler}
            disabled={this.state.loading}
            overrideStyles={{ width: "14.2rem" }}
          />
        </div>
      );
    } else if (
      this.props.mode == CREATE_TERMINAL_MODE &&
      this.state.currentStage == 2
    ) {
      return (
        <div className={styles.btnWrapper}>
          <FormButtons
            text={CANCEL}
            click={this.cancelHandler}
            disabled={this.state.loading}
            overrideStyles={{ width: "14.2rem" }}
          />
          <FormButtons
            text={SUBMIT}
            click={this.createTerminalHandler}
            disabled={this.state.loading}
            overrideStyles={{ width: "14.2rem" }}
          />
        </div>
      );
    } else if (
      this.props.mode == CREATE_TERMINAL_MODE &&
      this.state.currentStage == 3
    ) {
      return (
        <div className={styles.singleBtnWrapper}>
          <Button
            text={CLOSE}
            click={this.oncloseHandler}
            disabled={this.state.loading}
          />
        </div>
      );
    }
  };
  createTerminalHandler = () => {
    this.setState({ loading: true }, () => {
      let terminalData = { ...this.state.terminalData };

      let params = {
        terminalData: {
          deviceName: terminalData.deviceName,
          macAddress: terminalData.macAddress,
          terminalType: terminalData.terminalType,
          externalId: terminalData.externalId,
          hardware: terminalData.hardware,
          firmware: terminalData.firmware,
          latitude: terminalData.latitude,
          longitude: terminalData.longitude,
          locationName: terminalData.locationName,
          destIp: terminalData.destIp,
          destPort: terminalData.destPort,
          recvPort: terminalData.recvPort,
          apiKey: terminalData.apiKey,
        },
      };
      httpRequest(createTerminalManually, params).then((res) => {
        this.checkResponseHandler(res, CREATE_MODE);
      });
    });
  };
  fetchTerminalDetailsHandler = () => {
    if (this.checkValidHandler()) {
      this.setState({ loading: true }, () => {
        httpRequest(fetchTerminalDetails, {
          apiKey: this.state.terminal.apiKey.value.trim(),
          externalId: this.state.terminal.externalId.value.trim(),
        }).then((res) => {
          this.checkResponseHandler(res, NEXT);
        });
      });
    }
  };
  fireSwalHandler = (title, text, type) => {
    Swal.fire({
      title: title,
      text: text,
      type: type,
    }).then((res) => {
      if (type === "success") {
        //this.props.reloadPage();
        this.setState({ currentStage: 3, loading: false });
      }
    });
  };
  checkResponseHandler = (res, actionType) => {
    if (res.errors !== undefined) {
      this.fireSwalHandler(
        "Warning",
        res.errors[0].message.replace(/_/g, " "),
        "warning"
      );
      this.setState({ loading: false });
    } else {
      if (actionType == NEXT)
        this.intiallizeDataHandler(res.data.manualTerminal);
      else if (actionType == CREATE_MODE)
        this.fireSwalHandler(
          "Success",
          "Terminal is manually created",
          "success"
        );
    }
  };
  checkValidHandler = () => {
    let copyInputs = { ...this.state.terminal };
    let isValid = true;
    if (copyInputs.externalId.value == "") {
      isValid = false;
      copyInputs.externalId.error = true;
    }
    if (copyInputs.apiKey.value == "") {
      isValid = false;
      copyInputs.apiKey.error = true;
    }
    this.setState({ terminal: copyInputs }, () => {});
    return isValid;
  };
  clearDataHandler = () => {
    let copyInputs = { ...this.state.terminal };
    let networkInputs = { ...this.state.networkConfig };
    let copyTerminalType = { ...this.state.terminal_type };
    let location = { ...this.state.location };

    for (let key in copyInputs) {
      copyInputs[key].value = "";
      copyInputs[key].error = false;
    }
    for (let key in networkInputs) {
      networkInputs[key].value = "";
      networkInputs[key].error = false;
    }

    location.address = "";
    location.lat = "";
    location.lng = "";
    location.country = "";

    copyTerminalType.value = "";
    this.setState(
      {
        terminal: copyInputs,
        networkConfig: networkInputs,
        terminalType: copyTerminalType,
        location: location,
        loading: false,
        terminalData: null,
        currentStage: 1,
      },
      () => {}
    );
  };
  render() {
    return (
      <div className={styles.CreateTerminals}>
        <div className={styles.headerWrapper}>
          <h3 className={styles.header} id="header">
            <span className={styles.icon}>
              <Icon defination={{ prefix: "fal", iconName: "tablet-rugged" }} />
            </span>
            Create Terminal Manually
          </h3>
          <div className={styles.resetBtnWrapper}>
            {this.state.currentStage == 2 && (
              <Button
                click={this.clearDataHandler}
                overrideStyles={{
                  marginRight: "2rem",
                  height: "4rem",
                }}
                text={"Start Over"}
              />
            )}
          </div>
        </div>
        <div className={styles.destinationBreakLine}></div>
        <div className={styles.stepsWrapper}>
          <div className={styles.stepWrapper}>
            <span
              className={styles.count}
              style={{
                backgroundColor: this.state.currentStage == 1 ? "#ffb637" : "",
              }}
            >
              1
            </span>
            <span className={styles.stepname}>Enter Terminal Details</span>
          </div>
          <span className={styles.stepIcon}></span>
          <div className={styles.stepWrapper}>
            <span
              className={styles.count}
              style={{
                backgroundColor: this.state.currentStage == 2 ? "#ffb637" : "",
              }}
            >
              2
            </span>
            <span className={styles.stepname}>Confirm Terminal Details</span>
          </div>
          <span className={styles.stepIcon}></span>
          <div className={styles.stepWrapper}>
            <span
              className={styles.count}
              style={{
                backgroundColor: this.state.currentStage == 3 ? "#ffb637" : "",
              }}
            >
              3
            </span>
            <span className={styles.stepname}>View Terminal</span>
          </div>
        </div>
        <div
          className={styles.form}
          style={{ opacity: this.state.loading ? 0.6 : 1 }}
        >
          {this.getInputTerminalHandler()}

          <Input
            name={this.state.terminalType.name}
            key={this.state.terminalType.name}
            value={getTypeOfTerminal(this.state.terminalType.value)}
            placeholder={"N/A"}
            label={"Terminal Type"}
            error={this.state.terminalType.error}
            errMsg={this.state.terminalType.errMsg}
            inputWrapper={styles.inputWrapper}
            disabled={true}
          />

          <div className={styles.destinationWrapper}>
            <p className={styles.destinationTitle}>
              <span className={styles.iconNet}>
                <Icon
                  defination={{
                    prefix: "fas",
                    iconName: "network-wired",
                  }}
                />
              </span>
              <strong>Network Configuration</strong>
            </p>
            <div className={styles.destinationBreakLine}></div>
            <div className={styles.inputWrapperDestWrapper}>
              <Input
                name={this.state.networkConfig.destIp.name}
                key={this.state.networkConfig.destIp.name}
                value={this.state.networkConfig.destIp.value}
                placeholder={"1.2.3.4"}
                phoneMode={this.props.phoneMode}
                label={this.state.networkConfig.destIp.label}
                inputWrapper={styles.inputWrapper}
                disabled={true}
              />
              <Input
                name={this.state.networkConfig.destPort.name}
                key={this.state.networkConfig.destPort.name}
                value={this.state.networkConfig.destPort.value}
                placeholder={"0-65535"}
                phoneMode={this.props.phoneMode}
                label={this.state.networkConfig.destPort.label}
                inputWrapper={styles.inputWrapper}
                disabled={true}
              />
              <Input
                name={this.state.networkConfig.recvPort.name}
                key={this.state.networkConfig.recvPort.name}
                value={this.state.networkConfig.recvPort.value}
                placeholder={"0-65535"}
                label={this.state.networkConfig.recvPort.label}
                inputWrapper={styles.inputWrapper}
                phoneMode={this.props.phoneMode}
                disabled={true}
              />

              <Input
                name={"location"}
                key={"location"}
                value={
                  this.state.location.address == ""
                    ? "N/A"
                    : this.state.location.address
                }
                label={"Default Location"}
                phoneMode={this.props.phoneMode}
                inputWrapper={styles.inputWrapper}
                disabled={true}
              />
            </div>
          </div>
        </div>
        {this.getActionsHandler()}
        <div
          className={styles.spinnerWrapper}
          style={{ display: this.state.loading ? "block" : "none" }}
        >
          <SpinnerLoader />
        </div>
      </div>
    );
  }
}
