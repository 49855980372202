import React, { Component, Fragment } from "react";
import Geocode from "react-geocode";
import styles from "./SearchBar.module.scss";
import { loadGoogleMaps } from "./LoadScript";
import Icon from "../../../Component/Icon/Icon";
import { GOOGLE_MAP_API_KEY } from "../../../Util/constants";
Geocode.setApiKey(GOOGLE_MAP_API_KEY);
/* global google */
export default class SearchBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
    };
    this.autoCompleteInput = React.createRef();
    this.autocomplete = "";
  }

  componentDidMount() {
    try {
      setTimeout(() => {
        loadGoogleMaps(() => {
          this.autocomplete = new google.maps.places.Autocomplete(
            this.autoCompleteInput.current,
            { types: ["geocode"] }
          );

          if (this.autocomplete !== "" || this.autocomplete !== undefined) {
            this.autocomplete.addListener(
              "place_changed",
              this.handlePlaceChanged
            );

            if (this.props.address !== undefined || this.props.address !== "") {
              this.setState({ value: this.props.address });
            }
          }
        });
      }, 200);
    } catch (error) {}
  }
  getAutocompletePacContainer = (autocomplete) => {
    const place = autocomplete.gm_accessors_.place;
    const placeKey = Object.keys(place).find(
      (value) =>
        typeof place[value] === "object" &&
        place[value].hasOwnProperty("gm_accessors_")
    );

    const input = place[placeKey].gm_accessors_.input[placeKey];

    const inputKey = Object.keys(input).find(
      (value) =>
        input[value].classList &&
        input[value].classList.contains("pac-container")
    );

    return input[inputKey];
  };
  componentWillUnmount() {
    try {
      let el = document.getElementById("search");
      if (el !== null) {
        document.getElementById("search").remove();
      }
      this.getAutocompletePacContainer(this.autocomplete).remove();
    } catch (error) {}
  }
  onChangeHandler = (event) => {
    this.setState({ value: event.target.value }, () => {
      if (this.state.value === "") {
        this.props.formatAddress(-1);
      }
    });
  };
  componentWillReceiveProps(nextprops) {
    if (nextprops.address != this.state.value) {
      this.setState({ value: nextprops.address });
    }
  }
  updateAddressStateHandler = (address) => {
    this.setState({ value: address.results[0].formatted_address });
  };
  handlePlaceChanged = () => {
    try {
      if (this.autocomplete.getPlace() === undefined) {
        this.updateAddressStateHandler("");
        this.props.formatAddress(-1);
      } else {
        const place = JSON.parse(JSON.stringify(this.autocomplete.getPlace()));

        if (place !== undefined) {
          Geocode.fromAddress(place.formatted_address)
            .then((res) => {
              this.updateAddressStateHandler(res);
              this.props.formatAddress(res);
            })
            .catch((err) => {});
        }
      }
    } catch (error) {
      return;
    }
  };

  render() {
    return (
      <Fragment>
        <div className={this.props.searchWrapper}>
          <p className={styles.label}>
            <span className={styles.text}>{this.props.label}</span> 
            {this.props.phoneMode && (
              <span
              className={styles.error}
                style={{ visibility: this.props.error ? "visible" : "hidden" }}
              >
                {" "}
                Location missing
              </span>
            )}
          </p>
          <input
            id="searchBarGoogle"
            ref={this.autoCompleteInput}
            autoComplete="off"
            value={this.state.value}
            onChange={(e) => this.onChangeHandler(e)}
            className={styles.input}
            type="text"
            placeholder="Enter location"
            disabled={this.props.disabled}
          />
          <span className={styles.icon}>
            <Icon defination={{ prefix: "fas", iconName: "location-arrow" }} />
          </span>

          {!this.props.phoneMode && (
            <p
              className={styles.error}
              style={{ display: this.props.error ? "block" : "none" }}
            >
              Location missing. Max character 250
            </p>
          )}
        </div>
      </Fragment>
    );
  }
}
