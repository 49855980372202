import React, { Component } from "react";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import styles from "./DateTimePicker.module.scss";
import Icon from "../Icon/Icon";
export default class DateTimePicker extends Component {
  componentDidMount() {
    var picker = document.getElementsByClassName("rdtPicker");
    for (var i = 0; i < picker.length; i++) {
      picker[i].classList.add(this.props.pickerStyles);
    }
  }

  renderInput = (props, openCalendar, closeCalendar) => {
    return (
      !this.props.isOpen && (
        <>
          <label className={styles.label}>
            <span className={styles.text}>{this.props.label}</span>
            {this.props.phoneMode && (
              <span
                className={styles.errorMobile}
                style={{ visibility: this.props.error ? "visible" : "hidden" }}
              >
                {this.props.errMsg}
              </span>
            )}
          </label>
          <input
            {...props}
            className={styles.input}
            id={this.props.id !== undefined ? this.props.id : this.props.label}
          />
          <span
            className={styles.calenderIcon}
            onClick={openCalendar}
            style={this.props.iconStyles}
          >
            <Icon defination={this.props.iconDefination} />
          </span>
          <span
            className={styles.closeIcon}
            onClick={closeCalendar}
         
            style={this.props.iconStyles}
          >
            <Icon defination={{ prefix: "fal", iconName: "times" }} />
          </span>
          {!this.props.phoneMode && (
            <p
              className={styles.error}
              style={{ visibility: this.props.error ? "visible" : "hidden" }}
            >
              {this.props.errMsg}
            </p>
          )}
        </>
      )
    );
  };
  onChange = (date) => {
    this.props.onChange(date, this.props.name);
  };

  render() {
    const {
      name,
      value,
      placeholder,
      disabled,
      timeFormat,
      dateFormat,
      inputWrapper,
    } = this.props;

    return (
      <>
        {!this.props.isOpen && (
          <Datetime
            renderInput={this.renderInput}
            onChange={this.onChange}
            className={inputWrapper}
            timeFormat={timeFormat}
            dateFormat={dateFormat}
            value={value}
            inputProps={{
              placeholder: placeholder,
              disabled: disabled,
              name: name,
              autoComplete: "off",
            }}
          ></Datetime>
        )}
        {this.props.isOpen && (
          <Datetime
            onChange={this.onChange}
            className={inputWrapper}
            timeFormat={timeFormat}
            dateFormat={dateFormat}
            value={value}
            open={true}
            input={false}
            inputProps={{
              placeholder: placeholder,
              disabled: disabled,
              name: name,
            }}
          ></Datetime>
        )}
      </>
    );
  }
}
