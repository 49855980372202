import React, { Component, Fragment } from "react";
import MapButton from "../MapButton/MapButton";
import styles from "./FullScreenControl.module.scss";
import Icon from "../../Icon/Icon";
import KeyValue from "../../KeyValue/KeyValue";
import RadioButton from "../../RadioButton/RadioButton";
import CheckBox from "../../CheckBox/CheckBox";
import moment from "moment";
import TOGGLE_ON_ICON from "../../../assets/toggleOnIcon.svg";
import TOGGLE_OFF_ICON from "../../../assets/toggleOffIcon.svg";
import RotateCar from "../RotateCar/RotateCar";
import {
  INDEX,
  TIMSESTAMP,
  SPEED,
  HEADING,
  TRACKING,
  ROLL,
  GPS,
  SIMPLE,
  ADVANCED,
  TELEMETRY,
  NAME,
  TYPE,
  MODEL,
  MANUFACTURER,
  VIN_NUMBER,
  CONVERT_NUMBER_MILE,
  VEHICLE,
  TELEMETRY_TABLE,
  SOCKET_LOADER,
  START,
  DISABLE_COLOR_FOR_GPS_UNLOCK,
  LOCK,
  OTHER_ALIAS_NAME,
} from "../../../Util/constants";

export default class FullScreenControl extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showTelemetry: true,
      telemetry: [],
      isOnline: "",
      isFullScreenMode: false,
      inclination: 0,
      showTelemetryTable: true,
      showVehicleData: true,
      toggleBtn: SIMPLE,
      icon: "",
      vehicleData: {},
      checkboxTelemetry: false,
      showFencing: true,
    };
    this.handleKeyPress = this.handleKeyPress.bind(this);
  }
  onClickToggleTableView = (type) => {
    if (type === TELEMETRY_TABLE) {
      this.setState({ showTelemetryTable: !this.state.showTelemetryTable });
    } else {
      this.setState({ showVehicleData: !this.state.showVehicleData });
    }
  };
  componentDidMount() {
    if (this.props.refTelemetryFullscreen !== undefined) {
      this.props.refTelemetryFullscreen(this.setDataTelemetryDataHandler);
    }
    document.addEventListener("fullscreenchange", (e) =>
      this.handleKeyPress(e)
    );
    document.addEventListener("mozfullscreenchange", (e) =>
      this.handleKeyPress(e)
    );
    document.addEventListener("webkitfullscreenchange", (e) =>
      this.handleKeyPress(e)
    );
    document.addEventListener("msfullscreenchange", (e) =>
      this.handleKeyPress(e)
    );
  }

  handleKeyPress = (event) => {
    if (
      document.fullscreenElement == null &&
      document.webkitFullscreenElement == null &&
      document.mozFullScreenElement == null &&
      document.msFullscreenElement == null &&
      this.state.isFullScreenMode
    ) {
      this.setState(
        { isFullScreenMode: false, showTelemetryTable: true },
        () => {
          this.props.handleESCPress();
        }
      );
    }
  };
  createDetailsElement = () => {
    if (!Object.keys(this.state.vehicleData).length) return;
    let obj = { ...this.state.vehicleData };
    let details = [
      { label: NAME, value: obj.name },
      { label: TYPE, value: obj.vClass.displayName.toLowerCase()=='other'? OTHER_ALIAS_NAME:obj.vClass.displayName },
      { label: "License No.", value: obj.licensePlate.number },
      { label: MODEL, value: obj.model },
      { label: "Year", value: obj.year },
      { label: MANUFACTURER, value: obj.make },
      { label: VIN_NUMBER, value: obj.vin },
      {
        label: "location",
        value: obj.location.address,
      },
    ];
    let list = details.map((item, index) => {
      return (
        <KeyValue
          key={index}
          item={item}
          index={index}
          wrapperStyles={styles.item}
        />
      );
    });
    return list;
  };
  setDataTelemetryDataHandler = (data, isOnline, vehicleData) => {
    this.setState({
      telemetry: data,
      isOnline: isOnline,
      inclinationVehicle: data.length ? data[0].inclination : 0,
      vehicleData: vehicleData,
      //activeMapType:DEFAULT_MAP_TYPE
    });
  };
  showTelemetryDataHandler = () => {
    let test = !this.state.showTelemetry;
    this.setState({ showTelemetry: !this.state.showTelemetry });
  };
  onClickCheckBoxHandler = () => {
    this.setState({ checkboxTelemetry: !this.state.checkboxTelemetry }, () => {
      this.props.onClickCheckBox();
    });
  };

  showFullscreenHandler = () => {
    this.props.fullScreenMode(() => {
      this.setState({
        isFullScreenMode: !this.state.isFullScreenMode,
        showTelemetry: true,
        showInclination: true,
      });
    });
    this.props.getStatusToggleBtn((data) => {
      this.setState({
        toggleBtn: data.toggle,
        checkboxTelemetry: data.checkbox,
      });
    });
  };
  buildTableHeaderHandler = () => {
    return [INDEX, TIMSESTAMP, SPEED, GPS, ROLL, HEADING].map((item, index) => {
      return (
        <div key={index} className={styles.textCell}>
          <strong>{item}</strong>
        </div>
      );
    });
  };
  convertGpsDataHandler = (Gps) => {
    if (Gps == null || parseInt(Gps) === 0) {
      return "UNLOCK";
    } else {
      return LOCK;
    }
  };
  convetDateHandler = (ts, diff = null) => {
    if (diff == null) {
      return moment(ts).format("MM-DD-YYYY HH:mm:ss");
    } else {
      return moment(ts).format("MM-DD-YY HH:mm");
    }
  };
  convertTrackHandler = (track) => {
    if (parseInt(track) == 5) {
      return "LOCK";
    } else {
      return "UNLOCK";
    }
  };
  getBackgroundColor = (item) => {
    if (parseInt(item.gpsFixStatus) === 0) {
      //UNLOCK
      return { backgroundColor: DISABLE_COLOR_FOR_GPS_UNLOCK, color: "#000" };
    } else {
      return { backgroundColor: "#fff" };
    }
  };
  buildTableRowHandler = () => {
    if (!this.state.telemetry.length) {
      return <div className={styles.noDataRow}>No telemetry data found</div>;
    }
    return this.state.telemetry.map((item, index) => {
      return (
        <div
          className={styles.tableRow}
          key={index}
          style={this.getBackgroundColor(item)}
        >
          <div className={styles.textCell}>{index + 1}</div>
          <div className={`${styles.textCell} ${styles.dateTooltip} `}>
            <span className={styles.toolTip}>
              {this.convetDateHandler(item.createdAt)}
            </span>
            {this.convetDateHandler(item.createdAt)}
          </div>

          <div className={styles.textCell}>
            {this.state.vehicleData.speedUnit == 1
              ? parseFloat(item.speed).toFixed(1)
              : parseFloat(item.speed * CONVERT_NUMBER_MILE).toFixed(1)}
            <span className={styles.speedText}>
              {this.state.vehicleData.speedUnitStr}
            </span>
          </div>
          <div className={`${styles.textCell}  ${styles.gpsTooltip}`}>
            <span className={styles.toolTip}>
              {this.convertGpsDataHandler(item.gpsFixStatus)}
            </span>
            {this.convertGpsDataHandler(item.gpsFixStatus)}
          </div>
          <div className={styles.textCell}>
            {item.inclination.toFixed(1)}&#xb0;
          </div>
          <div className={styles.textCell}>{item.heading.toFixed(1)}&#xb0;</div>
          {/* <div className={styles.textCell}>
            {this.convertTrackHandler(item.antennaTrackingStatus)}
          </div> */}
        </div>
      );
    });
  };

  getTableDataHandler = () => {
    return (
      <>
        <div className={styles.tableWrapper} style={{ opacity: 1 }} id="form">
          <div className={`${styles.tableRow} `} id="tblHeader">
            {this.buildTableHeaderHandler()}
          </div>
          <div
            className={styles.table}
            style={{
              opacity: 1,
            }}
          >
            {this.buildTableRowHandler()}
          </div>
        </div>
      </>
    );
  };
  onClickToggleHandler = (type) => {
    this.setState({ toggleBtn: type }, () => {
      this.props.onClickToggleBtn(this.state.toggleBtn);
    });
  };
  onClickFencingHandler = () => {
    this.setState({ showFencing: !this.state.showFencing }, () => {
      this.props.onClickFencing();
    });
  };
  render() {
    return (
      <div className={styles.FullScreenControls}>
        <div className={styles.btnsWrapper}>
          {this.state.isFullScreenMode && (
            <MapButton
              id="ShowTelemetry"
              label="Show"
              btnAction={this.showTelemetryDataHandler}
              icon={{ prefix: "fas", iconName: "file-alt" }}
              showClose={this.state.showTelemetry}
              customClass={styles.btn}
              iconClass={styles.iconShow}
              customStyles={{ marginRight: "1rem" }}
            />
          )}
          {this.state.isFullScreenMode &&
            this.props.phoneMode && ( // for mobile
              <MapButton
                id="ShowTelemetry"
                label=""
                btnAction={this.showTelemetryDataHandler}
                icon={{
                  prefix: "far",
                  iconName: this.state.showTelemetry ? "eye-slash" : "eye",
                }}
                showClose={false}
                customClass={styles.btnMobile}
                iconClass={styles.iconShowMobile}
              />
            )}
          <div className={styles.wrapperFullscreenColumn}>
            <MapButton
              id="fullscreen"
              label=""
              btnAction={this.showFullscreenHandler}
              icon={{
                prefix: "fas",
                iconName: this.state.isFullScreenMode ? "compress" : "expand",
              }}
              customClass={styles.btnScreen}
              iconClass={styles.icon}
            />

            <MapButton
              id="fencing"
              label={""}
              // disabled={this.state.disableControls || this.state.loading}
              btnAction={this.onClickFencingHandler}
              icon={{ prefix: "fas", iconName: "border-outer"}}
              showClose={false}
              customClass={styles.btnScreen}
              iconClass={styles.iconMap}
            />

            {this.props.phoneMode && (
              <RotateCar
                onClickDismissAlarm={this.props.onClickDismissAlarm}
                saveHeadingAdjustment={this.props.saveHeadingAdjustment}
                refHeadingControl={this.props.refHeadingControl}
                phoneMode={this.props.phoneMode}
              />
            )}
          </div>
        </div>
        {this.state.isFullScreenMode && this.state.showTelemetry && (
          <div className={styles.dataVehclieWrapper}>
            <div className={styles.titleWrapper}>
              <p className={`${styles.subTitle} ${styles.setIcon}`}>
                Vehicle Details
              </p>
              <span className={styles.externalId}>
                Terminal ID:
                {this.state.vehicleData.terminal != null &&
                this.state.vehicleData.terminal.externalId != ""
                  ? this.state.vehicleData.terminal.externalId
                  : "N/A"}
              </span>
              <div
                className={styles.displayIconWarpperTop}
                onClick={() => this.onClickToggleTableView(VEHICLE)}
              >
                <span className={styles.arrow}>
                  <span className={styles.toolTip}>
                    {this.state.showVehicleData ? "Close" : "Open"}
                  </span>
                  <span className={styles.iconclose}>
                    <Icon
                      defination={{
                        prefix: "fal",
                        iconName: this.state.showVehicleData
                          ? "chevron-up"
                          : "chevron-down",
                      }}
                    />
                  </span>
                </span>
              </div>
            </div>
            <div className={styles.breakLine}></div>
            {this.state.isFullScreenMode &&
              this.state.showTelemetry &&
              this.state.showVehicleData && (
                <Fragment>
                  <div className={styles.wrapperDetailsVehcile}>
                    {this.createDetailsElement()}
                  </div>
                </Fragment>
              )}

            {this.state.isFullScreenMode && (
              <div className={styles.telemetryWrapper}>
                <div className={`${styles.title} ${styles.titleWrapperToggle}`}>
                  <span
                    style={{
                      visibility:
                        this.state.isOnline !== "" ? "visible" : "hidden",
                    }}
                    className={styles.dotColor}
                  >
                    <span className={styles.subTitle}>Telemetry&nbsp;</span>
                    <span className={styles.subTitle}>
                      {this.state.isOnline ? "(Online)" : "(Offline)"}
                    </span>
                  </span>
                  <div className={styles.toggleWrapper}>
                    {/* <div className={styles.checkboxWrapper}>
                      <CheckBox
                        id={TELEMETRY + "1"}
                        name={TELEMETRY + "1"}
                        value={this.state.checkboxTelemetry}
                        disabled={this.state.loading}
                        click={this.onClickCheckBoxHandler}
                        checkBoxWrapper={styles.checkBoxTelemetryWrapper}
                      />
                      <span>Current ride</span>
                    </div> */}
                    {/* <span className={styles.line}></span> */}
                    {/* <RadioButton
                      name={SIMPLE + "1"}
                      id={SIMPLE + "1"}
                      label={SIMPLE}
                      inputWrapper={styles.radioWrapper}
                      change={() => this.onClickToggleHandler(SIMPLE)}
                      checked={this.state.toggleBtn === SIMPLE}
                    /> */}
                    {/* <RadioButton
                      name={ADVANCED + "1"}
                      id={ADVANCED + "1"}
                      label={ADVANCED}
                      inputWrapper={styles.radioWrapper}
                      change={() => this.onClickToggleHandler(ADVANCED)}
                      checked={this.state.toggleBtn === ADVANCED}
                      disabled={!this.props.canUseRoads()}
                    /> */}
                    <div
                      className={styles.displayIconWarpper}
                      onClick={() =>
                        this.onClickToggleTableView(TELEMETRY_TABLE)
                      }
                    >
                      <span className={styles.arrow}>
                        <span className={styles.toolTip}>
                          {this.state.showTelemetryTable
                            ? "Close table"
                            : "Open table"}
                        </span>
                        <span className={styles.iconclose}>
                          <Icon
                            defination={{
                              prefix: "fal",
                              iconName: this.state.showTelemetryTable
                                ? "chevron-up"
                                : "chevron-down",
                            }}
                          />
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
                <div className={styles.breakline}></div>
                {this.state.showTelemetryTable && this.state.showTelemetry && (
                  <div className={styles.wrapperDetails}>
                    {this.getTableDataHandler()}
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}
