import { isLocalhost } from "./browser";
import {
  BOAT_LOOKUPID,
  CAR_LOOKUPID,
  DYNAMIC,
  FISHNING_BOAT_LOOKUPID,
  FIXED_LR,
  FIXED_RL,
  INVALID,
  MANUAL,
  SYNC,
  TRUCK_LOOKUPID,
  VALID,
} from "./constants";
import {
  getVersionNo,
  storeVersionNo,
} from "../Store/localStorageManager/localStorageManager";
import moment from "moment";
import CAR_ICON_OFFLINE from "../assets/CarIconRed.svg";
import TRUCK_ICON_OFFLINE from "../assets/VanIconRed.svg";
import OTHER_ICON_OFFLINE from "../assets/OtherIconRed.svg";
import CAR_ICON_ONLINE from "../assets/CarIconGreen.svg";
import TRUCK_ICON_ONLINE from "../assets/VanIconGreen.svg";
import OTHER_ICON_ONLINE from "../assets/OtherIconGreen.svg";
import BOAT_ICON_OFFLINE from "../assets/BoatIconRed.svg";
import BOAT_ICON_ONLINE from "../assets/BoatIconGreen.svg";
import BOAT_ICON_SIDE from "../assets/BoatIconSide.svg";
import CAR_ICON_SIDE from "../assets/CarIconSide.svg";
import VAN_ICON_SIDE from "../assets/VanIconSide.svg";
import OTHER_ICON_SIDE from "../assets/OtherIconSide.svg";
import FISHING_BOAT_ICON_OFFLINE from "../assets/FishingBoatIconRed.svg";
import FISHING_BOAT_ICON_ONLINE from "../assets/FishingBoatIconGreen.svg";
import FISHING_BOAT_ICON_SIDE from "../assets/FishingBoatIconSide.svg";
/**
 * returns the empty rows when there is no data
 * @param {*} styles
 * @param {*} noOfRecords
 */

export const getTypeOfTerminal = (id) => {
  if (parseInt(id) === 3 || parseInt(id) === 1) {
    return FIXED_RL;
  } else if (parseInt(id) === 4) {
    return FIXED_LR;
  } else if (parseInt(id) === 2) {
    return DYNAMIC;
  } else {
    return "";
  }
};

export const getPartnerLookupId = (partners) => {

  if (partners == null || partners == undefined) return "";
  else if (partners.length == 1) {
    return partners[0].lookupId;
  } else {
    let data = partners.filter(
      (partner) => partner.name.toLowerCase() == "hisky local"
    );
    return data.length ? data[0].lookupId : "";
  }
};
export const getHostName = () => {
  let host = window.location.host
    .replace(/^(https?:\/\/)?(www\.)?/, "")
    .replace(/\/$/, "");

  let hostList = {
    "hisky360.com": "hisky prod",
    "qa.hiskynms.com": "hisky qa",
    "3.139.186.130": "hisky qa", //3.134.115.236
    "dev.hiskynms.com": "hisky dev",
    "18.219.4.174": "hisky dev",
    "3.138.36.211": "hisky integration",
    "integration.hiskynms.com": "hisky integration",
    local: "hisky local",
  };
  let domain = hostList[host];
  //let domain = 'hisky qa'
  if (isLocalhost()) return hostList.local;
  else return domain;
};
export const dateConverstion = (date) => {
  const d = new Date(date);
  if (date != "" && date !== undefined) {
    return d;
  } else if (
    d.toString().toLowerCase() === "invalid date" ||
    date === undefined ||
    date === ""
  ) {
    return "";
  }
};
export const getUserRole = (id) => {
  let roleList = {
    1: "Administrator",
    2: "User",
  };
  return roleList[id] == undefined ? "" : roleList[id];
  //return roleList[id];
};
export const randomID = (id = "") => {
  // Math.random should be unique because of its seeding algorithm.
  // Convert it to base 36 (numbers + letters), and grab the first 9 characters
  // after the decimal.
  return id + "_" + Math.random().toString(36).substr(2, 9);
};
// export const onlyUnique=(value, index, self) =>{
//   return self.indexOf(value) === index;
// }
export const checkVersionApp = (version) => {

  if (getVersionNo() === version.name) {
    return true;
  }
  storeVersionNo(version.name);
  return false;
};
export const getCarDirection = (heading) => {
  if (heading == null) {
    return "towards";
  } else if (parseFloat(heading) > 180) {
    return "left";
  } else {
    return "right";
  }
};
/**
 * Get marker src
 */
export const getImageUrl = (type, isOnline) => {
  if (type == CAR_LOOKUPID) {
    return isOnline ? CAR_ICON_ONLINE : CAR_ICON_OFFLINE;
  } else if (type === TRUCK_LOOKUPID) {
    return isOnline ? TRUCK_ICON_ONLINE : TRUCK_ICON_OFFLINE;
  } else if (type === BOAT_LOOKUPID) {
    return isOnline ? BOAT_ICON_ONLINE : BOAT_ICON_OFFLINE;
  } else if (type === FISHNING_BOAT_LOOKUPID) {
    return isOnline ? FISHING_BOAT_ICON_ONLINE : FISHING_BOAT_ICON_OFFLINE;//TODO
  } else return isOnline ? OTHER_ICON_ONLINE : OTHER_ICON_OFFLINE;
};
export const getInclinationVehicle = (type) => {
  if (type == CAR_LOOKUPID) {
    return CAR_ICON_SIDE
  } else if (type === TRUCK_LOOKUPID) {
    return VAN_ICON_SIDE
  } else if (type === BOAT_LOOKUPID) {
    return BOAT_ICON_SIDE;
  } else if (type === FISHNING_BOAT_LOOKUPID) {
    return FISHING_BOAT_ICON_SIDE; //TODO
  } else return OTHER_ICON_SIDE;
}
export const getTerminalCreationType = (id)=>{
  if(id==1)return MANUAL;
  else if(id==0 || id==2) return SYNC
  else return 'N/A';
}
export const getTerminalApiKeyStatus = (id)=>{
  if(id==1)return VALID;
  else if(id==2) return INVALID
  else return 'N/A';
}
export const trimStringAtEnd=(str,count=10)=>{
  return str.length > count ?  str.substring(0, count)+"..." : str;
}
export const convertDate = (ts, format="MM/DD/YYYY HH:mm:ss") => {
  return moment(ts).format(format);
};
export const trimStringAtStart = (str, count = 10) => {
  return str.length > 10 ? "..." + str.substring(str.length, count) : str;
};
export const trimChatAtStart = (str, count) => {
  return str.length >count ? str.substring(0,count)+"..." : str;
};
export const converStringToJson = (json) => {
  var newJson = json.replace(/([a-zA-Z0-9]+?):/g, '"$1":');
  newJson = newJson.replace(/'/g, '"');
  var data = JSON.parse(newJson);
  return data;
};
export const getTimeZone = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};
export const getCombinedBounds = (bounds) => {
  //var bounds = e.overlay.getBounds();
  var start = bounds.getNorthEast();
  var end = bounds.getSouthWest();
  var center = bounds.getCenter();
  let arr = [
    {
      lat: parseFloat(start.lat()),
      lng: parseFloat(start.lng()),
    },
    {
      lat: parseFloat(end.lat()),
      lng: parseFloat(end.lng()),
    },
    {
      lat: parseFloat(center.lat()),
      lng: parseFloat(center.lng()),
    },
  ];

  return arr;
};
export const getMapBoundsForBounding = (map, maps, places) => {
  if (maps != null && places.length > 0) {
    const bounds = new maps.LatLngBounds();
    places.forEach((place) => {
      bounds.extend(new maps.LatLng(place.lat, place.lng));
    });
    return bounds;
  }
};
export const convertPointsForGoogleMapHandler = (data) => {
  let temp = [];
  let temp2 = [];
  if (parseInt(data.type) == 1) {
    temp2 = converStringToJson(data.line);
    for (let j = 0; j < temp2.length; j++) {
      temp.push({
        lat: parseFloat(temp2[j].latitude),
        lng: parseFloat(temp2[j].longitude),
      });
    }
    return temp;
  } else if (parseInt(data.type) == 2) {
    let obj = converStringToJson(data.circle);
    return {
      center: {
        lat: parseFloat(obj.center.latitude),
        lng: parseFloat(obj.center.longitude),
      },
      radius: parseFloat(obj.radius),
    };
    
  } else if (parseInt((data.type = 3))) {
    let obj = converStringToJson(data.rectangle);
    return {
      north: parseFloat(obj.north),
      south: parseFloat(obj.south),
      east: parseFloat(obj.east),
      west: parseFloat(obj.west),
    };
  }
};
export const generateMarkerId = (id) => {
  return id.replace(/[^a-zA-Z0-9 ]/g, "");
};