import React, { Component } from "react";
import {
  CANCEL,
  CLOSE,
  EDIT_MODE,
  SUBMIT,
  VIEW_MODE,
  TERMINAL_ID
} from "../../Util/constants";
import Button from "../Button/Button";
import Icon from "../Icon/Icon";
import Input from "../Input/Input";
import SpinnerLoader from "../SpinnerLoader/SpinnerLoader";
import styles from "./ViewTerminal.module.scss";
import {
  editTerminalApikey,
  getTerminalById,
} from "../../RequestManager/GqlBuilderAccount";
import { httpRequest } from "../../RequestManager/HttpRequest";
import Swal from "sweetalert2/dist/sweetalert2.js";
import FormButtons from "../FormButtons/FormButtons";
export default class ViewTerminal extends Component {
  state = {
    loading: true,
    isMouted: false,
    isValidForm: true,
    terminal: {
      externalId: {
        value: "",
        error: false,
        errMsg: "Invalid Id",
        label: TERMINAL_ID,
        name: "externalId",
        size: 250,
        placeholder: "Enter ID",
      },
      deviceName: {
        value: "",
        error: false,
        errMsg: "Invalid device Name",
        label: "Device name",
        name: "deviceName",
        size: 250,
        placeholder: "Enter device name",
      },
      description: {
        value: "",
        error: false,
        errMsg: "Invalid description",
        label: "description",
        name: "description",
        size: 250,
        placeholder: "Enter description",
      },
      apiKey: {
        value: "",
        error: false,
        errMsg: "Invalid key",
        label: "Terminal API key",
        name: "apiKey",
        size: 250,
        placeholder: "Enter key",
      },
    },
    networkConfig: {
      destIp: {
        name: "destIp",
        value: "",
        error: false,
        errMsg: "Not a valid destination IP",
        type: "text",
        updateKey: "destinationIp",
        label: "destination IP",
        size: 50,
      },
      destPort: {
        name: "destPort",
        value: "",
        error: false,
        errMsg: "Not a valid port destination",
        type: "text",
        updateKey: "destinationPort",
        label: "destination port",
        size: 50,
      },
      recvPort: {
        name: "recvPort",
        value: "",
        error: false,
        errMsg: "Not a valid port received",
        type: "text",
        updateKey: "receivedPort",
        label: "received port",
        size: 50,
      },
    },
    location: {
      lat: "",
      lng: "",
      error: false,
      address: "",
      locationError: false,
    },
    terminalType: {
      label: "terminal Type",
      value: "",
      error: false,
      errMsg: "Invalid device type",
    },
  };
  componentDidMount() {
    this.createHttpRequestHandler();
  }
  createHttpRequestHandler = () => {
    httpRequest(getTerminalById, { lookupId: this.props.data.lookupId }).then(
      (res) => {
        if (res.errors !== undefined) {
          this.fireSwalHandler(
            "Warning",
            res.errors[0].message.replace(/_/g, " "),
            "warning"
          );
          this.setState({ loading: false });
        } else this.intiallizeDataHandler(res.data.terminal);
      }
    );
  };
  intiallizeDataHandler = (copyDevice) => {
    if (copyDevice == null) return;
    let copyInputs = { ...this.state.terminal };
    let networkInputs = { ...this.state.networkConfig };
    let copyTerminalType = { ...this.state.terminal_type };
    let location = { ...this.state.location };
    for (let key in copyInputs) {
      if (copyDevice[key] !== undefined && copyDevice[key] !== null) {
        copyInputs[key].value = copyDevice[key];
      }
    }
    for (let key in networkInputs) {
      if (copyDevice[key] !== undefined && copyDevice[key] !== null) {
        networkInputs[key].value = copyDevice[key];
      }
    }
    if (copyDevice.location !== null) {
      location.address = copyDevice.location.address;
      location.lat = copyDevice.location.latitude;
      location.lng = copyDevice.location.longitude;
      location.country = copyDevice.location.country;
    }
    copyTerminalType.value = copyDevice.terminalType;
    this.setState(
      {
        terminal: copyInputs,
        networkConfig: networkInputs,
        terminalType: copyTerminalType,
        location: location,
        loading: false,
      },
      () => {}
    );
  };
  onChangeTypeTerminalhandler = (event) => {
    let terminalType = { ...this.state.terminalType };
    terminalType.value = event.target.value;
    this.setState({ terminalType: terminalType });
  };
  onChangeInputsHandler = (event) => {
    let copyTermianl = { ...this.state.terminal };
    copyTermianl[event.target.name].value = event.target.value;
    copyTermianl[event.target.name].error = false;
    this.setState({ terminal: copyTermianl });
  };
  getInputTerminalHandler = () => {
    return Object.values(this.state.terminal).map((item, index) => {
      let disabled =
        this.props.mode == VIEW_MODE ||
        (this.props.mode == EDIT_MODE && item.name != "apiKey");
      return (
        <Input
          key={index}
          inputWrapper={styles.inputWrapper}
          name={item.name}
          value={item.value != "" ? item.value : ""}
          placeholder={item.placeholder}
          label={item.label}
          phoneMode={this.props.phoneMode}
          change={(e) => this.onChangeInputsHandler(e)}
          disabled={disabled}
          error={item.error}
          errMsg={item.errMsg}
        />
      );
    });
  };
  fireSwalHandler = (title, text, type) => {
    Swal.fire({
      title: title,
      text: text,
      type: type,
    }).then((res) => {
      if (type === "success") {
        this.props.reloadPage();
      }
    });
  };
  editTerminalApikeyHandler = () => {
    let copyInputs = { ...this.state.terminal };
    let isValid = true;
    if (copyInputs.apiKey.value == "") {
      copyInputs.apiKey.error = true;
      isValid = false;
    }
    if (!isValid) {
      this.setState({terminal: copyInputs });
    } else {
      //update api key
      this.setState({ loading: true }, () => {
        let params = {
          lookupId: this.props.data.lookupId,
          apiKey: copyInputs.apiKey.value,
        };
        httpRequest(editTerminalApikey, params).then((res) => {
          this.checkResponseHandler(res);
        });
      });
    }
  };
  checkResponseHandler = (res, actionType) => {
    if (res.errors !== undefined) {
      this.fireSwalHandler(
        "Warning",
        res.errors[0].message.replace(/_/g, " "),
        "warning"
      );
      this.setState({ loading: false });
    } else {
      this.fireSwalHandler("Success", "Terminal apiKey is updated", "success");
    }
  };
  render() {
    return (
      <div className={styles.ViewTerminals}>
        <h3 className={styles.header} id="header">
          <span className={styles.icon}>
            <Icon defination={{ prefix: "fal", iconName: "tablet-rugged" }} />
          </span>
          View Terminal
        </h3>

        <div className={styles.destinationBreakLine}></div>
        <div
          className={styles.form}
          style={{ opacity: this.state.loading ? 0.6 : 1 }}
        >
          {this.getInputTerminalHandler()}

          <Input
            name={this.state.terminalType.name}
            key={this.state.terminalType.name}
            value={this.state.terminalType.value}
            placeholder={"N/A"}
            label={"Terminal Type"}
            error={this.state.terminalType.error}
            errMsg={this.state.terminalType.errMsg}
            inputWrapper={styles.inputWrapper}
            disabled={
              this.props.mode == VIEW_MODE || this.props.mode == EDIT_MODE
            }
          />

          <div className={styles.destinationWrapper}>
            <p className={styles.destinationTitle}>
              <span className={styles.iconNet}>
                <Icon
                  defination={{
                    prefix: "fas",
                    iconName: "network-wired",
                  }}
                />
              </span>
              <strong>Network Configuration</strong>
            </p>
            <div className={styles.destinationBreakLine}></div>
            <div className={styles.inputWrapperDestWrapper}>
              <Input
                name={this.state.networkConfig.destIp.name}
                key={this.state.networkConfig.destIp.name}
                value={this.state.networkConfig.destIp.value}
                placeholder={"1.2.3.4"}
                phoneMode={this.props.phoneMode}
                label={this.state.networkConfig.destIp.label}
                inputWrapper={styles.inputWrapper}
                disabled={
                  this.props.mode == VIEW_MODE || this.props.mode == EDIT_MODE
                }
              />
              <Input
                name={this.state.networkConfig.destPort.name}
                key={this.state.networkConfig.destPort.name}
                value={this.state.networkConfig.destPort.value}
                placeholder={"0-65535"}
                phoneMode={this.props.phoneMode}
                label={this.state.networkConfig.destPort.label}
                inputWrapper={styles.inputWrapper}
                disabled={
                  this.props.mode == VIEW_MODE || this.props.mode == EDIT_MODE
                }
              />
              <Input
                name={this.state.networkConfig.recvPort.name}
                key={this.state.networkConfig.recvPort.name}
                value={this.state.networkConfig.recvPort.value}
                placeholder={"0-65535"}
                label={this.state.networkConfig.recvPort.label}
                inputWrapper={styles.inputWrapper}
                phoneMode={this.props.phoneMode}
                disabled={
                  this.props.mode == VIEW_MODE || this.props.mode == EDIT_MODE
                }
              />

              <Input
                name={"location"}
                key={"location"}
                value={
                  this.state.location.address == ""
                    ? "N/A"
                    : this.state.location.address
                }
                label={"Default Location"}
                phoneMode={this.props.phoneMode}
                inputWrapper={styles.inputWrapper}
                disabled={
                  this.props.mode == VIEW_MODE || this.props.mode == EDIT_MODE
                }
              />
            </div>
          </div>
        </div>

        {this.props.mode == EDIT_MODE ? (
          <div className={styles.btnWrapper}>
            <FormButtons
              text={CANCEL}
              click={this.props.close}
              disabled={this.state.loading}
              overrideStyles={{ width: "14.2rem" }}
            />
            <FormButtons
              text={SUBMIT}
              click={this.editTerminalApikeyHandler}
              disabled={this.state.loading}
              overrideStyles={{ width: "14.2rem" }}
            />
          </div>
        ) : (
          <div className={styles.singleBtnWrapper}>
            <Button
              text={CLOSE}
              click={this.props.close}
              disabled={this.state.loading}
            />
          </div>
        )}

        <div
          className={styles.spinnerWrapper}
          style={{ display: this.state.loading ? "block" : "none" }}
        >
          <SpinnerLoader />
        </div>
      </div>
    );
  }
}
