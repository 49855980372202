import React, { Component } from "react";
import styles from "./ClearHistory.module.scss";
import Icon from "../Icon/Icon";
import CheckBox from "../CheckBox/CheckBox";
import FormButtons from "../FormButtons/FormButtons";
import SpinnerLoader from "../SpinnerLoader/SpinnerLoader";
import {
  SUBMIT,
  CANCEL,
  CLEAR_HISTORY,
  VERFITY_PWD_VIEW,
  CELAR_HISTORY_VIEW,
  AUTHENTICATION,
} from "../../Util/constants";
import {
  deleteAccountObjects,
  authenticateUserForClearHistory,
} from "../../RequestManager/GqlBuilderAccount";
import { httpRequest } from "../../RequestManager/HttpRequest";
import Swal from "sweetalert2/dist/sweetalert2.js";
import Input from "../Input/Input";
class ClearHistory extends Component {
  auth_err_msg = "";
  state = {
    loading: false,
    users: false,
    telemetryAndPolytrails: false,
    vehicles: false,
    telemetry_errors: false,
    error: true,
    view: VERFITY_PWD_VIEW,
    verify_pwd: {
      value: "",
      error: false,
      name: "verify_pwd",
      type: "password",
    },
    telemetryId: {
      value: "",
      err: false,
      errMsg: "invalid ID number",
      label: "Telemetry ID",
      name: "telemetryId",
      type:"number"
    },
    externalId: {
      value: "",
      err: false,
      errMsg: "Invalid External ID",
      label: "External ID",
      name: "externalId",
      type: "text",
    },
  };
  getMessageByTypeHandler = () => {
    var msg = [];
    if (this.state.users) {
      msg.push("users");
    }
    if (this.state.telemetryAndPolytrails) {
      msg.push("telemetry/ride");
    }
    if (this.state.vehicles) {
      msg.push("vehicles");
    }
    if (this.state.telemetry_errors) {
      msg.push("telemetry errors");
    }
    if (this.state.externalId.value!=="") {
      msg.push("terminal history");
    }
    return msg.join(", ");
  };
  fireSwalHandler = (title, text, type) => {
    Swal.fire({
      title: title,
      text: text,
      type: type,
    }).then((res) => {
      if (type === "success") {
        window.location.reload();
      } else {
        this.setState({ loading: false });
      }
    });
  };

  clearHistoryHandler = () => {
    if (this.validDataToServerHandler()) {
      Swal.fire({
        title: "Are you sure?",
        text: `You are about to delete ${this.getMessageByTypeHandler()}. This can not be reversed.`,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#ff1a1a",
        cancelButtonColor: "#00a0dc",
        confirmButtonText: "Yes. Delete.",
      }).then((res) => {
        if (res.dismiss !== "cancel" && res.dismiss !== "backdrop") {
          httpRequest(deleteAccountObjects, this.createObjectForServerHandler())
            .then((res) => {
              if (res.errors !== undefined) {
                this.fireSwalHandler(
                  "Warning",
                  res.errors[0].message.replace(/_/g, " "),
                  "warning"
                );
              } else {
                this.fireSwalHandler(
                  "Success",
                  "Account was updated, reloading now",
                  "success"
                );
              }
            })
            .catch((err) => {});
        } else {
          this.setState({ loading: false });
        }
      });
    } else {
      this.setState({ loading: false });
    }
  };
  onClickSubmitHandler = () => {
    if (this.state.view == VERFITY_PWD_VIEW) {
      this.verifyPwdHandler();
    } else this.clearHistoryHandler();
  };
  onClickCheckBoxHandler = (type) => {
    this.setState({ [type]: !this.state[type], error: true }, () => {});
  };
  createObjectForServerHandler = () => {
    if (this.state.view == VERFITY_PWD_VIEW)
      return {
        password: this.state.verify_pwd.value.trim(),
      };
    else
      return {
        vehicles: this.state.vehicles,
        telemetryAndPolytrails: this.state.telemetryAndPolytrails,
        users: this.state.users,
        telemetryErrors: this.state.telemetry_errors,
        groups: true,
        deletedTelemetryId:this.state.telemetryId.value!==""?this.state.telemetryId.value:null,
        externalId:this.state.externalId.value.trim()
      };
  };
  validDataToServerHandler = () => {
    let isValid = true;
    if (
      !this.state.users &&
      !this.state.telemetryAndPolytrails &&
      !this.state.vehicles &&
      !this.state.telemetry_errors
    ) {
      isValid = false;
      if (this.state.telemetryId.value!==""){
        isValid=true
      }
      if (this.state.externalId.value.trim()!==""){
        isValid = true; 
      }
    }
    this.setState({ error: isValid });
    return isValid;
  };
  onChangePwdHandler = (event) => {
    if (this.state.loading) return;
    let obj= { ...this.state[event.target.name]};
    obj.value = event.target.value;
    obj.error = false;
    this.auth_err_msg = "";
    this.setState({[event.target.name]: obj });
  };
  onClickIconPasswordHandler = () => {
    let verify_pwd = { ...this.state.verify_pwd };
    verify_pwd.type = verify_pwd.type == "password" ? "text" : "password";
    this.setState({ verify_pwd });
  };
  isValidPwdHandler = () => {
    let verify_pwd = { ...this.state.verify_pwd };
    let isValid = true;
    if (verify_pwd.value.trim() === "") {
      verify_pwd.error = true;
      isValid = false;
    }
    this.setState({ verify_pwd });
    return isValid;
  };
  verifyPwdHandler = () => {
    if (this.isValidPwdHandler()) {
      this.setState({ loading: true }, () => {
        httpRequest(
          authenticateUserForClearHistory,
          this.createObjectForServerHandler()
        )
          .then((res) => {
            if (res.errors !== undefined) {
              this.auth_err_msg = res.errors[0].message.replace(/_/g, " ");
              this.setState({ loading: false });
            } else {
              this.auth_err_msg = "";
              this.setState({ loading: false, view: CELAR_HISTORY_VIEW });
            }
          })
          .catch((err) => {
            this.setState({ loading: false });
          });
      });
    } else {
      this.setState({ loading: false });
    }
  };
  getHeaderTitleAndIconHandler = () => {
    if (this.state.view == VERFITY_PWD_VIEW) {
      return (
        <>
          <span className={styles.headerIcon}>
            <Icon defination={{ prefix: "fal", iconName: "lock-alt" }} />
          </span>
          {AUTHENTICATION}
        </>
      );
    } else {
      return (
        <>
          <span className={styles.headerIcon}>
            <Icon defination={{ prefix: "fal", iconName: "history" }} />
          </span>
          {CLEAR_HISTORY}
        </>
      );
    }
  };
  render() {
    return (
      <div
        className={styles.ClearHistory}
        {...(this.state.view == VERFITY_PWD_VIEW && {
          style: { height: "25rem" },
        })}
      >
        <h3 className={styles.header}>{this.getHeaderTitleAndIconHandler()}</h3>
        <div className={styles.breakLine}></div>
        <div className={styles.formWrapper}>
          {this.state.view == VERFITY_PWD_VIEW && (
            <>
              <p
                className={styles.stickError}
                style={{
                  visibility: this.auth_err_msg != "" ? "visible" : "hidden",
                }}
              >
                {this.auth_err_msg}
              </p>
              <Input
                label="Password"
                value={this.state.verify_pwd.value}
                error={this.state.verify_pwd.error}
                errMsg="Invalid password"
                type={this.state.verify_pwd.type}
                name={this.state.verify_pwd.name}
                isRegularText={false}
                phoneMode={this.props.phoneMode}
                placeholder="Enter password"
                iconDefination={{ prefix: "far", iconName:this.state.verify_pwd.type=="password"? "eye-slash":"eye" }}
                change={(e) => this.onChangePwdHandler(e)}
                inputWrapper={styles.inputWrapper}
                iconAction={this.onClickIconPasswordHandler}
              />
            </>
          )}
          {this.state.view == CELAR_HISTORY_VIEW && (
            <>
              {" "}
              <CheckBox
                id={"Accout Telemetry"}
                name={"Accout Telemetry"}
                value={this.state.telemetry}
                disabled={this.state.loading}
                click={() =>
                  this.onClickCheckBoxHandler("telemetryAndPolytrails")
                }
                checkBoxWrapper={styles.checkBoxTelemetryWrapper}
                labelMsg="Telemetry/Ride history"
              />
              <CheckBox
                id={"Accout vehicle"}
                name={"Accout vehicle"}
                value={this.state.vehicle}
                disabled={this.state.loading}
                click={() => this.onClickCheckBoxHandler("vehicles")}
                checkBoxWrapper={styles.checkBoxTelemetryWrapper}
                labelMsg="Vehicles"
              />
              <CheckBox
                id={"Account Users"}
                name={"Account Users"}
                value={this.state.users}
                disabled={this.state.loading}
                click={() => this.onClickCheckBoxHandler("users")}
                checkBoxWrapper={styles.checkBoxTelemetryWrapper}
                labelMsg="Users"
              />
              <CheckBox
                id={"Telemetry Errors"}
                name={"Telemetry Errors"}
                value={this.state.telemetry_errors}
                disabled={this.state.loading}
                click={() => this.onClickCheckBoxHandler("telemetry_errors")}
                checkBoxWrapper={styles.checkBoxTelemetryWrapper}
                labelMsg="Telemetry Errors"
              />
              <Input
                label={this.state.telemetryId.label}
                value={this.state.telemetryId.value}
                error={this.state.telemetryId.error}
                errMsg="Invalid Telemetry ID"
                type={this.state.telemetryId.type}
                name={this.state.telemetryId.name}
                isRegularText={false}
                phoneMode={true}
                placeholder="Enter Telemetry ID"
                change={(e) => this.onChangePwdHandler(e)}
                inputWrapper={styles.checkBoxTelemetryWrapper}
              />
               <Input
                label={this.state.externalId.label}
                value={this.state.externalId.value}
                error={this.state.externalId.error}
                errMsg="Invalid External ID"
                type={this.state.externalId.type}
                name={this.state.externalId.name}
                isRegularText={false}
                phoneMode={true}
                placeholder="Enter External ID"
                change={(e) => this.onChangePwdHandler(e)}
                inputWrapper={styles.checkBoxTelemetryWrapper}
              />
            </>
          )}
        </div>
        <p
          className={styles.errMsg}
          style={{ visibility: !this.state.error ? "visible" : "hidden" }}
        >
          You need to select the fields to be removed{" "}
        </p>
        <div className={styles.btnWrapper}>
          <FormButtons
            overrideClass={styles.btn}
            text={CANCEL}
            disabled={this.state.loading}
            click={() => this.props.cancel("")}
          />
          <FormButtons
            overrideClass={styles.btn}
            text={SUBMIT}
            disabled={this.state.loading}
            click={this.onClickSubmitHandler}
          />
        </div>
        <div
          className={styles.spinnerWrapper}
          style={{ display: this.state.loading ? "block" : "none" }}
        >
          <SpinnerLoader />
        </div>
      </div>
    );
  }
}

export default ClearHistory;
