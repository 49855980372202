import React from "react";
import styles from "./PageNotFound.module.scss";
import Button from "../../Component/Button/Button";
import Icon from "../../Component/Icon/Icon";
import { withRouter } from "react-router-dom";
class PageNotFound extends React.Component {

  clickBackBtnHandler = () => {
    if (this.props.history !== undefined) {
      this.props.history.goBack();
    }
  };

  render() {
    return (
      <div className={styles.pageNotFounds}>
        <div className={styles.container}>
          <h2 className={styles.header}>PAGE NOT FOUND</h2>
          <h2 className={styles.icon}>
            <Icon defination={{ prefix: "fal", iconName: "file-search" }} />
          </h2>
          <div className={styles.btnWrapper}>
            <Button
              text="back"
              click={this.clickBackBtnHandler}
              overrideClass={styles.btn}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(PageNotFound);
