import React, { Component } from "react";
import {
  CANCEL,
  SUBMIT,
  VEHICLE_TYPES,
  DYNAMIC,
  EDIT_MODE,
  CREATE_MODE,
  VIEW_MODE,
  CLOSE,
  PICTURE,
  FILE,
  CLOUDINARY_UPLOAD_PRESET,
  UPLOAD_PRESET,
  KM_HR,
  MILES,
  KPH,
  TERMINAL_ID,
  DEVICE_NAME,
  FENCING,
  FENCING_TITLE,
} from "../../Util/constants";
import moment from "moment";
import FormButtons from "../FormButtons/FormButtons";
import Controller from "../../Controller";
import SearchBar from "../GoogleMap/SearchBar/SearchBar";
import RadioButton from "../RadioButton/RadioButton";
import Icon from "../Icon/Icon";
import Input from "../Input/Input";
import DateTimePicker from "../DateTimePicker/DateTimePicker";
import SpinnerLoader from "../SpinnerLoader/SpinnerLoader";
import {
  assignFencesToVehicle,
  getAvailableTerminals,
  getAvailableTerminalsAndFencing,
} from "../../RequestManager/GqlBuilderAccount";
import { httpRequest } from "../../RequestManager/HttpRequest";
import UploadFile from "../UploadFile/UploadFile";
import styles from "./CreateVehicle.module.scss";
import SelectWithIcon from "../SelectWithIcon/SelectWithIcon";
import CheckBox from "../CheckBox/CheckBox";
import { dateConverstion, getTypeOfTerminal } from "../../Util/globalFunc";
import Button from "../Button/Button";
import {
  createVehicle,
  updateVehicle,
} from "../../RequestManager/GqlBuilderAccount";
import Swal from "sweetalert2/dist/sweetalert2.js";
import SelectWithOptions from "../SelectWithOptions/SelectWithOptions";
import ImageCrop from "../ImageCrop/ImageCrop";
import PreviewImage from "../PreviewImage/PreviewImage";
import { Fragment } from "react";
import MultiSelect from "../MultiSelect/MultiSelect";
const VEHICLES = "vehicles";
export default class CreateVehicle extends Component {
  nextYear = moment().add(1, "years").year();
  hasFencing = false;
  state = {
    unitErr: false,
    speed: KM_HR,
    loading: true,
    isMouted: false,
    isValidForm: true,
    selectedTerminal: {},
    availableTerminals: {},
    croppedImageUrl: null,
    showPreviewImage: false,
    openImageCropModel: false,
    selectAllZones: false,
    accountFencingList: [{ displayName: "No Zone Selected", lookupId: -1 }],
    showAssignedZones: false,
    address: {
      type: "address",
      address: "",
      error: false,
      size: 250,
      label: "Legal Address",
      errMsg: "Invalid address",
      country: "",
      lat: "",
      lng: "",
    },
    terminals: {
      value: "",
      error: false,
      errMsg: "You must terminal",
      label: DEVICE_NAME,
      type: "select",
    },

    terminalID: {
      value: "",
      error: false,
      errMsg: "You must terminal",
      label: TERMINAL_ID,
      type: "text",
      name: "terminalID",
    },
    uploadFile: {
      value: "",
      error: false,
      errMsg: "select file to upload",
      label: "",
      placeholder: "Select picture",
      fileName: "",
    },
    createVehicle: {
      vehicleName: {
        label: "Vehicle name",
        value: "",
        error: false,
        name: "vehicleName",
        type: "text",
        errMsg: "Invalid name",
        placeholder: "Enter vehicle Name ",
      },
      fencing: {
        label: "Select Zone",
        value: {},
        error: false,
        errMsg: "You must terminal",
        label: FENCING_TITLE,
        type: "multiSelect",
        name: "fencing",
      },
      vehicleType: {
        label: "Vehicle Type",
        value: "",
        error: false,
        name: "vehicleType",
        type: "text",
        errMsg: "Invalid Vehicle type",
        placeholder: "Enter vehicle type",
      },
      modelNumber: {
        label: "Model",
        value: "",
        error: false,
        name: "modelNumber",
        type: "text",
        errMsg: "Invalid model number",
        placeholder: "Enter model number",
      },
      year: {
        label: "Year",
        value: "",
        error: false,
        name: "year",
        type: "number",
        errMsg: "Invalid year, choose between 1950-" + this.nextYear,
        placeholder: "Enter year",
      },
      manufacturer: {
        label: "Manufacturer",
        value: "",
        error: false,
        name: "manufacturer",
        type: "text",
        errMsg: "Invalid manufacturer",
        placeholder: "Enter manufacturer",
      },

      licenseNumber: {
        label: "License Number",
        value: "",
        error: false,
        name: "licenseNumber",
        type: "text",
        errMsg: "Invalid license number",
        placeholder: "Enter license number",
      },
      vinNumber: {
        label: "VIN Number",
        value: "",
        error: false,
        name: "vinNumber",
        type: "text",
        errMsg: "Invalid VIN number",
        placeholder: "Enter vin number",
      },
    },
  };
  componentDidMount() {
    if (this.props.mode !== VIEW_MODE) this.initTermianlsHandler();
    else this.intiallizeDataHandler(null);
  }
  getLockUpIdHandler = () => {
    if (this.props.mode === CREATE_MODE) {
      return "";
    } else if (
      this.props.data.terminal !== null &&
      Object.keys(this.props.data.terminal).length
    ) {
      return this.props.data.terminal.lookupId;
    } else {
      return "";
    }
  };
  initTermianlsHandler = () => {
    httpRequest(getAvailableTerminalsAndFencing, {
      first: this.getLockUpIdHandler(),
      display: "available",
      accountLookupId:
        this.props.mode == CREATE_MODE
          ? Controller.getAccountId()
          : this.props.data.ownerAccount.lookupId,
    })
      .then((res) => {
        this.intiallizeDataHandler(res.data);
      })
      .catch((err) => {});
  };
  convertTerminalsHandler = (arr) => {
    let terminals = {};
    if (arr == null) return terminals;
    arr.map((item) => {
      terminals[item.lookupId] = {
        id: item.lookupId,
        name: `${item.externalId} -(${
          item.deviceName.length > 15
            ? item.deviceName.substring(0, 15) + "..."
            : item.deviceName
        })`,
        macId: item.macAddress,
        type: item.terminalType,
      };
    });
    return terminals;
  };
  onCliclSelectAllZonesHandler = () => {
    let fencing = { ...this.state.createVehicle.fencing.value };
    let copyData = { ...this.state.createVehicle };

    this.state.accountFencingList.map((item, index) => {
      if (index > 0) {
        if (this.state.selectAllZones) delete fencing[item.lookupId];
        else fencing[item.lookupId] = item.displayName;
      }
    });
    copyData.fencing.value = fencing;
    copyData.fencing.error = false;
    this.setState({
      selectAllZones: !this.state.selectAllZones,
      createVehicle: copyData,
    });
  };
  intiallizeDataHandler = (apiData) => {
    let copyInputs = { ...this.state.createVehicle };
    let copyLocation = { ...this.state.address };
    let copyTerminals = { ...this.state.terminals };
    let copy_terminalID = { ...this.state.terminalID };
    let selectAllZones = false;
    let availableTerminals = null;
    let obj = {};
    let accountFencingList = [];
    let data = this.props.data;
    if (apiData != null && apiData.accountFencing.objs != null) {
      accountFencingList = [
        ...this.state.accountFencingList,
        ...apiData.accountFencing.objs,
      ];
    }
    if (apiData != null) availableTerminals = apiData.terminals.terminals;
    obj = this.convertTerminalsHandler(availableTerminals);
    if (this.props.mode !== CREATE_MODE) {
      copy_terminalID.value =
        data.terminal !== null ? data.terminal.externalId : "N/A";
      copyInputs.licenseNumber.value = data.licensePlate.number;
      copyInputs.year.value = new Date(data.year.toString());
      copyInputs.vinNumber.value = data.vin;
      copyInputs.manufacturer.value = data.make;
      copyInputs.vehicleName.value = data.name;
      copyInputs.vehicleType.value =
        VEHICLE_TYPES[data.vClass.lookupId] == undefined
          ? {}
          : VEHICLE_TYPES[data.vClass.lookupId];
      copyInputs.modelNumber.value = data.model;
      copyTerminals.value =
        data.terminal != null
          ? this.props.mode == VIEW_MODE
            ? data.terminal.deviceName
            : data.terminal.lookupId
          : "";
      if (data.location !== null) {
        copyLocation.address = data.location.address;
        copyLocation.country = data.location.country;
        copyLocation.lat = parseFloat(data.location.latitude);
        copyLocation.lng = parseFloat(data.location.longitude);
      }
      let selectedFences = {};
      data.fences.map((item) => {
        selectedFences[item.lookupId] = item.displayName;
      });
      if (data.fences.length == accountFencingList.length - 1) {
        selectAllZones = true;
      }
      copyInputs.fencing.value = selectedFences;
      this.setState(
        {
          speed:
            data.speedUnit == 1 ? KM_HR : data.speedUnit == 3 ? KPH : MILES,
          terminalID: copy_terminalID,
          createVehicle: copyInputs,
          address: copyLocation,
          terminals: copyTerminals,
          availableTerminals: obj,
          accountFencingList: accountFencingList,
          selectedTerminal: {},
          loading: false,
          selectAllZones,
          croppedImageUrl:
            this.props.mode == CREATE_MODE ? null : this.props.data.avatar,
        },
        () => {}
      );
    }

    this.setState(
      {
        createVehicle: copyInputs,
        address: copyLocation,
        availableTerminals: obj,
        selectedTerminal: {},
        loading: false,
        accountFencingList,
      },
      () => {}
    );
  };
  onclickHandler = (item) => {
    let copyData = { ...this.state.createVehicle };
    copyData.vehicleType.value = item;
    copyData.vehicleType.error = false;
    this.setState(
      {
        createVehicle: copyData,
        showOptionsList: Object.keys(item).length == 0,
        unitErr: false,
      },
      () => {}
    );
  };
  onclickFenceHandler = (item) => {
    let fencing = { ...this.state.createVehicle.fencing.value };
    let selectAllZones = this.state.selectAllZones;
    if (item.lookupId == -1) {
      fencing = {};
    } else if (fencing[item.lookupId] != undefined) {
      delete fencing[item.lookupId];
    } else fencing[item.lookupId] = item.displayName;

    if (
      Object.keys(fencing).length <
      this.state.accountFencingList.length - 1
    ) {
      selectAllZones = false;
    }
    let copyData = { ...this.state.createVehicle };
    copyData.fencing.value = fencing;
    copyData.fencing.error = false;
    this.setState({
      createVehicle: copyData,
      selectAllZones,
    });
  };
  showOptionsHandler = (val) => {
    this.setState({ showOptionsList: val, isValidForm: true });
  };
  showFencingHandler = (val) => {
    this.setState({ showFencingList: val, isValidForm: true });
  };
  dateChangeHandler = (date, name) => {
    let copyInputs = { ...this.state.createVehicle };

    if (date === "") copyInputs.year.value = "";
    else copyInputs.year.value = moment(date).format("MM/DD/YYYY");

    copyInputs.year.error = false;
    this.setState(
      { createVehicle: copyInputs, loading: false, isValidForm: true },
      () => {}
    );
  };
  buildInputsElementsHandler = () => {
    let arr = Object.values(this.state.createVehicle);
    return arr.map((item, index) => {
      if (item.name == "year" && this.props.mode != VIEW_MODE) {
        return (
          <DateTimePicker
            onChange={this.dateChangeHandler}
            timeFormat={false}
            name="year"
            key={index}
            dateFormat={"YYYY"}
            value={dateConverstion(item.value)}
            placeholder="year"
            label="Year"
            disabled={this.state.loading || this.props.mode == VIEW_MODE}
            error={item.error}
            errMsg={item.errMsg}
            phoneMode={this.props.phoneMode}
            inputWrapper={styles.inputWrapper}
            pickerStyles={styles.picker}
            iconDefination={{
              prefix: "fas",
              iconName: "calendar-alt",
            }}
          />
        );
      } else if (item.name == "fencing" && this.props.mode != VIEW_MODE) {
        const titleTextAndHTml = (
          <>
            <span>Permitted Zone</span>
            <CheckBox
              labelMsg="Select All Zones"
              disabled={
                this.state.loading || this.state.accountFencingList.length == 0
              }
              name="selectAllZones"
              click={this.onCliclSelectAllZonesHandler}
              checkBoxWrapper={styles.selectAllZoneCheckbox}
              value={this.state.selectAllZones}
            />
          </>
        );
        return (
          <MultiSelect
            //labelStyles={styles.label}
            titleText={titleTextAndHTml}
            selectOptions={this.state.accountFencingList}
            error={item.error}
            key={index}
            errMsg={item.errMsg}
            phoneMode={this.props.phoneMode}
            wrapperSelected={styles.selectWithOptionsWrapper}
            disabled={this.state.loading}
            click={(item) => this.onclickFenceHandler(item)}
            selectedOptionDisplay={
              this.state.selectAllZones
                ? "All Zones Selected"
                : Object.values(item.value).length == 0
                ? this.state.accountFencingList[0].displayName
                : Object.values(item.value).join(", ")
            }
            selectedList={item.value}
            showOptionsList={this.state.showFencingList}
            showOptions={this.showFencingHandler}
          />
        );
      } else if (item.name == "vehicleType" && this.props.mode != VIEW_MODE) {
        return (
          <SelectWithIcon
            title={styles.label}
            titleText="Vehicle Type"
            selectOptions={Object.values(VEHICLE_TYPES)}
            error={item.error}
            key={index}
            errMsg={item.errMsg}
            phoneMode={this.props.phoneMode}
            wrapperSelected={styles.selectWithOptionsWrapper}
            disabled={this.state.loading || this.props.mode == VIEW_MODE}
            click={(item) => this.onclickHandler(item)}
            selectedOption={item.value}
            showOptionsList={this.state.showOptionsList}
            showOptions={this.showOptionsHandler}
          />
        );
      } else {
        let value = item.value;
        if (item.name == "year") {
          value =
            item.value != "" ? moment(item.value, "DD/MM/YYYY").year() : "";
        }
        if (item.name == "vehicleType") {
          value = Object.keys(item.value).length ? item.value.displayName : "";
        }
        if (item.name == "fencing") {
          return;
        }
        return (
          <Input
            type={item.type}
            name={item.name}
            key={index}
            value={value}
            placeholder={item.label}
            label={item.label}
            error={item.error}
            errMsg={item.errMsg}
            inputWrapper={styles.inputWrapper}
            change={(e) => this.onChangeHandler(e)}
            phoneMode={this.props.phoneMode}
            disabled={this.props.mode == VIEW_MODE}
          />
        );
      }
    });
  };
  getZonesListHandler = () => {
    return (
      <div className={styles.infoWrapperZones}>
        <div className={styles.infoDataWrapper}>
          <div className={styles.infoHeader}>
            <span className={styles.infoTitle}>
              <span className={styles.infoIcon}>
                <Icon
                  defination={{ prefix: "fal", iconName: "border-outer" }}
                />
              </span>
              Assinged Zones
            </span>
            <span
              className={styles.closeIcon}
              onClick={this.showAssignedZonesHandler}
            >
              <Icon defination={{ prefix: "fal", iconName: "times" }} />
              <span className={styles.toolTip}>Close</span>
            </span>
          </div>
          <div className={styles.breakLine}></div>

          <div className={styles.listWrapperZones}>
            <ul>
              {this.props.data.fences.map((item, index) => {
                return (
                  <li className={styles.listItem} key={index}>
                    {item.displayName}
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    );
  };
  getMarkerHandler = () => {
    return [{ lat: 51.5074, lng: 0.1278 }];
  };

  onChangeHandler = (event) => {
    if (this.state.loading) {
      return;
    }
    let copyInputs = { ...this.state.createVehicle };
    copyInputs[event.target.name].value = event.target.value;
    copyInputs[event.target.name].error = false;
    this.setState(
      { createVehicle: copyInputs, loading: false, isValidForm: true },
      () => {}
    );
  };
  onChangePhoneHandler = (value) => {
    if (this.state.loading) return;
    let copycreate = { ...this.state.createVehicle };
    copycreate.telephone.value = value;
    copycreate.telephone.error = false;
    this.setState({ createAccount: copycreate, errorMsg: "" });
  };
  formatAddressHandler = (address) => {
    let copyAddress = { ...this.state.address };
    if (address === -1 || address.status !== "OK") {
      copyAddress.address = "";
      copyAddress.country = "";
      this.setState({ address: copyAddress }, () => {});
      return;
    } else {
      let arr = address.results[0].address_components;
      copyAddress.address = address.results[0].formatted_address;
      copyAddress.lat = address.results[0].geometry.location.lat;
      copyAddress.lng = address.results[0].geometry.location.lng;
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].types[0] === "country") {
          copyAddress.country = arr[i].short_name;
          copyAddress.error = false;
          break;
        }
      }
      copyAddress.error = false;
      this.setState({ address: copyAddress }, () => {});
    }
  };
  uploadFileHandler = (event) => {
    let uploadFile = { ...this.state.uploadFile };

    if (this.checkFileValidHandler(event.target.files)) {
      uploadFile.value = event.target.files[0];
      uploadFile.error = false;
      uploadFile.fileName = event.target.files[0].name;
    } else {
      uploadFile.error = true;
    }
    this.setState(
      {
        uploadFile: uploadFile,
        openImageCropModel: true,
        croppedImageUrl: null,
      },
      () => {}
    );
  };
  checkFileValidHandler = (file) => {
    if (file.length) {
      let typeOfFile = ["png", "jpg", "jpeg"];
      let typeImage = file[0].type.split("/")[1].toLowerCase();
      return typeOfFile.includes(typeImage);
    } else {
      return false;
    }
  };
  fireSwalHandler = (title, text, type) => {
    Swal.fire({
      title: title,
      text: text,
      type: type,
    }).then((res) => {
      if (type === "success") {
        this.props.reloadPage(VEHICLES);
      }
    });
  };
  checkRespondServerHandler = (res) => {
    if (res.errors !== undefined) {
      this.fireSwalHandler(
        "Warning",
        res.errors[0].message.replace(/_/g, " "),
        "warning"
      );
      this.setState({ loading: false });
    } else {
      this.fireSwalHandler(
        "Success",
        this.props.mode === EDIT_MODE
          ? "Vehicle was updated"
          : "Vehicle was created",
        "success"
      );
    }
  };
  updateFencesToVehicleHandler = (res) => {
    if (res.errors !== undefined) {
      this.fireSwalHandler(
        "Warning",
        res.errors[0].message.replace(/_/g, " "),
        "warning"
      );
      this.setState({ loading: false });
    } else if (
      this.props.mode === CREATE_MODE &&
      Object.keys(this.state.createVehicle.fencing.value).length > 0
    ) {
      let params = {
        vehicleLookupId: res.data.createVehicle.vehicle.lookupId,
        fencesLookupIds: Object.keys(this.state.createVehicle.fencing.value),
      };
      httpRequest(assignFencesToVehicle, params).then((res) => {
        this.checkRespondServerHandler(res);
      });
    } else {
      this.checkRespondServerHandler(res);
    }
  };
  submitVehicleDataHandler = (data) => {
    let query = this.props.mode == CREATE_MODE ? createVehicle : updateVehicle;
    httpRequest(query, data)
      .then((res) => {
        this.updateFencesToVehicleHandler(res);
      })
      .catch((err) => {});
  };
  createVehicleHandler = () => {
    if (this.state.loading) return;
    //login
    if (this.checkValidGeneralInfoHandler()) {
      this.setState({ loading: true }, () => {
        let vehicle = this.createObjForServer();
        if (this.props.mode === CREATE_MODE) {
          if (this.state.uploadFile.value !== "") {
            let formData = new FormData();
            //formData.append(FILE, this.state.uploadFile.value);
            formData.append(FILE, this.state.croppedImageUrl);
            formData.append(UPLOAD_PRESET, CLOUDINARY_UPLOAD_PRESET);
            httpRequest({}, formData, PICTURE)
              .then((res) => {
                return Promise.resolve(res.data.secure_url);
              })
              .then((fileUrl) => {
                vehicle.avatar = fileUrl;
                let data = {
                  vehicle: vehicle,
                };
                this.submitVehicleDataHandler(data);
              })
              .catch((err) => {
                console.log(err);
              });
          } else {
            let data = {
              vehicle: vehicle,
            };
            this.submitVehicleDataHandler(data);
          }
        } else {
          ////edit mode
          let data = {
            lookupId: this.props.data.lookupId,
            terminalLookupId: this.state.terminals.value,
            fencesLookupIds: Object.keys(
              this.state.createVehicle.fencing.value
            ),
          };
          delete vehicle.accountLookupId;
          delete vehicle.terminalLookupId;
          if (this.state.uploadFile.value !== "") {
            let formData = new FormData();
            formData.append(FILE, this.state.croppedImageUrl);
            formData.append(UPLOAD_PRESET, CLOUDINARY_UPLOAD_PRESET);
            httpRequest({}, formData, PICTURE)
              .then((res) => {
                return Promise.resolve(res.data.secure_url);
              })
              .then((fileUrl) => {
                vehicle.avatar = fileUrl;
                data.vehicle = vehicle;
                this.submitVehicleDataHandler(data);
              })
              .catch((err) => {
                console.log(err);
              });
          } else {
            data.vehicle = vehicle;
            this.submitVehicleDataHandler(data);
          }
        }
      });
    } else {
      this.setState({ loading: false });
    }
  };
  checkValidGeneralInfoHandler = () => {
    let isValid = true;
    let unitErr = false;
    let copyAccount = { ...this.state.createVehicle };

    let addressCopy = { ...this.state.address };
    let passArr = ["vehicleType", "year", "fencing"];
    let vehichleTypeValidForKph = ["fishing boat", "boat"];
    for (let key in copyAccount) {
      if (!passArr.includes(key) && copyAccount[key].value.trim() === "") {
        copyAccount[key].error = true;
        isValid = false;
      }
    }
    if (addressCopy.address === "" || addressCopy.address.length > 250) {
      addressCopy.error = true;
      isValid = false;
    }
    if (
      copyAccount.vehicleType.value == "" ||
      copyAccount.vehicleType.value.lookupId == -1
    ) {
      copyAccount.vehicleType.error = true;
      isValid = false;
    }
    let year = moment(copyAccount.year.value, "DD/MM/YYYY").year();

    if (copyAccount.year.value === "" || year < 1950 || year > this.nextYear) {
      copyAccount.year.error = true;
      isValid = false;
    }
    if (
      this.state.speed == KPH &&
      copyAccount.vehicleType.value !== "" &&
      !vehichleTypeValidForKph.includes(copyAccount.vehicleType.value.nameLower)
    ) {
      isValid = false;
      unitErr = true;
    }
    this.setState({
      createVehicle: copyAccount,
      address: addressCopy,
      unitErr: unitErr,
    });

    return isValid;
  };
  onClickTerminalItem = (item, index) => {
    this.setState({ selectedTerminal: item });
  };
  onClickUserItem = (item, index) => {
    this.setState({ selectedUser: item });
  };
  getIconTypeDevice = (device) => {
    if (
      device.terminalType === 1 ||
      device.terminalType == 3 ||
      device.terminalType == 4
    ) {
      return getTypeOfTerminal(device.terminalType);
    } else if (device.terminalType === 2) {
      return DYNAMIC;
    } else {
      return <span>N/A</span>;
    }
  };

  createObjForServer = () => {
    let copyAccount = { ...this.state.createVehicle };
    let addressCopy = { ...this.state.address };
    return {
      name: copyAccount.vehicleName.value,
      make: copyAccount.manufacturer.value,
      model: copyAccount.modelNumber.value,
      vin: copyAccount.vinNumber.value,
      vClassLookupId: copyAccount.vehicleType.value.lookupId,
      year: moment(copyAccount.year.value, "DD/MM/YYYY").year().toString(),
      licensePlateNumber: copyAccount.licenseNumber.value,
      accountLookupId: Controller.getAccountId(),
      //alerts: this.state.permittedZone,
      terminalLookupId: this.state.terminals.value,
      speedUnit:
        this.state.speed === KM_HR
          ? "KMH"
          : this.state.speed == MILES
          ? "MPH"
          : KPH,
      location: {
        address: addressCopy.address,
        country: addressCopy.country,
        latitude: addressCopy.lat.toFixed(6).toString(),
        longitude: addressCopy.lng.toFixed(6).toString(),
        postCode: "",
      },
    };
  };

  getClearPermHandelr = () => {
    if (this.props.mode === CREATE_MODE || this.props.mode == VIEW_MODE)
      return false;
    return true;
  };

  clearSelectedTerminalHandler = () => {
    let terminal = { ...this.state.terminals };
    terminal.value = "";
    this.setState({ terminals: terminal });
  };
  onSelectTerminalHandler = (event) => {
    let terminal = { ...this.state.terminals };
    terminal.value = event.target.value;
    this.setState({ terminals: terminal });
  };
  sendImageUrlHandler = (url) => {
    this.setState({ croppedImageUrl: url });
  };
  closePreviewHandler = () => {
    this.setState({ showPreviewImage: false, openImageCropModel: false });
  };
  showPreviewHandler = () => {
    this.setState({ showPreviewImage: true }, () => {});
  };
  showAssignedZonesHandler = () => {
    this.setState(
      { showAssignedZones: !this.state.showAssignedZones },
      () => {}
    );
  };
  cancelImageHandler = () => {
    let uploadFile = { ...this.state.uploadFile };
    uploadFile.value = "";
    uploadFile.error = false;
    uploadFile.fileName = "";
    this.setState({
      uploadFile: uploadFile,
      croppedImageUrl: null,
    });
  };
  onClickToggleBtnHandler = (type) => {
    this.setState({ speed: type, unitErr: false });
  };
  render() {
    const { terminals, terminalID } = this.state;
    return (
      <div className={styles.CreateVehicles}>
        <h3 className={styles.header}>
          <span className={styles.headerIcon}>
            <Icon defination={{ prefix: "fal", iconName: "truck-pickup" }} />
          </span>
          <span className={styles.text}>{this.props.mode} Vehicle</span>
        </h3>
        <div className={styles.breakLine}></div>
        <p
          className={styles.stickError}
          style={{
            visibility: !this.state.isValidForm ? "visible" : "hidden",
          }}
        >
          Please enter missing information
        </p>
        <div
          className={styles.infoWrapper}
          style={{ opacity: this.state.loading ? 0.6 : 1 }}
        >
          <div
            className={
              (this.state.uploadFile.value != "" &&
                this.state.openImageCropModel) ||
              this.state.showPreviewImage
                ? styles.openWrapper
                : styles.closeWrapper
            }
          >
            {this.state.showPreviewImage ? (
              <PreviewImage
                previewImage={this.props.data.avatar}
                closePreview={this.closePreviewHandler}
              />
            ) : (
              <ImageCrop
                imageSrc={this.state.uploadFile.value}
                sendImageUrl={this.sendImageUrlHandler}
                previewImage={this.state.croppedImageUrl}
                closePreview={this.closePreviewHandler}
                mode={this.props.mode}
                cancel={this.cancelImageHandler}
              />
            )}
          </div>
          <div className={styles.formWrapper}>
            <div className={styles.titleWrapper}>
              <p className={styles.formTitle}>Vehicle Details </p>
              <span>
                {this.props.mode !== VIEW_MODE && (
                  <>
                    <Button
                      text="Preview Image"
                      click={this.showPreviewHandler}
                      disabled={
                        this.state.loading || this.props.data.avatar == null
                      }
                      overrideStyles={{ marginRight: "2rem" }}
                    />
                  </>
                )}
                {this.props.mode == VIEW_MODE && (
                  <Button
                    text="Assinged Zones"
                    click={this.showAssignedZonesHandler}
                    disabled={
                      this.state.loading || !this.props.data.fences.length
                    }
                    overrideStyles={{ marginLeft: "2rem" }}
                  />
                )}
                {this.getClearPermHandelr() && (
                  <Button
                    text="Clear Terminal"
                    click={this.clearSelectedTerminalHandler}
                  />
                )}
              </span>
            </div>
            <div className={styles.titleBreakLine}></div>
            <div className={styles.form}>
              {this.state.showAssignedZones && this.getZonesListHandler()}
              {this.buildInputsElementsHandler()}
              {this.props.mode == VIEW_MODE ? (
                <Fragment>
                  <Input
                    label={DEVICE_NAME}
                    error={terminals.error}
                    errMsg={terminals.errMsg}
                    value={terminals.value == "" ? "N/A" : terminals.value}
                    name={terminals.name}
                    inputWrapper={styles.inputWrapper}
                    disabled={true}
                    phoneMode={this.props.phoneMode}
                  />
                  <Input
                    label={TERMINAL_ID}
                    error={terminalID.error}
                    errMsg={terminalID.errMsg}
                    value={terminalID.value == "" ? "N/A" : terminalID.value}
                    name={terminalID.name}
                    inputWrapper={styles.inputWrapper}
                    disabled={true}
                    phoneMode={this.props.phoneMode}
                  />
                </Fragment>
              ) : (
                <SelectWithOptions
                  wrapperClass={styles.inputWrapper}
                  optionsList={Object.values(this.state.availableTerminals)}
                  defaultOptionTitle={"Select Terminal"}
                  label={"Terminals"}
                  error={terminals.error}
                  errMsg={terminals.errMsg}
                  name={terminals.name}
                  phoneMode={this.props.phoneMode}
                  onchange={(e) => this.onSelectTerminalHandler(e)}
                  selected={terminals.value}
                  disabled={this.props.mode == VIEW_MODE}
                />
              )}
              {this.props.mode != VIEW_MODE ? (
                <SearchBar
                  label={"Location"}
                  phoneMode={this.props.phoneMode}
                  address={this.state.address.address}
                  formatAddress={this.formatAddressHandler}
                  searchWrapper={styles.inputWrapper}
                  error={this.state.address.error}
                  disabled={this.props.mode == VIEW_MODE}
                />
              ) : (
                <Input
                  label={"Location"}
                  error={""}
                  errMsg={""}
                  value={this.state.address.address}
                  name={"address"}
                  inputWrapper={styles.inputWrapper}
                  disabled={true}
                  phoneMode={this.props.phoneMode}
                />
              )}
              <div className={styles.inputWrapper}>
                <label className={styles.titleSpeed}>
                  Units
                  <span
                    className={styles.unitErr}
                    style={{
                      visibility: this.state.unitErr ? "visible" : "hidden",
                    }}
                  >
                    &nbsp;Unit type does not match the vehicle type
                  </span>
                </label>

                <div
                  className={styles.radioWrapper}
                  style={{
                    position: this.props.mode == VIEW_MODE ? "unset" : "",
                  }}
                >
                  <RadioButton
                    name={KM_HR}
                    label={KM_HR}
                    id={KM_HR}
                    // inputWrapper={styles.radioWrapper}
                    change={() => this.onClickToggleBtnHandler(KM_HR)}
                    checked={this.state.speed === KM_HR}
                    disabled={
                      this.state.loading || this.props.mode == VIEW_MODE
                    }
                  />
                  <RadioButton
                    name={MILES}
                    label={MILES}
                    id={MILES}
                    inputWrapper={styles.radio}
                    change={() => this.onClickToggleBtnHandler(MILES)}
                    checked={this.state.speed === MILES}
                    disabled={
                      this.state.loading || this.props.mode == VIEW_MODE
                    }
                  />
                  <RadioButton
                    name={KPH}
                    label={"Knots"}
                    id={KPH}
                    inputWrapper={styles.radio}
                    change={() => this.onClickToggleBtnHandler(KPH)}
                    checked={this.state.speed === KPH}
                    disabled={
                      this.state.loading || this.props.mode == VIEW_MODE
                    }
                  />
                </div>
              </div>
              <div
                className={styles.inputWrapper}
                style={{
                  marginTop: this.props.mode == VIEW_MODE ? "1.5rem" : "",
                }}
              >
                {this.props.mode != VIEW_MODE ? (
                  <UploadFile
                    label="Upload"
                    inputWrapper={styles.uploadFileWrapper}
                    showLoader={this.state.loading}
                    error={this.state.uploadFile.error}
                    errMsg={this.state.uploadFile.errMsg}
                    name="upload_image"
                    phoneMode={this.props.phoneMode}
                    acceptedFiles=".jpeg, .png, .jpg"
                    fileName={this.state.uploadFile.fileName}
                    placeholder={this.state.uploadFile.placeholder}
                    clickAction={(e) => this.uploadFileHandler(e)}
                  />
                ) : (
                  <div className={styles.previewWrapper}>
                    <Button
                      text="Preview Image"
                      click={this.showPreviewHandler}
                      disabled={this.props.data.avatar == null}
                    />
                    {this.props.phoneMode && (
                      <Button
                        text="Assinged Zones"
                        click={this.showAssignedZonesHandler}
                        disabled={
                          this.state.loading || !this.props.data.fences.length
                        }
                        overrideStyles={{ marginLeft: "2rem" }}
                      />
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className={styles.btnWrapper}>
          {this.props.mode == VIEW_MODE ? (
            <Button
              click={this.props.close}
              overrideStyles={{
                marginRight: this.props.phoneMode ? "" : "2rem",
              }}
              text={CLOSE}
            />
          ) : (
            <>
              <FormButtons
                text={CANCEL}
                click={this.props.close}
                disabled={this.state.loading}
                overrideStyles={{ width: "14.2rem" }}
              />
              <FormButtons
                text={SUBMIT}
                click={this.createVehicleHandler}
                disabled={this.state.loading}
                overrideStyles={{ width: "14.2rem" }}
              />
            </>
          )}
        </div>
        <div
          className={styles.spinnerWrapper}
          style={{ display: this.state.loading ? "block" : "none" }}
        >
          <SpinnerLoader />
        </div>
      </div>
    );
  }
}
