import React, { Component } from "react";
import styles from "./SelectWithOptions.module.scss";
export default class SelectWithOptions extends Component {
  render() {
    return (
      <div className={this.props.wrapperClass}>
        <label className={styles.label}>
          <span className={styles.text}>{this.props.label}</span>
          {this.props.phoneMode && (
            <span
              className={styles.error}
              style={{ visibility: this.props.error ? "visible" : "hidden" }}
            >
              {this.props.errMsg}
            </span>
          )}
        </label>
        <select
          id="selectWithOption"
          className={styles.select}
          onChange={this.props.onchange}
          name={this.props.name}
          value={this.props.selected}
          disabled={
            this.props.loading ||
            !this.props.optionsList.length ||
            this.props.disabled
          }
        >
          {this.props.defaultOptionTitle && (
            <option key="-1" value="-1">
              {this.props.defaultOptionTitle}
            </option>
          )}
          {this.props.optionsList.map((item, index) => {
            return (
              <option key={index} value={item.id}>
                {item.name}
              </option>
            );
          })}
        </select>
        {!this.props.phoneMode && (
          <p
            className={styles.error}
            style={{ visibility: this.props.error ? "visible" : "hidden" }}
          >
            {this.props.errMsg}
          </p>
        )}
      </div>
    );
  }
}
