import React from "react";
import { LOGIN } from "../src/Util/routes";
import axios from "axios";
import Config from "../src/config";
import { checkUserToken } from "../src/RequestManager/GqlBuilderAccount";
import {
  setAuthToken,
  getAuthToken,
  getRefreshToken,
  removeAuthToken,
} from "../src/Store/localStorageManager/localStorageManager";

var timeout_hoc = null;

const HOC = (InnerComponent) =>
  class extends React.Component {
    constructor() {
      super();
      this.state = {
        isLogin: true,
      };
    }
    componentDidMount() {
      timeout_hoc = setTimeout(() => {
        this.updateTokenHandler();
      }, 10000);
    }
    componentWillUnmount() {
      timeout_hoc = null;
      clearTimeout(timeout_hoc);
    }
    updateTokenHandler = () => {
  
      var TOKEN_HOC = getAuthToken();
      axios
        .request({
          method: "post",
          data: checkUserToken({ refreshToken: getRefreshToken() }),
          url: Config.api.API_URL,
          headers: {
            Authorization: TOKEN_HOC ? `JWT ${TOKEN_HOC}` : null,
          },
        })
        .then((res) => {
          if (res.data.data.refreshToken !== null) {
            setAuthToken(
              JSON.stringify({
                refreshToken: res.data.data.refreshToken.refreshToken,
                token: res.data.data.refreshToken.token,
              })
            );
            // if (timeout_hoc !== null) {
              timeout_hoc = setTimeout(() => {
                this.updateTokenHandler();
              }, 21600000); /////bernhard change the time
            // }
          } else {
            this.setState({ isLogin: false }, () => {
              timeout_hoc = null;
              removeAuthToken();
              window.location.href = LOGIN;
            });
          }
        })
        .catch((err) => {
          timeout_hoc = null;
          this.setState({ isLogin: false }, () => {
            removeAuthToken();
            window.location.href = LOGIN;
          });
        });
    };
    render() {
      return (
        <InnerComponent
          {...this.props}
          {...this.state}
          update={this.updateTokenHandler.bind(this)}
        />
      );
    }
  };
export default HOC;
