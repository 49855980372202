import * as actionType from "../actions/types";
import { updateObject } from "./utilReducer";

const initialState = {
  isAuth: null,
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.SET_AUTH:
      return updateObject(state, { isAuth: action.payload });

    default:
      return state;
  }
};

export default reducer;
