import { GOOGLE_MAP_API_KEY } from "../../../Util/constants";
export const loadGoogleMaps = callback => {

  if (typeof google === "object" && typeof window.google.maps === "object" ) {
    
    return callback();
  } else {
    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAP_API_KEY}&libraries=places&language=en`;
   
    script.id = "search";

    document.body.appendChild(script);

    script.onload = () => {
      if (callback) callback();
    };
  }
};
