import React, { Component,Fragment } from 'react';
import styles from "./Button.module.scss"
class Button extends Component {
    render() {
        const { text,disabled,id, overrideStyles } = this.props;
        return (
          <Fragment>
            <button disabled={disabled} className={`${styles.btn} ${this.props.overrideClass!=undefined && this.props.overrideClass}`} id={id !==undefined ?id:text}
             onClick={this.props.click}
             style={overrideStyles}
             >
              {text}
            </button>
          </Fragment>
        );
      }
    }


export default Button;