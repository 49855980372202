import * as route from "./Util/routes";
class Controller {
  instance = Controller.instance || new Controller();

  static setAccountInfo(dataSet) {
    this.instance = {
      accountDetails: dataSet,
    };
  }
  static getBasicInfo = () => {
    let data = { ...this.instance };
    if (data == null || data == undefined || !Object.keys(data).length) {
      return null;
    } else {
      return {
        firstName: data.accountDetails.firstName,
        hasFencing: data.accountDetails.hasFencing,
        unreadNotificationsCount: data.accountDetails.unreadNotificationsCount,
        generateWsToken: data.accountDetails.generateWsToken,
        generateWsTokenBroadcast: data.accountDetails.generateWsTokenBroadcast,
        lastName: data.accountDetails.lastName,
        name: data.accountDetails.name,
        lookupId: data.accountDetails.lookupId,
        avatar: data.accountDetails.avatar,
        email: data.accountDetails.email,
        account_id: data.accountDetails.account_id,
        avatar: data.accountDetails.avatar,
        phone: data.accountDetails.phone,
        location: data.accountDetails.location,
        companyName: data.accountDetails.companyName,
        apiKey: data.accountDetails.apiKey,
        isUser: data.accountDetails.isUser,
        userRole: data.accountDetails.userRole,
        groupColor: data.accountDetails.groupColor,
        speedUnit: data.accountDetails.speedUnit,
        syncAccountDetailsWithPartner:
          data.accountDetails.syncAccountDetailsWithPartner,
        selectedIntegrationPartner:
          data.accountDetails.selectedIntegrationPartner,
        entityTier: data.accountDetails.entityTier,
        recommendedTextMsgBytes: data.accountDetails.recommendedTextMsgBytes,
        
      };
    }
  };
  static isHiskyEntity = () => {
    let obj = { ...this.instance };
    return obj.accountDetails.isHiskyEntity;
  };
  static getWsTokenNotification = () => {
    let obj = { ...this.instance };
    return obj.accountDetails.generateWsToken;
  };
  static getWsTokenBroardcast = () => {
    let obj = { ...this.instance };
    return obj.accountDetails.generateWsTokenBroadcast;
  };
  static checkHasFencing = () => {
    let obj = { ...this.instance };
    return obj.accountDetails.hasFencing;
  };
  static setLengthMsgchat = (len) => {
    let t = this;
    let temp = t.getBasicInfo();
    temp.recommendedTextMsgBytes = len;
    t.setAccountInfo(temp);
  };
  static getEntityTeir() {
    let obj = { ...this.instance };
    return obj.accountDetails.entityTier;
  }
  static getAccountId() {
    let obj = { ...this.instance };
    return obj.accountDetails.account_id;
  }
  static getUserId() {
    let obj = { ...this.instance };
    return obj.accountDetails.lookupId;
  }
  static getIsUser = () => {
    let obj = { ...this.instance };

    if (Object.keys(obj).length) {
      return obj.accountDetails.isUser;
    }
  };
  static getMsgChatLenght = () => {
    let obj = { ...this.instance };
    return obj.accountDetails.recommendedTextMsgBytes;
  };
  static isAuthenticated() {
    let obj = { ...this.instance };
    return obj.accountDetails.userRole == 1;
  }
  static getUnreadCountNotification = () => {
    let obj = { ...this.instance };
    return obj.accountDetails.unreadNotificationsCount;
  };
  static getUnreadSosCountNotification = () => {
    let obj = { ...this.instance };
    return obj.accountDetails.sosUnreadNotificationsCount;
  };
  static getUnreadChatCountNotification = () => {
    let obj = { ...this.instance };
    return obj.accountDetails.chatUnreadNotificationsCount;
  };
  static getUnreadFencingCountNotification = () => {
    let obj = { ...this.instance };
    return obj.accountDetails.fenceUnreadNotificationsCount;
  };
  static isDefaultColorSet() {
    let obj = { ...this.instance };
    return obj.accountDetails.groupColor;
  }
  static getRelaeseNotes=()=>{
    let obj = { ...this.instance };
    return obj.accountDetails.systemVersions; 
  }
}

export default Controller;
