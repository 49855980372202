import React, { Component, Fragment } from "react";
import styles from "./Tabs.module.scss";
class Tabs extends Component {
  handleChangeHandler = (index) => {
    this.props.click(index);
  };

  render() {
    return (
      <div className={styles.tabWrapper}>
        <ul className={styles.inline}>
          {this.props.tabArray.map((item, index) => {
            let tabstyle = index == this.props.selected ? styles.selected : "";
            return (
              <li
                className={tabstyle}
                key={index}
                onClick={() => this.handleChangeHandler(index)}
                style={{
                  opacity: this.props.isPreview ? 0.6 : "",
                  pointerEvents: this.props.isPreview ? "none" : "",
                }}
              >
                <span className={styles.textTab}>
                  {item.split(" ").map((itemT, index) => {
                    return <span key={index}>{itemT}&nbsp;</span>;
                  })}
                </span>
              </li>
            );
          })}
        </ul>
        {/* <div className={styles.tab}></div> */}
      </div>
    );
  }
}
export default Tabs;
