import React, { Component } from "react";
import styles from "./ResetPassword.module.scss";
import Logo from "../../assets/main-hisky-login.svg";
import Icon from "../../Component/Icon/Icon";
import SmallIotsat from "../../assets/hisky-logo.svg";
import OneTracklogo from "../../assets/mobile/hiWayLogoGrey.svg";
import LogoBottom from "../../assets/mobile/whiteHiskyLogoMobile.svg";
import Button from "../../Component/Button/Button";
import Input from "../../Component/Input/Input";
import { httpRequest } from "../../RequestManager/HttpRequest";
import { updatePassword } from "../../RequestManager/GqlBuilderAccount";
import * as route from "../../Util/routes";
import { withRouter } from "react-router-dom";
class ResetPassword extends Component {
  state = {
    loading: false,
    errorMsg: "",
    reset: {
      password: {
        value: "",
        error: false,
        name: "password",
        type: "password",
        errMsg: "Password does not match",
      },
      newPassword: {
        value: "",
        error: false,
        name: "newPassword",
        type: "password",
        errMsg: "Password does not match",
      },
    },
  };
  componentWillUnmount() {}
  checkValidFormHandler = () => {
    let isValid = true;
    let copyreset = { ...this.state.reset };
    if (
      copyreset.password.value === "" ||
      copyreset.newPassword.value === "" ||
      copyreset.password.value.length < 6 ||
      copyreset.newPassword.value.length < 6 ||
      copyreset.password.value !== copyreset.newPassword.value
    ) {
      copyreset.password.error = true;
      isValid = false;
    }
    this.setState({ reset: copyreset });
    return isValid;
  };
  onClickIconPasswordHandler = (event) => {
    let reset = { ...this.state.reset };
    reset.password.type =
      reset.password.type == "password" ? "text" : "password";
    this.setState({ reset: reset });
  };
  onClickIconNewPasswordHandler = (event) => {
    let reset = { ...this.state.reset };
    reset.newPassword.type =
      reset.newPassword.type == "password" ? "text" : "password";
    this.setState({ reset: reset });
  };
  onClickResetPasswordHandler = () => {
    this.setState({ loading: true }, () => {
      if (this.checkValidFormHandler()) {
        httpRequest(updatePassword, {
          pwUpdateToken: this.props.match.params.id,
          password: this.state.reset.password.value,
        })
          .then((res) => {
            this.checkresponServerHandler(res);
          })
          .catch((err) => {});
      } else {
        this.setState({ loading: false });
      }
    });
  };
  checkresponServerHandler = (res) => {
    if (res.errors !== undefined) {
      this.setState({
        validUser: true,
        loading: false,
        errorMsg: res.errors[0].message.replace(/_/g, " "),
      });
    } else {
      const urlParams = new URLSearchParams(window.location.search);
      const continue_to = urlParams.get("continue_to");
      if (continue_to) {
        window.location.href = "//" + decodeURIComponent(continue_to);
      } else {
        this.props.history.push(route.LOGIN);
      }
    }
  };
  onChangeHandler = (event) => {
    if (this.state.loading) return;
    let resetCopy = { ...this.state.reset };
    resetCopy[event.target.name].value = event.target.value;
    resetCopy[event.target.name].error = false;
    this.setState({ reset: resetCopy });
  };
  onKeyUpHandler = (e) => {
    if (e.key === "Enter") {
      this.onClickResetPasswordHandler();
    }
  };
  render() {
    const { reset } = this.state;
    return (
      <div className={styles.ResetPassword}>
        <div className={styles.leftDiv}>
          <img src={Logo} className={styles.img} alt="logo-hiway-logo" />
          <div className={styles.bottomIcon}>
            <span className={styles.imgPreview}>Powered by</span>
            <img
              className={styles.logoSatSmall}
              src={SmallIotsat}
              alt="hisky-logo-small"
            />
          </div>
        </div>
        <div className={styles.imgPhoneHeader}>
          <img
            src={OneTracklogo}
            alt="hisky-mobile-logo"
            className={styles.hiwayMobileLogo}
          />
        </div>
        <div className={styles.rightDiv}>
          <div
            className={styles.formWrapper}
            style={{ opacity: this.state.loading ? ".6" : "1" }}
          >
            <div className={styles.headerwrapper}>
              <h3 className={styles.signInTitle}>
                Reset password
                <span
                  className={styles.loadingIcon}
                  style={{
                    visibility: this.state.loading ? "visible" : "hidden",
                  }}
                >
                  <Icon
                    defination={{ prefix: "fal", iconName: "spinner" }}
                    isSpinner={true}
                  />
                </span>
              </h3>

              <p
                className={styles.mainError}
                style={{
                  visibility: this.state.errorMsg != "" ? "visible" : "hidden",
                }}
              >
                {this.state.errorMsg}
              </p>
            </div>
            <div
              className={styles.inputWrapperSignIn}
              onKeyUp={this.onKeyUpHandler}
            >
              <Input
                label="New password"
                value={reset.password.value}
                error={reset.password.error}
                errMsg="Invalid password"
                type={reset.password.type}
                isRegularText={true}
                name={reset.password.name}
                placeholder="Enter password"
                phoneMode={this.props.phoneMode}
                inputWrapper={styles.inputWrapper}
                change={(e) => this.onChangeHandler(e)}
                iconAction={(e) => this.onClickIconPasswordHandler(e)}
                iconDefination={{ prefix: "far", iconName: "eye-slash" }}
              />
              <Input
                label="retype Password"
                value={reset.newPassword.value}
                error={reset.newPassword.error}
                errMsg="Invalid password"
                type={reset.newPassword.type}
                name={reset.newPassword.name}
                isRegularText={true}
                phoneMode={this.props.phoneMode}
                placeholder="Enter password"
                iconDefination={{ prefix: "far", iconName: "eye-slash" }}
                change={(e) => this.onChangeHandler(e)}
                inputWrapper={styles.inputWrapper}
                iconAction={(e) => this.onClickIconNewPasswordHandler(e)}
              />
              <div className={styles.btnWrapper}>
                <Button
                  text="Reset password"
                  click={this.onClickResetPasswordHandler}
                  disabled={this.state.loading}
                  overrideClass={styles.btn}
                />
              </div>
            </div>
          </div>

          <div className={styles.logoMobileBottom}>
            <span className={styles.imgPreviewMobileText}>
              Powered by{" "}  </span>
              <img
                className={styles.bottomLogoMobile}
                src={LogoBottom}
                alt="hisky-moblie-bottom-logo"
              />
          
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ResetPassword);
