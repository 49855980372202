import React, { Component } from "react";
import CheckBox from "../../CheckBox/CheckBox";
import Icon from "../../Icon/Icon";
import styles from "./VehicleFilters.module.scss";
export default class VehicleFilters extends Component {
  getFiltersListHandler = () => {
    let list = (
      <div className={styles.listWrapper}>
        {/* <span className={styles.filterTitle}>By Group</span> */}
        <div className={styles.itemsWrapper}>
          {this.getList(this.props.groupFilters)}
        </div>
      </div>
    );

    return list;
  };
  getList = (data) => {
    return data.map((item, index) => {
      return (
        <div id={index} key={index} className={styles.types}>
          <CheckBox
            labelMsg={item.name}
            checkBoxWrapper={styles.checkboxWrapper}
            value={this.props.ischecked(item)}
            click={() => this.props.onCheck(item)}
            name={item.name}
          />
        </div>
      );
    });
  };
  render() {
    return (
      <div
        className={`${styles.mainWrapper}`}
        style={{ display: this.props.show ? "flex" : "none" }}
      >
        <div className={styles.header}>
          <span className={styles.closeIcon} style={{ marginRight: ".2rem",marginLeft:"unset" }}>
            <Icon
              defination={{
                prefix: "far",
                iconName: "filter",
              }}
            />
          </span>
          <span className={styles.title}>By Group</span>
          <span className={styles.closeIcon} onClick={this.props.closeFilters}>
            <Icon defination={{ prefix: "far", iconName: "times" }} />
          </span>
        </div>

        <div className={styles.mainListWrapper}>
          {this.getFiltersListHandler()}
        </div>
      </div>
    );
  }
}
