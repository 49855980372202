import React, { Component, Fragment } from "react";
import styles from "./SelectWithIcon.module.scss";
import Icon from "../../Component/Icon/Icon";
export default class SelectWithIcon extends Component {
  state = {
    iconhover: "",
  };
  changeIconColorHandler = (id) => {
    this.setState({ iconhover: id });
  };
  revertIconColorHandler = () => {
    this.setState({ iconhover: "" });
  };
  buildSelectMenu = () => {
    let arrayOption = this.props.selectOptions.map((item, index) => {
      return (
        <li
          key={index}
          onClick={() => this.props.click(item)}
          onMouseEnter={() => this.changeIconColorHandler(item.lookupId)}
          onMouseLeave={this.revertIconColorHandler}
        >
          <span
            className={styles.icon}
            style={{
              color: this.state.iconhover == item.lookupId ? "#fff" : "",
            }}
          >
            {item.icon}
          </span>
          <span className={styles.iconLabel}>{item.displayName}</span>
        </li>
      );
    });
    return arrayOption;
  };
  render() {
    return (
      <div className={styles.SelectWithIcons}>
        <p className={styles.label}>
          <span className={styles.text}>{this.props.titleText}</span>
          {this.props.phoneMode && (
            <span
              className={styles.error}
              style={{ visibility: this.props.error ? "visible" : "hidden" }}
            >
              {" "}
              {this.props.errMsg}
            </span>
          )}
        </p>
        <div
          className={styles.mainWrapper}
          onMouseLeave={() => this.props.showOptions(false)}
          style={{ backgroundColor: this.props.disabled ? "#e2e2e2" : "" }}
          onClick={
            this.props.disabled ? () => {} : () => this.props.showOptions(true)
          }
        >
          {this.props.selectedOption != "" ? (
            <>
              <span className={styles.icon}>
                {this.props.selectedOption.icon}
              </span>
              <span className={styles.iconLabel}>
                {this.props.selectedOption.displayName}
              </span>
            </>
          ) : (
            this.props.selectOptions[0].displayName
          )}
          <span className={styles.iconArrow}>
            {this.props.showOptionsList ? (
              <Icon defination={{ prefix: "fal", iconName: "chevron-up" }} />
            ) : (
              <Icon defination={{ prefix: "fal", iconName: "chevron-down" }} />
            )}
          </span>
          <ul
            className={
              this.props.showOptionsList
                ? `${styles.itemsList} ${styles.showList}`
                : `${styles.itemsList}`
            }
          >
            {this.buildSelectMenu()}
          </ul>
        </div>
        {!this.props.phoneMode && (
          <p
            className={styles.error}
            style={{ visibility: this.props.error ? "visible" : "hidden" }}
          >
            {this.props.errMsg}
          </p>
        )}
      </div>
    );
  }
}
