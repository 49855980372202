import React, { Component } from "react";
import styles from "./Speedmeter.module.scss";
import LINE_ICON from "../../../../assets/lineIcon.svg";
import {
  CONVERT_NUMBER_MILE,
  CONVERT_NUMBER_KPH,
} from "../../../../Util/constants";
class Speedmeter extends Component {
  state = { speed: 0, vehicleData: {}, lastTelemetry: {}, icon: "" };
  componentDidMount() {
    if (this.props.refSpeedUdpate !== undefined) {
      this.props.refSpeedUdpate(this.setSpeedVehicleHandler);
    }
  }
  setPitchCarHandler = () => {
   
    let isOtherICon = this.state.icon.indexOf('lineIcon')>-1;
    if (
      !Object.keys(this.state.lastTelemetry).length ||
      this.state.lastTelemetry.inclination === 0
    ) {
      return {
        transform: `rotateZ(${0}deg)`,
        transformOrigin: isOtherICon ? 'center' : "bottom center",
      };
    } else if (this.state.lastTelemetry.inclination > 0) {
      return {
        transform: `rotateZ(${this.state.lastTelemetry.inclination * -1}deg)`,
        transformOrigin:isOtherICon ? 'center' : "center bottom",
        // left: 0,
      };
    } else {
      return {
        transform: `rotateZ(${this.state.lastTelemetry.inclination * -1}deg)`,
        transformOrigin: isOtherICon ? 'center' : "center bottom",
        // right: 0,
      };
    }
  };
  setSpeedVehicleHandler = (data) => {
    if (!Object.keys(data).length) {
      this.setState({ speed: 0, lastTelemetry: {}, vehicleData: {}, icon: "" });
    } else {
      let speed =
        data.speed > 180
          ? 180
          : data.vehicleData.speedUnit == 1
          ? parseFloat(data.speed).toFixed(1)
          : data.vehicleData.speedUnit == 2
          ? parseFloat(data.speed * CONVERT_NUMBER_MILE).toFixed(1)
          : parseFloat(data.speed * CONVERT_NUMBER_KPH).toFixed(1);
      this.setState(
        {
          speed: speed,
          vehicleData: data.vehicleData,
          lastTelemetry: data.lastTelemetry,
          icon: data.icon.indexOf('OtherIcon')>-1? LINE_ICON : data.icon,
        },
        () => {}
      );
    }
  };
  render() {
    return (
      <div className={styles.Speedmeter}>
        <div className={styles.itemWrapper}>
          <div className={`${styles.imgWrapper}`}>
            <span className={styles.text}>{this.state.speed}</span>
            <span className={`${styles.text} ${styles.textUnit}`}>
              {Object.keys(this.state.vehicleData).length
                ? this.state.vehicleData.speedUnitStr
                : ""}
            </span>
          </div>
        </div>
        <div className={styles.itemWrapper}>
          <div className={styles.imgWrapper}>
            {this.state.icon != "" && (
              <img
                style={this.setPitchCarHandler()}
                className={styles.imgVehicle}
                src={this.state.icon}
                alt="img-Vehicle"
              />
            )}
            <span className={`${styles.text} ${styles.textUnit}`}>
              {`${
                Object.keys(this.state.lastTelemetry).length
                  ? parseFloat(this.state.lastTelemetry.inclination).toFixed(1)
                  : 0
              }`}{" "}
              &#xb0;
            </span>
          </div>
        </div>
      </div>
    );
  }
}

export default Speedmeter;
