var dt_template = {
    query: "",
    variables: {},
  };
  export const getUsers = (data) => {
    let users = {
      query:
      "query($accountLookupId:String, $lookupIds:[String], $search:String, $offset:Int, $limit:Int, $ascending:Boolean, $startDate:String, $endDate:String){\n systemVersion{ lookupId\n name\n description\n createdAt releasedAt}\n   users(accountLookupId:$accountLookupId, lookupIds:$lookupIds, search:$search, offset:$offset, limit:$limit, ascending:$ascending, startDate:$startDate, endDate:$endDate){\n    count\n    users{\n  role{tier}    lookupId\n   avatar\n   email\n firstName\n lastName\n  phone\n defaultColor\n location{\n address\n country\n postCode\n latitude\n longitude\n}\n  accounts{\n     owner{firstName lastName} name   lookupId\n      }\n    }\n  }\n}",
      variables: data,
    };
  
    dt_template.query = users.query;
    dt_template.variables = users.variables;
    return dt_template;
  };