import React, { Component } from "react";
import Controller from "../../Controller";
import { isEmail } from "validator";
import {
  CANCEL,
  CLOSE,
  CREATE_MODE,
  SUBMIT,
  VIEW_MODE,
  PICTURE,
  CLOUDINARY_UPLOAD_PRESET,
  FILE,
  UPLOAD_PRESET,
  EDIT_MODE,
} from "../../Util/constants";
import { httpRequest } from "../../RequestManager/HttpRequest";
import {
  createNewuser,
  updateUser,
} from "../../RequestManager/GqlBuilderAccount";
import Button from "../Button/Button";
import FormButtons from "../FormButtons/FormButtons";
import SelectWithOptions from "../SelectWithOptions/SelectWithOptions";
import SearchBar from "../GoogleMap/SearchBar/SearchBar";
import Icon from "../Icon/Icon";
import Input from "../Input/Input";
import SpinnerLoader from "../SpinnerLoader/SpinnerLoader";
import Telephone from "../Telephone/Telephone";
import UploadFile from "../UploadFile/UploadFile";
import styles from "./CreateUser.module.scss";
import Swal from "sweetalert2/dist/sweetalert2.js";
import CheckBox from "../CheckBox/CheckBox";
import PreviewImage from "../PreviewImage/PreviewImage";
import ImageCrop from "../ImageCrop/ImageCrop";
import { getUserRole } from "../../Util/globalFunc";
export default class CreateUser extends Component {
  isMouted = true;
  avatar_url = "";
  state = {
    loading: false,
    isValidForm: true,
    croppedImageUrl: null,
    showPreviewImage: false,
    openImageCropModel: false,
    address: {
      type: "address",
      address: "",
      country: "",
      error: false,
      size: 250,
      label: "Legal Address",
      errMsg: "Invalid address",
      lat: "",
      lng: "",
    },
    uploadFile: {
      value: "",
      error: false,
      errMsg: "select file to upload",
      label: "",
      placeholder: "Select picture",
      fileName: "",
    },
    createUser: {
      firstName: {
        label: "First name",
        value: "",
        error: false,
        name: "firstName",
        type: "text",
        errMsg: "Invalid First name",
      },
      lastName: {
        label: "Last name",
        value: "",
        error: false,
        name: "lastName",
        type: "text",
        errMsg: "Invalid last Name",
      },
      email: {
        label: "Email",
        value: "",
        error: false,
        name: "email",
        type: "text",
        errMsg: "Invalid email",
      },

      phone: {
        label: "Telephone",
        value: "",
        error: false,
        name: "phone",
        type: "text",
        errMsg: "Invalid telephone",
      },
    },
    updatePassword: {
      password: {
        label:
          this.props.mode === CREATE_MODE ? "New password" : "Current password",
        value: "",
        error: false,
        name: "password",
        type: "password",
        errMsg: "Passwords does not match",
        placeholder: "Enter password",
      },
      newPassword: {
        label:
          this.props.mode === CREATE_MODE ? "Repeat password" : "New password",
        value: "",
        error: false,
        name: "newPassword",
        type: "password",
        errMsg: "Passwords does not match",
        placeholder:
          this.props.mode === CREATE_MODE ? "repeat password " : "New password",
      },
    },
    userRole: {
      value: "",
      error: false,
      errMsg: "Invalid role type",
      label: "Role type",
      type: "select",
    },
    defaultColor: false,
  };
  componentDidMount() {
    if (this.props.mode != CREATE_MODE && Object.keys(this.props.data).length) {
      this.intiallizeDataHandler(this.props.data);
    }
  }

  intiallizeDataHandler = (data) => {
    let copyInputs = { ...this.state.createUser };
    let copyLocation = { ...this.state.address };
    let copyRole = { ...this.state.userRole };
    for (let key in copyInputs) {
      if (data[key] !== undefined && data[key] !== null) {
        copyInputs[key].value = data[key];
      }
    }
    let userRole = Object.keys(data).length ? (this.props.controller? data.userRole: data.role.tier): '';
    copyRole.value = userRole;
    if (data.location !== null) {
      copyLocation.address = data.location.address;
      copyLocation.country = data.location.country;
      copyLocation.lat = parseFloat(data.location.latitude);
      copyLocation.lng = parseFloat(data.location.longitude);
    }
    this.setState({
      userRole: copyRole,
      createUser: copyInputs,
      address: copyLocation,
      defaultColor: Controller.isDefaultColorSet(),
      croppedImageUrl:
        this.props.mode == CREATE_MODE ? null : this.props.data.avatar,
    });
  };
  checkForDisabledModeHandler = (item) => {
    if (
      (item.name == "email" && this.props.mode === EDIT_MODE) ||
      this.props.mode == VIEW_MODE
    ) {
      return true;
    }
    return false;
  };
  onClickIconPasswordHandler = (fieldName) => {    
    let updatePassword = { ...this.state.updatePassword };
    updatePassword[fieldName].type =
    updatePassword[fieldName].type == "password" ? "text" : "password";
    this.setState({ updatePassword});
  };
  buildInputsElementsHandler = () => {
    let arr = Object.values(this.state.createUser);
    return arr.map((item, index) => {
      if (item.name !== "phone") {
        return (
          <Input
            type={item.type}
            name={item.name}
            key={index}
            value={item.value}
            placeholder={item.label}
            label={item.label}
            error={item.error}
            errMsg={item.errMsg}
            inputWrapper={styles.inputWrapper}
            change={(e) => this.onChangeHandler(e)}
            phoneMode={this.props.phoneMode}
            disabled={this.checkForDisabledModeHandler(item)}
          />
        );
      } else if (item.name == "phone") {
        return (
          <Telephone
            type={item.type}
            name={item.name}
            key={index}
            value={item.value}
            placeholder={item.label}
            label={item.label}
            error={item.error}
            errMsg={item.errMsg}
            inputWrapper={styles.inputWrapper}
            change={(e) => this.onChangeHandler(e)}
            phoneMode={this.props.phoneMode}
            change={(e) => this.onChangePhoneHandler(e)}
            defaultCountry={"us"} //for no value
            isRegularText={false}
            disabled={this.state.loading || this.props.mode == VIEW_MODE}
          />
        );
      } else if (item.name == "password" && this.props.mode == CREATE_MODE) {
        return (
          <Input
            type={item.type}
            name={item.name}
            key={index}
            value={item.value}
            placeholder={item.label}
            label={item.label}
            error={item.error}
            errMsg={item.errMsg}
            inputWrapper={styles.inputWrapper}
            change={(e) => this.onChangeHandler(e)}
            phoneMode={this.props.phoneMode}
            disabled={this.props.mode == VIEW_MODE}           
          />
        );
      }
    });
  };
  getMarkerHandler = () => {
    return [{ lat: 51.5074, lng: 0.1278 }];
  };

  onChangeHandler = (event) => {
    if (this.state.loading) {
      return;
    }
    let copyInputs = { ...this.state.createUser };
    copyInputs[event.target.name].value = event.target.value;
    copyInputs[event.target.name].error = false;
    this.setState(
      { createUser: copyInputs, loading: false, isValidForm: true },
      () => {}
    );
  };
  onChangePhoneHandler = (value) => {
    if (this.state.loading) return;
    let copycreate = { ...this.state.createUser };
    copycreate.phone.value = value;
    copycreate.phone.error = false;
    this.setState({ createAccount: copycreate, errorMsg: "" });
  };

  formatAddressHandler = (address) => {
    let copyAddress = { ...this.state.address };
    if (address === -1 || address.status !== "OK") {
      copyAddress.address = "";
      copyAddress.country = "";
      copyAddress.error = false;
      this.setState({ address: copyAddress }, () => {});
      return;
    } else {
      let arr = address.results[0].address_components;
      copyAddress.address = address.results[0].formatted_address;
      copyAddress.lat = address.results[0].geometry.location.lat;
      copyAddress.lng = address.results[0].geometry.location.lng;
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].types[0] === "country") {
          copyAddress.country = arr[i].short_name;
          copyAddress.error = false;
          break;
        }
      }
      copyAddress.error = false;
      this.setState({ address: copyAddress }, () => {});
    }
  };
  onChangePasswordHandler = (event) => {
    let copyPassword = { ...this.state.updatePassword };
    copyPassword[event.target.name].value = event.target.value;
    copyPassword[event.target.name].error = false;
    this.setState({ updatePassword: copyPassword });
  };
  uploadFileHandler = (event) => {
    let uploadFile = { ...this.state.uploadFile };

    if (this.checkFileValidHandler(event.target.files)) {
      uploadFile.value = event.target.files[0];
      uploadFile.error = false;
      uploadFile.fileName = event.target.files[0].name;
    } else {
      uploadFile.error = true;
    }
    this.setState({
      uploadFile: uploadFile,
      openImageCropModel: true,
      croppedImageUrl: null,
    });
  };
  checkFileValidHandler = (file) => {
    if (file.length) {
      let typeOfFile = ["png", "jpg", "jpeg"];
      let typeImage = file[0].type.split("/")[1].toLowerCase();
      return typeOfFile.includes(typeImage);
    } else {
      return false;
    }
  };
  createUserObjHandler = () => {
    let copyUser = { ...this.state.createUser };
    if (this.props.mode === CREATE_MODE) {
      return {
        firstName: copyUser.firstName.value,
        lastName: copyUser.lastName.value,
        password: this.state.updatePassword.password.value,
        phone: copyUser.phone.value,
        email: copyUser.email.value,
        role: parseInt(this.state.userRole.value),
        location: {
          address: this.state.address.address,
          country: this.state.address.country,
          latitude: this.state.address.lat.toFixed(4).toString(),
          longitude: this.state.address.lng.toFixed(4).toString(),
          postCode: "",
        },
        defaultColor: this.state.defaultColor,
      };
    } else {
      return {
        firstName: copyUser.firstName.value,
        lastName: copyUser.lastName.value,
        oldPassword: this.state.updatePassword.password.value,
        newPassword: this.state.updatePassword.newPassword.value,
        phone: copyUser.phone.value,
        location: {
          address: this.state.address.address,
          country: this.state.address.country,
          latitude: this.state.address.lat.toFixed(4).toString(),
          longitude: this.state.address.lng.toFixed(4).toString(),
          postCode: "",
        },
        defaultColor: this.state.defaultColor,
      };
    }
  };
  fireSwalHandler = (title, text, type, objUser) => {
    Swal.fire({
      title: title,
      text: text,
      type: type,
    }).then((res) => {
      if (type === "success") {
        if (this.props.mode === CREATE_MODE) {
          this.props.reloadPage();
        } else {
          //update controller incase of logged in person is user
          let data = Controller.getBasicInfo();
          if (data.lookupId == objUser.lookupId && data.isUser) {
            data.lastName = objUser.lastName;
            data.firstName = objUser.firstName;
            data.name = objUser.firstName + " " + objUser.lastName;
            data.phone = objUser.phone;
            data.avatar = this.avatar_url == "" ? data.avatar : this.avatar_url;
            data.location = objUser.location;
            Controller.setAccountInfo(data);
            this.props.infoUpdated();
          } else if (data.lookupId == objUser.lookupId) {
            data.groupColor = objUser.defaultColor;
            Controller.setAccountInfo(data);
          }
          if (this.props.controller == undefined) this.props.reloadPage();
          else {
            this.props.close();
          }
        }
      }
    });
  };

  checkRespondServerHandler = (res) => {
    if (res.errors !== undefined) {
      this.fireSwalHandler(
        "Warning",
        res.errors[0].message.replace(/_/g, " "),
        "warning"
      );
      this.setState({ loading: false });
    } else {
      this.fireSwalHandler(
        "Success",
        this.props.mode === EDIT_MODE ? "User was updated" : "User was created",
        "success",
        this.props.mode === CREATE_MODE
          ? res.data.createUser.user
          : res.data.updateUser.user
      );
    }
  };

  onClickCreateEditUserHandler = () => {
    if (this.checkValidGeneralInfoHandler()) {
      this.setState({ loading: true }, () => {
        if (this.props.mode === CREATE_MODE) {
          var user = this.createUserObjHandler();
          if (this.state.uploadFile.value !== "") {
            let formData = new FormData();
            formData.append(FILE, this.state.croppedImageUrl);
            formData.append(UPLOAD_PRESET, CLOUDINARY_UPLOAD_PRESET);
            httpRequest({}, formData, PICTURE)
              .then((res) => {
                return Promise.resolve(res.data.secure_url);
              })
              .then((fileUrl) => {
                this.avatar_url = fileUrl;
                user.avatar = fileUrl;
                let data = {
                  accountLookUpId: Controller.getAccountId(),
                  user: user,
                };

                httpRequest(createNewuser, data)
                  .then((res) => {
                    this.checkRespondServerHandler(res);
                  })
                  .catch((err) => {});
              })
              .catch((err) => {
                console.log(err);
              });
          } else {
            let data = {
              accountLookUpId: Controller.getAccountId(),
              user: user,
            };
            httpRequest(createNewuser, data)
              .then((res) => {
                this.checkRespondServerHandler(res);
              })
              .catch((err) => {});
          }
        } else {
          if (this.state.uploadFile.value !== "") {
            let formData = new FormData();
            let user = this.createUserObjHandler();
            formData.append(FILE, this.state.croppedImageUrl);
            formData.append(UPLOAD_PRESET, CLOUDINARY_UPLOAD_PRESET);
            httpRequest({}, formData, PICTURE)
              .then((res) => {
                return Promise.resolve(res.data.secure_url);
              })
              .then((fileUrl) => {
                user.avatar = fileUrl;
                this.avatar_url = fileUrl;
                let data = {
                  lookupId: this.props.data.lookupId,
                  user: user,
                };

                httpRequest(updateUser, data)
                  .then((res) => {
                    this.checkRespondServerHandler(res);
                  })
                  .catch((err) => {});
              })
              .catch((err) => {
                console.log(err);
              });
          } else {
            let user = this.createUserObjHandler();
            let data = {
              lookupId: this.props.data.lookupId,
              user: user,
            };
            httpRequest(updateUser, data)
              .then((res) => {
                this.checkRespondServerHandler(res);
              })
              .catch((err) => {});
          }
        }
      });
    } else {
      this.setState({ loading: false });
    }
  };
  onSelectRoleHandler = (event) => {
    let role = { ...this.state.userRole };
    role.value = event.target.value;
    role.error = false;
    this.setState({ userRole: role }, () => {});
  };
  checkValidGeneralInfoHandler = () => {
    let isValid = true;
    let copyUser = { ...this.state.createUser };
    let addressCopy = { ...this.state.address };
    let copyUpdatePassword = { ...this.state.updatePassword };
    let userRoleCopy = { ...this.state.userRole };

    for (let key in copyUser) {
      if (
        copyUser[key].value.trim() === "" &&
        key !== "email" &&
        key !== "phone"
      ) {
        copyUser[key].error = true;
        isValid = false;
      } else if (
        key == "phone" &&
        (copyUser[key].value.trim() == "" ||
          (copyUser[key].value.trim() !== "" && copyUser[key].value.length < 7))
      ) {
        copyUser[key].error = true;
        isValid = false;
      } else if (key == "email") {
        if (!isEmail(copyUser.email.value)) {
          isValid = false;
          copyUser.email.error = true;
        }
      }
    }
    if (
      this.props.mode === CREATE_MODE &&
      (copyUpdatePassword.password.value !==
        copyUpdatePassword.newPassword.value ||
        copyUpdatePassword.password.value.length < 6 ||
        copyUpdatePassword.password.value == "")
    ) {
      copyUpdatePassword.password.error = true;

      isValid = false;
    }

    if (
      this.props.mode === EDIT_MODE &&
      (copyUpdatePassword.newPassword.value !== "" ||
        copyUpdatePassword.password.value !== "")
    ) {
      if (
        copyUpdatePassword.newPassword.value.length < 6 ||
        copyUpdatePassword.password.value.length < 6
      ) {
        copyUpdatePassword.newPassword.error = true;
        isValid = false;
      }
    }

    if (addressCopy.address === "" || addressCopy.address.length > 250) {
      addressCopy.error = true;
      isValid = false;
    }
    if (
      this.props.mode === CREATE_MODE &&
      (parseInt(userRoleCopy.value) == -1 || userRoleCopy.value == "")
    ) {
      userRoleCopy.error = true;
      isValid = false;
    }

    this.setState(
      {
        createUser: copyUser,
        address: addressCopy,
        isValidForm: isValid,
        userRole: userRoleCopy,
        updatePassword: copyUpdatePassword,
      },
      () => {}
    );
    return isValid;
  };
  onCheckColorHandler = () => {
    this.setState({ defaultColor: !this.state.defaultColor });
  };
  sendImageUrlHandler = (url) => {
    this.setState({ croppedImageUrl: url });
  };
  closePreviewHandler = () => {
    this.setState({ showPreviewImage: false, openImageCropModel: false });
  };
  showPreviewHandler = () => {
    this.setState({ showPreviewImage: true }, () => {});
  };
  cancelImageHandler = () => {
    let uploadFile = { ...this.state.uploadFile };
    uploadFile.value = "";
    uploadFile.error = false;
    uploadFile.fileName = "";
    this.setState({
      uploadFile: uploadFile,
      croppedImageUrl: null,
    });
  };
  render() {
    const { updatePassword, userRole } = this.state;
    return (
      <div
        className={
          this.props.controller === undefined
            ? styles.CreateUsers
            : styles.editUser
        }
      >
        <h3 className={styles.header}>
          <span className={styles.headerIcon}>
            <Icon defination={{ prefix: "fal", iconName: "user" }} />
          </span>
          {this.props.mode} User
        </h3>
        <div className={styles.breakLine}></div>
        <p
          className={styles.stickError}
          style={{
            visibility: !this.state.isValidForm ? "visible" : "hidden",
          }}
        >
          Please enter missing information
        </p>
        <div
          className={styles.infoWrapper}
          style={{ opacity: this.state.loading ? 0.6 : 1 }}
        >
          <div
            className={
              (this.state.uploadFile.value != "" &&
                this.state.openImageCropModel) ||
              this.state.showPreviewImage
                ? styles.openWrapper
                : styles.closeWrapper
            }
          >
            {this.state.showPreviewImage ? (
              <PreviewImage
                previewImage={this.props.data.avatar}
                closePreview={this.closePreviewHandler}
              />
            ) : (
              <ImageCrop
                imageSrc={this.state.uploadFile.value}
                sendImageUrl={this.sendImageUrlHandler}
                previewImage={this.state.croppedImageUrl}
                closePreview={this.closePreviewHandler}
                mode={this.props.mode}
                cancel={this.cancelImageHandler}
              />
            )}
          </div>
          <div className={styles.formWrapper}>
            <div className={styles.titleWrapper}>
              <p className={styles.title}>User Details</p>
              {this.props.mode !== VIEW_MODE && (
                <div className={styles.btnpreview}>
                  <Button
                    text="Preview Image"
                    click={this.showPreviewHandler}
                    disabled={
                      this.state.loading || this.props.data.avatar == null
                    }
                    //overrideStyles={{ marginRight: "2rem" }}
                  />
                </div>
              )}
            </div>
            <div className={styles.breakLine}></div>

            <div className={styles.form}>
              {this.buildInputsElementsHandler()}
              {this.props.mode === CREATE_MODE ? (
                <SelectWithOptions
                  wrapperClass={styles.inputWrapper}
                  optionsList={[
                    { id: 1, name: "adminstrator" },
                    { id: 2, name: "user" },
                  ]}
                  defaultOptionTitle={"Select Role"}
                  label={"User role"}
                  error={userRole.error}
                  errMsg={userRole.errMsg}
                  name={userRole.name}
                  phoneMode={this.props.phoneMode}
                  onchange={(e) => this.onSelectRoleHandler(e)}
                  selected={userRole.value}
                  disabled={this.props.mode == VIEW_MODE}
                />
              ) : (
                <Input
                label={"User role"}
                  error={""}
                  errMsg={""}
                  value={getUserRole(userRole.value)}
                  name={userRole.name}
                  inputWrapper={styles.inputWrapper}
                  disabled={true}
                  phoneMode={this.props.phoneMode}
                />
              )}
              {this.props.mode != VIEW_MODE ? (
                <SearchBar
                  label={"Location"}
                  address={this.state.address.address}
                  formatAddress={this.formatAddressHandler}
                  searchWrapper={styles.inputWrapper}
                  error={this.state.address.error}
                  phoneMode={this.props.phoneMode}
                  disabled={this.props.mode == VIEW_MODE}
                />
              ) : (
                <Input
                  label={"Location"}
                  error={""}
                  errMsg={""}
                  value={this.state.address.address}
                  name={"address"}
                  inputWrapper={styles.inputWrapper}
                  disabled={true}
                  phoneMode={this.props.phoneMode}
                />
              )}
              {this.props.mode != VIEW_MODE ? (
                <UploadFile
                  label="Upload"
                  inputWrapper={styles.uploadFileWrapper}
                  showLoader={this.state.loading}
                  error={this.state.uploadFile.error}
                  errMsg={this.state.uploadFile.errMsg}
                  name="upload_image"
                  acceptedFiles=".jpeg, .png, .jpg"
                  fileName={this.state.uploadFile.fileName}
                  placeholder={this.state.uploadFile.placeholder}
                  clickAction={(e) => this.uploadFileHandler(e)}
                  imgurl={
                    this.state.uploadFile.fileName != ""
                      ? URL.createObjectURL(this.state.uploadFile.value)
                      : ""
                  }
                  showPreview={true}
                />
              ) : (
                <div className={styles.previewWrapper}>
                  <Button
                    text="Preview Image"
                    click={this.showPreviewHandler}
                    disabled={this.props.data.avatar == null}
                  />
                </div>
              )}
              {this.props.mode !== VIEW_MODE && (
                <div className={styles.checkboxMainWrapper}>
                  <CheckBox
                    labelMsg={""}
                    checkBoxWrapper={styles.checkboxWrapper}
                    value={this.state.defaultColor}
                    click={this.onCheckColorHandler}
                    name={"defaultColor"}
                  />
                  <span className={styles.colorIcon}></span>
                  <span className={styles.colorLabel}>
                    Highlight vehicles in multiple groups on the map
                  </span>
                </div>
              )}
            </div>
         
          {this.props.mode !== VIEW_MODE && (
            <>
              <p className={styles.title}>
                {this.props.mode == CREATE_MODE
                  ? "Password"
                  : "Update password"}
              </p>
              <div className={styles.breakLine}></div>
              <div className={styles.passwordform}>
                <Input
                  value={updatePassword.password.value}
                  label={updatePassword.password.label}
                  inputWrapper={styles.inputWrapper}
                  errMsg={updatePassword.password.errMsg}
                  phoneMode={this.props.phoneMode}
                  name={updatePassword.password.name}
                  error={updatePassword.password.error}
                  type={updatePassword.password.type}
                  placeholder={updatePassword.password.placeholder}
                  change={(e) => this.onChangePasswordHandler(e)}
                  iconDefination={{ prefix: "far", iconName: "eye-slash" }}
                  iconAction={()=>this.onClickIconPasswordHandler('password')}
                />
                <Input
                  value={updatePassword.newPassword.value}
                  name={updatePassword.newPassword.name}
                  label={updatePassword.newPassword.label}
                  inputWrapper={styles.inputWrapper}
                  errMsg={updatePassword.newPassword.errMsg}
                  phoneMode={this.props.phoneMode}
                  type={updatePassword.newPassword.type}
                  error={updatePassword.newPassword.error}
                  placeholder={updatePassword.newPassword.placeholder}
                  change={(e) => this.onChangePasswordHandler(e)}
                  iconDefination={{ prefix: "far", iconName: "eye-slash" }}
                  iconAction={()=>this.onClickIconPasswordHandler('newPassword')}
                />
              </div>
            </>
          )}
           </div>
        </div>
        <div className={styles.btnWrapper}>
          {this.props.mode == VIEW_MODE ? (
            <Button
              click={this.props.close}            
              text={CLOSE}
            />
          ) : (
            <>
              <FormButtons
                text={CANCEL}
                click={this.props.close}
                disabled={this.state.loading}
                overrideStyles={{ width: "14.2rem" }}
              />
              <FormButtons
                text={SUBMIT}
                click={this.onClickCreateEditUserHandler}
                disabled={this.state.loading}
                overrideStyles={{ width: "14.2rem" }}
              />
            </>
          )}
        </div>
        <div
          className={styles.spinnerWrapper}
          style={{ display: this.state.loading ? "block" : "none" }}
        >
          <SpinnerLoader />
        </div>
      </div>
    );
  }
}
