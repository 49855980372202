import React, { Component } from "react";
import styles from "./RadioButton.module.scss";
class RadioButton extends Component {
  render() {
    return (
      <div className={this.props.inputWrapper}>
        <label className={styles.radio} htmlFor={this.props.id}>
          <input
            type="radio"
            id={this.props.id}
            name={this.props.name}
            value={this.props.value}
            disabled={this.props.disabled}
            checked={this.props.checked}
            onChange={this.props.change}
          />
          <span>{this.props.label}</span>
        </label>
      </div>
    );
  }
}

export default RadioButton;
