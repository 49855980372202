import React, { Component } from "react";
import Icon from "../../Icon/Icon";
import styles from "./MapButton.module.scss";
export default class MapButton extends Component {
  render() {
    return (
      <>
        <button
          id={this.props.id}
          onClick={this.props.btnAction}
          className={
            this.props.customClass != undefined
              ? `${styles.Btn} ${this.props.customClass} `
              : styles.Btn
          }
          style={
            this.props.customStyles != undefined ? this.props.customStyles : {}
          }
          disabled={this.props.disabled}
        >
          <span
            className={`${styles.toolTip} ${this.props.toolTipOverride}`}
            style={{
              display:
                this.props.toolTip !== undefined ? "inline-block" : "none",
            }}
          >
          {this.props.toolTip}
          </span>
          {this.props.icon != undefined && (
            <span
              //className={styles.icon}
              className={
                this.props.iconClass != undefined
                  ? `${styles.icon} ${this.props.iconClass} `
                  : styles.icon
              }
            >
              <Icon
                isSpinner={this.props.isSpinner}
                defination={this.props.icon}
              />
            </span>
          )}
          {this.props.imageType != undefined ? (
            <span
              className={
                this.props.tool === undefined ? styles.tool : this.props.tool
              }
            >
              <img
                className={
                  this.props.imageClass != undefined
                    ? this.props.imageClass
                    : styles.imageIcon
                }
                src={this.props.imageType}
                alt="pen"
              />
              {this.props.label}
            </span>
          ) : this.props.label != undefined && this.props.label != "" ? (
            <span style={{ width: "100%" }}>{this.props.label}</span>
          ) : (
            ""
          )}
          {this.props.showClose && (
            <span className={styles.closeIcon} onClick={this.props.btnAction}>
              <Icon defination={{ prefix: "fas", iconName: "times" }} />
            </span>
          )}
        </button>
      </>
    );
  }
}
