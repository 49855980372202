import React, { Component } from "react";
import { CANCEL, SUBMIT } from "../../Util/constants";
import FormButtons from "../FormButtons/FormButtons";
import Icon from "../Icon/Icon";
import SearchBox from "../SearchBox/SearchBox";
import SpinnerLoader from "../SpinnerLoader/SpinnerLoader";
import styles from "./SyncTerminals.module.scss";
import { getPartnerLookupId } from "../../Util/globalFunc";
import Button from "../Button/Button";
import DeviceItem from "./DeviceItem/DeviceItem";
import {
  getExternalTerminals,
  syncTerminals,
  getIntegrationPartners,
} from "../../RequestManager/GqlBuilderAccount";
import { httpRequest } from "../../RequestManager/HttpRequest";

import Swal from "sweetalert2/dist/sweetalert2.js";
export default class CreateVehicle extends Component {
  partnerLookupId = "";
  state = {
    loading: false,
    isMouted: false,
    isValidForm: true,
    selectedTerminals: {},
    availableTerminals: {},
    originalAvailableTerminals: {},
    relatedTerminals: {},
    originalRelatedTerminals: {},
    searchKey: "",
  };
  componentDidMount() {
    this.createHttpRequestHandler();
  }
  createHttpRequestHandler = () => {
    this.setState({ loading: true }, () => {
      httpRequest(getIntegrationPartners, {}).then((res) => {
        this.partnerLookupId = getPartnerLookupId(
          res.data.integrationPartners.partners
        );
        this.intializeDataHandler();
      });
    });
  };
  intializeDataHandler = () => {
    let params = {
      partnerLookupId: this.partnerLookupId,
      includeManualElsewhere: true,
    };
    this.setState({ loading: true }, () => {
      httpRequest(getExternalTerminals, params)
        .then((res) => {
          if (res.errors !== undefined) {
            this.fireSwalHandler(
              "Warning",
              res.errors[0].message.replace(/_/g, " "),
              "warning"
            );
            this.setState({ loading: false });
          } else this.setDataHandler(res.data.externalTerminals);
        })
        .catch((err) => {
          this.setState({ loading: false });
        });
    });
  };
  setDataHandler = (data) => {
    let list = {};
    data.externalTerminals.map((item) => {
      list[item.externalId] = { ...item };
    });
    let relatedList = {};
    data.onetrackTerminals.map((item) => {
      relatedList[item.externalId] = { ...item };
    });
    this.setState(
      {
        availableTerminals: list,
        originalAvailableTerminals: list,
        //selectedTerminals: list,
        relatedTerminals: relatedList,
        originalRelatedTerminals: relatedList,
        loading: false,
      },
      () => {}
    );
  };

  onClickAddItem = (item, index, type) => {
    let terminalsCopy = JSON.parse(
      JSON.stringify(this.state.availableTerminals)
    );
    delete terminalsCopy[item.externalId];
    let org_terminalsCopy = JSON.parse(
      JSON.stringify(this.state.originalAvailableTerminals)
    );
    delete org_terminalsCopy[item.externalId];
    let selectedTerminalsCopy = JSON.parse(
      JSON.stringify(this.state.selectedTerminals)
    );
    selectedTerminalsCopy[item.externalId] = { ...item };
    this.setState({
      availableTerminals: terminalsCopy,
      originalAvailableTerminals: org_terminalsCopy,
      selectedTerminals: selectedTerminalsCopy,
    });
  };
  onClickRemoveItem = (item, index, type) => {
    let terminalsCopy = JSON.parse(
      JSON.stringify(this.state.availableTerminals)
    );
    let org_terminalsCopy = JSON.parse(
      JSON.stringify(this.state.originalAvailableTerminals)
    );
    org_terminalsCopy[item.externalId] = { ...item };
    terminalsCopy[item.externalId] = { ...item };

    let listCopy = JSON.parse(JSON.stringify(this.state[type]));
    delete listCopy[item.externalId];
    this.setState({
      availableTerminals: terminalsCopy,
      originalAvailableTerminals: org_terminalsCopy,
      [type]: listCopy,
    });
  };

  onSearchChangeHandler = (event) => {
    if (event.target.value.trim() === "") {
      this.setState({
        searchKey: event.target.value,
        availableTerminals: JSON.parse(
          JSON.stringify(this.state.originalAvailableTerminals)
        ),
      });
    } else {
      this.setState({ searchKey: event.target.value }, () => {
        this.onClickSearchHandler();
      });
    }
  };

  onClickSearchHandler = () => {
    let search = this.state.searchKey.trim();
    if (this.state.searchKey.trim() === "") {
      return;
    } else {
      let arrMatch = {};
      Object.values(this.state.originalAvailableTerminals).map((item) => {
        //loop and push all matched devices
        try {
          if (
            item.deviceName.toLowerCase().indexOf(search.toLowerCase()) !==
              -1 ||
            item.externalId.indexOf(search) !== -1
          )
            arrMatch[item.externalId] = item;
        } catch (error) {}
      });
      this.setState({
        availableTerminals: arrMatch,
      });
    }
  };
  onKeyDownHandler = (e) => {
    if (e.key === "Enter") {
      this.onClickSearchHandler();
    }
  };
  onClickClearHandler = () => {
    this.setState({
      searchKey: "",
      availableTerminals: JSON.parse(
        JSON.stringify(this.state.originalAvailableTerminals)
      ),
    });
  };
  onSubmitHandler = () => {
    let selected = JSON.parse(JSON.stringify(this.state.selectedTerminals));
    let org_related = JSON.parse(
      JSON.stringify(this.state.originalRelatedTerminals)
    );
    //selected list
    let selectedList = {};
    Object.values(selected).map((item) => {
      if (this.state.originalRelatedTerminals[item.externalId] == undefined) {
        delete item.manuallyAddedElsewhere;
        delete item.manuallyAddedElsewhereAccount;
        selectedList[item.externalId] = { ...item };
      }
    });
    //removed list
    let removedList = {};
    Object.values(org_related).map((item) => {
      if (
        this.state.relatedTerminals[item.externalId] == undefined &&
        this.state.selectedTerminals[item.externalId] == undefined
      ) {
        delete item.manuallyAddedElsewhere;
        delete item.manuallyAddedElsewhereAccount;
        removedList[item.externalId] = { ...item };
      }
    });
    this.syncTerminalsHandler(
      Object.values(selectedList),
      Object.values(removedList)
    );
  };
  syncTerminalsHandler = (addedTerminals, removedTerminals) => {
    let removedList = [];
    removedTerminals.map((item) => removedList.push(item.lookupId));
    let params = {
      partnerLookupId: this.partnerLookupId,
      addTerminals: addedTerminals,
      removeTerminalsLookupIds: removedList,
    };
    this.setState({ loading: true }, () => {
      if (addedTerminals.length || removedList.length) {
        httpRequest(syncTerminals, params)
          .then((res) => {
            this.checkSyncResponseHandler(res);
          })
          .catch((err) => {});
      } else {
        //no api call as there is no data to get updates
        this.fireSwalHandler("Success", "Terminals are synced", "success");
      }
    });
  };
  checkSyncResponseHandler = (res) => {
    if (res.errors !== undefined) {
      this.fireSwalHandler(
        "Warning",
        res.errors[0].message.replace(/_/g, " "),
        "warning"
      );
      this.setState({ loading: false });
    } else {
      this.fireSwalHandler("Success", "Terminals are synced", "success");
    }
  };
  fireSwalHandler = (title, text, type) => {
    Swal.fire({
      title: title,
      text: text,
      type: type,
    }).then((res) => {
      if (type === "success") {
        this.props.reloadPage();
      }
    });
  };
  render() {
    return (
      <div className={styles.CreateVehicles}>
        <h3 className={styles.header}>
          <span className={styles.headerIcon}>
            <Icon defination={{ prefix: "fal", iconName: "sync" }} />
          </span>
          Sync Terminals
          <span className={styles.btnResetWrapper}>
            <Button
              text="Reload"
              click={this.intializeDataHandler}
              disabled={this.state.loading}
            />
          </span>
        </h3>

        <div className={styles.breakLine}></div>

        <div
          className={styles.infoWrapper}
          style={{ opacity: this.state.loading ? 0.6 : 1 }}
        >
          <div className={styles.listWrapper}>
            <SearchBox
              name="searchTerminals"
              title={`Search Terminals (${
                Object.values(this.state.availableTerminals).length
              })`}
              placeholder={"Search Terminals"}
              errmsg={""}
              change={(e) => this.onSearchChangeHandler(e)}
              click={this.onClickSearchHandler}
              value={this.state.searchKey}
              onKeyDown={this.onKeyDownHandler}
              clickClear={this.onClickClearHandler}
            />

            <div className={styles.itemsWrapper}>
              {Object.values(this.state.availableTerminals).length == 0 && (
                <p className={styles.noSearchResult}>No Terminals</p>
              )}
              {Object.values(this.state.availableTerminals).map(
                (item, index) => {
                  // return this.getItemHandler(item, index);
                  return (
                    <DeviceItem
                      item={item}
                      click={this.onClickAddItem}
                      index={index}
                      iconType={"plus"}
                    />
                  );
                }
              )}
            </div>
          </div>

          <div className={styles.listWrapper}>
            <p className={styles.subtitle}>
              Selected Terminals (
              {Object.values(this.state.selectedTerminals).length})
            </p>
            <div className={styles.itemsWrapper}>
              {Object.values(this.state.selectedTerminals).map(
                (item, index) => {
                  return (
                    <DeviceItem
                      item={item}
                      click={this.onClickRemoveItem}
                      index={index}
                      iconType={"minus"}
                      type="selectedTerminals"
                    />
                  );
                }
              )}
            </div>
          </div>
          <div className={styles.listWrapper}>
            <p className={styles.subtitle}>
              Related Terminals (
              {Object.values(this.state.relatedTerminals).length})
            </p>
            <div className={styles.itemsWrapper}>
              {Object.values(this.state.relatedTerminals).map((item, index) => {
                return (
                  <DeviceItem
                    item={item}
                    click={this.onClickRemoveItem}
                    index={index}
                    iconType={"minus"}
                    type="relatedTerminals"
                  />
                );
              })}
            </div>
          </div>
        </div>
        <div className={styles.btnWrapper}>
          <>
            <FormButtons
              text={CANCEL}
              click={this.props.close}
              disabled={this.state.loading}
              overrideStyles={{ width: "14.2rem" }}
            />
            <FormButtons
              text={SUBMIT}
              click={this.onSubmitHandler}
              disabled={this.state.loading}
              overrideStyles={{ width: "14.2rem" }}
            />
          </>
        </div>
        <div
          className={styles.spinnerWrapper}
          style={{ display: this.state.loading ? "block" : "none" }}
        >
          <SpinnerLoader />
        </div>
      </div>
    );
  }
}
