import React, { Component } from "react";
import styles from "./TelemetryHistory.module.scss";
import {
  dateConverstion,
  trimChatAtStart,
  getTimeZone,
} from "../../../../Util/globalFunc";
import Icon from "../../../Icon/Icon";
import Checkbox from "../../../CheckBox/CheckBox";
import {
  INDEX,
  INTITAL_OFFSET,
  PAGE_LIMIT,
  LATITUDE,
  LONGITUDE,
  TIMSESTAMP,
  SPEED,
  HEADING,
  ROLL,
  GPS,
  CONVERT_NUMBER_MILE,
  IMPORT_GOOGLE_URL,
  DISABLE_COLOR_FOR_GPS_UNLOCK,
  SOS_MSG,
  FENCE,
  CHAT_LOGS,
  TELEMETRY_LOGS,
  KEEP_ALIVE,
  TYPE_KEEP_ALIVE,
  FENCING,
  INVALID_LOGS,
} from "../../../../Util/constants";
import Controller from "../../../../Controller";
import SpinnerLoader from "../../../SpinnerLoader/SpinnerLoader";
import Pagination from "../../../Pagination/Pagination";
import { httpRequest } from "../../../../RequestManager/HttpRequest";
import {
  getTelemetryHistory,
  getTelemetryHistoryCSV,
  alertLogs,
  chatMsgLogs,
} from "../../../../RequestManager/GqlBuilderAccount";
import DateTimePicker from "../../../DateTimePicker/DateTimePicker";
import SelectWithOptions from "../../../SelectWithOptions/SelectWithOptions";
import CustomMenu from "../../../CustomMenu/CustomMenu";
import moment from "moment";

import Swal from "sweetalert2/dist/sweetalert2.js";
export default class History extends Component {
  alertType = FENCE;
  keepAliveStatus = {
    2: "ALIVE",
    3: "DEAD",
    1: "ALL",
  };
  decodeInvalidLogs = {
    1: "Good Logs",
    2: "Bad Logs",
    3: "All Logs",
  };
  query = getTelemetryHistory;
  lookupId = this.props.vehicleData.lookupId;
  title = {
    LiveChat: "Chat",
    FENCE: "Alert Zone",
    SOS: "SOS Alert",
    TelemetryHistory: "Telemetry",
    KeepAlive: "Keep alive",
    InvalidLogs: "Invalid Logs",
  };
  decodeSent = {
    1: "Vehicle",
    2: "You",
    3: "FMS",
    4: "FMS",
  };
  statusMsg = {
    0: "Sent", ///sent
    1: "Seen", ///seen
    2: "Received", //Received
    3: "Failed", //fail
  };
  state = {
    invalidLogType: 3,
    typeOfLog: this.props.typeOfLog,
    loading: false,
    data: [],
    activeIndex: 0,
    totalRecords: 0,
    pageNo: 1,
    offset: INTITAL_OFFSET,
    limit: PAGE_LIMIT,
    searchKey: "",
    originalData: [],
    showfilter: false,
    dateFilter: {
      start: {
        label: "Start date",
        value: "",
        error: false,
        name: "start",
        type: "number",
        errMsg: "Invalid dates",
        placeholder: "MM/DD/YYYY --:--",
      },
      end: {
        label: "End date",
        value: "",
        error: false,
        name: "end",
        type: "number",
        errMsg: "Invalid date",
        placeholder: "MM/DD/YYYY --:--",
      },
      pointsCount: {
        label: "Points",
        value: "",
        error: false,
        name: "ponts",
        type: "number",
        errMsg: "Invalid points",
        placeholder: "0",
      },
    },
    keepAliveStatus: 1,
    excludeGPS: true,
    msgDisplay: {},
    indexMsg: "",
  };
  componentDidMount() {
    if (
      this.state.typeOfLog !== KEEP_ALIVE &&
      this.state.typeOfLog !== INVALID_LOGS
    ) {
      if (this.state.typeOfLog == SOS_MSG) {
        this.alertType = SOS_MSG;
      }
      if (this.state.typeOfLog !== TELEMETRY_LOGS) {
        if (this.state.typeOfLog !== CHAT_LOGS) {
          this.query = alertLogs;
        } else {
          this.query = chatMsgLogs;
        }
      }

      this.createHttpRequestHandler();
    } else {
      this.setState({ loading: false });
    }
  }
  buildTableHeaderHandler = () => {
    if (this.state.typeOfLog == TELEMETRY_LOGS) {
      return [
        INDEX,
        TIMSESTAMP,
        LATITUDE,
        LONGITUDE,
        SPEED,
        GPS,
        ROLL,
        HEADING,
      ].map((item, index) => {
        return (
          <div key={index} className={styles.textCell}>
            <strong>{item}</strong>
          </div>
        );
      });
    } else if (this.state.typeOfLog == FENCE) {
      return [
        { title: INDEX, explanation: "" },
        {
          title: "Created",
          explanation: (
            <span>
              <span>Time of alarming about</span>
              <br />
              exiting the permitted zone
            </span>
          ),
        },
        {
          title: "Alert Off",
          explanation: (
            <>
              Time of turning off
              <br />
              the out-of-zone alert
            </>
          ),
        },
        {
          title: "Monitoring Disabled",
          explanation: (
            <>
              Time of disabling vehicle <br />
              zone monitoring
            </>
          ),
        },
        {
          title: "Zone Re-entry",
          explanation: (
            <>
              Time of re-entering
              <br />
              permitted zone
            </>
          ),
        },
        {
          title: "Received UDA",
          explanation: (
            <>
              Time of receiving <br />
              out-of-zone alert on UDA
            </>
          ),
        },
        {
          title: "Dismissed UDA",
          explanation: (
            <>
              Time of dismissing
              <br />
              out-of-zone alert on UDA
            </>
          ),
        },
        {
          title: "seen UDA",
          explanation: (
            <>
              Time of seeing
              <br />
              out-of-zone alert on UDA
            </>
          ),
        },
        {
          title: "failed",
          explanation: (
            <>
              Time of declaring the
              <br />
              alert send-out has failed
            </>
          ),
        },
      ].map((item, index) => {
        return (
          <div key={index} className={styles.textAlertCell}>
            <span
              className={index != 0 ? styles.toolTip : ""}
              style={{ left: index == 1 ? "11rem" : "" }}
            >
              {item.explanation}
            </span>
            {item.title.split(" ").map((itemH, indexH) => {
              return (
                <span key={indexH}>
                  <strong>{itemH}</strong>
                </span>
              );
            })}
          </div>
        );
      });
    } else if (this.state.typeOfLog == SOS_MSG) {
      return [
        INDEX,
        "Created ",
        "dismissed",
        "bypass SOS",
        "help sent",
        LATITUDE,
        LONGITUDE,
        GPS,
      ].map((item, index) => {
        return (
          <div className={styles.textCellSos} key={index}>
            <strong>{item}</strong>
          </div>
        );
      });
    } else if (this.state.typeOfLog == CHAT_LOGS) {
      return [
        INDEX,
        "Created",
        "Sent by",
        "Status",
        "Total Cycles",
        "Total Retries",
        "Message",
      ].map((item, index) => {
        return (
          <div className={styles.chatText} key={index}>
            <strong>{item}</strong>
          </div>
        );
      });
    }
  };
  getIndexOfPageHandler = (pageIndex) => {
    return 1 + (pageIndex - 1) * PAGE_LIMIT;
  };
  convertDateHandler = (ts, diff = null) => {
    if (ts == null) return "N/A";
    if (diff == null) {
      return moment(ts).format("MM-DD-YYYY HH:mm:ss");
    } else {
      return moment(ts).format("MM-DD-YY HH:mm:ss");
    }
  };
  onClickClearDateHandler = () => {
    let date_copy = { ...this.state.dateFilter };
    date_copy.start.value = "";
    date_copy.end.value = "";
    date_copy.pointsCount.value = "";
    this.setState(
      {
        dateFilter: date_copy,
        loading: this.state.typeOfLog !== KEEP_ALIVE ? true : false,
        activeIndex: 0,
        totalRecords: 0,
        offset: INTITAL_OFFSET,
        pageNo: 1,
        originalData: [],
        data: [],
        showfilter: false,
      },
      () => {
        if (this.state.typeOfLog !== KEEP_ALIVE) {
          this.createHttpRequestHandler();
        }
      }
    );
  };
  onClickMsgHandler = (item, index = "") => {
    this.setState({ msgDisplay: item, indexMsg: index });
  };
  createHttpRequestHandler = (search = "") => {
    let params = {
      offset: this.state.offset,
      limit: this.state.limit,
      lookupId: this.lookupId,
      startDate:
        this.state.dateFilter.start.value !== ""
          ? moment(this.state.dateFilter.start.value).toISOString()
          : "",
      endDate:
        this.state.dateFilter.end.value !== ""
          ? moment(this.state.dateFilter.end.value).toISOString()
          : "",
    };
    if (this.state.typeOfLog == TELEMETRY_LOGS) {
      params.points =
        this.state.dateFilter.pointsCount.value !== ""
          ? parseInt(this.state.dateFilter.pointsCount.value)
          : 0;
      params.vehicleLookupId = this.lookupId;
      delete params.lookupId;
      // params.lookupId=this.lookupId
    }
    if (
      this.state.typeOfLog !== TELEMETRY_LOGS &&
      this.state.typeOfLog != CHAT_LOGS
    ) {
      params.alertType = this.alertType;
    }

    if (this.state.typeOfLog == CHAT_LOGS) {
      // delete params.lookupId;
      params.vehicleLookupId = this.lookupId;
      params.excludeTypes = [3, 4];
      params.reverseAfterLimit = false;
    }

    this.setState({ loading: true }, () => {
      httpRequest(this.query, params)
        .then((res) => {
          this.setDataHandler(res.data);
        })
        .catch((err) => {});
    });
  };
  onSelectKeeplAliveHandler = (event) => {
    this.setState({ [event.target.name]: event.target.value }, () => {});
  };
  checkValidDatesHandler = () => {
    let start = moment(this.state.dateFilter.start.value).format(
      "MM/DD/YYYY HH:mm"
    );
    let end = moment(this.state.dateFilter.end.value).format(
      "MM/DD/YYYY HH:mm"
    );
    return moment(end).isAfter(start) || moment(start).isSame(end);
  };
  getBackgroundColor = (item) => {
    if (parseInt(item.gpsFixStatus) === 0) {
      //UNLOCK
      return { backgroundColor: DISABLE_COLOR_FOR_GPS_UNLOCK, color: "#000" };
    } else {
      return { backgroundColor: "#fff" };
    }
  };

  buildTableRowHandler = () => {
    switch (this.state.typeOfLog) {
      case TELEMETRY_LOGS:
        if (
          (this.state.data[this.state.activeIndex] == undefined ||
            this.state.data[this.state.activeIndex].length == 0) &&
          !this.state.loading
        ) {
          return <div className={styles.noDataRow}>No records found</div>;
        } else if (this.state.totalRecords > 0) {
          return this.state.data[this.state.activeIndex].map((item, index) => {
            let indexPage = this.getIndexOfPageHandler(
              this.state.activeIndex + 1
            );
            return (
              <div
                className={styles.tableRow}
                key={index}
                style={this.getBackgroundColor(item)}
              >
                <div className={styles.textCell}>{indexPage + index}</div>
                <div className={`${styles.textCell} ${styles.dateTooltip} `}>
                  <span className={styles.toolTip}>
                    {this.convertDateHandler(item.createdAt)}
                  </span>
                  {this.convertDateHandler(item.createdAt, "diff")}
                </div>
                <div className={styles.textCell}>
                  {parseFloat(item.latitude).toFixed(3)}&#xb0;
                </div>
                <div className={styles.textCell}>
                  {parseFloat(item.longitude).toFixed(3)}&#xb0;
                </div>

                <div className={styles.textCell}>
                  {this.props.vehicleData.speedUnit == 1
                    ? parseFloat(item.speed).toFixed(1)
                    : parseFloat(item.speed * CONVERT_NUMBER_MILE).toFixed(1)}
                  <span className={styles.subTitle}>
                    {this.props.vehicleData.speedUnitStr}
                  </span>
                </div>
                <div className={`${styles.textCell}  ${styles.gpsTooltip}`}>
                  <span className={styles.toolTip}>
                    {this.props.convertGpsData(item.gpsFixStatus)}
                  </span>
                  {this.props.convertGpsData(item.gpsFixStatus)}
                </div>
                <div className={styles.textCell}>
                  {item.inclination.toFixed(1)}&#xb0;
                </div>
                <div className={styles.textCell}>
                  {item.heading.toFixed(1)}&#xb0;
                </div>
              </div>
            );
          });
        }
        break;

      case FENCE:
        if (
          (this.state.data[this.state.activeIndex] == undefined ||
            this.state.data[this.state.activeIndex].length == 0) &&
          !this.state.loading
        ) {
          return <div className={styles.noDataRow}>No records found</div>;
        } else if (this.state.totalRecords > 0) {
          return this.state.data[this.state.activeIndex].map((item, index) => {
            let indexPage = this.getIndexOfPageHandler(
              this.state.activeIndex + 1
            );
            return (
              <div
                className={styles.tableRow}
                key={index}
                style={this.getBackgroundColor(item)}
              >
                <div className={styles.textAlertCell}>{indexPage + index}</div>
                <div
                  className={`${styles.textAlertCell} ${styles.dateTooltip} `}
                >
                  <span className={styles.toolTip}>
                    {this.convertDateHandler(item.createdAt)}
                  </span>
                  {this.convertDateHandler(item.createdAt, "diff")}
                </div>
                <div className={styles.textAlertCell}>
                  {this.convertDateHandler(item.readAt)}
                </div>
                <div className={styles.textAlertCell}>
                  {this.convertDateHandler(item.vehicleAlertsDisabledAt)}
                </div>

                <div className={styles.textAlertCell}>
                  {this.convertDateHandler(item.eventConcludedAt)}
                </div>
                <div
                  className={`${styles.textAlertCell}  ${styles.gpsTooltip}`}
                >
                  <span className={styles.toolTip}>
                    {this.convertDateHandler(item.receivedAt)}
                  </span>
                  {this.convertDateHandler(item.receivedAt)}
                </div>
                <div className={styles.textAlertCell}>
                  {this.convertDateHandler(item.dismissedAt)}
                </div>
                <div className={styles.textAlertCell}>
                  {this.convertDateHandler(item.seenAt)}
                </div>
                <div className={styles.textAlertCell}>
                  {this.convertDateHandler(item.failedAt)}
                </div>
              </div>
            );
          });
        }
        break;
      case SOS_MSG:
        if (
          (this.state.data[this.state.activeIndex] == undefined ||
            this.state.data[this.state.activeIndex].length == 0) &&
          !this.state.loading
        ) {
          return <div className={styles.noDataRow}>No records found</div>;
        } else if (this.state.totalRecords > 0) {
          return this.state.data[this.state.activeIndex].map((item, index) => {
            let indexPage = this.getIndexOfPageHandler(
              this.state.activeIndex + 1
            );
            return (
              <div
                className={styles.tableRow}
                key={index}
                style={this.getBackgroundColor(item)}
              >
                <div className={styles.textCellSos}>{indexPage + index}</div>
                <div className={`${styles.textCellSos} ${styles.dateTooltip} `}>
                  <span className={styles.toolTip}>
                    {this.convertDateHandler(item.createdAt)}
                  </span>
                  {this.convertDateHandler(item.createdAt, "diff")}
                </div>
                <div className={`${styles.textCellSos} ${styles.dateTooltip}`}>
                  <span className={styles.toolTip}>
                    {this.convertDateHandler(item.createdAt)}
                  </span>
                  {this.convertDateHandler(item.readAt)}
                </div>
                <div className={`${styles.textCellSos} ${styles.dateTooltip} `}>
                  <span className={styles.toolTip}>
                    {this.convertDateHandler(item.helpBypassedAt)}
                  </span>
                  {this.convertDateHandler(item.helpBypassedAt, "diff")}
                </div>
                <div className={`${styles.textCellSos} ${styles.dateTooltip} `}>
                  <span className={styles.toolTip}>
                    {this.convertDateHandler(item.helpDispatchedAt)}
                  </span>
                  {this.convertDateHandler(item.helpDispatchedAt, "diff")}
                </div>
                <div className={styles.textCellSos}>
                  {item.latitude !== null
                    ? parseFloat(item.latitude).toFixed(3)
                    : "N/A"}
                  &#xb0;
                </div>

                <div className={styles.textCellSos}>
                  {item.longitude !== null
                    ? parseFloat(item.longitude).toFixed(3)
                    : "N/A"}
                  &#xb0;
                </div>
                <div className={styles.textCellSos}>
                  {this.props.convertGpsData(item.gpsFixStatus)}
                </div>
              </div>
            );
          });
        }
        break;
      case CHAT_LOGS:
        if (
          (this.state.data[this.state.activeIndex] == undefined ||
            this.state.data[this.state.activeIndex].length == 0) &&
          !this.state.loading
        ) {
          return <div className={styles.noDataRow}>No records found</div>;
        } else if (this.state.totalRecords > 0) {
          return this.state.data[this.state.activeIndex].map((item, index) => {
            let indexPage = this.getIndexOfPageHandler(
              this.state.activeIndex + 1
            );

            return (
              <div className={styles.tableRow} key={index}>
                <div className={styles.chatText}>{indexPage + index}</div>
                <div className={`${styles.chatText} ${styles.dateTooltip} `}>
                  <span className={styles.toolTip}>
                    {this.convertDateHandler(item.createdAt)}
                  </span>
                  {this.convertDateHandler(item.createdAt, "diff")}
                </div>
                <div className={styles.chatText}>
                  {this.decodeSent[item.direction]}
                </div>
                <div className={styles.chatText}>
                  {this.statusMsg[item.status]}
                </div>
                <div className={styles.chatText}>{item.totalSendCycles}</div>
                <div className={styles.chatText}>
                  {item.totalRetriesOverAllCycles}
                </div>
                <div
                  className={styles.chatText}
                  style={{ textTransform: "none" }}
                >
                  <span
                    className={styles.plus}
                    onClick={() =>
                      this.onClickMsgHandler(item, index + indexPage)
                    }
                  >
                    <Icon defination={{ prefix: "fas", iconName: "plus" }} />
                  </span>
                  &nbsp;
                  {trimChatAtStart(item.body, 18)}
                </div>
              </div>
            );
          });
        }
        break;
    }
  };
  downLoadFileHandler = (data, type) => {
    let url = data.fileUrl;
    var a = document.createElement("A");
    a.href = url;
    a.download = url;
    a.target = "_blank";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    let msg = `${data.message}<br/><a href='${IMPORT_GOOGLE_URL}' target='_blank'>Import into Google MyMaps</a><br/>`;
    if ((type = "CSV")) msg = data.message;
    this.fireSwalHandler("Success", msg, "success");
  };
  fireSwalHandler = (title, text, type) => {
    Swal.fire({
      title: title,
      html: text,
      type: type,
    }).then((res) => {
      this.setState({ loading: false });
    });
  };
  setDataHandler = (data) => {
    let chunk = [...this.state.data];
    let telemetry =
      this.state.typeOfLog == TELEMETRY_LOGS
        ? data.telemetryHistory.telemetry
        : this.state.typeOfLog == CHAT_LOGS
        ? data.chatMessages.objects
        : data.vehicleAlerts.objects;
    /**
     * Store the records by spliting in to two arrays
     * If user requested for next+1 records
     */
    if (telemetry.length > PAGE_LIMIT) {
      chunk[this.state.pageNo - 2] = telemetry.slice(0, PAGE_LIMIT);
      chunk[this.state.pageNo - 1] = telemetry.slice(PAGE_LIMIT);
    } else {
      chunk[this.state.pageNo - 1] = telemetry;
    }

    let originalData = [...this.state.originalData, ...telemetry];
    this.setState(
      {
        totalRecords:
          this.state.typeOfLog == TELEMETRY_LOGS
            ? data.telemetryHistory.count
            : this.state.typeOfLog !== CHAT_LOGS
            ? data.vehicleAlerts.count
            : data.chatMessages.count,
        data: chunk,
        originalData: originalData,
        msgDisplay: {},
      },
      () => {
        this.setState({
          activeIndex: this.getActiveIndexHandler(telemetry.length),
          loading: false,
        });
      }
    );
  };
  onClickPageNumberHandler = (pageNumber) => {
    if (this.state.data[pageNumber - 1] !== undefined) {
      this.setState({
        activeIndex: pageNumber - 1,
        pageNo: pageNumber,
      });
    } else {
      /**
       * offset: 3 (even if pressed next+1 pageno)
       */
      let nuberOfRecordstoFetch =
        (pageNumber - (this.state.activeIndex + 1)) * PAGE_LIMIT;
      if (pageNumber - 1 > this.state.pageNo) {
        this.setState(
          {
            offset: (this.state.activeIndex + 1) * PAGE_LIMIT + 1,
            limit: nuberOfRecordstoFetch,
            pageNo: pageNumber,
          },
          () => {
            this.createHttpRequestHandler();
          }
        );
      } else {
        this.setState(
          {
            offset: (pageNumber - 1) * PAGE_LIMIT + 1,
            pageNo: pageNumber,
            limit: PAGE_LIMIT,
          },
          () => {
            this.createHttpRequestHandler();
          }
        );
      }
    }
  };
  getActiveIndexHandler = (noOfRecords) => {
    let nextIndex = 0;
    if (noOfRecords > PAGE_LIMIT) {
      nextIndex = this.state.pageNo - 1;
    } else {
      nextIndex =
        this.state.activeIndex + 1 === this.state.data.length
          ? this.state.activeIndex
          : this.state.activeIndex + 1;
    }
    return nextIndex;
  };
  onClickPrevHandler = () => {
    let nextIndex =
      this.state.activeIndex - 1 < 0 ? 0 : this.state.activeIndex - 1;
    this.setState({
      activeIndex: nextIndex,
      pageNo: nextIndex + 1,
      msgDisplay: {},
    });
  };
  onClickNextHandler = () => {
    /**
     * pageNo : is the prev page number here
     * ActiveIndex = PageNo-1
     * offset : (currentPAgeNo -1) * PAGE_LIMIT +1
     */
    if (this.state.data[this.state.pageNo] !== undefined) {
      this.setState({
        activeIndex: this.state.pageNo,
        pageNo: this.state.pageNo + 1,
        activeTableRow: "",
        msgDisplay: {},
      });
    } else if (
      this.state.pageNo + 1 <=
      Math.ceil(this.state.totalRecords / PAGE_LIMIT)
    ) {
      this.setState({ pageNo: this.state.pageNo + 1 }, () => {
        this.setState(
          {
            offset: (this.state.pageNo - 1) * PAGE_LIMIT + 1,
            limit: PAGE_LIMIT,
          },
          () => {
            this.createHttpRequestHandler();
          }
        );
      });
    }
  };
  onClickTypeOfLogHnadelr = (logType) => {
    this.setState({ invalidLogType: logType });
  };
  dateChangeHandler = (date, name) => {
    let copyInputs = { ...this.state.dateFilter };
    if (date === "") copyInputs[name].value = "";
    else copyInputs[name].value = moment(date).format("MM/DD/YYYY HH:mm:ss");
    copyInputs[name].error = false;
    this.setState({ dateFilter: copyInputs, loading: false }, () => {});
  };
  pointsChangeHandler = (event) => {
    if (this.state.loading) {
      return;
    }
    let copyInputs = { ...this.state.dateFilter };
    copyInputs.pointsCount.value = event.target.value;
    copyInputs.pointsCount.error = false;
    this.setState({ dateFilter: copyInputs });
  };
  onChangeGpsCheckHandler = () => {
    this.setState({ excludeGPS: !this.state.excludeGPS });
  };
  onClickExportHandler = (type) => {
    let modelname = "Telemetry";
    if (
      this.state.typeOfLog !== CHAT_LOGS &&
      this.state.typeOfLog !== TELEMETRY_LOGS &&
      this.state.typeOfLog != INVALID_LOGS
    ) {
      modelname = "VehicleAlert";
    }
    if (this.state.typeOfLog == CHAT_LOGS) {
      modelname = "ChatMessage";
    }
    if (this.state.typeOfLog == KEEP_ALIVE) {
      modelname = "KeepAliveHistory";
    }
    this.setState({ loading: true }, () => {
      let params = {
        modelName: modelname,
        localTz: getTimeZone(),
        format: type,
        categoryA: this.keepAliveStatus[parseInt(this.state.keepAliveStatus)],
        excludeTypes: [3, 4],
        excludeGpsUnlock: this.state.excludeGPS,
        vehicleLookupId: this.lookupId,
        startDate:
          this.state.dateFilter.start.value !== ""
            ? moment(this.state.dateFilter.start.value).toISOString()
            : "",
        endDate:
          this.state.dateFilter.end.value !== ""
            ? moment(this.state.dateFilter.end.value).toISOString()
            : "",
      };
      if (
        this.state.typeOfLog == TELEMETRY_LOGS &&
        this.state.dateFilter.pointsCount.value != ""
      ) {
        params.points = parseInt(this.state.dateFilter.pointsCount.value);
      }
      if (this.state.typeOfLog == INVALID_LOGS) {
        params.goodTelemetry = this.state.invalidLogType;
      }
      if (this.state.typeOfLog == SOS_MSG || this.state.typeOfLog == FENCE) {
        params.alertType = this.alertType;
        params.lookupId = this.lookupId;
        delete params.vehicleLookupId;
      }
      httpRequest(getTelemetryHistoryCSV, params)
        .then((res) => {
          this.checkFileResponseHandler(res, type);
        })
        .catch((err) => {});
    });
  };
  checkFileResponseHandler = (res, type) => {
    if (res.errors !== undefined) {
      this.fireSwalHandler(
        "Warning",
        res.errors[0].message.replace(/_/g, " "),
        "warning"
      );
      this.setState({ loading: false });
    } else {
      this.downLoadFileHandler(res.data.exportTableFile, type);
    }
  };
  onClickSearchDateHandler = () => {
    let copy_date = { ...this.state.dateFilter };
    if (
      this.state.dateFilter.start.value !== "" &&
      this.state.dateFilter.end.value &&
      !this.checkValidDatesHandler()
    ) {
      copy_date.start.error = true;
      this.setState({ dateFilter: copy_date });
    } else {
      this.setState(
        {
          loading: true,
          activeIndex: 0,
          totalRecords: 0,
          offset: INTITAL_OFFSET,
          pageNo: 1,
          originalData: [],
          data: [],
          showfilter: false,
          msgDisplay: {},
        },
        () => {
          this.createHttpRequestHandler();
        }
      );
    }
  };
  onClickCloseMobileHandler = () => {
    this.setState({ showfilter: false });
  };
  onClickOpenFiltersHandler = () => {
    this.setState({ showfilter: true });
  };
  render() {
    return (
      <>
        {this.state.showfilter && (
          <div className={styles.dateWrapperMobile}>
            <span
              className={styles.filterCloseIconFilter}
              onClick={this.onClickCloseMobileHandler}
            >
              <span className={styles.toolTip}>Close</span>
              <Icon defination={{ prefix: "fas", iconName: "times" }} />
            </span>
            <div className={styles.datesInputMobileWrapper}>
              <DateTimePicker
                onChange={this.dateChangeHandler}
                timeFormat={"HH:mm:ss"}
                name={this.state.dateFilter.start.name}
                key={this.state.dateFilter.start.name}
                dateFormat={"MM/DD/YYYY"}
                value={dateConverstion(this.state.dateFilter.start.value)}
                placeholder={this.state.dateFilter.start.placeholder}
                label={this.state.dateFilter.start.label}
                disabled={this.state.loading}
                error={this.state.dateFilter.start.error}
                errMsg={this.state.dateFilter.start.errMsg}
                phoneMode={this.props.phoneMode}
                inputWrapper={styles.inputWrapper}
                pickerStyles={styles.picker}
                iconDefination={{
                  prefix: "fas",
                  iconName: "calendar-alt",
                }}
              />
              <DateTimePicker
                onChange={this.dateChangeHandler}
                timeFormat={"HH:mm:ss"}
                name={this.state.dateFilter.end.name}
                key={this.state.dateFilter.end.name}
                dateFormat={"MM/DD/YYYY"}
                value={dateConverstion(this.state.dateFilter.end.value)}
                placeholder={this.state.dateFilter.end.placeholder}
                label={this.state.dateFilter.end.label}
                disabled={this.state.loading}
                error={this.state.dateFilter.end.error}
                errMsg={this.state.dateFilter.end.errMsg}
                phoneMode={this.props.phoneMode}
                inputWrapper={styles.inputWrapper}
                pickerStyles={styles.picker}
                iconDefination={{
                  prefix: "fas",
                  iconName: "calendar-alt",
                }}
              />

              {this.state.typeOfLog == KEEP_ALIVE && (
                <SelectWithOptions
                  wrapperClass={styles.inputWrapper}
                  optionsList={TYPE_KEEP_ALIVE}
                  defaultOptionTitle={""}
                  label={"keep alive status"}
                  error={false}
                  errMsg={"not valid"}
                  name={"keepAliveStatus"}
                  phoneMode={this.props.phoneMode}
                  onchange={(e) => this.onSelectKeeplAliveHandler(e)}
                  selected={this.state.keepAliveStatus}
                  disabled={this.state.loading}
                />
              )}
            </div>
            <div className={styles.btnMobileWrapper}>
              {" "}
              <button
                disabled={this.state.loading}
                className={styles.btn}
                onClick={this.onClickClearDateHandler}
              >
                clear
              </button>
              {this.state.typeOfLog !== KEEP_ALIVE &&
                this.state.typeOfLog != INVALID_LOGS && (
                  <button
                    disabled={this.state.loading}
                    className={styles.btn}
                    onClick={this.onClickSearchDateHandler}
                  >
                    search
                  </button>
                )}
            </div>
          </div>
        )}
        <div
          className={styles.histroyWrapper}
          style={{
            opacity: this.state.loading || this.state.showfilter ? 0.6 : 1,
          }}
        >
          <div className={styles.titleWrapper}>
            <p className={styles.title}>
              {this.title[this.state.typeOfLog]} History
            </p>
            <span className={styles.icon} onClick={this.props.closeHistory}>
              <Icon defination={{ prefix: "fas", iconName: "times" }} />
            </span>
          </div>
          <div className={styles.breakLine}></div>
          <div className={styles.filterWrapper}>
            <div className={styles.contralsMobile}>
              <button
                disabled={this.state.loading}
                className={styles.btn}
                onClick={this.onClickOpenFiltersHandler}
              >
                filter Dates & status
              </button>
              <button
                disabled={this.state.loading}
                className={styles.btn}
                onClick={this.onClickClearDateHandler}
              >
                Clear
              </button>
            </div>
            <div className={styles.dateWrapper}>
              <DateTimePicker
                onChange={this.dateChangeHandler}
                timeFormat={"HH:mm:ss"}
                name={this.state.dateFilter.start.name}
                key={this.state.dateFilter.start.name}
                dateFormat={"MM/DD/YYYY"}
                value={dateConverstion(this.state.dateFilter.start.value)}
                placeholder={this.state.dateFilter.start.placeholder}
                label={this.state.dateFilter.start.label}
                disabled={this.state.loading}
                error={this.state.dateFilter.start.error}
                errMsg={this.state.dateFilter.start.errMsg}
                phoneMode={this.props.phoneMode}
                inputWrapper={styles.inputWrapper}
                pickerStyles={styles.picker}
                iconDefination={{
                  prefix: "fas",
                  iconName: "calendar-alt",
                }}
              />
              <DateTimePicker
                onChange={this.dateChangeHandler}
                timeFormat={"HH:mm:ss"}
                name={this.state.dateFilter.end.name}
                key={this.state.dateFilter.end.name}
                dateFormat={"MM/DD/YYYY"}
                value={dateConverstion(this.state.dateFilter.end.value)}
                placeholder={this.state.dateFilter.end.placeholder}
                label={this.state.dateFilter.end.label}
                disabled={this.state.loading}
                error={this.state.dateFilter.end.error}
                errMsg={this.state.dateFilter.end.errMsg}
                phoneMode={this.props.phoneMode}
                inputWrapper={styles.inputWrapper}
                pickerStyles={styles.picker}
                iconDefination={{
                  prefix: "fas",
                  iconName: "calendar-alt",
                }}
              />
              {this.state.typeOfLog == KEEP_ALIVE && (
                <SelectWithOptions
                  wrapperClass={styles.keepAliveWrapper}
                  optionsList={TYPE_KEEP_ALIVE}
                  defaultOptionTitle={""}
                  label={"keep alive status"}
                  error={false}
                  errMsg={"not valid"}
                  name={"keepAliveStatus"}
                  phoneMode={this.props.phoneMode}
                  onchange={(e) => this.onSelectKeeplAliveHandler(e)}
                  selected={this.state.keepAliveStatus}
                  disabled={this.state.loading}
                />
              )}

              <button
                disabled={this.state.loading}
                className={styles.btn}
                onClick={this.onClickClearDateHandler}
              >
                clear
              </button>
              {this.state.typeOfLog !== KEEP_ALIVE &&
                this.state.typeOfLog != INVALID_LOGS && (
                  <button
                    disabled={this.state.loading}
                    className={styles.btn}
                    onClick={this.onClickSearchDateHandler}
                  >
                    search
                  </button>
                )}
            </div>
            <div className={styles.customMenuWrapper}>
              {this.state.typeOfLog != CHAT_LOGS &&
                this.state.typeOfLog !== KEEP_ALIVE &&
                this.state.typeOfLog !== FENCE && (
                  <Checkbox
                    name="excludeGps"
                    labelMsg={
                      <span style={{ fontSize: "1.4rem" }}>Exclude GPS</span>
                    }
                    value={this.state.excludeGPS}
                    click={this.onChangeGpsCheckHandler}
                    checkBoxWrapper={styles.checkBoxTelemetryWrapper}
                    disabled={this.state.loading}
                  />
                )}
              {!this.props.phoneMode &&
                Controller.isHiskyEntity() &&
                this.state.typeOfLog == INVALID_LOGS && (
                  <CustomMenu
                    disabled={this.state.loading}
                    style={{
                      height: this.props.phoneMode ? "3.5rem" : "",
                      width: "unset",
                      marginRight: "1rem",
                    }}
                    title={this.decodeInvalidLogs[this.state.invalidLogType]}
                    menuItems={[
                      {
                        label: "Good Logs",
                        callback: () => this.onClickTypeOfLogHnadelr(1),
                      },
                      {
                        label: "Bad Logs",
                        callback: () => this.onClickTypeOfLogHnadelr(2),
                      },
                      {
                        label: "All Logs",
                        callback: () => this.onClickTypeOfLogHnadelr(3),
                      },
                    ]}
                  />
                )}
              <CustomMenu
                title="Export"
                style={{
                  height: this.props.phoneMode ? "3.5rem" : "",
                  width: "unset",
                }}
                disable={
                  (this.state.loading || !this.state.totalRecords) &&
                  this.state.typeOfLog !== KEEP_ALIVE &&
                  this.state.typeOfLog !== INVALID_LOGS
                }
                menuItems={
                  this.state.typeOfLog == TELEMETRY_LOGS
                    ? [
                        {
                          label: "EXCEL",
                          callback: () => this.onClickExportHandler("CSV"),
                        },
                        {
                          label: "GOOGLE",
                          callback: () =>
                            this.onClickExportHandler("GOOGLE-MYMAPS"),
                        },
                      ]
                    : [
                        {
                          label: "EXCEL",
                          callback: () => this.onClickExportHandler("CSV"),
                        },
                      ]
                }
              />
            </div>
          </div>
          {Object.keys(this.state.msgDisplay).length > 0 && (
            <div className={styles.msgDisplayWrapper}>
              <div className={styles.topMsg}>
                <span>Index Number:&nbsp;{this.state.indexMsg}</span>
                <span
                  onClick={() => this.onClickMsgHandler({})}
                  style={{
                    marginLeft: "auto",
                    cursor: "pointer",
                    padding: "1rem",
                    fontSize: "1.4rem",
                  }}
                >
                  <Icon defination={{ prefix: "fas", iconName: "times" }} />
                </span>
              </div>
              <div className={styles.breakLine}></div>
              <p className={styles.magDisplay}>{this.state.msgDisplay.body}</p>
            </div>
          )}
          {this.state.typeOfLog !== KEEP_ALIVE &&
            this.state.typeOfLog !== INVALID_LOGS && (
              <div className={styles.wrapperDetails}>
                <div
                  className={styles.tableWrapper}
                  style={{ opacity: 1 }}
                  id="form"
                >
                  <div className={`${styles.tableRow} `} id="tblHeader">
                    {this.buildTableHeaderHandler()}
                  </div>
                  <div
                    className={styles.table}
                    style={{
                      opacity: 1,
                    }}
                  >
                    {this.buildTableRowHandler()}
                    <div
                      className={styles.spinnerWrapper}
                      style={{ display: this.state.loading ? "block" : "none" }}
                    >
                      <SpinnerLoader msg="" />
                    </div>
                  </div>
                </div>
                <Pagination
                  style={styles}
                  totalRecordsPerPage={
                    this.state.data.length > 0
                      ? this.state.data[this.state.activeIndex].length
                      : ""
                  }
                  totalRecords={this.state.totalRecords}
                  totalPages={Math.ceil(this.state.totalRecords / PAGE_LIMIT)}
                  setPage={this.onClickPageNumberHandler}
                  prev={this.onClickPrevHandler}
                  data={this.state.data}
                  next={this.onClickNextHandler}
                  disabled={
                    this.state.loading ||
                    this.state.data.length === 0 ||
                    this.state.showfilter
                  }
                  pageNumber={this.state.activeIndex + 1}
                />
              </div>
            )}
          {this.state.typeOfLog == KEEP_ALIVE && (
            <p className={styles.keepAliveMsg}>
              Keep Alive History is export-only, please select a start and/or
              end date if desired, and click Export to download.
            </p>
          )}
          {this.state.typeOfLog == INVALID_LOGS && (
            <p className={styles.keepAliveMsg}>
              Invalid logs is export-only, please select a start and/or end date
              if desired, and click Export to download.
            </p>
          )}
        </div>
      </>
    );
  }
}
