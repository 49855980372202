import React, { Component } from "react";
import styles from "./FollowVehicle.module.scss";
import { CAR, TRAIL, OFF } from "../../../Util/constants";
import MapButton from "../MapButton/MapButton";
class FollowVehicle extends Component {
  state = {
    text: CAR,
    hidePolytrail: false,
    activeFollowMenu: false,
  };
  componentDidMount() {
    if (this.props.refVehicleMapControl !== undefined) {
      this.props.refVehicleMapControl(this.resetBoundTypeHandler);
    }
  }
  onClickFollowHandler = (type) => {
    this.setState({ text: type, activeFollowMenu: false }, () => {
      this.props.click(type);
    });
  };
  removeTrailHandler = () => {
    this.setState({ hidePolytrail: !this.state.hidePolytrail }, () => {
      this.props.removeTrail();
    });
  };

  /**
   * Ref functions
   */
  resetBoundTypeHandler = () => {
    this.setState({ hidePolytrail: false, text: CAR }, () => {
      this.props.resetAllowRecenter();
    });
  };
  onClickBtnHandler = () => {
    this.setState({ activeFollowMenu: !this.state.activeFollowMenu });
  };
  render() {
    return (
      <>
        <div className={styles.BtnMainFollow} onClick={this.onClickBtnHandler}>
          <span className={styles.menuItem}>{`Follow ${this.state.text}`}</span>

          <ol
            className={
              this.props.phoneMode
                ? !this.state.activeFollowMenu
                  ? styles.closeFollowPhoneMode
                  : styles.openFolllowPhoneMode
                : styles.subMenu
            }
          >
            <li
              className={styles.menuItem}
              onClick={() => this.onClickFollowHandler(CAR)}
            >
              <span>{CAR}</span>
            </li>
            <li
              className={styles.menuItem}
              onClick={() => this.onClickFollowHandler(TRAIL)}
            >
              <span>{TRAIL}</span>
            </li>
            <li
              className={styles.menuItem}
              onClick={() => this.onClickFollowHandler(OFF)}
            >
              <span>{OFF}</span>
            </li>
          </ol>
          {/* </li> */}
        </div>
        <MapButton
          id="showTrail"
          label="Trail"
          btnAction={this.removeTrailHandler}
          customClass={styles.trailBtn}
          showClose={!this.state.hidePolytrail}
        />
        <MapButton
          id="showTrailMobile"
          label=""
          btnAction={this.removeTrailHandler}
          icon={{
            prefix: "far",
            iconName: "road",
          }}
          showClose={false}
          customClass={styles.trailBtnMobile}
          iconClass={
            this.state.hidePolytrail
              ? styles.iconShowMobile
              : `${styles.iconShowMobile} ${styles.activeRoad}`
          }
        />
      </>
    );
  }
}

export default FollowVehicle;
