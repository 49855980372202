import React, { Component } from "react";
import styles from "./RightMarker.module.scss";
import Icon from "../../Icon/Icon";
export default class RightMarker extends Component {
  render() {
    return (
      <div className={styles.RightMarker} onClick={this.props.click}>
        <span className={styles.title} onClick={this.props.click}>
          Delete
        </span>
        <span className={styles.closeIcon} onClick={this.props.cancel}>
          <Icon defination={{ prefix: "fas", iconName: "times" }} />
        </span>
      </div>
    );
  }
}
