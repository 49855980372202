import React, { Component, Fragment } from "react";
import { httpRequest } from "../../../RequestManager/HttpRequest";
import {
  RELOAD_FENCING_LIST,
  START,
  STOP,
  UPDATE_FENCING_LIST,
  UPDATE_VERSION,
  FENCING,
  NOTIFICATION_CONTAINER,
  SOS_MSG,
  BROADCAST_CONTIANER,
  OVERVIEW,
  VEHICLES_OF_FENCE,
  OPEN_FENCING_MENU,
  CLEAR_ALL_ACTION_BOXES,
} from "../../../Util/constants";
import {
  checkVersionApp,
  convertPointsForGoogleMapHandler,
} from "../../../Util/globalFunc";

import GoogleMap from "../GoogleMap";
import {
  getVehiclesForMap,
  getVehiclesForMapAndAccountFencing,
  getVehiclesOnFencing,
} from "../../../RequestManager/GqlBuilderAccount";
import Swal from "sweetalert2";
class GoogleMapWrapper extends Component {
  webSocketNotification = null;
  webSocketBroardcast = null;
  _refVehicleControl = null;
  _refFiltersControl = null;
  _refDisableControls = null;
  _refDisableActions = null;
  _refOverviewArray = null;
  _refFencingDataControl = null;
  _isMounted = true;
  _apiTigger = false;
  _searchKey = "";
  _viewedVehicles = [];
  _selectedVehicle = "";
  timer = null;
  _excludeIds = [];
  state = {
    loading: false,
    markers: [],
    loadingSearch: false,
    groupFilters: [],
    isFiltersOpen: false,
    showLicense: true,
    isimportTrailOn: false,
    importTrailLoader: false,
    accountFencingList: {},
    viewVehicleLookupId: "",
  };
  componentDidMount() {
    //api call
    this.createHttpRequestHandler();
    if (this.props.refSearchControl !== undefined) {
      this.props.refSearchControl(this.createHttpRequestHandler);
    }
  }
  componentWillUnmount() {
    this._isMounted = false;
    clearTimeout(this.timer);
    this.timer = null;
    if (this.webSocketNotification !== null) {
      this.webSocketNotification.close(1000);
    }
    if (this.webSocketBroardcast !== null) {
      this.webSocketBroardcast.close(1000);
    }
    document.removeEventListener("fullscreenchange", () => {});
  }
  /**
   * Http on and off actions
   */
  contralHttpIntervalHandler = (type) => {
    if (type === STOP) {
      clearTimeout(this.timer);
      this.timer = null;
    } else if (type === START) {
      clearTimeout(this.timer);
      this.timer = null;
      this.createHttpRequestHandler();
    }
  };
  updateWebSocketHandler = (socket, type) => {
    if (type == NOTIFICATION_CONTAINER) {
      this.webSocketNotification = socket;
    } else {
      this.webSocketBroardcast = socket;
    }
  };
  clearMapDataHandler = (type) => {
    this.setState(
      {
        markers: [],
        isimportTrailOn: type == STOP,
        importTrailLoader: type == STOP ? true:false,
      },
      () => {
        this._refDisableControls != undefined &&
          this._refDisableControls(this.state.isimportTrailOn);
        this._refDisableActions != undefined &&
          this._refDisableActions(this.state.isimportTrailOn);
        this.props.disableHeaderSearch(this.state.isimportTrailOn);
        this.contralHttpIntervalHandler(type);
      }
    );
  };
  stopLoaderHandler = () => {
    this.setState({ importTrailLoader: false });
  };
  createHttpRequestHandler = (type = "") => {
    if (
      !this._isMounted 
     // (this._refFiltersControl !== null || this._refVehicleControl !== null)
    )
      return;
    //no http call if any model is open, but timer should run.
    if (
      this.props.mode != "" ||
      this.props.disabledInterval ||
      this.state.isimportTrailOn
    ) {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.createHttpRequestHandler();
      }, 5000);
      return;
    }
    let params = {
      include: this.props.searchVehicle,
      exclude: this.state.isFiltersOpen ? this._excludeIds : [],
    };
    this.setState({ loading: true }, () => {
      let query = getVehiclesForMap;
      if (Object.keys(this.state.accountFencingList).length == 0) {
        query = getVehiclesForMapAndAccountFencing;
      }
      httpRequest(query, params)
        .then((res) => {
          if (this._isMounted) {
            let obj = {
              offlineCount: res.data.vehicleMapV2.offlineCount,
              onlineCount: res.data.vehicleMapV2.onlineCount,
              sosCount: res.data.vehicleMapV2.sosCount,
              aliveCount: res.data.vehicleMapV2.aliveCount,
              deadCount: res.data.vehicleMapV2.deadCount,
            };
            if (!checkVersionApp(res.data.systemVersion)) {
              this.props.openModel(UPDATE_VERSION);
            } else {
              if (this._refOverviewArray !== null) {
                this._refOverviewArray(res.data.vehicleMapV2.objs, obj);
              }
              this.setDataHandler(res.data);
            }
          }
        })
        .catch((err) => {});
    });
  };
  apiIsLoadedTriggerHandler = () => {
    this._apiTigger = true;
  };
  fireSwalHandler = (title, text, type) => {
    Swal.fire({
      title: title,
      text: text,
      type: type,
      confirmButtonText: "Ok",
    });
  };
  updateOverViewHandler = (ref) => {
    this._refOverviewArray = ref;
  };
  updateVehicleDataHandler = (data, type) => {
    let copyMarker = [...this.state.markers];
    if (type == FENCING) {
      for (let i = 0; i < copyMarker.length; i++) {
        if (copyMarker[i].lookupId == data) {
          copyMarker[i].showVehicleOutsideFenceAnimation = false;
          copyMarker[i].exitFenceAlert.read = true;
          break;
        }
      }
      this.setState({ markers: copyMarker });
    } else if (type == NOTIFICATION_CONTAINER) {
      let licenseNumber = data.vehicle.licensePlate.number;
      let flag = true;
      for (let i = 0; i < copyMarker.length; i++) {
        if (copyMarker[i].licensePlate.number == licenseNumber) {
          if (data.notificationType == 4) {
            copyMarker[i].isMsg = true;
          }
          this.markerClickHandler(copyMarker[i]);
          flag = false;
          break;
        }
      }
      if (flag) {
        this.fireSwalHandler(
          "No vehicle",
          `Vehicle (${licenseNumber})is not currently on the map`,
          "info"
        );
      }
    } else if (type == SOS_MSG) {
      for (let i = 0; i < copyMarker.length; i++) {
        if (copyMarker[i].lookupId == data) {
          copyMarker[i].sosAlert = null;
          break;
        }
      }
      this.setState({ markers: copyMarker });
    } else if (type == OVERVIEW) {
      for (let i = 0; i < copyMarker.length; i++) {
        if (copyMarker[i].lookupId == data.lookupId) {
          this.markerClickHandler(copyMarker[i]);
          break;
        }
      }
    }
  };
  setDataHandler = (data) => {
    let accountFencingList = { ...this.state.accountFencingList };

    if (data.accountFencing!=undefined && data.accountFencing.objs != null) {
      data.accountFencing.objs.map((fencing) => {
        accountFencingList[fencing.uuid] = {};
        accountFencingList[fencing.uuid].name = fencing.name;
        accountFencingList[fencing.uuid].isActive = fencing.isActive;
        accountFencingList[fencing.uuid].uuid = fencing.uuid;
        accountFencingList[fencing.uuid].createdAt = fencing.createdAt;
        accountFencingList[fencing.uuid].updatedAt = fencing.updatedAt;
        accountFencingList[fencing.uuid].color = fencing.color;
        accountFencingList[fencing.uuid].borderColor = fencing.borderColor;
        accountFencingList[fencing.uuid].type = fencing.type;
        accountFencingList[fencing.uuid].lookupId = fencing.lookupId;
        accountFencingList[fencing.uuid].coordinates =
          convertPointsForGoogleMapHandler(fencing);
      });
    }
    this.setState(
      {
        groupFilters: data.groups.groups,
        markers: this.state.isimportTrailOn ? [] : data.vehicleMapV2.objs,
        importTrailLoader: false,
        accountFencingList: accountFencingList,
      },
      () => {
        if (this._apiTigger) {
          this._refFiltersControl(this.state.groupFilters);
          if (this.props.searchVehicle.length) {
            //clear the viewd vehicles list when serach key chnages
            if (this.props.searchKey != this._searchKey) {
              this._selectedVehicle = "";
              this._viewedVehicles = [];
            }
            if (this.state.markers.length) {
              if (
                this._selectedVehicle == "" ||
                this._selectedVehicle != this.state.markers[0].lookupId
              ) {
                this._refVehicleControl(this.state.markers[0]);
              }
              this._selectedVehicle = this.state.markers[0].lookupId;
            }
            this._searchKey = this.props.searchKey;
          }
        }
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          this.createHttpRequestHandler();
        }, 5000);
      }
    );
  };
  refVehicleControlHandler = (ref) => {
    this._refVehicleControl = ref;
  };

  markerClickHandler = (selectedVehicle) => {
    this.props.manageHeaderView();
    //this.props.clearMapButtonActionsSelector(CLEAR_ALL_ACTION_BOXES);
    this._refFencingDataControl({}, CLEAR_ALL_ACTION_BOXES);
    if (this.props.searchVehicle.length) {
      //storing lookup which is closed
      this._viewedVehicles.push(this._selectedVehicle);
      this._selectedVehicle = "";
      this.props.clearSelectedVehicle();
    }
    this.setState(
      {
        viewVehicleLookupId:
          selectedVehicle !== "" ? selectedVehicle.lookupId : "",
      },
      () => {
        this._refVehicleControl(selectedVehicle);
      }
    );
  };

  /**s.
   * Filters list update
   */
  refFiltersControlsHandler = (ref) => {
    this._refFiltersControl = ref;
    this._refFiltersControl(this.state.groupFilters);
  };
  refDisableControlsHandler = (ref) => {
    this._refDisableControls = ref;
  };
  refDisableActionsHandler = (ref) => {
    //left action buttons
    this._refDisableActions = ref;
  };
  exludeGroupsHandler = (excludeIds) => {
    clearTimeout(this.timer);
    this.timer = null;
    this._excludeIds = excludeIds;
    this.createHttpRequestHandler();
  };
  triggerFilterHandler = (val) => {
    this.setState({ isFiltersOpen: val }, () => {
      //remove the exclude groupids from query and api call immediately if group filter is closed
      if (!this.state.isFiltersOpen) this.createHttpRequestHandler();
    });
  };
  showLicenseHandler = () => {
    this.setState({ showLicense: !this.state.showLicense });
  };
  /**
   *
   * @ FEncing related functions
   */
  refFencingDataControlHandler = (ref) => {
    this._refFencingDataControl = ref; //send action and data
  };
   onClickFencingItemHandler = (uuid) => {
    this._refFencingDataControl({uuid}, OPEN_FENCING_MENU);
  }
  updateFencingDataHandler = (action, fencing) => {
    if (action == UPDATE_FENCING_LIST) {
      let list = { ...this.state.accountFencingList };
      list[fencing.uuid].name = fencing.name;
      list[fencing.uuid].uuid = fencing.uuid;
      list[fencing.uuid].createdAt = fencing.createdAt;
      list[fencing.uuid].updatedAt = fencing.updatedAt;
      list[fencing.uuid].color = fencing.color;
      list[fencing.uuid].borderColor = fencing.borderColor;
      list[fencing.uuid].type = fencing.type;
      list[fencing.uuid].lookupId = fencing.lookupId;
      list[fencing.uuid].coordinates =
        convertPointsForGoogleMapHandler(fencing);
      this.setState({ accountFencingList: list }, () => {});
    } else if (action == RELOAD_FENCING_LIST) {
      this.setState({ accountFencingList: {} }, () => {
        this.contralHttpIntervalHandler(STOP);
        this.createHttpRequestHandler();
      });
    }                         
  };
  /**
   * get vehicles of a fence
   */
  getVehiclesofFenceHandler = (fenceLookupID) => {
    this.contralHttpIntervalHandler(STOP);
    httpRequest(getVehiclesOnFencing, {
      lookupId: fenceLookupID,
    }).then((res) => {
      this._refFencingDataControl(res.data.fence.vehicles, VEHICLES_OF_FENCE);
      this.contralHttpIntervalHandler(START);
    });
  };
  render() {
    return (
      <Fragment>
        <GoogleMap
          updateOverView={this.updateOverViewHandler}
          {...this.props}
          markers={this.state.markers}
          refFiltersControls={this.refFiltersControlsHandler}
          refDisableControls={this.refDisableControlsHandler}
          refDisableActions={this.refDisableActionsHandler}
          refVehicleControl={this.refVehicleControlHandler}
          markerClick={this.markerClickHandler}
          viewVehicleLookupId={this.state.viewVehicleLookupId}
          btnOptions={{
            centerBtn: true,
            filtersBtn: true,
            nmsTool: true,
            showLicense: true,
          }}
          groupFilters={this.state.groupFilters}
          exludeGroups={this.exludeGroupsHandler}
          triggerFilter={this.triggerFilterHandler}
          isFiltersOpen={this.state.isFiltersOpen}
          excludeIds={this._excludeIds}
          updateWebSocket={this.updateWebSocketHandler}
          showLicense={this.state.showLicense}
          showLicenseClick={this.showLicenseHandler}
          apiIsLoadedTrigger={this.apiIsLoadedTriggerHandler}
          contralHttpInterval={this.contralHttpIntervalHandler}
          clearMapData={this.clearMapDataHandler}
          toggoleIosScreen={this.props.toggoleIosScreen}
          phoneMode={this.props.phoneMode}
          importTrailLoader={this.state.importTrailLoader}
          stopLoader={this.stopLoaderHandler}
          updateVehicleData={this.updateVehicleDataHandler}
          accountFencingList={this.state.accountFencingList}
          updateFencingData={this.updateFencingDataHandler}
          getVehiclesofFence={this.getVehiclesofFenceHandler}
          refFencingDataControl={this.refFencingDataControlHandler}
          onClickFencingItem={this.onClickFencingItemHandler}
        ></GoogleMap>
      </Fragment>
    );
  }
}

export default GoogleMapWrapper;
