import React, { Component } from "react";
import {
  DYNAMIC,
  TERMINAL_ID,
  DEVICE_NAME,
  TERMINAL_TYPE,
} from "../../../Util/constants";
import { getTypeOfTerminal } from "../../../Util/globalFunc";
import Icon from "../../Icon/Icon";
import styles from "./DeviceItem.module.scss";
export default class DeviceList extends Component {
  getIconTypeDevice = (device) => {
    if (
      device.terminalType === 1 ||
      device.terminalType == 3 ||
      device.terminalType == 4
    ) {
      return getTypeOfTerminal(device.terminalType);
    } else if (device.terminalType === 2) {
      return DYNAMIC;
    } else {
      return <span>N/A</span>;
    }
  };
  render() {
    const { item, iconType, index, type = "" } = this.props;
    return (
      <div
        id={item.id}
        key={index}
        className={
          item.manuallyAddedElsewhere ? styles.disabledItem : styles.item
        }
        style={{ opacity: 1 }}
      >
        <div className={styles.itemInfoWrapper}>
          <div className={styles.dataWrapper}>
            <span className={styles.dataLabel}>{TERMINAL_ID}:&nbsp;</span>
            <span>{item.externalId ? item.externalId : "N/A"}</span>
          </div>
          <div className={styles.dataWrapper}>
            <span className={styles.dataLabel}>{DEVICE_NAME}:&nbsp;</span>
            <span>{item.deviceName}</span>
          </div>
          
          <div className={styles.dataWrapper}>
            <span className={styles.dataLabel}>Online:&nbsp; </span>
            <span>{item.isOnline ? "Online" : "Offline"}</span>
          </div>
          {item.manuallyAddedElsewhere && (
            <div className={styles.dataWrapper} style={{ fontWeight: "bold" }}>
              <span className={styles.dataLabel}>Assigned To:&nbsp;</span>
              <span>{item.manuallyAddedElsewhereAccount}</span>
            </div>
          )}
        </div>
        {!item.manuallyAddedElsewhere && (
          <div
            className={styles.iconWrapper}
            onClick={() => this.props.click(item, index, type)}
          >
            <span className={styles.icon}>
              <Icon defination={{ prefix: "fal", iconName: iconType }} />
            </span>
          </div>
        )}
      </div>
    );
  }
}
