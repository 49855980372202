import React, { Component, Fragment } from "react";
import Icon from "../../Icon/Icon";
import KeyValue from "../../KeyValue/KeyValue";
import styles from "./VehicleDetails.module.scss";
import Button from "../../../Component/Button/Button";
import LOG_ICON from "../../../assets/log-icon.svg";
import TOGGLE_ON_ICON from "../../../assets/toggleOnIcon.svg";
import TOGGLE_OFF_ICON from "../../../assets/toggleOffIcon.svg";
import KEEP_ALIVE_ON from "../../../assets/keepaliveon.svg";
import KEEP_ALIVE_OFF from "../../../assets/keepaliveoff.svg";
import UserChat from "../UserChat/UserChat";
import SOS from "../../../assets/sos.svg";
import SOS_SEEN from "../../../assets/sos-seen.svg";
import TRAIL_END_POINT from "../../../assets/OtherIconRed.svg";
import {
  INDEX,
  START,
  STOP,
  GRAPHQL_ENDPOINT,
  LATITUDE,
  LONGITUDE,
  TIMSESTAMP,
  SPEED,
  HEADING,
  ROLL,
  GPS,
  INVALID_TOKEN,
  NAME,
  TYPE,
  MODEL,
  MANUFACTURER,
  VIN_NUMBER,
  ADVANCED,
  SIMPLE,
  POLYTRAIL,
  TELEMETRY,
  MINUTE,
  LOCK,
  UNLOCK,
  NOTIFICATION_OPTIONS,
  DEFAULT_MAP_TYPE,
  LNG_HEADERS,
  LAT_HEADERS,
  CONVERT_NUMBER_MILE,
  CONVERT_NUMBER_KPH,
  SOCKET_LOADER,
  LOCATION,
  YEAR,
  SAVE,
  TRACKING_HEADERS,
  HEADING_HEADERS,
  DISABLE_COLOR_FOR_GPS_UNLOCK,
  FENCING,
  SOS_MSG,
  SOS_LOGS,
  ALERT_LOGS,
  CHAT_LOGS,
  TELEMETRY_LOGS,
  LOGS,
  FENCE,
  KEEP_ALIVE,
  HOURS,
  MAX_HOURS,
  MAX_MIN,
  TERMINAL_ID,
  INVALID_LOGS,
  OTHER_ALIAS_NAME,
} from "../../../Util/constants";
import VehicleMap from "../VehicleMap";
import moment from "moment";
import TelemetryHistory from "./TelemetryHistory/TelemetryHistory";
import Slider from "rc-slider";
import "../../../../node_modules/rc-slider/assets/index.css";
import {
  getWsAuthToken,
  lastTelemetryHistory,
  adjustVehicleHeading,
  getWsAuthTokenAndAccountFencing,
  markVehicleAlertRead,
  vehiclePresubscription,
  sendVehicleAlertUpdate,
  vehicleAlertUpdateMessageBypass,
} from "../../../RequestManager/GqlBuilderAccount";
import { httpRequest } from "../../../RequestManager/HttpRequest";
import CarIconSide from "../../../assets/CarIconSide.svg";
import MapButton from "../MapButton/MapButton";
import {
  convertPointsForGoogleMapHandler,
  getInclinationVehicle,
} from "../../../Util/globalFunc";
import ImportFile from "../../ImportFile/ImportFile";
import validator from "validator";
import { ToastContainer, toast } from "react-toastify";
import * as XLSX from "xlsx";
import Swal from "sweetalert2";
import Controller from "../../../Controller";

export default class VehicleDetails extends Component {
  webSocket = "";
  showPromtSOSMsg = true;

  timer = null;
  socket_id = Math.floor(Math.random() * 1000);
  timer_history = null;
  _refVehicleMap = null;
  _refMapTypes = null;
  ref_telemetry = null;
  _vehicle_lookUp = null;
  ref_speed = null;
  heightTimer = null;
  re_init_socket_timer = null;
  start_drive_date = null;
  ref_headingControl = null;
  _isSavedHeading = false;
  _fencingList = {};
  state = {
    showZoneList: false,
    configurationTrail: false,
    typeOfLog: LOGS,
    showInfoVehicle: false,
    lastValidHeading: 0,
    lastValidPosition: {
      latitude: 0,
      longitude: 0,
    },
    terminalLookupId: "",
    vehicleLookupId: "",
    deltaType: 0, ////Change this at @STU
    checkboxTelemetry: false, ///true send 1
    lookupId: "",
    value: 0,
    toggleBtn: SIMPLE,
    error: false,
    inValidInputs: false,
    errorMsg: "",
    showError: false,
    vehicleData: {},
    telemetry: [],
    polytrail: [],
    historyDataLoaded: false,
    loading: false,
    timerPolytrial: false,
    showTelemetryHistory: false,
    showChatUser: false,
    timeOutReset: false,
    inclinationVehicle: 0,
    isTerminalOnline: "",
    isFullScreenMode: false,
    hidePolytrail: false,
    iosFullScreen: true,
    rotateVehicle: 0,
    showFencing: true,
    uploadFile: {
      value: "",
      error: false,
      errMsg: "select file to upload",
      label: "",
      placeholder: "Select file",
      fileName: "",
    },
    isImportTrailOn: false,
    isMapTypeOpen: false,
    activeMapType: DEFAULT_MAP_TYPE,
  };

  componentDidMount() {
    if (this.props.refVehicleControl !== undefined) {
      this.props.refVehicleControl(this.setDataHandler);
    }
    // if (this.props.refFencingControl !== undefined) {
    //   this.props.refFencingControl(this.updateFencingDataHandler);
    // }
    if (this.props.refDisableActions !== undefined) {
      this.props.refDisableActions(this.updateDataHandler);
    }
    document.addEventListener("fullscreenchange", (e) =>
      this.handleKeyPress(e)
    );
    document.addEventListener("mozfullscreenchange", (e) =>
      this.handleKeyPress(e)
    );
    document.addEventListener("webkitfullscreenchange", (e) =>
      this.handleKeyPress(e)
    );
    document.addEventListener("msfullscreenchange", (e) =>
      this.handleKeyPress(e)
    );
    this.setHeightToDefault();
  }

  refVehicleMapControl = (ref) => {
    this._refVehicleMap = ref;
  };
  removeTrailHandler = () => {
    this.setState({ hidePolytrail: !this.state.hidePolytrail });
  };
  refSpeedUdpateHandler = (ref) => {
    this.ref_speed = ref;
  };
  refActiveMapTypeControlHandler = (ref) => {
    this.ref_activeMapType = ref;
  };
  refSetMapTypeControlHandler = (ref) => {
    this.ref_setMapType = ref;
  };
  refHeadingControlHandler = (ref) => {
    this.ref_headingControl = ref;
  };
  updateDataHandler = (data) => {
    this.setState({ isImportTrailOn: data }, () => {});
  };
  setHeightToDefault = () => {
    this.heightTimer = setTimeout(() => {
      if (document.getElementById("vehicleDetailsWrapper") != undefined) {
        document.getElementById("vehicleDetailsWrapper").style.height = "0";
        document.getElementById("vehicleDetailsWrapper").style.zIndex = "unset";
        clearTimeout(this.heightTimer);
        this.heightTimer = null;
      } else {
        this.setHeightToDefault();
      }
    });
  };
  onClickCheckBoxHandler = () => {
    if (this.state.loading) return;
    this.updateSocketSpinnerHandler(START);
    if (!this.state.checkboxTelemetry) {
      this.setState(
        { loading: true, checkboxTelemetry: !this.state.checkboxTelemetry },
        () => {
          this.closeSocketHandelr();
          this.getWsAuthTokenHandler();
        }
      );
    } else {
      this.setState({ loading: true }, () => {
        let telemetry = [...this.state.telemetry];
        let temp = telemetry.splice(0, 1);
        this.setState(
          {
            polytrail: [],
            telemetry: temp,
            loading: false,
            checkboxTelemetry: !this.state.checkboxTelemetry,
          },
          () => {
            this.updateSocketSpinnerHandler(STOP);
          }
        );
      });
    }
  };
  createDetailsElement = () => {
    if (!Object.keys(this.state.vehicleData).length) return;
    let obj = { ...this.state.vehicleData };
    let details = [
      { label: NAME, value: obj.name },
      {
        label: TYPE,
        value:
          obj.vClass.displayName.toLowerCase() == "other"
            ? OTHER_ALIAS_NAME
            : obj.vClass.displayName,
      },
      { label: "License No.", value: obj.licensePlate.number },
      { label: MODEL, value: obj.model },
      { label: YEAR, value: obj.year },
      { label: MANUFACTURER, value: obj.make },
      { label: VIN_NUMBER, value: obj.vin },
      {
        label: LOCATION,
        value: obj.location.address,
      },
    ];
    let list = details.map((item, index) => {
      return (
        <KeyValue
          key={index}
          item={item}
          index={index}
          wrapperStyles={styles.item}
        />
      );
    });
    return list;
  };
  convetDateHandler = (ts, diff = null) => {
    return moment(ts).format("MM-DD-YYYY HH:mm:ss");
  };
  onChangeSelecthandler = (event) => {
    let id = 0;
    if (event.target.value == MINUTE) {
      id = 0;
    } else {
      id = 1;
    }
    this.setState({ deltaType: id, value: 0, inValidInputs: false });
  };
  // updateFencingDataHandler = (fencing) => {
  //   let list = { ...this._fencingList };
  //   list[fencing.uuid].name = fencing.name;
  //   list[fencing.uuid].isActive = fencing.uuid;
  //   list[fencing.uuid].uuid = fencing.uuid;
  //   list[fencing.uuid].createdAt = fencing.createdAt;
  //   list[fencing.uuid].updatedAt = fencing.updatedAt;
  //   list[fencing.uuid].color = fencing.color;
  //   list[fencing.uuid].borderColor = fencing.borderColor;
  //   list[fencing.uuid].type = fencing.type;
  //   list[fencing.uuid].coordinates =
  //     this.covertePointsForGoogleMapHandler(fencing);
  //   this._fencingList = list;
  //   this.setState({ showFencing: false }, () => {});
  // };
  setDataHandler = (vehicleData) => {
    let openChat = vehicleData.isMsg == undefined ? null : true;
    if (
      vehicleData !== "" &&
      this.state.vehicleLookupId !== vehicleData.lookupId
    ) {
      /////set lastvalidPostion
      let headingAdjustment =
        vehicleData.headingAdjustment != null
          ? vehicleData.headingAdjustment
          : 0;
      let lastValidPosition = {
        latitude:
          vehicleData.lastTelemetry !== null
            ? vehicleData.lastValidPosition.latitude
            : vehicleData.location.latitude,
        longitude:
          vehicleData.lastTelemetry !== null
            ? vehicleData.lastValidPosition.longitude
            : vehicleData.location.longitude,
      };
      this._fencingList = {};
      this.setState(
        {
          configurationTrail: false,
          showZoneList: false,
          vehicleLookupId:
            vehicleData.lookupId !== null ? vehicleData.lookupId : "",
          terminalLookupId:
            vehicleData.terminal != null ? vehicleData.terminal.lookupId : "",
          vehicleData: vehicleData,
          loading:
            vehicleData.terminal != null && vehicleData.terminal.lookupId != ""
              ? true
              : false,
          telemetry: [],
          polytrail: [],
          inclinationVehicle: 0,
          historyDataLoaded: false,
          showError: false,
          showTelemetryHistory: false,
          checkboxTelemetry: false,
          hidePolytrail: false,
          toggleBtn: SIMPLE,
          value: 0,
          deltaType: 0,
          lastValidHeading:
            vehicleData.lastValidHeading !== null
              ? vehicleData.lastValidHeading.heading
              : 0,
          rotateVehicle: headingAdjustment,
          lastValidPosition: lastValidPosition,
          showFencing: true,
          showChatUser: false,
          showInfoVehicle: false,
          invalidMsg: false,
        },
        () => {
          this._refVehicleMap != undefined && this._refVehicleMap();
          this.ref_activeMapType != undefined &&
            this.ref_activeMapType(DEFAULT_MAP_TYPE);
          this.ref_setMapType != undefined && this.ref_setMapType();
          this.ref_headingControl != undefined &&
            this.ref_headingControl(
              headingAdjustment / 90,
              this.state.vehicleData
            );
          if (document.getElementById("vehicleDetailsWrapper") != undefined) {
            document.getElementById("vehicleDetailsWrapper").style.height =
              "100%";
            document.getElementById("vehicleDetailsWrapper").style.zIndex =
              "100";
          }
          if (this.state.vehicleLookupId != "") {
            this.showPromtSOSMsg = true;
            this.reset_timer = null;
            clearTimeout(this.reset_timer);
            this.closeSocketHandelr();
            this.ref_telemetry != undefined && this.ref_telemetry([], "");
            this.ref_speed != undefined && this.ref_speed({});
            this.start_drive_date = null;
            this.start_drive_date = new Date();
            this.getWsAuthTokenHandler(openChat);
          } else {
            this.closeSocketHandelr();
          }
        }
      );
    } else {
      this.setState({ showChatUser: openChat == null ? false : true });
    }
  };
  closeSocketHandelr = () => {
    if (this.webSocket !== "") {
      this.webSocket.close(1000);
    }
  };
  componentWillUnmount() {
    if (this.webSocket !== "") {
      clearTimeout(this.timer);
      this.closeSocketHandelr();
      this.ref_telemetry([], "");
    }
    if (
      document.getElementById("vehicleDetailsWrapper") === null ||
      document.getElementById("vehicleDetailsWrapper") == null
    )
      return;
    document.getElementById("vehicleDetailsWrapper").style.height = "0";
    document.getElementById("vehicleDetailsWrapper").style.zIndex = "unset";
  }

  getWsAuthTokenHandler = (openChat = null) => {
    this.props.contralHttpInterval(STOP);
    httpRequest(vehiclePresubscription, {
      lookupId: this.state.vehicleLookupId,
      delta:
        this.state.deltaType == 0
          ? parseInt(this.state.value)
          : parseInt(this.state.value) * 60,
      startAt: this.converDateTimeUtcHandler(),
      limitRecords: this.state.checkboxTelemetry ? 1 : 0,
      deltaType: this.state.deltaType,
      display: this.state.toggleBtn === SIMPLE ? TELEMETRY : POLYTRAIL,
    })
      .then((res) => {
        this.props.contralHttpInterval(START);
        let list = { ...this._fencingList };
        res.data.fencesForVehicle.objs.map((fencing, index) => {
          list[fencing.uuid] = {};
          list[fencing.uuid].name = fencing.name;
          list[fencing.uuid].uuid = fencing.uuid;
          list[fencing.uuid].isActive = fencing.isActive;
          list[fencing.uuid].createdAt = fencing.createdAt;
          list[fencing.uuid].updatedAt = fencing.updatedAt;
          list[fencing.uuid].color = fencing.color;
          list[fencing.uuid].borderColor = fencing.borderColor;
          list[fencing.uuid].type = fencing.type;
          list[fencing.uuid].lookupId = fencing.lookupId;
          list[fencing.uuid].coordinates =
            convertPointsForGoogleMapHandler(fencing);
        });
        this._fencingList = list;
        this.socket_id = Math.floor(Math.random() * 1000);
        let obj = this.setDataPreSocektHandler(res.data.vehiclePresubscription);
        this.setState(
          {
            showChatUser: openChat !== null ? true : false,
            lastValidPosition: obj.lastValidPosition,
            telemetry: obj.telemetry,
            lastValidHeading: obj.lastValidHeading,
            isTerminalOnline: obj.isTerminalOnline,
            rotateVehicle: obj.rotateVehicle,
            polytrail: obj.polytrail,
            vehicleData: obj.vehicleData,
            loading: false,
            timerPolytrial: false,
          },
          () => {
            this.ref_telemetry(
              this.state.telemetry,
              this.state.isTerminalOnline,
              this.state.vehicleData
            );
            this.ref_speed({
              lastTelemetry: this.state.telemetry.length
                ? this.state.telemetry[0]
                : {},
              speed: this.state.telemetry.length
                ? this.state.telemetry[0].speed
                : 0,
              vehicleData: Object.keys(this.state.vehicleData).length
                ? this.state.vehicleData
                : {},
              icon: this.getInclinationVehicleHandler(),
            });
            let headingAdjustment =
              this.state.vehicleData.headingAdjustment != null
                ? this.state.vehicleData.headingAdjustment
                : 0;
            this.ref_headingControl(
              headingAdjustment / 90,
              this.state.vehicleData
            );
            this.updateSocketSpinnerHandler(STOP);
            this.createWebSocketConnectionHandler(res.data.generateWsToken);
            if (
              this.showPromtSOSMsg &&
              this.state.vehicleData.sosAlert !== null &&
              this.state.vehicleData.sosAlert !== null &&
              this.state.vehicleData.sosAlert.alertVehiclealertupdatemessage !==
                null &&
              this.state.vehicleData.sosAlert.alertVehiclealertupdatemessage
                .status == 3
            ) {
              this.showPromtSOSMsg = false;
              this.onClickDismissSosAlertAHandler();
            }
          }
        );
      })
      .catch((res) => {});
  };
  checkAndTrimPoliytrialArrayTimeHandler = (arr) => {
    let datetime = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");

    let datePlus = moment(moment(datetime).subtract(MAX_HOURS, "hours")).format(
      "YYYY-MM-DD HH:mm:ss"
    );

    for (let i = arr.length - 1; i > 0; i--) {
      let local = moment(arr[i].createdAt).format("YYYY-MM-DD HH:mm:ss");
      if (moment(local).isBefore(datePlus)) {
        arr.splice(i, 1);
      } else {
        break;
      }
    }

    return arr;
  };
  setDataPreSocektHandler = (data) => {
    let lastValidPosition = {
      latitude:
        data.lastValidPosition !== null
          ? data.lastValidPosition.latitude
          : this.state.lastValidPosition.latitude,
      longitude:
        data.lastValidPosition !== null
          ? data.lastValidPosition.longitude
          : this.state.lastValidPosition.longitude,
    };
    let lastValidHeading =
      data.lastValidHeading != null
        ? data.lastValidHeading.heading
        : this.state.lastValidHeading;

    return {
      lastValidPosition: lastValidPosition,
      lastValidHeading: lastValidHeading,
      vehicleData: data.vehicle,
      polytrail: data.polytrail,
      inclinationVehicle: data.telemetry.length
        ? data.telemetry[0].inclination
        : 0,
      isTerminalOnline: data.vehicle.terminal.isOnline,
      rotateVehicle: data.vehicle.headingAdjustment,
      telemetry: data.telemetry,
    };
  };
  setRefTelemetryOnFullscreenHandler = (ref) => {
    this.ref_telemetry = ref;
  };
  toggoleIosScreenHandler = () => {
    this.setState({ iosFullScreen: !this.state.iosFullScreen }, () => {
      this.props.toggoleIosScreenDash();
    });
  };
  converDateTimeUtcHandler = () => {
    return moment(
      moment.utc(this.start_drive_date, "YYYY-MM-DD HH:mm:ss")
    ).toISOString();
  };
  createWebSocketConnectionHandler = (wsAuthToken) => {
    clearTimeout(this.timer);
    var webSocket = new WebSocket(GRAPHQL_ENDPOINT, "graphql-ws");
    webSocket.onopen = () => {
      this.webSocket = webSocket;
      webSocket.send(
        JSON.stringify({
          type: START,
          id: this.socket_id,
          payload: {
            query:
              "subscription ($lookupId:String,$wsAuthToken:String,$display:String) {\n  vehicleSubscription(display:$display,lookupId:$lookupId,wsAuthToken:$wsAuthToken) {\n  lastValidHeading{\n heading\n}\n lastValidPosition{\n latitude\n longitude\n }\n  polytrail {\n      longitude\n   createdAt\n   latitude\n heading\n  transparent\n }\n    telemetry {\n      longitude\n      latitude\n      latitude\n      longitude\n      inclination\n    heading\n   createdAt\n      speed\n  gpsFixStatus\n inclinationSide\n  }\n    vehicle {\n fences{name\n color\nuuid\n}\n avatar\nsosAlert{lookupId\nalertVehiclealertupdatemessage{\nstatus\n}\n }\n newMessageAlert{isActive\nlookupId\n}\nshowVehicleOutsideFenceAnimation\nvehicleIsOutsideFence\n exitFenceAlert{lookupId\n read\n}\n headingAdjustment  lastTelemetry{heading\n latitude\n longitude\n}\n  speedUnit\n speedUnitStr\n licensePlate{number}    name\n   model\n   vin\n      lookupId\n      updatedAt\n      make\n      name\n year\n terminal{isOnline\n externalId\n lookupId\nisAlive\n}\n    location {\n  address\n      latitude\n        longitude\n        country\n      }\n      vClass {\n        displayName\n        lookupId\n  nameLower\n usesRoads\n   }\n    }\n  }\n}\n",
            variables: {
              lookupId: this.state.vehicleLookupId,
              wsAuthToken: wsAuthToken,
              display: this.state.toggleBtn === SIMPLE ? TELEMETRY : POLYTRAIL,
            },
          },
        })
      );
      webSocket.onerror = (event) => {
        this.resetSocketHandler();
      };
      webSocket.onmessage = (event) => {
        this.setVehicleDetails(JSON.parse(event.data));
      };
    };
  };
  saveHeadingAdjustmentHandler = (headingAdj, type = null) => {
    if (type == SAVE) {
      this.updateSocketSpinnerHandler(START);
      this.setState({ loading: true }, () => {
        let params = {
          vehicleLookupId: this.state.vehicleData.lookupId,
          headingAdjustment: headingAdj,
        };
        //stop the scoket????????
        this.props.contralHttpInterval(STOP);
        httpRequest(adjustVehicleHeading, params)
          .then((res) => {
            this.setState({ loading: false }, () => {
              this.updateSocketSpinnerHandler(STOP);
              this.props.contralHttpInterval(START);
            });
          })
          .catch((res) => {});
      });
    } else {
      this.setState({ rotateVehicle: headingAdj }, () => {
        this.ref_headingControl(headingAdj / 90, this.state.vehicleData);
      });
    }
  };

  resetSocketHandler = () => {
    if (this.state.terminalLookupId !== "") {
      this.setState({ loading: true }, () => {
        this.closeSocketHandelr();
        this.getWsAuthTokenHandler();
      });
    }
  };
  getLastTelemetryHistoryHandler = () => {
    this.closeSocketHandelr();
    this.props.contralHttpInterval(STOP);
    httpRequest(lastTelemetryHistory, {
      lookup_id: this.state.terminalLookupId,
    })
      .then((res) => {
        this.props.contralHttpInterval(START);
        this.setState(
          {
            telemetry: res.data.lastTelemetryHistory,
            timeOutReset:
              res.data.lastTelemetryHistory.length === 0 ? true : false,
            loading: false,
            historyDataLoaded: true,
            inclinationVehicle: res.data.lastTelemetryHistory.length
              ? res.data.lastTelemetryHistory[0].inclination
              : 0,
            timerPolytrial: false,
          },
          () => {}
        );
      })
      .catch((err) => {});
  };
  convertGpsDataHandler = (Gps) => {
    if (Gps == null || parseInt(Gps) === 0) {
      return UNLOCK;
    } else {
      return LOCK;
    }
  };

  updateSocketSpinnerHandler = (type) => {
    let elem = document.getElementById(SOCKET_LOADER);
    if (elem != null) {
      if (type == START) elem.style.display = "block";
      else {
        elem.style.display = "none";
      }
    }
    if (document.getElementsByClassName("gm-style")[1] !== null) {
      if (type == START)
        document.getElementsByClassName("gm-style")[1].style.opacity = "0.6";
      else document.getElementsByClassName("gm-style")[1].style.opacity = "1";
    }
  };
  setVehicleDetails = (data) => {
    if (
      data.payload !== undefined &&
      data.payload.data !== null &&
      data.payload.data !== undefined &&
      data.payload.data.vehicleSubscription !== null
    ) {
      let socketData = data.payload.data.vehicleSubscription;
      this._poliLineId = this.state.terminalLookupId;
      this.updateSocketSpinnerHandler(STOP);
      let vehicleDataCopy = JSON.parse(JSON.stringify(this.state.vehicleData));
      if (socketData.vehicle != null && Object.keys(socketData.vehicle).length)
        vehicleDataCopy = socketData.vehicle;

      let lastValidPosition = {
        latitude:
          socketData.lastValidPosition !== null
            ? socketData.lastValidPosition.latitude
            : this.state.lastValidPosition.latitude,
        longitude:
          socketData.lastValidPosition !== null
            ? socketData.lastValidPosition.longitude
            : this.state.lastValidPosition.longitude,
      };
      let polytrailArr = this.checkAndTrimPoliytrialArrayTimeHandler([
        ...this.state.polytrail,
      ]);
      this.setState(
        {
          telemetry:
            socketData.telemetry != null
              ? socketData.telemetry
              : this.state.telemetry,
          timerPolytrial: false,
          polytrail:
            socketData.polytrail == null
              ? this.state.polytrail
              : [...socketData.polytrail, ...polytrailArr],
          loading: false,
          timeOutReset: false,
          inclinationVehicle:
            socketData.telemetry !== null
              ? socketData.telemetry[0].inclination
              : this.state.inclinationVehicle,
          isTerminalOnline: socketData.vehicle.terminal.isOnline,
          showError: false,
          vehicleData: vehicleDataCopy,
          lastValidHeading:
            socketData.lastValidHeading !== null
              ? socketData.lastValidHeading.heading
              : this.state.lastValidHeading,
          lastValidPosition: lastValidPosition,
          rotateVehicle: socketData.vehicle.headingAdjustment,
        },
        () => {
          this.ref_telemetry(
            this.state.telemetry,
            this.state.isTerminalOnline,
            this.state.vehicleData
          );
          this.ref_speed({
            lastTelemetry: this.state.telemetry.length
              ? this.state.telemetry[0]
              : {},
            speed: this.state.telemetry.length
              ? this.state.telemetry[0].speed
              : 0,
            vehicleData: Object.keys(this.state.vehicleData).length
              ? this.state.vehicleData
              : {},
            icon: this.getInclinationVehicleHandler(),
          });
          let headingAdjustment =
            this.state.vehicleData.headingAdjustment != null
              ? this.state.vehicleData.headingAdjustment
              : 0;
          this.ref_headingControl(
            headingAdjustment / 90,
            this.state.vehicleData
          );

          if (
            this.showPromtSOSMsg &&
            this.state.vehicleData.sosAlert !== null &&
            this.state.vehicleData.sosAlert !== null &&
            this.state.vehicleData.sosAlert.alertVehiclealertupdatemessage !==
              null &&
            this.state.vehicleData.sosAlert.alertVehiclealertupdatemessage
              .status == 3
          ) {
            this.showPromtSOSMsg = false;
            this.onClickDismissSosAlertAHandler();
          }
          this.clearInitSocketTimerHandler();
          this.re_init_socket_timer = setTimeout(() => {
            this.resetSocketHandler();
          }, 1800000);
        }
      );
    } else if (data.payload.errors !== undefined) {
      this.updateSocketSpinnerHandler(STOP);
      let msg = data.payload.errors[0].message;
      this.closeSocketHandelr();
      this.clearInitSocketTimerHandler();
      this.setState(
        { loading: true, errorMsg: msg, showError: true },
        () => {}
      );
    }
  };

  buildTableHeaderHandler = () => {
    return [
      INDEX,
      TIMSESTAMP,
      LATITUDE,
      LONGITUDE,
      SPEED,
      GPS,
      ROLL,
      HEADING,
    ].map((item, index) => {
      return (
        <div key={index} className={styles.textCell}>
          <strong>{item}</strong>
        </div>
      );
    });
  };
  getBackgroundColor = (item) => {
    if (parseInt(item.gpsFixStatus) === 0) {
      //UNLOCK
      return { backgroundColor: DISABLE_COLOR_FOR_GPS_UNLOCK, color: "#000" };
    } else {
      return { backgroundColor: "#fff" };
    }
  };
  buildTableRowHandler = () => {
    if (this.state.telemetry.length === 0 && !this.state.loading) {
      return <div className={styles.noDataRow}>No telemetry data found</div>;
    } else {
      return this.state.telemetry.map((item, index) => {
        return (
          <div
            className={styles.tableRow}
            key={index}
            style={this.getBackgroundColor(item)}
          >
            <div className={styles.textCell}>{index + 1}</div>
            <div className={`${styles.textCell} ${styles.dateTooltip} `}>
              <span className={styles.toolTip}>
                {this.convetDateHandler(item.createdAt)}
              </span>
              {this.convetDateHandler(item.createdAt, "diff")}
            </div>

            <div className={styles.textCell}>
              {parseFloat(item.latitude).toFixed(3)}&#xb0;
            </div>
            <div className={styles.textCell}>
              {parseFloat(item.longitude).toFixed(3)}&#xb0;
            </div>

            <div className={styles.textCell}>
              {this.state.vehicleData.speedUnit == 1
                ? parseFloat(item.speed).toFixed(1)
                : this.state.vehicleData.speedUnit == 2
                ? parseFloat(item.speed * CONVERT_NUMBER_MILE).toFixed(1)
                : parseFloat(item.speed * CONVERT_NUMBER_KPH).toFixed(1)}
              <span className={styles.subTitle}>
                {this.state.vehicleData.speedUnitStr}
              </span>
            </div>
            <div className={`${styles.textCell}  ${styles.gpsTooltip}`}>
              <span className={styles.toolTip}>
                {this.convertGpsDataHandler(item.gpsFixStatus)}
              </span>
              {this.convertGpsDataHandler(item.gpsFixStatus)}
            </div>
            <div className={styles.textCell}>
              {item.inclination.toFixed(1)}&#xb0;
            </div>
            <div className={styles.textCell}>
              {item.heading.toFixed(1)}&#xb0;
            </div>
          </div>
        );
      });
    }
  };
  getTableDataHandler = () => {
    return (
      <>
        <div className={styles.tableWrapper} style={{ opacity: 1 }} id="form">
          <div className={`${styles.tableRow} `} id="tblHeader">
            {this.buildTableHeaderHandler()}
          </div>
          <div
            className={styles.table}
            style={{
              opacity: 1,
            }}
          >
            {this.buildTableRowHandler()}
          </div>
        </div>
      </>
    );
  };
  onClickAppyTimeHandler = () => {
    if (
      (!parseInt(this.state.value) && this.state.value.toString() !== "0") ||
      parseInt(this.state.value) < 0 ||
      (parseFloat(this.state.value) > 180 && this.state.deltaType == 0) ||
      (this.state.deltaType == 1 && parseFloat(this.state.value) > 3)
    ) {
      this.setState({ error: true, inValidInputs: true }, () => {});
    } else {
      this.setState({ timerPolytrial: true, historyDataLoaded: false }, () => {
        this.updateSocketSpinnerHandler(START);
        this.clearInitSocketTimerHandler();
        this.closeSocketHandelr();
        this.getWsAuthTokenHandler();
      });
    }
  };
  clearInitSocketTimerHandler = () => {
    clearTimeout(this.re_init_socket_timer);
    this.re_init_socket_timer = null;
  };
  onCloseVechileDetialHandler = () => {
    this.closeSocketHandelr();
    this.clearInitSocketTimerHandler();
    this.updateSocketSpinnerHandler(STOP);
    clearTimeout(this.timer);
    this.timer = null;
    this.start_drive_date = null;
    this._refVehicleMap();
    this.showPromtSOSMsg = true;
    this.ref_speed({});
    this.reset_timer = null;
    this._fencingList = {}; ///added this
    clearTimeout(this.reset_timer);
    this.setState(
      {
        showInfoVehicle: false,
        terminalLookupId: "",
        timerPolytrial: false,
        polytrail: [],
        vehicleLookupId: "",
        telemetry: [],
        invalidMsg: false,
        vehicleData: {},
        loading: false,
        timeOutReset: false,
        showTelemetryHistory: false,
        showChatUser: false,
        value: 0,
        inclinationVehicle: 0,
        historyDataLoaded: false,
        isTerminalOnline: "",
        showError: false,
        errorMsg: "",
        deltaType: 0,
        checkboxTelemetry: false,
        hidePolytrail: false,
        toggleBtn: SIMPLE,
        value: 0,
        deltaType: 0,
        activeMapType: DEFAULT_MAP_TYPE,
        showChatUser: false,
      },
      () => {
        this.props.contralHttpInterval(START);
        this.props.closeVehicleDetails("");
        document.getElementById("vehicleDetailsWrapper").style.height = "0";
        document.getElementById("vehicleDetailsWrapper").style.zIndex = "unset";
      }
    );
  };
  canUseRoadsHandler = () => {
    if (this.state.vehicleData.vClass != undefined) {
      return this.state.vehicleData.vClass.usesRoads;
    }
  };
  onClickToggleBtnHandler = () => {
    if (this.state.loading) return;
    let type = this.state.toggleBtn == ADVANCED ? SIMPLE : ADVANCED;
    this.updateSocketSpinnerHandler(START);
    this.setState(
      {
        toggleBtn: type,
        loading: true,
      },
      () => {
        this.re_init_socket_timer = null;
        clearTimeout(this.re_init_socket_timer);
        this.closeSocketHandelr();
        this.getWsAuthTokenHandler();
      }
    );
  };
  onChangeInputTimeHandler = (val) => {
    this.setState({ value: val, error: false });
  };
  onChangeInputValTimeHandler = (event) => {
    let copyDeltaMins = { ...this.state.value };
    copyDeltaMins = event.target.value;
    this.setState({ value: copyDeltaMins, error: false, inValidInputs: false });
  };
  toggleHistoryDetails = (type) => {
    if (this.state.loading || this.state.vehicleData.terminal == null) return;
    this.setState(
      {
        showTelemetryHistory: !this.state.showTelemetryHistory,
        timeOutReset: false,
        typeOfLog: type,
      },
      () => {
        if (this.state.showTelemetryHistory) {
          this.props.contralHttpInterval(STOP);
        } else {
          this.props.contralHttpInterval(START);
        }
      }
    );
  };
  onCloseErrorHandler = () => {
    if (this.state.errorMsg == INVALID_TOKEN) {
      this.closeSocketHandelr();
      this.setState({ loading: true, showError: false, errorMsg: "" }, () => {
        this.getWsAuthTokenHandler();
      });
    } else {
      this.onCloseVechileDetialHandler();
    }
  };
  getTelemetryLocationHandler = () => {
    if (Object.keys(this.state.vehicleData)) {
      const { vehicleData } = this.state;
      const { telemetry } = this.state;
      if (telemetry.length) {
        return [
          {
            latitude: parseFloat(telemetry[0].latitude),
            longitude: parseFloat(telemetry[0].longitude),
            heading: telemetry[0].heading, //prev:1, current:0
          },
        ];
      } else if (Object.keys(vehicleData).length) {
        return [
          {
            latitude: parseFloat(vehicleData.location.latitude),
            longitude: parseFloat(vehicleData.location.longitude),
            heading: null,
          },
        ];
      }
    } else {
      return [
        {
          latitude: 51.5074,
          longitude: 0.1278,
          heading: null,
        },
      ];
    }
  };

  onClickShowLogTypesHandler = (type) => {
    this.setState({ showTelemetryHistory: true, typeOfLog: type });
  };
  getMessageSOSByTypeHandler = () => {
    let sos = this.state.vehicleData.sosAlert;
    if (sos.alertVehiclealertupdatemessage == null) {
      return "Would you like to send assistance?";
    } else if (
      sos.alertVehiclealertupdatemessage.status == 1 ||
      sos.alertVehiclealertupdatemessage.status == 6 ||
      sos.alertVehiclealertupdatemessage.status == 2
    ) {
      return "You are going to confirm that SOS has concluded ";
    } else {
      return "Retry sending SOS assistance";
    }
  };
  onClickDismissSosAlertAHandler = () => {
    if (this.state.loading || Controller.getIsUser()) return;

    this.setState({ loading: true }, () => {
      if (
        this.state.vehicleData.sosAlert.alertVehiclealertupdatemessage ==
          null ||
        this.state.vehicleData.sosAlert.alertVehiclealertupdatemessage.status ==
          6 ||
        this.state.vehicleData.sosAlert.alertVehiclealertupdatemessage.status ==
          1 ||
        this.state.vehicleData.sosAlert.alertVehiclealertupdatemessage.status ==
          2
      ) {
        let msgSos = this.getMessageSOSByTypeHandler();
        Swal.fire({
          title: "Are you sure?",
          text: msgSos,
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#00a0dc",
          cancelButtonColor: "#ff1a1a",
          confirmButtonText: "Yes.",
        }).then((res) => {
          if (res.dismiss !== "cancel" && res.dismiss !== "backdrop") {
            this.props.contralHttpInterval(STOP);
            let query =
              this.state.vehicleData.sosAlert.alertVehiclealertupdatemessage ==
                null ||
              this.state.vehicleData.sosAlert.alertVehiclealertupdatemessage
                .status == 3
                ? sendVehicleAlertUpdate
                : markVehicleAlertRead;
            httpRequest(query, {
              alertLookupId: this.state.vehicleData.sosAlert.lookupId,
            })
              .then((res) => {
                this.props.contralHttpInterval(START);
                let copyVehicleData = { ...this.state.vehicleData };
                if (
                  this.state.vehicleData.sosAlert
                    .alertVehiclealertupdatemessage != null &&
                  (this.state.vehicleData.sosAlert
                    .alertVehiclealertupdatemessage.status == 1 ||
                    this.state.vehicleData.sosAlert
                      .alertVehiclealertupdatemessage.status == 2)
                ) {
                  copyVehicleData.sosAlert = null;
                } else if (
                  this.state.vehicleData.sosAlert
                    .alertVehiclealertupdatemessage == null ||
                  res.data.sendVehicleAlertUpdate.alert
                    .alertVehiclealertupdatemessage.status != 1
                ) {
                  copyVehicleData.sosAlert.alertVehiclealertupdatemessage.status =
                    res.data.sendVehicleAlertUpdate.alert.alertVehiclealertupdatemessage.status;
                }

                this.setState(
                  { loading: false, vehicleData: copyVehicleData },
                  () => {
                    if (
                      this.state.vehicleData.sosAlert != null &&
                      this.state.vehicleData.sosAlert
                        .alertVehiclealertupdatemessage != null &&
                      (this.state.vehicleData.sosAlert
                        .alertVehiclealertupdatemessage.status == 1 ||
                        this.state.vehicleData.sosAlert
                          .alertVehiclealertupdatemessage.status == 2)
                    ) {
                      this.props.updateVehicleData(
                        this.state.vehicleData.lookupId,
                        SOS_MSG
                      );
                    }
                  }
                );
              })
              .catch((err) => {});
          } else {
            this.setState({ loading: false });
          }
        });
      } else if (
        this.state.vehicleData.sosAlert.alertVehiclealertupdatemessage !==
          null &&
        this.state.vehicleData.sosAlert.alertVehiclealertupdatemessage.status ==
          0
      ) {
        this.fireSwalHandler(
          "Waiting for response",
          "Message has been sent",
          "info"
        );
      } else if (
        this.state.vehicleData.sosAlert.alertVehiclealertupdatemessage !==
          null &&
        this.state.vehicleData.sosAlert.alertVehiclealertupdatemessage.status ==
          3
      ) {
        Swal.fire({
          title: "Failed to reach the vehicle",
          // text: "Vehicle is not responding. You can bypass or try again",
          showCancelButton: false,
          showConfirmButton: false,
          showConfirmButton: false,
          type: "info",
          html:
            "Vehicle is not responding you can bypass or try again" +
            "<br>" +
            `<button type="button" role="button" tabindex="0"   id="cancel"  class="${styles.btnSwalMultiCancel}">` +
            "Cancel" +
            "</button>" +
            `<button type="button" role="button" tabindex="1" id="bypass" class="${styles.btnSwalMultiBypass}">` +
            "Yes, Bypass" +
            "</button>" +
            `<button type="button" role="button" tabindex="3"  id="retry" class="${styles.btnSwalMultiRetry}">` +
            "No, Retry" +
            "</button>",
          onBeforeOpen: () => {
            const cancel = document.getElementById("cancel");
            const bypass = document.getElementById("bypass");
            const retry = document.getElementById("retry");

            retry.addEventListener("click", () => {
              this.onClickFaliedStatusUpdate("retry");
            });

            bypass.addEventListener("click", () => {
              this.onClickFaliedStatusUpdate("bypass");
            });

            cancel.addEventListener("click", () => {
              this.onClickFaliedStatusUpdate("cancel");
            });
          },
        });
      }
    });
  };
  onClickFaliedStatusUpdate = (type) => {
    let copyVehicleData = { ...this.state.vehicleData };
    if (type == "cancel") {
      this.setState({ loading: false }, () => {
        Swal.close();
      });
    } else if (type == "retry") {
      this.setState({ loading: true }, () => {
        this.props.contralHttpInterval(STOP);
        httpRequest(sendVehicleAlertUpdate, {
          alertLookupId: this.state.vehicleData.sosAlert.lookupId,
        })
          .then((res) => {
            copyVehicleData.sosAlert.alertVehiclealertupdatemessage.status =
              res.data.sendVehicleAlertUpdate.alert.alertVehiclealertupdatemessage.status;
            this.props.contralHttpInterval(START);
            this.showPromtSOSMsg = true; /////change
            this.setState(
              { loading: false, vehicleData: copyVehicleData },
              () => {
                Swal.close();
              }
            );
          })
          .catch((err) => {});
      });
    } else if (type == "bypass") {
      this.setState({ loading: true }, () => {
        this.props.contralHttpInterval(STOP);
        httpRequest(vehicleAlertUpdateMessageBypass, {
          alertLookupId: this.state.vehicleData.sosAlert.lookupId,
        })
          .then((res) => {
            copyVehicleData.sosAlert.alertVehiclealertupdatemessage.status =
              res.data.vehicleAlertUpdateMessageBypass.alert.status;
            this.props.contralHttpInterval(START);
            this.setState(
              { loading: false, vehicleData: copyVehicleData },
              () => {
                Swal.close();
              }
            );
          })
          .catch((err) => {});
      });
    }
  };
  onClickInfoVehicleHandler = () => {
    this.setState({
      showInfoVehicle: !this.state.showInfoVehicle,
      configurationTrail: false,
      showZoneList: false,
    });
  };
  fireSwalHandler = (title, text, type) => {
    Swal.fire({
      title: title,
      html: text,
      type: type,
    }).then((res) => {
      this.setState({ loading: false });
    });
  };
  getInclinationVehicleHandler = () => {
    let icon = CarIconSide;
    if (this.state.vehicleData.vClass != undefined) {
      icon = getInclinationVehicle(this.state.vehicleData.vClass.lookupId);
    }
    return icon;
  };
  /**
   * full screen control functions
   */
  handleKeyPress = (event) => {
    if (!document.fullscreen && this.state.isFullScreenMode) {
      this.setState({ isFullScreenMode: false }, () => {
        this.props.handleESCPress(); //ref
      });
    }
  };
  showFullscreenHandler = () => {
    this.setState(
      {
        isFullScreenMode: !this.state.isFullScreenMode,
      },
      () => {
        this.props.fullScreenMode(); //ref
      }
    );
  };
  onClickUserChatHandler = () => {
    this.setState({ showChatUser: !this.state.showChatUser }, () => {
      this.props.clearAllActionsOnMap();
    });
  };
  checkFileValidHandler = (file) => {
    if (file.length) {
      let acceptedFiles = ["csv", "xls", "xlsx"];
      let len = file[0].name.split(".").length - 1;
      let typeOfFile = file[0].name.split(".")[len].toLowerCase();
      return acceptedFiles.includes(typeOfFile);
    } else {
      return false;
    }
  };
  checkValidInputsHandler = (event) => {
    let isValid = true;
    let uploadFileCopy = { ...this.state.uploadFile };
    if (uploadFileCopy.value === "" || uploadFileCopy.value.size === 0) {
      isValid = false;
      uploadFileCopy.error = true;
    } else if (this.checkFileValidHandler(event.target.files)) {
      uploadFileCopy.value = event.target.files[0];
      uploadFileCopy.error = false;
      uploadFileCopy.fileName = event.target.files[0].name;
    } else {
      uploadFileCopy.error = true;
    }
    this.setState({
      uploadFile: uploadFileCopy,
    });
    return isValid;
  };
  createListOfZonesHandler = () => {
    if (!Object.keys(this._fencingList).length)
      return <p>There are no zone assign</p>;
    return (
      <div className={styles.zoneList}>
        {Object.values(this._fencingList).map((item, index) => {
          return (
            <span className={styles.zoneItemWrapper} key={index}>
              <span
                className={styles.zoneDote}
                style={{ backgroundColor: item.color }}
              ></span>
              {item.name}
            </span>
          );
        })}
      </div>
    );
  };

  formatCordDataHandler = (data) => {
    let arr = data.trim().split("\n");
    let trail = [];
    let latHeaders = LAT_HEADERS;
    let lngHeaders = LNG_HEADERS;
    let headers = arr[0].replace("\r", "").toLowerCase().split(",");

    let latIndex, lngIndex, trackingIndex, headingIndex;
    trackingIndex = headers.indexOf(TRACKING_HEADERS);
    headingIndex = headers.indexOf(HEADING_HEADERS);
    latHeaders.map((item) => {
      latIndex = headers.indexOf(item);
      if (latIndex > -1) return;
    });
    lngHeaders.map((item) => {
      lngIndex = headers.indexOf(item);
      if (lngIndex > -1) return;
    });
    if (latIndex == -1 || lngIndex == -1) {
      //throw error
      return "No latitude or longitude headers found";
    }
    let inValidLatLngIndex = null;
    let heading = null;
    arr.map((item, index) => {
      if (index > 0 && inValidLatLngIndex == null) {
        let latLng = item.split(",");
        let lat = parseFloat(latLng[latIndex].replace("\r", ""));
        let lng = parseFloat(latLng[lngIndex].replace("\r", ""));

        if (trackingIndex > -1 && headingIndex > -1 && heading == null) {
          heading =
            latLng[trackingIndex] == "LOCK" ? latLng[headingIndex] : null;
        }
        if (validator.isLatLong(`${lat}, ${lng}`)) {
          trail.push({
            lat: lat,
            lng: lng,
            heading: null,
          });
        } else {
          //break loop , throw error
          inValidLatLngIndex = index + 1;
        }
      }
    });
    trail[0].heading = parseFloat(heading);

    if (inValidLatLngIndex != null) {
      return "Invalid latitude, longitude at position " + inValidLatLngIndex;
    } else if (trail.length == arr.length - 1) return trail;
    else {
      return "Error parsing CSV / XLS file";
    }
  };
  parseDataHandler = (callback, formatFunc) => {
    let uploadFileCopy = { ...this.state.uploadFile };
    let file = uploadFileCopy.value;

    let reader = new FileReader();

    reader.onload = function (evt) {
      try {
        var data = new Uint8Array(reader.result);
        var workbook = XLSX.read(data, { type: "array" });
        const wsname = workbook.SheetNames[0];
        const ws = workbook.Sheets[wsname];
        /* Convert array of arrays */
        const xlsData = XLSX.utils.sheet_to_csv(ws, { header: 1 });
        let formatedData = formatFunc(xlsData);
        callback(formatedData);
      } catch (e) {
        callback("");
      }
    };
    reader.readAsArrayBuffer(file);
    //reader.readAsText(file);
    reader.onerror = function () {
      callback("");
    };
  };
  onClickFencingHandler = () => {
    this.setState({ showFencing: !this.state.showFencing }, () => {});
  };
  onClickDismissAlarmHandler = () => {
    if (Controller.getIsUser()) return;
    this.setState({ loading: true }, () => {
      Swal.fire({
        title: "Are you sure?",
        text: `You are about dismiss the alert Zone for this vehicle.`,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#00a0dc",
        cancelButtonColor: "#ff1a1a",
        confirmButtonText: "Yes. Dismiss the alert Zone.",
      }).then((res) => {
        if (res.dismiss !== "cancel" && res.dismiss !== "backdrop") {
          this.props.contralHttpInterval(STOP);
          httpRequest(markVehicleAlertRead, {
            alertLookupId: this.state.vehicleData.exitFenceAlert.lookupId,
          })
            .then((res) => {
              this.props.contralHttpInterval(START);
              let copyVehicleData = { ...this.state.vehicleData };
              copyVehicleData.showVehicleOutsideFenceAnimation = false;
              copyVehicleData.exitFenceAlert.read = true;
              this.setState(
                { loading: false, vehicleData: copyVehicleData },
                () => {
                  let headingAdjustment =
                    this.state.vehicleData.headingAdjustment != null
                      ? this.state.vehicleData.headingAdjustment
                      : 0;
                  this.ref_headingControl(
                    headingAdjustment / 90,
                    this.state.vehicleData
                  );
                  this.props.updateVehicleData(
                    this.state.vehicleData.lookupId,
                    FENCING
                  );
                }
              );
            })
            .catch((err) => {});
        } else {
          this.setState({ loading: false });
        }
      });
    });
  };
  checkstatuseSOSHandler = () => {
    if (Object.keys(this.state.vehicleData).length == 0) return;
  };
  uploadTrailHandler = (event) => {
    let uploadFileCopy = { ...this.state.uploadFile };
    if (uploadFileCopy.fileName !== "" && event.target.files.length === 0) {
      return;
    } else if (this.checkFileValidHandler(event.target.files)) {
      uploadFileCopy.value = event.target.files[0];
      uploadFileCopy.error = false;
      uploadFileCopy.fileName = event.target.files[0].name;
      this.setState({ uploadFile: uploadFileCopy, loading: true }, () => {
        this.parseDataHandler((value) => {
          if (typeof value == "string") {
            const toastMsg = (
              <>
                <span className={styles.infoIcon}>
                  <Icon
                    defination={{ prefix: "fas", iconName: "info-circle" }}
                  />
                </span>
                {value}
              </>
            );
            toast(toastMsg, NOTIFICATION_OPTIONS);
          } else {
            // valid values build
            this.props.drawPolyline(value);
          }
        }, this.formatCordDataHandler);
      });
    } else {
      uploadFileCopy.error = true;
      const toastMsg = (
        <>
          <span className={styles.infoIcon}>
            <Icon defination={{ prefix: "fas", iconName: "info-circle" }} />
          </span>
          {"The file is invalid or empty. Please select a valid file"}
        </>
      );
      toast(toastMsg, NOTIFICATION_OPTIONS);
    }
  };

  onClickConigurationHandler = () => {
    this.setState({
      configurationTrail: !this.state.configurationTrail,
      showInfoVehicle: false,
      showZoneList: false,
    });
  };
  onClickAssignZonesHandler = () => {
    this.setState({ showZoneList: !this.state.showZoneList });
  };
  getTelemetryTableHandler = () => {
    return (
      <>
        <div className={styles.titleWrapper}>
          <p className={styles.title}>
            Telemetry&nbsp;
            <span
              className={styles.text}
              style={{
                visibility:
                  this.state.isTerminalOnline !== "" ? "visible" : "hidden",
              }}
            >
              {this.state.isTerminalOnline ? "(Online)" : "(Offline)"}
              <span
                className={styles.spinner}
                style={{
                  visibility: this.state.loading ? "visible" : "hidden",
                }}
              >
                <Icon
                  defination={{ prefix: "fal", iconName: "spinner" }}
                  isSpinner={true}
                />
              </span>
            </span>
            <span
              className={styles.historyData}
              style={{
                visibility: this.state.historyDataLoaded ? "visible" : "hidden",
              }}
            >
              (History)
            </span>
          </p>
        </div>
        <div className={styles.wrapperDetails}>
          {this.getTableDataHandler()}
        </div>
      </>
    );
  };
  render() {
    var userAgent = navigator.userAgent;
    var isIphone =
      /iPhone/.test(userAgent) && this.props.phoneMode && !window.MSStream;
    return (
      <>
        {Object.keys(this.state.vehicleData).length == 0 && (
          <div className={styles.leftControlsWrapper}>
            <div className={styles.leftTopWrapper}>
              {!this.props.phoneMode && (
                <ImportFile
                  error={this.state.uploadFile.error}
                  errMsg={this.state.uploadFile.errMsg}
                  name="upload_image"
                  phoneMode={this.props.phoneMode}
                  acceptedFiles=".csv,.xls,.xlsx"
                  fileName={this.state.uploadFile.fileName}
                  placeholder={this.state.uploadFile.placeholder}
                  clickAction={(e) => this.uploadTrailHandler(e)}
                  isImportTrailOn={this.state.isImportTrailOn}
                  clearTrail={this.props.clearImportedTrail}
                />
              )}
              <div
                className={this.props.phoneMode ? styles.phoneModeWrapper : ""}
              >
                <MapButton
                  id="fullscreen"
                  label=""
                  btnAction={this.showFullscreenHandler}
                  icon={{
                    prefix: "fas",
                    iconName: this.state.isFullScreenMode
                      ? "compress"
                      : "expand",
                  }}
                  customClass={styles.btnScreen}
                  iconClass={styles.iconMap}
                  toolTip="Full screen"
                  toolTipOverride={styles.toolTipOverride}
                />
              </div>
              {this.props.phoneMode && (
                <div className={styles.phoneModeWrapper}>
                  <ImportFile
                    error={this.state.uploadFile.error}
                    errMsg={this.state.uploadFile.errMsg}
                    name="upload_image"
                    phoneMode={this.props.phoneMode}
                    acceptedFiles=".csv,.xls,.xlsx"
                    fileName={this.state.uploadFile.fileName}
                    placeholder={this.state.uploadFile.placeholder}
                    clickAction={(e) => this.uploadTrailHandler(e)}
                    isImportTrailOn={this.state.isImportTrailOn}
                    clearTrail={this.props.clearImportedTrail}
                  />
                </div>
              )}

              <div className={styles.toastWrapper}>
                <ToastContainer progressClassName={styles.progressBar} />
              </div>
            </div>
            <div className={styles.importLegend} style={{
            visibility: this.state.isImportTrailOn
              ? "visible"
              : "hidden",
          }}>
              <span className={styles.iconsWrapper}>
                <span className={styles.trailStartIcon}>
                  <Icon defination={{ prefix: "fas", iconName: "circle" }} />
                </span>
                <span> Start</span>
              </span>
              <span className={styles.iconsWrapper}>
                <img src={TRAIL_END_POINT} className={styles.trailEndIcon} />{" "}
                <span>End</span>
              </span>
            </div>
          </div>
        )}
        <div
          className={
            !Object.keys(this.state.vehicleData).length
              ? `${styles.mainWrapper} ${styles.wrapperClose}`
              : styles.mainWrapper
          }
          style={{
            visibility: Object.keys(this.state.vehicleData).length
              ? "visible"
              : "hidden",
          }}
        >
          {this.state.iosFullScreen && (
            <span
              className={styles.headerMenuTitle}
              style={{ display: this.state.showTelemetryHistory ? "none" : "" }}
            >
              <Button
                overrideClass={styles.infoBtn}
                disabled={this.state.loading}
                click={this.onClickInfoVehicleHandler}
                text={
                  <span>
                    <Icon defination={{ prefix: "fas", iconName: "info" }} />
                  </span>
                }
              />
              <Button
                text={
                  <span className={styles.textWrapperBtn}>
                    <span className={styles.imgSvg}>
                      <Icon defination={{ prefix: "fas", iconName: "cog" }} />
                    </span>
                    <span className={styles.textBtn}>Trail Configurations</span>
                  </span>
                }
                disabled={this.state.loading}
                click={this.onClickConigurationHandler}
                overrideClass={styles.trailConfig}
              />
              <Button
                overrideClass={styles.liveChatBtn}
                click={this.onClickUserChatHandler}
                disabled={this.state.loading}
                id="livechat"
                text={
                  <span className={styles.textWrapperBtn}>
                    <img
                      className={styles.imgkeepalive}
                      src={
                        this.state.vehicleData.terminal != undefined &&
                        this.state.vehicleData.terminal.isAlive
                          ? KEEP_ALIVE_ON
                          : KEEP_ALIVE_OFF
                      }
                    />
                    <span className={styles.textBtn}>live chat</span>
                  </span>
                }
              />
              <span
                className={
                  this.state.loading
                    ? styles.historyDisabledWrapper
                    : styles.historyWrapper
                }
              >
                <Button
                  disabled={this.state.loading}
                  overrideClass={
                    this.state.loading
                      ? styles.disabledSelectedBtn
                      : styles.selectTypeBtn
                  }
                  text={
                    <span className={styles.textWrapperBtn}>
                      <span>
                        <img
                          className={styles.imglog}
                          alt="log icon"
                          src={LOG_ICON}
                        />
                      </span>
                      <span className={styles.textBtn}>{LOGS}</span>
                    </span>
                  }
                  click={() => {}}
                />

                <span className={styles.logsTypesWrapper}>
                  <span
                    className={styles.logType}
                    onClick={() => this.toggleHistoryDetails(TELEMETRY_LOGS)}
                  >
                    Telemetry History
                  </span>
                  <span
                    className={styles.logType}
                    onClick={() => this.toggleHistoryDetails(FENCE)}
                  >
                    {ALERT_LOGS}
                  </span>
                  <span
                    className={styles.logType}
                    onClick={() => this.toggleHistoryDetails(SOS_MSG)}
                  >
                    {SOS_LOGS}
                  </span>
                  <span
                    className={styles.logType}
                    onClick={() => this.toggleHistoryDetails(CHAT_LOGS)}
                  >
                    Live chat
                  </span>
                  {!Controller.getIsUser() && (
                    <span
                      className={styles.logType}
                      onClick={() => this.toggleHistoryDetails(KEEP_ALIVE)}
                    >
                      Keep Alive
                    </span>
                  )}
                  {Controller.isHiskyEntity() && !this.props.phoneMode && (
                    <span
                      className={styles.logType}
                      onClick={() => this.toggleHistoryDetails(INVALID_LOGS)}
                    >
                      Invalid Logs
                    </span>
                  )}
                </span>
              </span>
              <Button
                overrideClass={styles.infoZone}
                disabled={this.state.loading}
                click={this.onClickAssignZonesHandler}
                text={
                  <span className={styles.textWrapperBtn}>
                    <span className={styles.imgZone}>
                      <Icon
                        defination={{ prefix: "fas", iconName: "border-outer" }}
                      />
                    </span>
                    <span className={styles.textBtn}>Assign Zones</span>
                  </span>
                }
              />
              <button
                className={
                  this.state.vehicleData.sosAlert != null &&
                  (this.state.vehicleData.sosAlert
                    .alertVehiclealertupdatemessage == null ||
                    (this.state.vehicleData.sosAlert
                      .alertVehiclealertupdatemessage.status !== 1 &&
                      this.state.vehicleData.sosAlert
                        .alertVehiclealertupdatemessage.status !== 2 &&
                      this.state.vehicleData.sosAlert
                        .alertVehiclealertupdatemessage.status !== 6))
                    ? styles.sosBackgroundGlow
                    : styles.sosBackgroundNotGlow
                }
                style={{
                  display:
                    this.state.vehicleData.sosAlert !== null ? "block" : "none",
                }}
              >
                <img
                  onClick={this.onClickDismissSosAlertAHandler}
                  style={{
                    display:
                      this.state.vehicleData.sosAlert !== null
                        ? "block"
                        : "none",
                  }}
                  className={styles.imgSOS}
                  src={
                    Object.keys(this.state.vehicleData).length &&
                    this.state.vehicleData.sosAlert !== null &&
                    this.state.vehicleData.sosAlert
                      .alertVehiclealertupdatemessage != null &&
                    (this.state.vehicleData.sosAlert
                      .alertVehiclealertupdatemessage.status == 1 ||
                      this.state.vehicleData.sosAlert
                        .alertVehiclealertupdatemessage.status == 2 ||
                      this.state.vehicleData.sosAlert
                        .alertVehiclealertupdatemessage.status == 6)
                      ? SOS_SEEN
                      : SOS
                  }
                  alt="sos-button-dismiss"
                />
              </button>

              <span
                className={styles.closeIcon}
                onClick={() => this.onCloseVechileDetialHandler()}
              >
                <Icon defination={{ prefix: "fas", iconName: "times" }} />
              </span>
            </span>
          )}

          <div
            className={styles.DetailsDevice}
            style={{
              opacity: this.state.loading || this.state.showError ? 0.6 : 1,
              height: isIphone ? window.innerHeight : "", ///change to 80%
            }}
          >
            {this.state.showInfoVehicle && this.state.iosFullScreen && (
              <div className={styles.infoVehicleBoxWrapper}>
                <div style={{ marginLeft: "auto", cursor: "pointer" }}>
                  <span onClick={this.onClickInfoVehicleHandler}>
                    <Icon defination={{ prefix: "fas", iconName: "times" }} />
                  </span>
                </div>
                <div className={styles.infoVehicleBox}>
                  {this.state.showInfoVehicle && this.createDetailsElement()}
                </div>
              </div>
            )}
            {this.state.showZoneList && this.state.iosFullScreen && (
              <div className={styles.infoVehicleZoneBoxWrapper}>
                <div style={{ marginLeft: "auto", cursor: "pointer" }}>
                  <span onClick={this.onClickAssignZonesHandler}>
                    <Icon defination={{ prefix: "fas", iconName: "times" }} />
                  </span>
                </div>
                {Object.keys(this._fencingList).length > 0 && (
                  <p>{`Total Zones (${
                    Object.keys(this._fencingList).length
                  })`}</p>
                )}
                <div className={styles.infoVehicleBox}>
                  {this.state.showZoneList && this.createListOfZonesHandler()}
                </div>
              </div>
            )}
            {this.state.showError && (
              <div className={styles.errorWrapper}>
                <div
                  className={styles.headerMenuTitle}
                  style={{ backgroundColor: "red", color: "#fff" }}
                >
                  Error
                </div>

                <p className={styles.errorMsg}>{this.state.errorMsg}</p>
                <div className={styles.btnWrapper}>
                  <Button text="Close" click={this.onCloseErrorHandler} />
                </div>
              </div>
            )}

            <div
              className={
                this.state.showChatUser
                  ? styles.openWrapperChat
                  : styles.closeWrapper
              }
            >
              {this.state.showChatUser && (
                <UserChat
                  contralHttpInterval={this.props.contralHttpInterval}
                  vehicle={this.state.vehicleData}
                  closechat={this.onClickUserChatHandler}
                  phoneMode={this.props.phoneMode}
                  extermalId={
                    Object.keys(this.state.vehicleData).length ? (
                      <>
                        License No.&nbsp;&nbsp;
                        {this.state.vehicleData.licensePlate.number}
                      </>
                    ) : (
                      ""
                    )
                  }
                />
              )}
            </div>
            <div
              className={
                this.state.showTelemetryHistory
                  ? styles.openWrapper
                  : styles.closeWrapper
              }
            >
              {this.state.showTelemetryHistory && (
                <TelemetryHistory
                  vehicleData={this.state.vehicleData}
                  closeHistory={this.toggleHistoryDetails}
                  lookupId={this.state.terminalLookupId}
                  convertGpsData={this.convertGpsDataHandler}
                  phoneMode={this.props.phoneMode}
                  typeOfLog={this.state.typeOfLog}
                />
              )}
            </div>

            {this.iosFullScreen && (
              <Fragment>
                <p className={styles.titleTimer}>
                  Vehicle Location Trail (mins)
                </p>
                <div className={styles.breakLine}></div>
              </Fragment>
            )}
            {this.state.iosFullScreen && this.state.configurationTrail && (
              <div className={styles.histroyWrapper}>
                <span
                  className={styles.iconConfigClose}
                  onClick={this.onClickConigurationHandler}
                >
                  <Icon
                    defination={{
                      prefix: "fas",
                      iconName: "times",
                    }}
                  />
                </span>
                <div className={styles.sliderWrapper}>
                  <p
                    className={styles.invalidMsg}
                    style={{
                      visibility: this.state.inValidInputs
                        ? "visible"
                        : "hidden",
                    }}
                  >
                    Invalid input.Must be less then 180 min (3 hours)
                  </p>
                  <Slider
                    onAfterChange={this.onChangeInputTimeHandler}
                    onChange={this.onChangeInputTimeHandler}
                    min={0}
                    tipProps={{ visible: true }}
                    max={this.state.deltaType == 0 ? MAX_MIN : MAX_HOURS}
                    defaultValue={1}
                    value={this.state.value}
                    trackStyle={[{ backgroundColor: "#ffb637" }]}
                  />
                </div>
                <div className={styles.inputsWrapper}>
                  <input
                    className={styles.inputTime}
                    value={this.state.value}
                    onChange={this.onChangeInputValTimeHandler}
                    type="number"
                    min={0}
                    max={this.state.deltaType == 0 ? MAX_MIN : MAX_HOURS}
                  />
                  <div className={styles.selectWrapper}>
                    <select
                      value={this.state.deltaType == 0 ? MINUTE : HOURS}
                      className={styles.selectTelemetry}
                      onChange={(e) => this.onChangeSelecthandler(e)}
                    >
                      <option>{MINUTE}</option>
                      <option>{HOURS}</option>
                    </select>
                  </div>

                  <button
                    className={styles.btnApply}
                    onClick={this.onClickAppyTimeHandler}
                    disabled={
                      this.state.loading ||
                      this.state.timerPolytrial ||
                      this.state.telemetry.length === 0
                    }
                  >
                    Apply
                  </button>
                </div>
              </div>
            )}

            <div
              className={styles.vehiclemapWrapper}
              style={{ height: !this.state.iosFullScreen ? "100%" : "70%" }}
            >
              <div className={styles.toastWrapperHeading}>
                <ToastContainer progressClassName={styles.progressBar} />
              </div>
              <VehicleMap
                {...this.props}
                onClickDismissAlarm={this.onClickDismissAlarmHandler}
                lastValidHeading={this.state.lastValidHeading}
                lastValidPosition={this.state.lastValidPosition}
                toggleBtn={this.state.toggleBtn}
                onClickCheckBox={this.onClickCheckBoxHandler}
                refTelemetryFullscreen={this.setRefTelemetryOnFullscreenHandler}
                timeOutReset={this.state.timeOutReset}
                checkboxTelemetry={this.state.checkboxTelemetry}
                toggoleIosScreen={this.toggoleIosScreenHandler}
                onClickFencing={this.onClickFencingHandler}
                loading={this.state.loading}
                markers={
                  Object.keys(this.state.vehicleData).length
                    ? [
                        {
                          id: this.state.terminalLookupId,
                          name: this.state.vehicleData.name,
                          type: this.state.vehicleData.vClass.lookupId,
                          vin: this.state.vehicleData.vin,
                          details: this.state.vehicleData,
                          isOnline: this.state.isTerminalOnline,
                          licensePlate: this.state.vehicleData.licensePlate,
                          // headingAdjustment:
                          //   this.state.vehicleData.headingAdjustment,
                        },
                      ]
                    : []
                }
                polytrail={this.state.polytrail}
                hidePolytrail={this.state.hidePolytrail}
                terminalLookupId={this.state.terminalLookupId}
                btnOptions={{
                  centerBtn: true,
                  filtersBtn: false,
                  showLicense: false,
                }}
                refVehicleMapControl={this.refVehicleMapControl}
                removeTrail={this.removeTrailHandler}
                onClickToggleBtn={this.onClickToggleBtnHandler}
                refSpeedUdpate={this.refSpeedUdpateHandler}
                phoneMode={this.props.phoneMode}
                canUseRoads={this.canUseRoadsHandler}
                refActiveMapTypeControl={this.refActiveMapTypeControlHandler}
                refSetMapTypeControl={this.refSetMapTypeControlHandler}
                saveHeadingAdjustment={this.saveHeadingAdjustmentHandler}
                rotateVehicle={this.state.rotateVehicle}
                refHeadingControl={this.refHeadingControlHandler}
                fencingList={this._fencingList}
                showFencing={this.state.showFencing}
              ></VehicleMap>
            </div>
            {this.state.iosFullScreen && (
              <Fragment>
                <div className={styles.titleWrapperLocation}>
                  <span className={styles.subHeader}>
                    <span>
                      <strong>{TERMINAL_ID}:</strong>&nbsp;
                    </span>
                    <span>
                      {Object.keys(this.state.vehicleData).length ? (
                        <strong>
                          {this.state.vehicleData.terminal.externalId}
                        </strong>
                      ) : (
                        ""
                      )}
                    </span>

                    <span
                      style={{
                        display: "inline-block",
                        width: "1px",
                        height: "1.4rem",
                        display: this.props.phoneMode ? "none" : "",
                        backgroundColor: "#646464",
                      }}
                    ></span>

                    <span>
                      {Object.keys(this.state.vehicleData).length ? (
                        <strong>
                          {this.state.vehicleData.licensePlate.number}
                        </strong>
                      ) : (
                        ""
                      )}
                    </span>
                  </span>
                  <span
                    className={`${styles.spinner} ${styles.spinnerLocation}`}
                    style={{
                      visibility: this.state.loading ? "visible" : "hidden",
                    }}
                  >
                    <Icon
                      defination={{ prefix: "fal", iconName: "spinner" }}
                      isSpinner={true}
                    />
                  </span>
                  <span
                    className={styles.subHeader}
                    style={{ marginLeft: "auto" }}
                  >
                    <span
                      className={styles.toggleBtnWrapper}
                      style={{ marginLeft: "auto" }}
                    >
                      <span>
                        <strong>Start Trip</strong>
                      </span>
                      &nbsp;
                      <span>Off</span>
                      <span
                        className={styles.zoneActionIcon}
                        onClick={() => this.onClickCheckBoxHandler()}
                      >
                        <img
                          src={
                            this.state.checkboxTelemetry
                              ? TOGGLE_ON_ICON
                              : TOGGLE_OFF_ICON
                          }
                          alt="toggle icon"
                          className={styles.zoneActionImg}
                        />
                      </span>
                      <span>On</span>
                      <span
                        style={{
                          visibility: this.state.checkboxTelemetry
                            ? "visible"
                            : "hidden",
                        }}
                      >
                        {this.start_drive_date !== null
                          ? moment(this.start_drive_date).format(
                              "MM-DD-YY HH:mm"
                            )
                          : ""}
                      </span>
                    </span>

                    <span
                      style={{
                        display: "inline-block",
                        width: "1px",
                        height: "1.4rem",
                        backgroundColor: "#646464",
                        display: this.props.phoneMode ? "none" : "",
                        visibility: this.state.checkboxTelemetry
                          ? "visible"
                          : "hidden",
                      }}
                    ></span>

                    <span className={styles.toggleBtnWrapper}>
                      <span>
                        <strong>Snap To Road</strong>
                      </span>
                      &nbsp;
                      <span>Off</span>
                      <span
                        className={styles.zoneActionIcon}
                        onClick={() => this.onClickToggleBtnHandler()}
                      >
                        <img
                          src={
                            this.state.toggleBtn == ADVANCED
                              ? TOGGLE_ON_ICON
                              : TOGGLE_OFF_ICON
                          }
                          alt="toggle icon"
                          className={styles.zoneActionImg}
                        />
                      </span>
                      <span>On</span>
                    </span>
                  </span>
                </div>
                <div className={styles.breakLine}></div>
              </Fragment>
            )}
            {this.state.iosFullScreen && this.getTelemetryTableHandler()}
          </div>
        </div>
      </>
    );
  }
}
