import React, { Component } from "react";
import SpinnerLoader from "../../SpinnerLoader/SpinnerLoader";
import styles from "./SocketLoader.module.scss";
import { SOCKET_LOADER } from "../../../Util/constants";
class SocketLoader extends Component {
  render() {
    return (
      <div
        className={styles.SocketLoaders}
        id={SOCKET_LOADER}
        style={{ display: "none" }}
      >
        <SpinnerLoader
          msg={
            <span className={styles.msgWrapper}>
              <span>Connecting &nbsp;</span>
              <span>to server</span>
            </span>
          }
        />
      </div>
    );
  }
}

export default SocketLoader;
