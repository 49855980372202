import React from "react";
import Icon from "../Component/Icon/Icon";
import MapBluePin from "../assets/MapPinBlue.svg";
import MapRedPin from "../assets/MapPinRed.svg";
import { Slide } from "react-toastify";
import AERIAL from "../assets/Aerial.png";
import HYBRID from "../assets/Hybrid.png";
import DEFALUT_MAP_ICON from "../assets/DefaultMapIcon.png";
import TERRIAN from "../assets/Terrain.png";
import { getInclinationVehicle } from "./globalFunc";
// export const GOOGLE_MAP_API_KEY = "AIzaSyAXxhLDqgKZGkewUojgnFfOk96d8JZRbrw";old key
export const GOOGLE_MAP_API_KEY = "AIzaSyDs7W2G5rhUs5ND400absLJy5aqtQsTkag";
export const CLOUDINARY_URL = "https://api.cloudinary.com/v1_1/hisky/upload";
export const IMPORT_GOOGLE_URL = "https://www.google.com/maps/d/u/0/";
export const CLOUDINARY_UPLOAD_PRESET = "iduhvjfq";
export const DEFAULT_MAP_LOCATION = { lat: 51.5074, lng: 0.1278 };
export const PAGE_LIMIT = 10;
export const INTITAL_OFFSET = 1;
export const MAX_LICENSE_NUMER_LENGTH = 11;
export const MAX_LENGTH_MESSAGE = 1200;
export const SAVE = "save";
export const EDIT_MODE = "edit";
export const CREATE_MODE = "create";
export const VIEW_MODE = "view";
export const CREATE_TERMINAL_MODE = "create terminal";
export const PICTURE = "picture";
export const FILE = "file";
export const UPLOAD_PRESET = "upload_preset";
export const CONVERT_NUMBER_MILE = 0.62137;
export const CONVERT_NUMBER_KPH = 0.53996;
export const SHOW_FENCING = "Show Permitted Zone";
export const HIDE_FENCING = "Hide Permitted Zone";
export const EDIT_FENCING = "Edit Permitted Zone";
export const SHOW_FENCING_VEHICLES = "show  Permitted Zone vehicles";
export const DELETE_FENCING = "Delete Permitted Zone";
export const DELETE = "Delete";
export const UPLOAD_KLM = "Upload KML";
export const DRAW_FENCING = "Draw Permitted Zone";
export const UPDATE_FENCING_LIST = "update Fencing list";
export const RELOAD_FENCING_LIST = "reload fencing list";
export const DOT_MARKER = "dot marker";
export const CIRCLE = "CIRCLE";
export const POLYLINE = "POLYLINE";
export const RECTANGLE = "RECTANGLE";
export const CONTAINER = "chat-container";
export const NOTIFICATION_CONTAINER = "notification-container";
export const FENCING = "fencing";
export const FENCING_TITLE = "Zone";
export const SOS_MSG = "SOS";
export const BROADCAST_CONTIANER = "broadcast-contianer";
export const LOGS = "Vehicle Logs";
export const TELEMETRY_LOGS = "TelemetryHistory";
export const KEEP_ALIVE = "KeepAlive";
export const SOS_LOGS = "SOS Alerts";
export const ALERT_LOGS = "Zone Alerts";
export const INVALID_LOGS="InvalidLogs"
export const CHAT_LOGS = "LiveChat";
export const FENCE = "FENCE";
export const ALERT = "ALERT";
export const ASSIGN_TO_ZONE = "Alert zone";
export const ONLINE = "ONLINE";
export const OFFLINE = "OFFLINE";
export const LICENSE_NO = "License No.";
export const LENGHT_MESSAGE_SIZE="It may take some time to transmit long messages. Consider a shorter message."
export const LENGHT_MESSAGE_SIZE_OVER_MAX="You have reached the max. number of characters"
export const POLYGON_SETTINGS = {
  strokeOpacity: 0.8,
  strokeWeight: 2,
  fillColor: "#00bdd6",
  fillOpacity: 0.6,
  strokeColor: "#ffb637",
};
export const HIGHLIGHT_POLYGON_SETTINGS = {
  strokeColor: "#ffb637",
  strokeWeight: 2,
  zIndex: 999,
};
export const DISABLED_POLYGON_SETTINGS = {
  fillOpacity: 0.3,
  strokeOpacity: 1,
  strokeWeight:3,
  strokeColor:'#000'
}
export const HIDDEN_POLYGON_SETTINGS = {
  fillOpacity: 0,
  strokeOpacity: 0,
}
export const DEFAULT_ZONE_DRAW_COLOR= "#1a8092";
export const TYPE_KEEP_ALIVE = [
  { id: 1, name: "All Logs" },
  { id: 2, name: "Only Alive Logs" },
  { id: 3, name: "Exclude Alive Logs" },
];
export const CLUSTER_LEVEL_ZOOM = 11;
export const DEFAULT_ZOOM_LEVEL = 3; //prev value 11
// export const FMS_JOOLUE_INTEGRATION = "hisky-fms-integration.jooule.com";
export const FMS_INTEGRATION = "integration.hiwayfms.com";
export const INDIA_SERVER = "hiwayfms.in";
export const INDIA_SERVER_WWW = "www.hiwayfms.in";
export const PROD_FMS = "hiwayfms.com";
export const DEV_FMS = "dev.hiwayfms.com";
export const LOCAL_SERVER = "localhost:3000";
export const LOCAL_SERVER3001="localhost:3001"
export const BACKEND_SERVER_LOCAL = "localhost:3002";
export const OBJ_ENDPOINT = {
  // [FMS_JOOLUE_INTEGRATION]:
  //   "wss://hisky-fms-integration.jooule.com:8000/ws/graphql",
  [INDIA_SERVER]: "wss://hiwayfms.in:8000/ws/graphql",
  [INDIA_SERVER_WWW]: "wss://hiwayfms.in:8000/ws/graphql",
  [PROD_FMS]: "wss://hiwayfms.com:8000/ws/graphql",
  [LOCAL_SERVER]: "wss://dev.hiwayfms.com:8000/ws/graphql",
  [DEV_FMS]: "wss://dev.hiwayfms.com:8000/ws/graphql",
  [BACKEND_SERVER_LOCAL]: "ws://127.0.0.1:8000/ws/graphql",
  [LOCAL_SERVER]: "wss://dev.hiwayfms.com:8000/ws/graphql",
  [FMS_INTEGRATION]: "wss://integration.hiwayfms.com:8000/ws/graphql",
  [LOCAL_SERVER3001]:"wss://dev.hiwayfms.com:8000/ws/graphql",
};
export const GRAPHQL_ENDPOINT = OBJ_ENDPOINT[window.location.host];
// export const GRAPHQL_ENDPOINT = OBJ_ENDPOINT[PROD_FMS];
export const VEHICLE_OBJ_ENDPOINT = {
  // [FMS_JOOLUE_INTEGRATION]:
  //   "wss://hisky-fms-integration.jooule.com:8000/ws/vehiclechat",
  [PROD_FMS]: "wss://hiwayfms.com:8000/ws/vehiclechat",
  [INDIA_SERVER]: "wss://hiwayfms.in:8000/ws/vehiclechat",
  [INDIA_SERVER_WWW]: "wss://hiwayfms.in:8000/ws/vehiclechat",
  [LOCAL_SERVER]: "wss://dev.hiwayfms.com:8000/ws/vehiclechat",
  [DEV_FMS]: "wss://dev.hiwayfms.com:8000/ws/vehiclechat",
  [FMS_INTEGRATION]: "wss://integration.hiwayfms.com:8000/ws/vehiclechat",
  [BACKEND_SERVER_LOCAL]: "ws://127.0.0.1:8000/ws/vehiclechat",
  [LOCAL_SERVER3001]:"wss://dev.hiwayfms.com:8000/ws/vehiclechat",
};
export const VEHICLE_CHAT_ENDPOINT=VEHICLE_OBJ_ENDPOINT[window.location.host]
// export const VEHICLE_CHAT_ENDPOINT=VEHICLE_OBJ_ENDPOINT[PROD_FMS]
export const OTHER_ALIAS_NAME= 'General';
export const START = "start";
export const STOP = "stop";
export const DEFAULT_GROUP_COLOR = "#9ACD32";
export const RELOAD = "reload";
export const TRAIL = "Trail";
export const CAR = "Car";
export const OFF = "Off";
export const DONE = "Done";
export const SIMPLE = "Simple";
export const ADVANCED = "Advanced";
export const POLYTRAIL = "polytrail";
export const TELEMETRY = "telemetry";
export const VALID = "valid  "; //space is needed
export const INVALID = "invalid";
export const LAT_HEADERS = ["lat", "latitude", "latitude (°)"];
export const LNG_HEADERS = ["lng", "long", "longitude", "longitude (°)"];
export const TRACKING_HEADERS = "tracking";
export const HEADING_HEADERS = "heading";
// Telemetry
export const LATITUDE = "Latitude";
export const LONGITUDE = "Longitude";
export const TIMSESTAMP = "Date";
export const SPEED = "Speed";
export const ROLL = "Inclination";
export const HEADING = "Heading";
export const TRACKING = "Tracking";
export const GPS = "GPS";
export const PITCH = "Inclination";
export const MANUAL = "Manual";
export const SYNC = "Sync";
export const MINUTE = "Minutes";
export const HOURS = "Hours";
export const MAX_HOURS = 3;
export const MAX_MIN = 180;
export const POINTS = "Points";
export const UNLOCK = "UNLOCK";
export const LOCK_I = "LOCK I";
export const LOCK_II = "LOCK II";
export const LOCK = "LOCK";
export const VEHICLE = "Vehicle";
export const TELEMETRY_TABLE = "telemetry table";
export const SOCKET_LOADER = "socket-loader";
export const DRAW_FENCING_POINTS = "drawFencingPoints";
export const DRAW_FENCING_CIRCEL = "draw fencing circel";
export const DRAW_FENCING_RECTANGLE = "draw fencing rectangle";
export const DEFALUT_FILL_COLOR = "#00b6c9";
export const DEFALUT_BORDER_COLOR = "#ffb637";
/**
 * Table HEaders
 */
export const INDEX = "#";
export const TYPE = "type";
export const NAME = "name";
export const VEHICLE_NAME = "Vehicle name";
export const ROLE = "role";

//Terminals
export const MAC_ID = "MAC Address";
export const UPDATE_AT = "updated at";
export const STATUS = "status";
export const FIRST_NAME = "First Name";
export const LAST_NAME = "Last Name";
export const EMAIL = "Email";
export const TELEPHONE = "Telephone";
export const EXTERNAL_ID = "external ID";
export const CREATION_TYPE = "creation type";
export const ENABLED = "Enabled";
export const SYNC_STATUS = "Sync Status";
export const LAST_SYNCED_AT = "Last synced at";
export const TERMINAL_ID = "Terminal ID";
export const DEVICE_NAME = "Device Name";
export const TERMINAL_TYPE = "Terminal Type";
//Vehicles
export const MODEL = "Model";
export const LICENSE_NUMBER = "License number";
export const VIN_NUMBER = "VIN number";
export const ASSIGNED_TERMINAL = "Assigned terminal Id";
export const MANUFACTURER = "Manufacturer";
export const OWNER_NAME = "Owner name";
export const EXTERNAL_OWNER_NAME = "External Owner";
export const KM_HR = "km/h";
export const MILES = "mph";
export const KPH = "KNOTS";
export const ROTATE_ICON_ID = "rotateIconId";
export const LOCATION = "location";
export const YEAR = "year";
/////Groups
export const NAME_OF_GROUP = "name";
export const ADDRESS = "address";
export const COUNTRY_CODE = "country code";
export const COUNT_USERS = "vehicle count";
export const COLOR = "Group color";
export const OVERVIEW = "Overview";
/**
 * MODEL NAMES
 */
export const UPDATE_ACCOUNT_MODEL = "updateAccount";
export const TERMINAL = "terminal";
export const CREATE_TERMINAL_MODEL = "createTerminal";
export const VIEW_TERMINAL_MODEL = "viewTerminal";
export const TERMINALS_OVERLAY = "terminals";
export const USERS_OVERLAY = "users";
export const GROUPS_OVERLAY = "groups";
export const VEHICLES_OVERLAY = "vehicles";
export const CREATE_USER_MODEL = "createUser";
export const CREATE_VEHICLE_MODEL = "createVehicle";
export const SYNC_TERMINALS = "syncTerminals";
export const UPDATE_VERSION = "update version";
export const CLEAR_HISTORY = "Clear history";
export const VERFITY_PWD_VIEW = "verfiy password view";
export const CELAR_HISTORY_VIEW = "clear history view";
export const AUTHENTICATION = "Authentication";
/**
 * Button Names
 */
export const SUBMIT = "Submit";
export const CANCEL = "Cancel";
export const CLOSE = "close";
export const FETCH = "fetch";
export const NEXT = "next";

/**
 * Pin types
 */
/**
 * Google map Pins
 */
export const GOOGLE_PINS = {
  car: MapBluePin,
  terminal: MapRedPin,
};
//////////////////////////////////
/**
 * Error message socket
 *
 */
export const ACCESS_DENIED = "Error: Access denied.";
export const USER_NOT_FOUND = "Error: User not found.";
export const INVALID_TOKEN = "Error: Invalid token";
export const USER_IS_NOT_AUTHENTICATED = "Error: User is not authenticated";
export const TERMINAL_NOT_FOUND = "Error: Terminal not found";
///////////////////////
export const DYNAMIC = "Dynamic";
export const FIXED_RL = "Fixed RL";
export const FIXED_LR = "Fixed LR";
export const PROMT = "Choose Type";

export const CAR_LOOKUPID = "ag59KtDPLKRxXYqwduHFug";
export const TRUCK_LOOKUPID = "oLB7bfzNusk5CB8sHTNDkz";
export const OTHER_LOOKUPID = "97naKgFx4m6XSsXciXd4LQ";
export const BOAT_LOOKUPID = "bbVMZH4QWCKD5GTDGetq34";
export const FISHNING_BOAT_LOOKUPID = "RCuVraQSbTUCGB7gMJnhFf";

////////////////Map types /////////////////////////
export const DEFAULT_MAP_TYPE = "roadmap";
export const TERRAIN_MAP_TYPE = "terrain";
export const SATELLITE_MAP_TYPE = "satellite";
export const HYBRID_MAP_TYPE = "hybrid";
export const GET_MAP_TYPE_ICON = {
  roadmap: DEFALUT_MAP_ICON,
  terrain: TERRIAN,
  satellite: AERIAL,
  hybrid: HYBRID,
};
export const DISABLE_COLOR_FOR_GPS_UNLOCK = "#abaaaa";
export const DEEP_ZOOM_LEVEL = 20;
/** teseting data */
export const lookUpIconVehicle = (lookupId) => {
  //TODO: remove
  if ("97naKgFx4m6XSsXciXd4LQ" === lookupId) {
    return {
      lookupId: "97naKgFx4m6XSsXciXd4LQ",
      nameLower: "general",
      displayName: "General",
      //icon: <Icon defination={{ prefix: "fas", iconName: "map-marker-alt" }} />,
      icon: (
        <img
          src={getInclinationVehicle("97naKgFx4m6XSsXciXd4LQ")}
          class={"vehicleIcon"}
          alt="side-car"
        />
      ),
    };
  }
  if ("7bZ6dxGTkZ4FBfqsqXoVxD" == lookupId) {
    return {
      lookupId: "7bZ6dxGTkZ4FBfqsqXoVxD",
      nameLower: "motorcycle",
      displayName: "Motorcycle",
      icon: <Icon defination={{ prefix: "far", iconName: "motorcycle" }} />,
    };
  }
  if (lookupId == "Yxb5riemZyJjqxo5g2oWgp")
    return {
      lookupId: "Yxb5riemZyJjqxo5g2oWgp",
      nameLower: "train",
      displayName: "Train",
      icon: <Icon defination={{ prefix: "far", iconName: "train" }} />,
    };
  if (lookupId == "bbVMZH4QWCKD5GTDGetq34") {
    return {
      lookupId: "bbVMZH4QWCKD5GTDGetq34",
      nameLower: "boat",
      displayName: "Boat",
      //icon: <Icon defination={{ prefix: "far", iconName: "ship" }} />,
      icon: (
        <img
          src={getInclinationVehicle("bbVMZH4QWCKD5GTDGetq34")}
          class={"vehicleIcon"}
          alt="side-car"
        />
      ),
    };
  }
  if (lookupId === "oLB7bfzNusk5CB8sHTNDkz") {
    return {
      lookupId: "oLB7bfzNusk5CB8sHTNDkz",
      nameLower: "truck",
      displayName: "Truck",
      //icon: <Icon defination={{ prefix: "far", iconName: "truck" }} />,
      icon: (
        <img
          src={getInclinationVehicle("oLB7bfzNusk5CB8sHTNDkz")}
          class={"vehicleIcon"}
          alt="side-car"
        />
      ),
    };
  }
  if (lookupId === "ag59KtDPLKRxXYqwduHFug") {
    return {
      lookupId: "ag59KtDPLKRxXYqwduHFug",
      nameLower: "car",
      displayName: "Car",
      // icon: <Icon defination={{ prefix: "far", iconName: "car" }} />,
      icon: (
        <img
          src={getInclinationVehicle("ag59KtDPLKRxXYqwduHFug")}
          class={"vehicleIcon"}
          alt="side-car"
        />
      ),
    };
  } else {
    return {
      lookupId: -1,
      nameLower: "flatbed truck",
      displayName: "Select vehicle",
    };
  }
};
export const VEHICLE_POSITION = {
  //TODO: remove
  car: {
    left: { flip: "horizontal", icon: "car-side" },
    right: { flip: "", icon: "car-side" },
    towards: { flip: "", icon: "car" },
  },
  truck: {
    left: { flip: "horizontal", icon: "truck" },
    right: { flip: "", icon: "truck" },
    towards: { flip: "", icon: "bus" },
  },
  "map-marker-alt": {
    //other
    towards: { flip: "", icon: "map-marker-alt" },
    left: { flip: "", icon: "map-marker-alt" },
    right: { flip: "", icon: "map-marker-alt" },
  },
};
export const VEHICLE_TYPES = {
  "-1": {
    lookupId: -1,
    nameLower: "flatbed truck",
    displayName: "Select vehicle",
  },
  ag59KtDPLKRxXYqwduHFug: {
    lookupId: "ag59KtDPLKRxXYqwduHFug",
    nameLower: "car",
    displayName: "Car",
    //icon: <Icon defination={{ prefix: "far", iconName: "car" }} />,
    icon: (
      <img
        src={getInclinationVehicle("ag59KtDPLKRxXYqwduHFug")}
        className={"vehicleIcon"}
        alt="side-car"
      />
    ),
    defination: { prefix: "far", iconName: "car" },
  },

  oLB7bfzNusk5CB8sHTNDkz: {
    lookupId: "oLB7bfzNusk5CB8sHTNDkz",
    nameLower: "truck",
    displayName: "Truck",
    //icon: <Icon defination={{ prefix: "far", iconName: "truck" }} />,
    icon: (
      <img
        src={getInclinationVehicle("oLB7bfzNusk5CB8sHTNDkz")}
        className={"vehicleIcon"}
        alt="side-car"
      />
    ),
    defination: { prefix: "far", iconName: "truck" },
  },
  bbVMZH4QWCKD5GTDGetq34: {
    lookupId: "bbVMZH4QWCKD5GTDGetq34",
    nameLower: "boat",
    displayName: "Boat",
    //icon: <Icon defination={{ prefix: "far", iconName: "ship" }} />,
    icon: (
      <img
        src={getInclinationVehicle("bbVMZH4QWCKD5GTDGetq34")}
        className={"vehicleIcon"}
        alt="side-car"
      />
    ),
    defination: { prefix: "far", iconName: "ship" },
  },
  RCuVraQSbTUCGB7gMJnhFf: {
    lookupId: "RCuVraQSbTUCGB7gMJnhFf",
    nameLower: "fishing boat",
    displayName: "Fishing Boat",
    icon: (
      <img
        src={getInclinationVehicle("RCuVraQSbTUCGB7gMJnhFf")}
        className={"vehicleIcon"}
        alt="side-car"
      />
    ),
  },
  "97naKgFx4m6XSsXciXd4LQ": {
    lookupId: "97naKgFx4m6XSsXciXd4LQ",
    nameLower: "general",
    displayName: "General",
    icon: (
      <img
        src={getInclinationVehicle("97naKgFx4m6XSsXciXd4LQ")}
        className={"vehicleIcon"}
        alt="side-car"
      />
    ),
    defination: { prefix: "fas", iconName: "map-marker-alt" },
  },
};
/* toastfiy options */
export const NOTIFICATION_OPTIONS = {
  transition: Slide,
  closeOnClick: true,
  autoClose: 5000,
  hideProgressBar: false,
  pauseOnHover: true,
};
/** cluster  */
export const CLUSTER = "cluster";
export const VEHICLE_SOS_COLOR = "red";
export const VEHICLE_ONLINE_COLOR = "green";
export const VEHICLE_OFFLINE_COLOR = "gray";

/**Fence */
export const VEHICLES_OF_FENCE = "get vehicles under a fence";
export const CREATE_NEW_ZONE = "create new zone by kml or drawing";
export const EDIT_FENCE_DETAILS = "edit fencing details other than colors";
export const DOWNLOAD_FENCING = "download fencing kml file";
export const TOGGLE_FENCE_ACTIVATION = "toggle fence activation";
export const OPEN_FENCING_MENU = "open uuid related fencing";
export const CLEAR_ALL_ACTION_BOXES = 'clear all boxes opned on map';