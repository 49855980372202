import React, { Component } from "react";
import styles from "./CustomMenu.module.scss";
import Icon from "../Icon/Icon";

export default class CustomMenu extends Component {
  buildSelectMenu = () => {
    let arrayOption = this.props.menuItems.map((item, index) => {
      return (
        <li
          key={index}
          onClick={item.callback}
          className={this.props.disable ? styles.avoidClicks : ""}
        >
          {item.label}
        </li>
      );
    });
    return arrayOption;
  };
  render() {
    return (
      <>
        <button
          className={styles.mainWrapper}
          disabled={this.props.disable}
          style={this.props.style}
        >
          {this.props.title}
          <span className={styles.selectedItem}>
            <Icon defination={{ prefix: "fas", iconName: "sort-down" }} />
          </span>
          <ul className={styles.menuItem} style={this.props.menuStyle}>
            {this.buildSelectMenu()}
          </ul>
        </button>
      </>
    );
  }
}