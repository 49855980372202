import React, { Component } from "react";
import styles from "./MultiSelect.module.scss";
import Icon from "../../Component/Icon/Icon";
export default class MultiSelect extends Component {
  buildSelectMenu = () => {
    let arrayOption = this.props.selectOptions.map((item, index) => {
      return (
        <li key={index} onClick={() => this.props.click(item,index)}>
          {index!=0 && <span className={styles.icon}>
            <input
              type="checkbox"
              checked={Object.keys(this.props.selectedList).indexOf(item.lookupId)!=-1}
              value={Object.keys(this.props.selectedList).indexOf(item.lookupId)!=-1}
              readOnly            
            />
          </span> }
          <span className={styles.iconLabel}>{item.displayName}</span>
        </li>
      );
    });
    return arrayOption;
  };
  render() {
    return (
      <div className={styles.MultiSelects}>
        <div className={styles.label}>
          <div className={styles.text}>{this.props.titleText}</div>          
        </div>
        <div
          className={styles.mainWrapper}
          onMouseLeave={() => this.props.showOptions(false)}
          style={{ backgroundColor: this.props.disabled ? "#e2e2e2" : "" }}
          onClick={
            this.props.disabled ? () => {} : () => this.props.showOptions(true)
          }
        >
          {this.props.selectedOptionDisplay}
          
          <span className={styles.iconArrow}>
            {this.props.showOptionsList ? (
              <Icon defination={{ prefix: "fal", iconName: "chevron-up" }} />
            ) : (
              <Icon defination={{ prefix: "fal", iconName: "chevron-down" }} />
            )}
          </span>
          <ul
            className={
              this.props.showOptionsList
                ? `${styles.itemsList} ${styles.showList}`
                : `${styles.itemsList}`
            }
          >
            {this.buildSelectMenu()}
          </ul>
        </div>
        {!this.props.phoneMode && (
          <p
            className={styles.error}
            style={{ visibility: this.props.error ? "visible" : "hidden" }}
          >
            {this.props.errMsg}
          </p>
        )}
      </div>
    );
  }
}
