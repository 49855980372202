import { isLocalhost } from "./Util/browser";
// API section
const TIMEOUT = 80000;
const HEADERS = {};
//const PROTO = isLocalhost() ? "http" : "http";Server usage
//const HOST="localhost"Server usage

const PROTO =
  isLocalhost()?window.location.port != "3002"? "https" : "http":"https";
const HOST = isLocalhost()
  ? window.location.port != "3002"
    ? "dev.hiwayfms.com"
    : "localhost"
  : window.location.hostname; //QA
const API_URL = `${PROTO}://${HOST}:8000/api/graphql`;
const EXPORTS_URL = `${PROTO}://${HOST}:8000/static`;
export default Object.freeze({
  api: {
    API_URL,
    TIMEOUT,
    HEADERS,
    EXPORTS_URL,
  },
});
