import React, { Component } from "react";
import "./SpinnerLoader.scss";

export default class SpinnerLoader extends Component {
  render() {
    return (
      <div className="wrapperSpinner">
        <p className="titleSpinner">
          <strong>{this.props.msg}</strong>
        </p>

        <div className="lds-spinner">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    );
  }
}
