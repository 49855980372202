import React, { Component } from "react";
import styles from "./Groups.module.scss";
import Icon from "../Icon/Icon";
import Button from "../Button/Button";
import Swal from "sweetalert2/dist/sweetalert2.js";
import Pagination from "../Pagination/Pagination";
import {
  INDEX,
  NAME_OF_GROUP,
  ADDRESS,
  COUNT_USERS,
  PAGE_LIMIT,
  INTITAL_OFFSET,
  CREATE_MODE,
  VIEW_MODE,
  EDIT_MODE,
  GROUPS_OVERLAY,
  UPDATE_VERSION,
} from "../../Util/constants";
import CreateGroup from "../CreateGroup/CreateGroup";
import { deleteGroup, getGroups } from "../../RequestManager/GqlBuilderAccount";
import { checkVersionApp } from "../../Util/globalFunc";
import { httpRequest } from "../../RequestManager/HttpRequest";
import SpinnerLoader from "../SpinnerLoader/SpinnerLoader";
import Controller from "../../Controller";

class Groups extends Component {
  _ismounted = true;
  state = {
    searchKey: "",
    openCreateGroup: false,
    data: [],
    groupShow: "",
    activeIndex: 0,
    totalRecords: 0,
    pageNo: 1,
    offset: INTITAL_OFFSET,
    limit: PAGE_LIMIT,
    originalData: [],
    loading: false,
    selectedGroup: {},
    mode: "",
  };
  componentDidMount() {
    if (this.props.refDashboard !== undefined) {
      this.props.refDashboard(this.updateStateHandler);
    }
    this.createHttpRequestHandler();
  }
  componentWillUnmount() {
    this._ismounted = false;
  }
  createHttpRequestHandler = (search = "") => {
    if (!this._ismounted) return;
    let params = {
      search: search,
      offset: this.state.offset,
      limit: this.state.limit,
    };
    this.setState({ loading: true }, () => {
      httpRequest(getGroups, params)
        .then((res) => {
          if (!checkVersionApp(res.data.systemVersion)) {
            this.props.closeModel(UPDATE_VERSION);
          } else {
            this.setDataHandler(res.data);
          }
        })
        .catch((err) => {});
    });
  };
  setDataHandler = (data) => {
    let chunk = [...this.state.data];
    let groups = data.groups.groups;
    /**
     * Store the records by spliting in to two arrays
     * If user requested for next+1 records
     */
    if (groups.length > PAGE_LIMIT) {
      chunk[this.state.pageNo - 2] = groups.slice(0, PAGE_LIMIT);
      chunk[this.state.pageNo - 1] = groups.slice(PAGE_LIMIT);
    } else {
      chunk[this.state.pageNo - 1] = groups;
    }

    let originalData = [...this.state.originalData, ...groups];
    this.setState(
      {
        totalRecords: data.groups.count,
        data: chunk,
        originalData: originalData,
      },
      () => {
        this.setState({
          activeIndex: this.getActiveIndexHandler(groups.length),
          loading: false,
        });
      }
    );
  };
  onClickPageNumberHandler = (pageNumber) => {
    if (this.state.data[pageNumber - 1] !== undefined) {
      this.setState({
        activeIndex: pageNumber - 1,
        pageNo: pageNumber,
      });
    } else {
      /**
       * offset: 3 (even if pressed next+1 pageno)
       */
      let nuberOfRecordstoFetch =
        (pageNumber - (this.state.activeIndex + 1)) * PAGE_LIMIT;
      if (pageNumber - 1 > this.state.pageNo) {
        this.setState(
          {
            offset: (this.state.activeIndex + 1) * PAGE_LIMIT + 1,
            limit: nuberOfRecordstoFetch,
            pageNo: pageNumber,
          },
          () => {
            this.createHttpRequestHandler();
          }
        );
      } else {
        this.setState(
          {
            offset: (pageNumber - 1) * PAGE_LIMIT + 1,
            pageNo: pageNumber,
            limit: PAGE_LIMIT,
          },
          () => {
            this.createHttpRequestHandler();
          }
        );
      }
    }
  };
  getActiveIndexHandler = (noOfRecords) => {
    let nextIndex = 0;
    if (noOfRecords > PAGE_LIMIT) {
      nextIndex = this.state.pageNo - 1;
    } else {
      nextIndex =
        this.state.activeIndex + 1 === this.state.data.length
          ? this.state.activeIndex
          : this.state.activeIndex + 1;
    }
    return nextIndex;
  };
  onClickPrevHandler = () => {
    let nextIndex =
      this.state.activeIndex - 1 < 0 ? 0 : this.state.activeIndex - 1;
    this.setState({
      activeIndex: nextIndex,
      pageNo: nextIndex + 1,
    });
  };
  onClickNextHandler = () => {
    /**
     * pageNo : is the prev page number here
     * ActiveIndex = PageNo-1
     * offset : (currentPAgeNo -1) * PAGE_LIMIT +1
     */
    if (this.state.data[this.state.pageNo] !== undefined) {
      this.setState({
        activeIndex: this.state.pageNo,
        pageNo: this.state.pageNo + 1,
        activeTableRow: "",
      });
    } else if (
      this.state.pageNo + 1 <=
      Math.ceil(this.state.totalRecords / PAGE_LIMIT)
    ) {
      this.setState({ pageNo: this.state.pageNo + 1 }, () => {
        this.setState(
          {
            offset: (this.state.pageNo - 1) * PAGE_LIMIT + 1,
            limit: PAGE_LIMIT,
          },
          () => {
            this.createHttpRequestHandler();
          }
        );
      });
    }
  };

  onChangeTextHandler = (event) => {
    this.setState({ searchKey: event.target.value }, () => {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.setState(
          {
            offset: INTITAL_OFFSET,
            pageNo: 1,
            activeIndex: 0,
            data: [],
          },
          () => {
            this.createHttpRequestHandler(this.state.searchKey.toLowerCase());
          }
        );
      }, 1000);
    });
  };
  buildTableHeaderHandler = () => {
    return [INDEX, NAME_OF_GROUP, ADDRESS, COUNT_USERS, ""].map(
      (item, index) => {
        return (
          <div key={index} className={styles.textCell}>
            <strong>{item}</strong>
          </div>
        );
      }
    );
  };
  getBackgroundColor = (item) => {
    if (!Object.keys(this.state.selectedGroup).length) {
      return { backgroundColor: "#fff" };
    } else if (item.lookupId == this.state.selectedGroup.lookupId) {
      return { backgroundColor: "#ffb637", color: "#fff" };
    } else {
      return { backgroundColor: "#fff" };
    }
  };
  getIconColorHandler = (item) => {
    if (!Object.keys(this.state.selectedGroup).length) {
      return { color: "#ffb637" };
    } else if (item.lookupId == this.state.selectedGroup.lookupId) {
      return { color: "#fff" };
    } else {
      return { color: "#ffb637" };
    }
  };
  onClickEditHandler = (item) => {
    this.setState({ selectedGroup: item, mode: EDIT_MODE });
  };
  onClickDeleteHandler = (item) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to delete ${item.name} from groups.`,
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#00a0dc",
      cancelButtonColor: "#ff1a1a",
      confirmButtonText: "Yes",
    }).then((res) => {
      if (res.dismiss !== "cancel" && res.dismiss !== "backdrop") {
        this.deleteRecordHandler(item);
      }
    });
  };
  fireSwalHandler = (title, text, type) => {
    Swal.fire({
      title: title,
      text: text,
      type: type,
    }).then((res) => {
      if (type === "success") {
        this.reloadPageHandler();
      }
    });
  };
  checkDeleteResponseHandler = (res) => {
    if (res.errors !== undefined) {
      this.fireSwalHandler(
        "Warning",
        res.errors[0].message.replace(/_/g, " "),
        "warning"
      );
      this.setState({ loading: false });
    } else {
      this.fireSwalHandler("Success", "Group was deleted", "success");
    }
  };
  deleteRecordHandler = (item) => {
    this.setState({ loading: true }, () => {
      httpRequest(deleteGroup, { lookupId: item.lookupId })
        .then((res) => {
          this.checkDeleteResponseHandler(res);
        })
        .catch((err) => {});
    });
  };
  onClickViewHandler = (item) => {
    this.setState({ selectedGroup: item, mode: VIEW_MODE });
  };
  buildTableRowHandler = () => {
    if (
      (this.state.data[this.state.activeIndex] == undefined ||
        this.state.data[this.state.activeIndex].length == 0) &&
      !this.state.loading
    ) {
      return <div className={styles.noDataRow}>No records found</div>;
    } else if (this.state.data.length > 0) {
      let indexPage = this.getIndexOfPageHandler(this.state.activeIndex + 1);
      return this.state.data[this.state.activeIndex].map((item, index) => {
        return (
          <div
            className={styles.tableRow}
            key={index}
            style={this.getBackgroundColor(item)}
          >
            <div className={styles.textCell}>{index + indexPage}</div>
            <div className={styles.textCell}>{item.name}</div>
            <div className={styles.textCell}>
              {item.location !== null && item.location !== undefined
                ? item.location.address
                : "N/A"}
            </div>
            <div className={styles.textCell}>{item.vehicles.length}</div>           
            <div className={`${styles.textCell} ${styles.iconwrapper}`}>
              <span
                style={{
                  color: this.getIconColorHandler(item),
                }}
                className={styles.actionIconDel}
                onClick={() => this.onClickViewHandler(item)}
              >
                <span className={styles.toolTip}>View</span>
                <Icon defination={{ prefix: "fas", iconName: "info-square" }} />
              </span>
              {Controller.isAuthenticated() && (
                <span
                  className={styles.actionIconEdit}
                  onClick={() => this.onClickEditHandler(item)}
                  style={{
                    color: this.getIconColorHandler(item),
                  }}
                >
                  <span className={styles.toolTip}>Edit</span>
                  <Icon defination={{ prefix: "fas", iconName: "pen" }} />
                </span>
              )}
              {Controller.isAuthenticated() && (
                <span
                  className={styles.actionIconDel}
                  onClick={() => this.onClickDeleteHandler(item)}
                  style={{
                    color: this.getIconColorHandler(item),
                  }}
                >
                  <span className={styles.toolTip}>Delete</span>
                  <Icon defination={{ prefix: "fas", iconName: "trash-alt" }} />
                </span>
              )}
            </div>
          </div>
        );
      });
    }
  };

  updateStateHandler = (type) => {
    if (!this._ismounted) return;
    if (type !== GROUPS_OVERLAY) {
      this.setState({ selectedGroup: {}, mode: "" });
    }
  };

  getIndexOfPageHandler = (pageIndex) => {
    return 1 + (pageIndex - 1) * PAGE_LIMIT;
  };

  getTableDataHandler = () => {
    return (
      <>
        <div
          className={styles.tableWrapper}
          style={{ opacity: this.state.loading ? 0.6 : 1 }}
          id="form"
        >
          <div className={`${styles.tableRow} `} id="tblHeader">
            {this.buildTableHeaderHandler()}
          </div>
          <div
            className={styles.table}
            style={{
              opacity: this.state.loading || this.props.showLoader ? 0.6 : 1,
            }}
          >
            {this.buildTableRowHandler()}
            <div
              className={styles.spinnerWrapper}
              style={{ display: this.state.loading ? "block" : "none" }}
            >
              <SpinnerLoader msg="" />
            </div>
          </div>
          <Pagination
            style={styles}
            totalRecordsPerPage={
              this.state.data.length > 0
                ? this.state.data[this.state.activeIndex].length
                : ""
            }
            totalRecords={this.state.totalRecords}
            totalPages={Math.ceil(this.state.totalRecords / PAGE_LIMIT)}
            setPage={this.onClickPageNumberHandler}
            prev={this.onClickPrevHandler}
            data={this.state.data}
            next={this.onClickNextHandler}
            disabled={this.state.loading || this.state.data.length === 0}
            pageNumber={this.state.activeIndex + 1}
          />

          <div className={styles.closeBtnWrapper}>
            <Button
              text="Close"
              click={() => this.props.closeModel("")}
              disabled={this.state.loading}
            
            />
          </div>
        </div>
      </>
    );
  };
  onClickCreateGroupHandler = () => {
    this.setState({ mode: CREATE_MODE });
  };
  reloadPageHandler = () => {
    this.setState(
      {
        data: [],
        groupShow: "",
        activeIndex: 0,
        totalRecords: 0,
        pageNo: 1,
        offset: INTITAL_OFFSET,
        limit: PAGE_LIMIT,
        originalData: [],
        loading: false,
        data: [],
        mode: "",
        selectedGroup: {},
      },
      () => {
        this.createHttpRequestHandler();
      }
    );
  };
  onClickCloseHandler = () => {
    this.setState({ mode: "", selectedGroup: {} });
  };
  render() {
    return (
      <div
        className={
          this.props.openModel === GROUPS_OVERLAY
            ? styles.Group
            : styles.CloseGroup
        }
        id="groupList"
      >
        <div className={this.state.mode != "" ? styles.open : styles.close}>
          {this.state.mode != "" && (
            <CreateGroup
              data={this.state.selectedGroup}
              mode={this.state.mode}
              phoneMode={this.props.phoneMode}
              close={this.onClickCloseHandler}
              reloadPage={this.reloadPageHandler}
            />
          )}
        </div>
        <div className={styles.info}>
          <h3 className={styles.header} id="header">
            <span className={styles.icon}>
              <Icon defination={{ prefix: "far", iconName: "users-class" }} />
            </span>
            <span className={styles.text}>Groups</span>
            {Controller.isAuthenticated() && (
              <span className={styles.btnCreateWrapper}>
                <Button
                  text="Create group"
                  click={this.onClickCreateGroupHandler}
                  disabled={this.state.loading}
                />
              </span>
            )}
          </h3>
          <div className={styles.breakLine}></div>
          <div className={styles.dataWrapper}>
            <div className={styles.searchDeviceWrapper}>
              <span className={styles.iconSearch}>
                <Icon defination={{ prefix: "fas", iconName: "search" }} />
              </span>
              <input
                onChange={(e) => this.onChangeTextHandler(e)}
                placeholder="Search Group"
                value={this.state.searchKey}
                className={styles.searchInput}
                type="search"
              />
            </div>
            {this.getTableDataHandler()}
          </div>
        </div>
      </div>
    );
  }
}

export default Groups;
