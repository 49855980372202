import React, { Component,Fragment } from "react";
import styles from "./FormButtons.module.scss"
export default class FormButtons extends Component {
  render() {
    const { text,disabled,id } = this.props;
    return (
      <Fragment>
        <button disabled={disabled} className={`${styles.btn} ${this.props.overrideClass!=undefined && this.props.overrideClass}`} id={id !==undefined ?id:text}
         onClick={this.props.click}>
          {text}
        </button>
      </Fragment>
    );
  }
}
