import React, { Component, PureComponent } from "react";
import Button from "../Button/Button";
import styles from "./ImageCrop.module.scss";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { VIEW_MODE } from "../../Util/constants";
import PreviewImage from "../PreviewImage/PreviewImage";

export default class ImagePreview extends PureComponent {
  _croppedImageUrl = "";
  _srcImg = "";
  state = {
    src: null,
    crop: {
      unit: "%",
      // width: 300,
      // aspect: 15 / 8,
      width: 300,
      aspect: this.props.aspectRatio ? this.props.aspectRatio : 16 / 9,
    },
    croppedImageUrl: null,
  };
  componentDidMount() {
    this.setState(
      {
        croppedImageUrl: this.props.previewImage,
      },
      () => {}
    );
  }
  getUrl = () => {
    const reader = new FileReader();
    reader.addEventListener("load", () =>
      this.setState(
        {
          src: reader.result,
          croppedImageUrl:
            this._srcImg != this.props.imageSrc.name
              ? null
              : this.state.croppedImageUrl,
        },
        () => {}
      )
    );
    reader.readAsDataURL(this.props.imageSrc);
    return reader.result;
  };

  // If you setState the crop in here you should return false.
  onImageLoaded = (image) => {
    this.imageRef = image;
  };

  onCropComplete = (crop) => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop, percentCrop) => {
    // You could also use percentCrop:
    // this.setState({ crop: percentCrop });
    this.setState({ crop });
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        "newFile.jpeg"
      );
      this._croppedImageUrl = croppedImageUrl;
    }
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");
   
    ctx.fillStyle = "#fff";
    ctx.fillRect(0, 0, window.innerWidth, window.innerHeight);
    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          //reject(new Error('Canvas is empty'));
          console.error("Canvas is empty");
          return;
        }
        blob.name = fileName;
        this.fileUrl = URL.createObjectURL(blob);
        resolve(canvas.toDataURL("image/jpeg"));
      }, "image/jpeg");
    });
  }
  //apply action
  sendImageUrl = () => {
    this.setState({ croppedImageUrl: this._croppedImageUrl }, () => {
      this.props.sendImageUrl(this.state.croppedImageUrl);
    });
  };
  backHandler = () => {
    this.setState({ croppedImageUrl: null }, () => {
      this.props.sendImageUrl(null);
    });
  };
  cancelHandler = () => {
    this._srcImg = "";
    this.props.cancel();
  };
  render() {
    const { crop, croppedImageUrl, src } = this.state;
    this.props.imageSrc != "" && this.getUrl();

    return (
      <>
        {this.props.previewImage != null ? (
          <>
            <PreviewImage
              previewImage={this.props.previewImage}
              closePreview={this.props.closePreview}
              showBackLink={
                this.props.previewImage != null && this.props.mode != VIEW_MODE
              }
              onBack={this.backHandler}
            />
          </>
        ) : (
          <>
            <div className={styles.titleWrapper}>
              <p className={styles.title}>Image Crop</p>
            </div>
            <div className={styles.ImagePreviews}>
              <ReactCrop
                src={src}
                crop={crop}
                ruleOfThirds
                onImageLoaded={this.onImageLoaded}
                onComplete={this.onCropComplete}
                onChange={this.onCropChange}
              />
            </div>
          </>
        )}

        {this.props.previewImage == null && this.props.mode != VIEW_MODE ? (
          <div className={styles.btnwrapper}>
            <Button
              click={this.cancelHandler}
              text="Cancel"
              overrideStyles={{ marginRight: "2rem" }}
            />
            <Button click={this.sendImageUrl} text="Apply" />
          </div>
        ) : (
          // <div className={styles.btnwrapper}>
          //   <Button click={this.props.closePreview} text="Close" />
          // </div><>
          <></>
        )}
      </>
    );
  }
}
