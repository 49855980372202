import React, { Component } from "react";
import styles from "./Terminals.module.scss";
import Pagination from "../Pagination/Pagination";
import Icon from "../Icon/Icon";
import Button from "../Button/Button";
import {
  DYNAMIC,
  PAGE_LIMIT,
  INDEX,
  DEVICE_NAME,
  UPDATE_AT,
  STATUS,
  TERMINALS_OVERLAY,
  VIEW_MODE,
  SYNC_TERMINALS,
  TERMINAL_ID,
  CREATE_TERMINAL_MODE,
  CREATION_TYPE,
  SYNC,
  ENABLED,
  MANUAL,
  SYNC_STATUS,
  LAST_SYNCED_AT,
  EDIT_MODE,
  OWNER_NAME,
  EXTERNAL_OWNER_NAME,
} from "../../Util/constants";
import moment from "moment";
import {
  convertDate,
  getTerminalApiKeyStatus,
  getTerminalCreationType,
  getTypeOfTerminal,
} from "../../Util/globalFunc";
import Swal from "sweetalert2/dist/sweetalert2.js";
import ViewTerminal from "../ViewTerminal/ViewTerminal";
import CreateTerminal from "../CreateTerminal/CreateTerminal";
import SyncTerminals from "../SyncTerminals/SyncTerminals";
import SpinnerLoader from "../SpinnerLoader/SpinnerLoader";
import Controller from "../../Controller";

export default class Terminals extends Component {
  state = {
    selectedTerminal: {},

    mode: "",
  };
  componentDidMount() {
    if (this.props.refDashboard !== undefined) {
      this.props.refDashboard(this.updateStateHandler);
    }
  }

  updateStateHandler = (type) => {
    if (type !== TERMINALS_OVERLAY) {
      this.setState({ selectedTerminal: {}, mode: "" });
    }
  };
  convertDateHandler = (ts) => {
    return moment(ts).format("MM/DD/YYYY");
  };
  getIndexOfPageHandler = (pageIndex) => {
    return 1 + (pageIndex - 1) * PAGE_LIMIT;
  };
  getBackgroundColor = (item) => {
    if (!Object.keys(this.state.selectedTerminal).length) {
      return { backgroundColor: "#fff" };
    } else if (parseInt(item.id) == parseInt(this.state.selectedTerminal.id)) {
      return { backgroundColor: "#1a8092", color: "#fff" };
    } else {
      return { backgroundColor: "#fff" };
    }
  };
  getIconColorHandler = (item) => {
    if (!Object.keys(this.state.selectedTerminal).length) {
      return { color: "#1a8092" };
    } else if (parseInt(item.id) == parseInt(this.state.selectedTerminal.id)) {
      return { color: "#fff" };
    } else {
      return { color: "#1a8092" };
    }
  };

  getIconTypeDevice = (device) => {
    if (
      device.terminalType === 1 ||
      device.terminalType == 3 ||
      device.terminalType == 4
    ) {
      return getTypeOfTerminal(device.terminalType);
    } else if (device.terminalType === 2) {
      return DYNAMIC;
    } else {
      return <span>N/A</span>;
    }
  };
  buildTableHeaderHandler = () => {
    let header = [
      INDEX,
      TERMINAL_ID,
      DEVICE_NAME,
      OWNER_NAME,
      EXTERNAL_OWNER_NAME,
      // UPDATE_AT,
      // LAST_SYNCED_AT,
      SYNC_STATUS,
      ENABLED,
      STATUS,
      CREATION_TYPE,
      "",
    ];
    return header.map((item, index) => {
      let item_h = [item];
      if (this.props.phoneMode) item_h = item.split(" ");
      return (
        <div
          key={index}
          className={styles.textCell}
          style={{ fontWeight: "bold", color: "black" }}
        >
          {item_h.map((k, i) => {
            return (
              <strong key={i}>
                {k}
                {i + 1 < item_h.length && <br />}
              </strong>
            );
          })}
        </div>
      );
    });
  };
  getColorStatus = (isActive) => {
    if (isActive) {
      return "green";
    } else {
      return "red";
    }
  };

  onClickEnableHandler = (item, index) => {
    if (this.props.loading || this.props.vehiclesLoading) return;
    let infoText = "Enable";
    if (item.isActive) infoText = "Disable";
    Swal.fire({
      title: "Are you sure?",
      text: `You want to ${infoText} ${item.deviceName} from terminals.`,
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#00a0dc",
      cancelButtonColor: "#ff1a1a",
      confirmButtonText: "Yes",
    }).then((res) => {
      if (res.dismiss !== "cancel" && res.dismiss !== "backdrop") {
        this.props.onActive(item, index);
      } else {
      }
    });
  };
  onClickDeleteHandler = (item, index) => {
    if (this.props.loading || this.props.vehiclesLoading) return;
    Swal.fire({
      title: "Are you sure?",
      text: `You want to delete ${item.deviceName} from terminals.`,
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#00a0dc",
      cancelButtonColor: "#ff1a1a",
      confirmButtonText: "Yes",
    }).then((res) => {
      if (res.dismiss !== "cancel" && res.dismiss !== "backdrop") {
        this.props.onDelete(item, index);
      } else {
      }
    });
  };
  onClickViewTerminalHandler = (copyDevice) => {
    if (this.props.loading || this.props.vehiclesLoading) return;
    this.scrollTopHandler();
    this.setState({ selectedTerminal: copyDevice, mode: VIEW_MODE }, () => {
      this.props.setCrudLayout();
    });
  };
  scrollTopHandler = () => {
    if (this.props.phoneMode) {
      var elmnt = document.getElementById("top-table");
      if (elmnt !== undefined) {
        elmnt.scrollIntoView({ behavior: "smooth" });
      }
    }
  };
  onClickEditHandler = (copyDevice) => {
    if (this.props.loading || this.props.vehiclesLoading) return;
    this.scrollTopHandler();
    this.setState({ selectedTerminal: copyDevice, mode: EDIT_MODE }, () => {
      this.props.setCrudLayout();
    });
  };
  onChangeNetworkConfigsHandler = (event) => {
    let config = { ...this.state.networkConfig };
    config[event.target.name].value = event.target.value;
    config[event.target.name].error = false;
    this.setState({ networkConfig: config });
  };
  buildTableRowHandler = () => {
    if (
      (this.props.data[this.props.activeIndex] == undefined ||
        this.props.data[this.props.activeIndex].length == 0) &&
      !this.props.loading
    ) {
      return <div className={styles.noDataRow}>No records found</div>;
    } else {
      let listArrayTableRows = null;
      if (this.props.data.length > 0) {
        let indexPage = this.getIndexOfPageHandler(this.props.activeIndex + 1);
        listArrayTableRows = this.props.data[this.props.activeIndex].map(
          (item, index) => {
            return (
              <div key={index} className={styles.tableRow}>
                <div className={styles.textCell}>{index + indexPage}</div>
                {/* <div className={`${styles.textCell} `}>
                  {getTypeOfTerminal(item.terminalType)}
                </div> */}
                <div className={`${styles.textCell} ${styles.externalId}`}>
                  <span className={styles.toolTip}>
                    {item.externalId ? item.externalId : "N/A"}
                  </span>
                  {item.externalId ? item.externalId : "N/A"}
                </div>
                <div className={`${styles.textCell} ${styles.ownerName}`}>
                  <span className={styles.toolTip}>{item.deviceName}</span>
                  {item.deviceName}
                </div>
                <div className={`${styles.textCell} ${styles.ownerName}`}>
                  {Object.keys(item.account).length && item.account.name != null
                    ? item.account.name
                    : "N/A"}
                  <span className={styles.toolTip}>
                    {Object.keys(item.account).length &&
                    item.account.name != null
                      ? item.account.name
                      : "N/A"}
                  </span>
                </div>
                <div className={styles.textCell}>
                  {item.externalOwnerAccountName != null ? item.externalOwnerAccountName : "N/A"}
                </div>   
                {/* <div className={styles.textCell}>
                  {this.convertDateHandler(item.updatedAt)}
                </div>
                <div className={`${styles.textCell} ${styles.syncedAt}`}>
                  {item.lastSyncedAt != null && (
                    <span className={styles.toolTip}>
                      {convertDate(item.lastSyncedAt)}
                    </span>
                  )}
                  {item.lastSyncedAt == null
                    ? "N/A"
                    : convertDate(item.lastSyncedAt)}
                </div> */}
                <div className={styles.textCell}>
                  <span
                    className={styles.enableDot}
                    style={{
                      backgroundColor: this.getColorStatus(
                        item.syncStatus == 1
                      ),
                    }}
                  ></span>
                  <span>{getTerminalApiKeyStatus(item.syncStatus)}</span>
                </div>
                <div className={styles.textCell}>
                  <span
                    className={styles.enableDot}
                    style={{
                      backgroundColor: this.getColorStatus(item.isActive),
                    }}
                  ></span>
                  {item.isActive ? "Yes" : "No"}
                </div>
                <div className={styles.textCell}>
                  <span
                    className={styles.enableDot}
                    style={{
                      backgroundColor: this.getColorStatus(item.isOnline),
                    }}
                  ></span>
                  {item.isOnline ? "Online" : "Offline"}
                </div>
                <div className={styles.textCell}>
                  {item.creationType != null
                    ? getTerminalCreationType(item.creationType)
                    : "N/A"}
                </div>
                <div className={`${styles.textCell} ${styles.iconwrapper}`}>
                  <span
                    className={styles.actionIcon}
                    onClick={() => this.onClickViewTerminalHandler(item)}
                  >
                    <span className={styles.toolTip}>View</span>
                    <Icon
                      defination={{ prefix: "fas", iconName: "info-square" }}
                    />
                  </span>

                  <span
                    className={styles.actionIcon}
                    onClick={() => this.onClickEnableHandler(item, index)}
                    style={{
                      color: item.isActive ? "rgb(0, 181, 0)":"rgb(237, 31, 3)",
                    }}
                  >
                    <span className={styles.toolTip}  style={{
                      color: !item.isActive ? "rgb(0, 181, 0)":"rgb(237, 31, 3)",
                    }}>
                      {item.isActive ? "Disable Terminal" : "Enable Terminal"}
                    </span>
                    <Icon defination={{ prefix: "fas", iconName: "ban" }} />
                  </span>
                  <span
                    className={styles.actionIcon}
                    style={{
                      color:
                        item.creationType != null &&
                        getTerminalCreationType(item.creationType) == SYNC
                          ? "#cbcbcb"
                          : "",
                      cursor:
                        item.creationType != null &&
                        getTerminalCreationType(item.creationType) == SYNC
                          ? "not-allowed"
                          : "",
                    }}
                    {...(item.creationType != null &&
                      getTerminalCreationType(item.creationType) == MANUAL && {
                        onClick: () => this.onClickEditHandler(item, index),
                      })}
                  >
                    <span className={styles.toolTip}>{"Edit Terminal"}</span>
                    <Icon defination={{ prefix: "fas", iconName: "pen" }} />
                  </span>
                  <span
                    className={styles.actionIcon}
                    onClick={() => this.onClickDeleteHandler(item, index)}
                  >
                    <span className={styles.toolTip}>{"Delete Terminal"}</span>
                    <Icon defination={{ prefix: "fas", iconName: "trash-alt" }} />
                  </span>
                </div>
              </div>
            );
          }
        );
      }
      return listArrayTableRows;
    }
  };
  getTableDataHandler = () => {
    return (
      <>
        <div
          className={styles.tableWrapper}
          style={{ opacity: this.props.loading ? 0.6 : 1 }}
          id="form"
        >
          <div className={`${styles.tableRow} `} id="tblHeader">
            {this.buildTableHeaderHandler()}
          </div>
          <div
            className={styles.table}
            style={{
              opacity: this.props.loading ? 0.6 : 1,
            }}
          >
            {this.buildTableRowHandler()}
            <div
              className={styles.spinnerWrapper}
              style={{ display: this.props.loading ? "block" : "none" }}
            >
              <SpinnerLoader msg="" />
            </div>
          </div>
          <Pagination
            style={styles}
            totalRecordsPerPage={
              this.props.data.length > 0
                ? this.props.data[this.props.activeIndex].length
                : ""
            }
            totalRecords={this.props.totalRecords}
            totalPages={Math.ceil(this.props.totalRecords / PAGE_LIMIT)}
            setPage={this.props.onClickPageNumberHandler}
            prev={(type) => this.props.onClickPrevHandler("terminals")}
            data={this.props.data}
            next={(type) => this.props.onClickNextHandler("terminals")}
            disabled={
              this.props.loading ||
              this.props.data.length === 0 ||
              this.state.mode != ""
            }
            pageNumber={this.props.activeIndex + 1}
          />

          <div className={styles.closeBtnWrapper}>
            <Button
              text="Close"
              click={() => this.props.closeModel("")}
              disabled={
                this.props.loading ||
                this.props.vehiclesLoading ||
                this.state.mode != ""
              }
            />
          </div>
        </div>
      </>
    );
  };

  onClickCloseHandler = () => {
    this.props.setCrudLayout();
    this.setState({ mode: "", selectedTerminal: {} });
  };
  onClickSyncTerminalsHandler = () => {
    if (this.props.loading || this.props.vehiclesLoading) return;
    this.scrollTopHandler();
    this.props.setCrudLayout();
    this.setState({ mode: SYNC_TERMINALS }, () => {});
  };
  onClickCreateTerminalsHandler = () => {
    if (this.props.loading || this.props.vehiclesLoading) return;
    this.scrollTopHandler();
    this.props.setCrudLayout();
    this.setState({ mode: CREATE_TERMINAL_MODE }, () => {});
  };
  reloadPageHandler = () => {
    this.props.setCrudLayout();
    this.setState(
      {
        mode: "",
      },
      () => {
        this.props.reloadPage();
      }
    );
  };
  render() {
    return (
      <div className={styles.terminals} id="updatesList">
        <div
          className={this.state.mode != "" ? styles.open : styles.close}
          style={{
            width:
              this.state.mode == SYNC_TERMINALS
                ? this.props.phoneMode
                  ? "100%"
                  : "80%"
                : this.props.phoneMode
                ? "100%"
                : "50%",
          }}
        >
          {(this.state.mode == VIEW_MODE || this.state.mode == EDIT_MODE) && (
            <ViewTerminal
              data={this.state.selectedTerminal}
              mode={this.state.mode}
              close={this.onClickCloseHandler}
              phoneMode={this.props.phoneMode}
              reloadPage={this.reloadPageHandler}
            />
          )}
          {this.state.mode == CREATE_TERMINAL_MODE && (
            <CreateTerminal
              data={this.state.selectedTerminal}
              mode={this.state.mode}
              close={this.onClickCloseHandler}
              phoneMode={this.props.phoneMode}
              reloadPage={this.reloadPageHandler}
            />
          )}
          {this.state.mode == SYNC_TERMINALS && (
            <SyncTerminals
              //data={this.state.selectedTerminal}
              mode={this.state.mode}
              close={this.onClickCloseHandler}
              phoneMode={this.props.phoneMode}
              reloadPage={this.reloadPageHandler}
            />
          )}
        </div>

        <div className={styles.info}>
          <h3 className={styles.header} id="header">
            <span className={styles.icon}>
              <Icon defination={{ prefix: "fal", iconName: "tablet-rugged" }} />
            </span>
            <span className={styles.text}>Terminals</span>
            {Controller.isAuthenticated() && (
              <Button
                text="New Terminal"
                click={this.onClickCreateTerminalsHandler}
                disabled={this.props.loading || this.props.vehiclesLoading}
                overrideStyles={{
                  marginRight: this.props.phoneMode ? "1rem" : "2rem",
                }}
              />
            )}
            {Controller.isAuthenticated() && (
              <Button
                text="Sync Terminals"
                click={this.onClickSyncTerminalsHandler}
                disabled={this.props.loading || this.props.vehiclesLoading}
              />
            )}
          </h3>
          <div className={styles.breakLine}></div>
          <div className={styles.dataWrapper}>
            <div className={styles.searchDeviceWrapper}>
              <span className={styles.iconSearch}>
                <Icon defination={{ prefix: "fas", iconName: "search" }} />
              </span>
              <input
                onChange={(e) => this.props.onChangeTextHandler(e)}
                placeholder="Search Device"
                value={this.props.searchKey}
                className={styles.searchInput}
                type="search"
              />
            </div>
            {this.getTableDataHandler()}
          </div>
        </div>
      </div>
    );
  }
}
