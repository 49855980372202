import React, { Component } from "react";
import styles from "./Users.module.scss";
import Icon from "../Icon/Icon";
import { checkVersionApp } from "../../Util/globalFunc";
import * as route from "../../Util/routes";
import Button from "../Button/Button";
import Pagination from "../Pagination/Pagination";
import Swal from "sweetalert2/dist/sweetalert2.js";
import Controller from "../../Controller";
import CreateUser from "../CreateUser/CreateUser";
import {
  LAST_NAME,
  FIRST_NAME,
  TELEPHONE,
  EMAIL,
  INDEX,
  USERS_OVERLAY,
  UPDATE_VERSION,
  INTITAL_OFFSET,
  PAGE_LIMIT,
  EDIT_MODE,
  CREATE_MODE,
  VIEW_MODE,
  ROLE,
  OWNER_NAME,
} from "../../Util/constants";
import { httpRequest } from "../../RequestManager/HttpRequest";
import { getUsers } from "../../RequestManager/GqlBuilderUser";

import SpinnerLoader from "../SpinnerLoader/SpinnerLoader";
import {
  deleteUser,
  hasFenceCreateWsToken,
  loginAsUser,
} from "../../RequestManager/GqlBuilderAccount";
import { getUserRole } from "../../Util/globalFunc";
import { setAuthToken } from "../../Store/localStorageManager/localStorageManager";

class Users extends Component {
  isTierOne = parseInt(Controller.getEntityTeir()) == 1;
  FLEX_TIER = this.props.phoneMode ? "0 0 13%" : "0 0 13%";
  timer = null;
  _ismounted = true;
  state = {
    loading: false,
    data: [],
    activeIndex: 0,
    totalRecords: 0,
    pageNo: 1,
    offset: INTITAL_OFFSET,
    limit: PAGE_LIMIT,
    searchKey: "",
    originalData: [],
    loading: false,
    selectedUser: {},
    mode: "",
  };
  componentDidMount() {
    if (this.props.refDashboard !== undefined) {
      this.props.refDashboard(this.updateStateHandler);
    }
    this.createHttpRequestHandler();
  }
  componentWillUnmount() {
    this._ismounted = false;
  }
  updateStateHandler = (type) => {
    if (!this._ismounted) return;
    if (type !== USERS_OVERLAY) {
      this.setState({ selectedUser: {}, mode: "" });
    }
  };
  createHttpRequestHandler = (search = "") => {
    if (!this._ismounted) return;
    let params = {
      search: search,
      offset: this.state.offset,
      limit: this.state.limit,
    };
    this.setState({ loading: true }, () => {
      httpRequest(getUsers, params)
        .then((res) => {
          if (!checkVersionApp(res.data.systemVersion)) {
            this.props.closeModel(UPDATE_VERSION);
          } else {
            this.setDataHandler(res.data);
          }
        })
        .catch((err) => {});
    });
  };
  setDataHandler = (data) => {
    let chunk = [...this.state.data];
    let users = data.users.users;
    /**
     * Store the records by spliting in to two arrays
     * If user requested for next+1 records
     */
    if (users.length > PAGE_LIMIT) {
      chunk[this.state.pageNo - 2] = users.slice(0, PAGE_LIMIT);
      chunk[this.state.pageNo - 1] = users.slice(PAGE_LIMIT);
    } else {
      chunk[this.state.pageNo - 1] = users;
    }

    let originalData = [...this.state.originalData, ...users];
    this.setState(
      {
        totalRecords: data.users.count,
        data: chunk,
        originalData: originalData,
      },
      () => {
        this.setState({
          activeIndex: this.getActiveIndexHandler(users.length),
          loading: false,
        });
      }
    );
  };
  onClickPageNumberHandler = (pageNumber) => {
    if (this.state.data[pageNumber - 1] !== undefined) {
      this.setState({
        activeIndex: pageNumber - 1,
        pageNo: pageNumber,
      });
    } else {
      /**
       * offset: 3 (even if pressed next+1 pageno)
       */
      let nuberOfRecordstoFetch =
        (pageNumber - (this.state.activeIndex + 1)) * PAGE_LIMIT;
      if (pageNumber - 1 > this.state.pageNo) {
        this.setState(
          {
            offset: (this.state.activeIndex + 1) * PAGE_LIMIT + 1,
            limit: nuberOfRecordstoFetch,
            pageNo: pageNumber,
          },
          () => {
            this.createHttpRequestHandler();
          }
        );
      } else {
        this.setState(
          {
            offset: (pageNumber - 1) * PAGE_LIMIT + 1,
            pageNo: pageNumber,
            limit: PAGE_LIMIT,
          },
          () => {
            this.createHttpRequestHandler();
          }
        );
      }
    }
  };
  getActiveIndexHandler = (noOfRecords) => {
    let nextIndex = 0;
    if (noOfRecords > PAGE_LIMIT) {
      nextIndex = this.state.pageNo - 1;
    } else {
      nextIndex =
        this.state.activeIndex + 1 === this.state.data.length
          ? this.state.activeIndex
          : this.state.activeIndex + 1;
    }
    return nextIndex;
  };
  onClickPrevHandler = () => {
    let nextIndex =
      this.state.activeIndex - 1 < 0 ? 0 : this.state.activeIndex - 1;
    this.setState({
      activeIndex: nextIndex,
      pageNo: nextIndex + 1,
    });
  };
  onClickNextHandler = () => {
    /**
     * pageNo : is the prev page number here
     * ActiveIndex = PageNo-1
     * offset : (currentPAgeNo -1) * PAGE_LIMIT +1
     */
    if (this.state.data[this.state.pageNo] !== undefined) {
      this.setState({
        activeIndex: this.state.pageNo,
        pageNo: this.state.pageNo + 1,
        activeTableRow: "",
      });
    } else if (
      this.state.pageNo + 1 <=
      Math.ceil(this.state.totalRecords / PAGE_LIMIT)
    ) {
      this.setState({ pageNo: this.state.pageNo + 1 }, () => {
        this.setState(
          {
            offset: (this.state.pageNo - 1) * PAGE_LIMIT + 1,
            limit: PAGE_LIMIT,
          },
          () => {
            this.createHttpRequestHandler();
          }
        );
      });
    }
  };

  onChangeTextHandler = (event) => {
    this.setState({ searchKey: event.target.value }, () => {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.setState(
          {
            offset: INTITAL_OFFSET,
            pageNo: 1,
            activeIndex: 0,
            data: [],
          },
          () => {
            this.createHttpRequestHandler(this.state.searchKey.toLowerCase());
          }
        );
      }, 1000);
    });
  };

  buildTableHeaderHandler = () => {
    let header = this.isTierOne
      ? [INDEX, FIRST_NAME, LAST_NAME, EMAIL, TELEPHONE, ROLE, OWNER_NAME, ""]
      : [INDEX, FIRST_NAME, LAST_NAME, EMAIL, TELEPHONE, ROLE, ""];
    return header.map((item, index) => {
      return (
        <div
          key={index}
          className={styles.textCell}
          style={{ flex: this.isTierOne && index !== 0 ? this.FLEX_TIER : "" }}
        >
          <strong>{item}</strong>
        </div>
      );
    });
  };
  getIndexOfPageHandler = (pageIndex) => {
    return 1 + (pageIndex - 1) * PAGE_LIMIT;
  };

  onClickDeleteHandler = (user) => {
    if (user.lookupId == Controller.getUserId()) {
      this.fireSwalHandler(
        "Warning",
        "You cannot delete a logged in user.",
        "warning"
      );
    } else {
      Swal.fire({
        title: "Are you sure?",
        text: `You want to delete ${user.firstName} ${user.lastName}.`,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#00a0dc",
        cancelButtonColor: "#ff1a1a",
        confirmButtonText: "Yes",
      }).then((res) => {
        if (res.dismiss !== "cancel" && res.dismiss !== "backdrop") {
          this.deleteRecordHandler(user);
        } else {
        }
      });
    }
  };
  /** login as user  */
  onClickLoginAsUserHandler = (user) => {
    if (user.lookupId == Controller.getUserId()) {
      return;
    } else {
      Swal.fire({
        title: "Are you sure?",
        text: `You about to login as ${user.firstName} ${user.lastName}.`,
        type: "info",
        showCancelButton: true,
        confirmButtonColor: "#00a0dc",
        cancelButtonColor: "#ff1a1a",
        confirmButtonText: "Yes",
      }).then((res) => {
        if (res.dismiss !== "cancel" && res.dismiss !== "backdrop") {
          this.loginAsUserHandler(user);
        } else {
        }
      });
    }
  };
  loginAsUserHandler = (user) => {
    this.setState({ loading: true }, () => {
      httpRequest(loginAsUser, { lookupId: user.lookupId })
        .then((res) => {
          this.checkLoginAsUserResponseHandler(res);
        })
        .catch((err) => {});
    });
  };
  checkLoginAsUserResponseHandler = (res) => {
    if (res.errors !== undefined) {
      this.fireSwalHandler(
        "Warning",
        res.errors[0].message.replace(/_/g, " "),
        "warning"
      );
      this.setState({ loading: false });
    } else {
      const login = res.data.loginAsUser;
      let apiData = login.account.puapikeyAccount;
      let info = {
        account_id: login.account.lookupId,
        entityTier: login.account.entity.tier,
        name: login.isUser
          ? login.user.firstName + " " + login.user.lastName
          : login.account.name,
        lastName: login.user.lastName,
        firstName: login.user.firstName,
        lookupId: login.user.lookupId,
        email: login.user.email,
        avatar: login.isUser ? login.user.avatar : login.account.logo,
        phone: login.user.phone,
        speedUnit: login.account.speedUnit,
        refreshToken: login.refreshToken,
        location: login.isUser ? login.user.location : login.account.location,
        companyName: login.account.name,
        isUser: login.isUser,
        recommendedTextMsgBytes:
          login.account.recommendedTextMsgBytes != null
            ? login.account.recommendedTextMsgBytes
            : 80,
        apiKey: apiData.length ? apiData[0].apiKey : "",
        userRole: login.user.role.tier,
        groupColor: login.user.defaultColor,
        syncAccountDetailsWithPartner:
          login.account.syncAccountDetailsWithPartner,
        selectedIntegrationPartner:
          login.account.selectedIntegrationPartner != null
            ? login.account.selectedIntegrationPartner.name
            : null,
      };
      setAuthToken(
        JSON.stringify({ token: login.token, refreshToken: login.refreshToken })
      );
      Controller.setAccountInfo(info);
      window.location.reload(true);
    }
  };
  /** login in user end */
  fireSwalHandler = (title, text, type) => {
    Swal.fire({
      title: title,
      text: text,
      type: type,
    }).then((res) => {
      if (type === "success") {
        this.reloadPageHandler();
      }
    });
  };
  checkDeleteResponseHandler = (res) => {
    if (res.errors !== undefined) {
      this.fireSwalHandler(
        "Warning",
        res.errors[0].message.replace(/_/g, " "),
        "warning"
      );
      this.setState({ loading: false });
    } else {
      this.fireSwalHandler("Success", "User was deleted", "success");
    }
  };
  deleteRecordHandler = (user) => {
    this.setState({ loading: true }, () => {
      httpRequest(deleteUser, { lookupId: user.lookupId })
        .then((res) => {
          this.checkDeleteResponseHandler(res);
        })
        .catch((err) => {});
    });
  };
  getBackgroundColor = (item) => {
    if (!Object.keys(this.state.selectedUser).length) {
      return { backgroundColor: "#fff" };
    } else if (item.lookupId == this.state.selectedUser.lookupId) {
      return { backgroundColor: "#ffb637", color: "#fff" };
    } else {
      return { backgroundColor: "#fff" };
    }
  };
  getIconColorHandler = (item) => {
    if (!Object.keys(this.state.selectedUser).length) {
      return { color: "#ffb637" };
    } else if (item.lookupId == this.state.selectedUser.lookupId) {
      return { color: "#fff" };
    } else {
      return { color: "#ffb637" };
    }
  };

  buildTableRowHandler = () => {
    if (
      (this.state.data[this.state.activeIndex] == undefined ||
        this.state.data[this.state.activeIndex].length == 0) &&
      !this.state.loading
    ) {
      return <div className={styles.noDataRow}>No records found</div>;
    } else if (this.state.data.length > 0) {
      return this.state.data[this.state.activeIndex].map((item, index) => {
        let indexPage = this.getIndexOfPageHandler(this.state.activeIndex + 1);
        return (
          <div
            key={index}
            className={styles.tableRow}
            style={this.getBackgroundColor(item)}
          >
            <div className={styles.textCell}>{indexPage + index}</div>
            <div
              className={styles.textCell}
              style={{ flex: this.isTierOne ? this.FLEX_TIER : "" }}
            >
              {item.firstName}
            </div>
            <div
              className={styles.textCell}
              style={{ flex: this.isTierOne ? this.FLEX_TIER : "" }}
            >
              {item.lastName}
            </div>
            <div
              className={styles.textCell}
              style={{
                textTransform: "unset",
                flex: this.isTierOne ? this.FLEX_TIER : "",
              }}
            >
              {item.email}
            </div>
            <div
              className={styles.textCell}
              style={{ flex: this.isTierOne ? this.FLEX_TIER : "" }}
            >
              {item.phone}
            </div>
            <div
              className={styles.textCell}
              style={{ flex: this.isTierOne ? this.FLEX_TIER : "" }}
            >
              {getUserRole(item.role.tier)}
            </div>
            {this.isTierOne && (
              <div
                className={`${styles.textCell} ${styles.accoountName}`}
                style={{ flex: this.isTierOne ? this.FLEX_TIER : "" }}
              >
                <div className={styles.toolTip}>{`${
                  item.accounts[0].name !== null ? item.accounts[0].name : "N/A"
                }`}</div>
                {`${
                  item.accounts[0].name !== null ? item.accounts[0].name : "N/A"
                }`}
              </div>
            )}
            <div
              className={`${styles.textCell} ${styles.iconwrapper}`}
              style={{ flex: this.isTierOne ? this.FLEX_TIER : "" }}
            >
              <span
                className={styles.actionIconDel}
                onClick={() => this.onClickViewHandler(item)}
                style={{
                  color: this.getIconColorHandler(item),
                }}
              >
                <span className={styles.toolTip}>View</span>
                <Icon defination={{ prefix: "fas", iconName: "info-square" }} />
              </span>
              {Controller.isAuthenticated() && (
                <span
                  className={styles.actionIconEdit}
                  onClick={() => this.onClickEditHandler(item, index)}
                  style={{
                    color: this.getIconColorHandler(item),
                  }}
                >
                  <span className={styles.toolTip}>Edit</span>
                  <Icon defination={{ prefix: "fas", iconName: "pen" }} />
                </span>
              )}
              {Controller.isAuthenticated() && (
                <>
                  <span
                    className={styles.actionIconDel}
                    onClick={() => this.onClickDeleteHandler(item)}
                    style={{
                      color: this.getIconColorHandler(item),
                    }}
                  >
                    <span className={styles.toolTip}>Delete</span>
                    <Icon
                      defination={{ prefix: "fas", iconName: "trash-alt" }}
                    />
                  </span>
                  {Controller.isHiskyEntity() && <span
                    className={styles.actionIconDel}
                    onClick={() => this.onClickLoginAsUserHandler(item)}
                    style={{
                      color: this.getIconColorHandler(item),
                      cursor:
                        item.lookupId == Controller.getUserId()
                          ? "not-allowed"
                          : "",
                    }}
                  >
                    <span className={styles.toolTip}>Login as User</span>
                    <Icon defination={{ prefix: "fas", iconName: "user" }} />
                  </span> }
                </>
              )}
            </div>
          </div>
        );
      });
    }
  };

  getTableDataHandler = () => {
    return (
      <>
        <div
          className={styles.tableWrapper}
          style={{ opacity: this.state.loading ? 0.6 : 1 }}
        >
          <div className={`${styles.tableRow} `} id="tblHeader">
            {this.buildTableHeaderHandler()}
          </div>
          <div
            className={styles.table}
            style={{
              opacity: this.state.loading || this.props.showLoader ? 0.6 : 1,
            }}
          >
            {this.buildTableRowHandler()}
            <div
              className={styles.spinnerWrapper}
              style={{ display: this.state.loading ? "block" : "none" }}
            >
              <SpinnerLoader msg="" />
            </div>
          </div>
          <Pagination
            style={styles}
            totalRecordsPerPage={
              this.state.data.length > 0
                ? this.state.data[this.state.activeIndex].length
                : ""
            }
            totalRecords={this.state.totalRecords}
            totalPages={Math.ceil(this.state.totalRecords / PAGE_LIMIT)}
            setPage={this.onClickPageNumberHandler}
            prev={this.onClickPrevHandler}
            data={this.state.data}
            next={this.onClickNextHandler}
            disabled={this.state.loading || this.state.data.length === 0}
            pageNumber={this.state.activeIndex + 1}
          />

          <div className={styles.closeBtnWrapper}>
            <Button
              text="Close"
              click={() => this.props.closeModel("")}
              disabled={this.state.loading}
              // overrideStyles={{
              //   width: "10rem",
              //   backgroundColor: "#1a8092",
              //   color: "#fff",
              // }}
            />
          </div>
        </div>
      </>
    );
  };

  onClickCreateUserHandler = () => {
    this.setState({ mode: CREATE_MODE });
  };

  onClickEditHandler = (data, index) => {
    this.setState({ selectedUser: data, mode: EDIT_MODE, indexUser: index });
  };
  onClickViewHandler = (copyDevice) => {
    this.setState({ selectedUser: copyDevice, mode: VIEW_MODE });
  };
  onClickCloseHandler = () => {
    this.setState({ mode: "", selectedUser: {} });
  };
  reloadPageHandler = () => {
    this.setState(
      {
        activeIndex: 0,
        data: [],
        totalRecords: 0,
        loading: true,
        selectedUser: {},
        mode: "",
        offset: INTITAL_OFFSET,
        limit: PAGE_LIMIT,
        searchKey: "",
      },
      () => {
        this.createHttpRequestHandler();
      }
    );
  };
  render() {
    return (
      <div
        className={
          this.props.openModel === USERS_OVERLAY
            ? styles.User
            : styles.UserClose
        }
      >
        <div
          className={
            this.state.mode != ""
              ? styles.openUpdateUser
              : styles.closeUpdateUser
          }
        >
          {this.state.mode != "" && (
            <CreateUser
              data={this.state.selectedUser}
              mode={this.state.mode}
              reloadPage={this.reloadPageHandler}
              close={this.onClickCloseHandler}
              phoneMode={this.props.phoneMode}
            />
          )}
        </div>

        <div className={styles.info}>
          <h3 className={styles.header} id="header">
            <span className={styles.icon}>
              <Icon defination={{ prefix: "fal", iconName: "user" }} />
            </span>
            <span className={styles.text}> Users</span>
            {Controller.isAuthenticated() && (
              <span className={styles.btnCreateWrapper}>
                <Button
                  text="Create User"
                  click={this.onClickCreateUserHandler}
                  disabled={this.state.loading}
                  // overrideStyles={{
                  //   width: "10rem",
                  //   backgroundColor: "#1a8092",
                  //   color: "#fff",
                  // }}
                />
              </span>
            )}
          </h3>
          <div className={styles.breakLine}></div>
          <div className={styles.dataWrapper}>
            <div className={styles.searchDeviceWrapper}>
              <span className={styles.iconSearch}>
                <Icon defination={{ prefix: "fas", iconName: "search" }} />
              </span>
              <input
                onChange={(e) => this.onChangeTextHandler(e)}
                placeholder="Search Users"
                value={this.state.searchKey}
                className={styles.searchInput}
                type="search"
              />
            </div>
            {this.getTableDataHandler()}
          </div>
        </div>
      </div>
    );
  }
}

export default Users;
