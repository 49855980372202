import { requestApi } from "./RequestManager";

export const httpRequest = (apiBuilderCallback, data, picture = null) => {
  if (picture === null) {
    return requestApi(apiBuilderCallback(data), picture)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        console.log("err http ",err)
        if(err.response.status == 401){
          window.location.reload();
        } else
           return Promise.reject(err.data);
      });
  } else {
    return requestApi(data, picture)
      .then((res) => {
        return Promise.resolve(res);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }
};
