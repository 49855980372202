import React, { Component } from "react";
import clusterM from "../../../assets/ClusterMarker.svg";
import {
  VEHICLE_ONLINE_COLOR,
  VEHICLE_SOS_COLOR,
} from "../../../Util/constants";
import ALERT_ICON from "../../../assets/alertIcon.png";
import styles from "./ClusterMarker.module.scss";
export default class clusterMarker extends Component {
  render() {
    return (
      <>
        <div
          className={styles.ClusterMarkers}
          onClick={() => this.props.click(this.props.markersInCluster)}
        >
          <img src={clusterM} className={styles.marker}></img>
          {this.props.dotColor != "" &&
            (this.props.dotColor == VEHICLE_SOS_COLOR ? (
              <img
                alt="alert-icon"
                className={styles.dotColor}
                src={ALERT_ICON}
              />
            ) : (
              <span
                className={styles.dotColor}
                style={{ backgroundColor: this.props.dotColor }}
              ></span>
            ))}
          {this.props.count && (
            <span className={styles.count}>{this.props.count}</span>
          )}
        </div>
      </>
    );
  }
}
