import React from "react";
import Icon from "../Icon/Icon";

import styles from "./CheckBox.module.scss";

const CheckBox = (props) => {
  return (
    <div className={props.checkBoxWrapper}>
      <input
        onChange={props.click}
        type="checkbox"
        style={props.override}
        name={props.name}
        className={styles.inputCheck}
        id={props.name}
        value={props.value}
        checked={props.value}
        disabled={props.disabled}
      />

      <label
        htmlFor={props.name}
        className={styles.label}
        disabled={props.disabled}
      >
        {props.iconDefination && props.iconDefination != "" && (
          <span className={styles.iconStyles}>
            <Icon defination={props.iconDefination} />
          </span>
        )}
        {props.dotColor && props.dotColor != "" && (
          <span
            className={styles.dotColor}
            style={{ backgroundColor: props.dotColor }}
          ></span>
        )}
        {props.labelMsg}
      </label>
    </div>
  );
};

export default CheckBox;
