import React, { Component } from "react";
import styles from "./ShortcutMenu.module.scss";
import SATELLITE_ICON from "../../../assets/Satellite.svg";
import Icon from "../../Icon/Icon";
export default class ShortcutMenu extends Component {
  getShorcutMenuHandler = () => {
    return (
      <>
        {this.props.data.fencingList.map((item, index) => {
          return (
            <div
              className={styles.item}
              key={index}
              onClick={(e) => {
                e.stopPropagation();
                this.props.onClickFencingItem(item.uuid);
              }}
            >
              <div className={styles.titleWrapper}>
                <span className={styles.satIcon}>
                  <Icon
                    defination={{ prefix: "fas", iconName: "border-outer" }}
                  />
                </span>
                {item.name}
              </div>
            </div>
          );
        })}
      </>
    );
  };
  render() {
    return (
      <div
        className={styles.ShortcutMenus}
        id="shortcutMenu"
        onClick={this.stopPropagationHandler}
      >
        <span
          className={styles.closeIcon}
          onClick={(e) => {
            e.stopPropagation();
            this.props.closeShortMenuForZone();
          }}
        >
          <Icon defination={{ prefix: "fas", iconName: "times" }} />
        </span>
        {this.getShorcutMenuHandler(this.props.data)}
      </div>
    );
  }
}
