import React, { Component } from "react";
import { Apps } from "./App.module.scss";
import Login from "../src/Container/Login/Login";
import { Helmet } from "react-helmet";
import ResetPassword from "../src/Container/ResetPassword/ResetPassword";
import { Switch, Route, withRouter, Redirect } from "react-router-dom";
/**font awesome */
import { config, library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/pro-solid-svg-icons";
import { far } from "@fortawesome/pro-regular-svg-icons";
import { fal } from "@fortawesome/pro-light-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import "@fortawesome/fontawesome-svg-core/styles.css"; // Import the CSS
import Dashboard from "./Container/Dashboard/DashBoard";
import Spinner from "./Component/Spinner/Spinner";
import PageNotFound from "../src/Container/PageNotFound/PageNotFound";
import * as route from "./Util/routes";
import { httpRequest } from "./RequestManager/HttpRequest";
import {
  checkUserAuth,
  checkUserToken,
  hasFenceCreateWsToken,
} from "./RequestManager/GqlBuilderAccount";
import {
  getAuthToken,
  removeAuthToken,
} from "./Store/localStorageManager/localStorageManager";
import Controller from "./Controller";

/**
 * Adding the SVG icons to library
 */

config.autoAddCss = false; // Tell Font Awesome to skip adding the CSS automatically since it's being imported above
library.add(fas, far, fal, fab);
var userAgent;
class App extends Component {
  state = {
    isAuth: null,
    phoneMode: false,
    tabletMode: false,
  };
  componentWillMount() {
    userAgent = navigator.userAgent;
    document.getElementById("lds-spinners").style.display = "none";
  }
  componentDidMount() {
    this.setScreenModes();
    this.checkAuthHandler();
  }
  setScreenModes = () => {
    this.setState(
      {
        phoneMode: parseInt(window.innerWidth) <= 640,
        tabletMode:
          parseInt(window.innerWidth) <= 1024 &&
          parseInt(window.innerWidth) >= 641,
      },
      () => {}
    );
  };
  setAuthHandler = (isAuth, reload = "") => {
    this.setState({ isAuth: isAuth }, () => {
      if (this.state.isAuth) {
        this.props.history.push(route.ROOT);
      } else {
        removeAuthToken();
        if (reload !== "") {
          this.props.history.push(route.LOGIN);
          window.location.reload(true);
        } else if (!window.location.pathname.includes(route.RESETPASSWORD)) {
          this.props.history.push(route.LOGIN);
        }
      }
    });
  };
  checkAuthHandler = () => {
    let info = getAuthToken();
    if (info === null || info === "") {
      this.setAuthHandler(false);
    } else {
      httpRequest(checkUserAuth, {})
        .then((res) => {
          if (res.data.userAuth != null) {
            const { userAuth } = res.data;

            let apiData = userAuth.account.puapikeyAccount;
            let info = {
              speedUnit: userAuth.account.speedUnit,
              syncAccountDetailsWithPartner:
                userAuth.account.syncAccountDetailsWithPartner,
              selectedIntegrationPartner:
                userAuth.account.selectedIntegrationPartner != null
                  ? userAuth.account.selectedIntegrationPartner.name
                  : null,
              account_id: userAuth.account.lookupId,
              name: userAuth.isUser
                ? userAuth.user.firstName + " " + userAuth.user.lastName
                : userAuth.account.name,
              lastName: userAuth.user.lastName,
              firstName: userAuth.user.firstName,
              lookupId: userAuth.user.lookupId,
              email: userAuth.user.email,
              avatar: userAuth.isUser
                ? userAuth.user.avatar
                : userAuth.account.logo,
              phone: userAuth.user.phone,
              location: userAuth.isUser
                ? userAuth.user.location
                : userAuth.account.location,
              recommendedTextMsgBytes:
                userAuth.account.recommendedTextMsgBytes != null
                  ? userAuth.account.recommendedTextMsgBytes
                  : 80,
              companyName: userAuth.account.name,
              isUser: userAuth.isUser,
              apiKey: apiData.length ? apiData[0].apiKey : "",
              userRole: userAuth.user.role.tier,
              groupColor: userAuth.user.defaultColor,
              entityTier: userAuth.account.entity.tier,
              isHiskyEntity: userAuth.user.selectedAccount.entity.tier == 1,
            };
            httpRequest(hasFenceCreateWsToken, {
              excludeRead: false,
              limit: 1,
              offset: 1,
            })
              .then((res) => {
                info.unreadNotificationsCount =
                  res.data.userNotifications.unreadNotificationsCount;
                info.chatUnreadNotificationsCount =
                  res.data.userNotifications.chatUnreadNotificationsCount;
                info.fenceUnreadNotificationsCount =
                  res.data.userNotifications.fenceUnreadNotificationsCount;
                info.sosUnreadNotificationsCount =
                  res.data.userNotifications.sosUnreadNotificationsCount;
                info.hasFencing = res.data.hasFencing;
                info.generateWsToken = res.data.generateWsToken;
                info.generateWsTokenBroadcast =
                  res.data.generateWsTokenbroadcast;
                info.systemVersions = res.data.systemVersions.objs;
                Controller.setAccountInfo(info);

                this.setState({ isAuth: true }, () => {
                  if (this.props.history.location.pathname === route.LOGIN) {
                    this.props.history.push(route.ROOT);
                  }
                });
              })
              .catch((err) => {});
          } else {
            this.setAuthHandler(false);
          }
        })
        .catch((err) => {
          this.setAuthHandler(false);
        });
    }
  };
  getUrlIdHandler = () => {
    let url = document.URL.split("/");
    return url[url.length - 1];
  };
  render() {
    let routes = (
      <Switch>
        <Route
          path={route.LOGIN}
          component={() => (
            <Login
              setAuthuntication={this.setAuthHandler}
              phoneMode={this.state.phoneMode}
            />
          )}
        />
        <Route
          path={route.RESETPASSWORD + "/:id"}
          component={() => (
            <ResetPassword
              setAuthuntication={this.setAuthHandler}
              phoneMode={this.state.phoneMode}
            />
          )}
        />
      </Switch>
    );

    return (
      <div className={Apps}>
        <Helmet>
          {/iPhone/.test(userAgent) && !window.MSStream ? (
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1, maximum-scale=1"
            />
          ) : (
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1,"
            />
          )}
        </Helmet>
        {this.state.isAuth !== null ? (
          this.state.isAuth ? (
            <Switch>
              <Route
                path={route.ROOT}
                component={() => (
                  <Dashboard
                    phoneMode={this.state.phoneMode}
                    tabletMode={this.state.tabletMode}
                    setAuthuntication={this.setAuthHandler}
                  />
                )}
                exact
              />
              <Route
                component={() => (
                  <PageNotFound setAuthuntication={this.setAuthHandler} />
                )}
              />
            </Switch>
          ) : (
            <div>
              {routes}
              {window.location.pathname.includes(route.RESETPASSWORD) ? (
                <Redirect
                  to={route.RESETPASSWORD + "/" + this.getUrlIdHandler()}
                />
              ) : (
                <Redirect to={route.LOGIN} />
              )}
            </div>
          )
        ) : (
          <Spinner />
        )}
      </div>
    );
  }
}

export default withRouter(App);
