import React, { Component } from "react";
import styles from "./RotateCar.module.scss";
import MOBILE_ALERT_ZONE from "../../../assets/mobile-alert-zone.svg";
import MOBILE_ALERT_OFF from "../../../assets/mobile-alert-off.svg";
import { SAVE, ROTATE_ICON_ID } from "../../../Util/constants";
import MapButton from "../MapButton/MapButton";
import Controller from "../../../Controller";
class RotateCar extends Component {
  timer = null;
  isClick = false;
  _isMounted = true;
  // _isSavedHeading  = false;
  state = {
    countRotate: 0,
    isSpinner: false,
    vehicleData: {},
  };
  componentDidMount() {
    if (this.props.refHeadingControl !== undefined) {
      this.props.refHeadingControl(this.setHeadingHandler);
    }
  }
  getAlarmStauseHandler = () => {
    if (
      this.state.vehicleData.showVehicleOutsideFenceAnimation == undefined ||
      !this.state.vehicleData.showVehicleOutsideFenceAnimation
    ) {
      return true;
    }

    return false;
  };
  componentWillUnmount() {
    this._isSavedHeading = false;
  }
  onClickDismissAlarmHandler = () => {
    if (
      this.state.vehicleData.showVehicleOutsideFenceAnimation !== undefined &&
      this.state.vehicleData.showVehicleOutsideFenceAnimation
    ) {
      this.props.onClickDismissAlarm();
    }
  };
  onClickSaveHandler = () => {
    this.props.saveHeadingAdjustment(this.state.countRotate * 90, SAVE);
  };
  setHeadingHandler = (val, vehicleData = {}) => {
    this.setState({ countRotate: val, vehicleData: vehicleData }, () => {});
  };
  onClickRotateHandler = () => {
    clearTimeout(this.timer);
    this.setState(
      {
        isSpinner: true,
        countRotate:
          this.state.countRotate < 3 ? (this.state.countRotate += 1) : 0,
      },
      () => {
        this.timer = setTimeout(() => {
          this.props.saveHeadingAdjustment(this.state.countRotate * 90);
          this.setState({ isSpinner: false });
        }, 100);
      }
    );
  };
  render() {
    var alramStatus = this.getAlarmStauseHandler();

    return (
      <div className={styles.RotateCars}>
        <MapButton
          id={ROTATE_ICON_ID + "4"}
          label={
            this.props.phoneMode ? "" : !alramStatus ? "Alarm On" : "Alarm Off"
          }
          imageClass={styles.imageClass}
          imageType={
            this.props.phoneMode
              ? !alramStatus
                ? MOBILE_ALERT_ZONE
                : MOBILE_ALERT_OFF
              : undefined
          }
          btnAction={this.onClickDismissAlarmHandler}
          showClose={false}
          disabled={Controller.getIsUser() || alramStatus}
          customClass={styles.alarmBtn}
          iconClass={!alramStatus ? styles.iconAlarmOn : styles.iconAlarmOff}
        
        />
        <MapButton
          id={ROTATE_ICON_ID + "0"}
          icon={{ prefix: "fal", iconName: "save" }}
          btnAction={this.onClickSaveHandler}
          showClose={false}
          customClass={styles.iconSave}
          iconClass={`${styles.IconText} `}
        />

        <MapButton
          id={ROTATE_ICON_ID + "1"}
          icon={{ prefix: "fal", iconName: "sync" }}
          btnAction={this.onClickRotateHandler}
          showClose={false}
          customClass={styles.iconRotate}
          isSpinner={this.state.isSpinner}
          customStyles={{
            color: this.state.countRotate > 0 ? "white" : "",
            backgroundColor: this.state.countRotate > 0 ? "green" : "",
          }}
          iconClass={
            this.state.countRotate > 0
              ? `${styles.IconText}  ${styles.iconSync}`
              : `${styles.IconText} `
          }
        />

        <MapButton
          id={ROTATE_ICON_ID + "2"}
          btnAction={this.onClickRotateHandler}
          showClose={false}
          label={
            <span className={styles.IconText}>
              {this.state.countRotate * 90}&#xb0;
            </span>
          }
          customClass={styles.countRotation}
          isSpinner={this.state.isSpinner}
          customStyles={{
            visibility: this.state.countRotate !== 0 ? "visible" : "hidden",
          }}
          iconClass={`${styles.IconText}`}
        />
      </div>
    );
  }
}

export default RotateCar;
