import React, { Component, Fragment } from "react";
import styles from "./DashBoard.module.scss";
import Header from "../Header/Header";
import Controller from "../../Controller";
import Hoc from "../../Hoc";
import Groups from "../../Component/Groups/Groups";
import GoogleMapWrapper from "../../Component/GoogleMap/GoogleMapWrapper/GoogleMapWrapper";
import UpdateAccount from "../../Component/UpdateAccount/UpdateAccount";
import { removeAuthToken } from "../../Store/localStorageManager/localStorageManager";
import Users from "../../Component/Users/Users";
import { httpRequest } from "../../RequestManager/HttpRequest";
import { globalSearch } from "../../RequestManager/GqlBuilderAccount";
import CreateUser from "../../Component/CreateUser/CreateUser";
import SpinnerLoader from "../../Component/SpinnerLoader/SpinnerLoader";
import {
  UPDATE_ACCOUNT_MODEL,
  USERS_OVERLAY,
  VEHICLES_OVERLAY,
  GROUPS_OVERLAY,
  EDIT_MODE,
  UPDATE_VERSION,
  RELOAD,
  CLEAR_HISTORY,
} from "../../Util/constants";
import Vehicles from "../../Component/Vehicles/Vehicles";
import Icon from "../../Component/Icon/Icon";
import VersionUpdate from "../../Component/VersionUpdate/VersionUpdate";
import ClearHistory from "../../Component/ClearHistory/ClearHistory";
import moment from "moment";
class DashBoard extends Component {
  relaeseNotes = Controller.getRelaeseNotes();
  _ref = [];
  _refSearchControl = null;
  _isMount = true;
  state = {
    showRelaeseNote:false,
    openModel: "",
    total: 0,
    resultSearch: [],
    searchVehicle: [],
    openGlobleSearch: false,
    disabledInterval: false,
    openMenuHeader: false,
    searchKey: "",
    iosFullScreen: true,
    openCrudVechile: false,
    accountDetails: {
      name: "",
      email: "",
      avatar: "",
    },
    disableHeaderSearch: false,
    isDetailsOpen: false,
  };
  componentWillUnmount() {
    this._isMount = false;
  }
  openModelHandler = (data) => {
    for (let i = 0; i < this._ref.length; i++) {
      let func = this._ref[i];
      func(data);
    }
    if (
      this.state.openModel === UPDATE_VERSION ||
      this.state.openModel == CLEAR_HISTORY
    )
      return;
    this.setState({ openModel: data,showRelaeseNote:false});
  };
  RefComponentHandler = (ref) => {
    this._ref.push(ref);
  };
  clickOnLogoHandler = () => {
    if (this.state.openModel === UPDATE_VERSION) return;
    this.setState({ openModel: "", openMenuHeader: false });
  };
  closeHeaderHandler = () => {
    this.setState({ openMenuHeader: !this.state.openMenuHeader ,showRelaeseNote:false}, () => {});
  };
  componentDidMount() {
    this.infoUpdatedHandler();
  }
  refSearchControlHandler = (ref) => {
    this._refSearchControl = ref;
  };
  infoUpdatedHandler = () => {
    let accountDetails = JSON.parse(JSON.stringify(this.state.accountDetails));
    let user = Controller.getBasicInfo();
    if (user == null) return;
    accountDetails.name = user.name;
    accountDetails.avatar = user.avatar;
    accountDetails.email = user.email;
    this.setState({ accountDetails: accountDetails }, () => {});
  };
  closeSearchHandler = () => {
    this.setState({ openGlobleSearch: !this.state.openGlobleSearch });
  };
  setCrudLayoutHandler = () => {
    this.setState({ openCrudVechile: !this.state.openCrudVechile });
  };
  openGlobalSearchHandler = () => {
    this.setState({ openGlobleSearch: true ,showRelaeseNote:false});
  };
  setSearchKeyHandler = (key, offset = null) => {
    if (key === "") {
      this.setState(
        {showRelaeseNote:false,
          searchVehicle: [],
          total: 0,
          resultSearch: [],
          searchKey: "",
          openGlobleSearch: false,
        },
        () => {
          this._refSearchControl();
        }
      );
    } else {
      this.setState(
        { disabledInterval: true, mode: "", searchVehicle: [] },
        () => {
          this.openModelHandler(this.state.mode);
          httpRequest(
            globalSearch,
            offset === null
              ? { search: key.trim(), limit: 10, offset: 0 }
              : { search: key.trim(), limit: 0, offset: 11 }
          )
            .then((res) => {
              if (this._isMount) {
                this.setState(
                  {
                    openGlobleSearch: true,
                    disabledInterval: false,
                    searchKey: key,
                    total: res.data.globalSearch.count,
                    resultSearch:
                      offset !== null && key === this.state.searchKey
                        ? [
                            ...this.state.resultSearch,
                            ...res.data.globalSearch.vehicles,
                          ]
                        : res.data.globalSearch.vehicles,
                  },
                  () => {}
                );
              }
            })
            .catch((err) => {
              this.setState({ disabledInterval: false });
            });
        }
      );
    }
  };
  onClickshowRelaeseNoteHandler=()=>{
    this.setState({showRelaeseNote:!this.state.showRelaeseNote ,openMenuHeader:false})
  }
  onClickVechileItemHandler = (item) => {
    this.setState(
      { searchVehicle: [item.lookupId], openGlobleSearch: false ,showRelaeseNote:false},
      () => {
        this._refSearchControl();
      }
    );
  };
  clearSelectedVehicleHandler = () => {
    this.setState({ searchVehicle: [] });
  };
  cancelClearHistoryHandler = () => {
    this.setState({ openModel: "" });
  };
  toggoleIosScreenHandler = () => {
    this.setState({ iosFullScreen: !this.state.iosFullScreen });
  };
  updateVersionHandler = () => {
    removeAuthToken();
    this.props.setAuthuntication(false, RELOAD);
  };
  disableHeaderSearchHandler = (data) => {
    this.setState({ disableHeaderSearch: data });
  };
  manageHeaderHandler = () => {
    this.setState({ isDetailsOpen: !this.state.isDetailsOpen });
  };
  render() {
  
    return (
      <Fragment>
        {this.state.openModel === CLEAR_HISTORY && (
          <ClearHistory
            cancel={this.cancelClearHistoryHandler}
            update={this.updateVersionHandler}
            phoneMode={this.props.phoneMode}
          />
        )}
        <div
          className={styles.Dashboards}
          style={{ opacity: this.state.openModel == CLEAR_HISTORY ? 0.6 : 1 }}
        >
          {this.state.iosFullScreen && (
            <Header
              {...this.props}
              openModel={this.openModelHandler}
              closeHeader={this.closeHeaderHandler}
              headerMenu={this.state.openMenuHeader}
              setSearchKey={this.setSearchKeyHandler}
              refDashboard={this.RefComponentHandler}
              accountDetails={this.state.accountDetails}
              clickOnLogo={this.clickOnLogoHandler}
              onClickshowRelaeseNote={this.onClickshowRelaeseNoteHandler}
              activeLink={this.state.openModel}
              openSearchList={this.openGlobalSearchHandler}
              totalResults={this.state.total}
              disableHeaderSearch={this.state.disableHeaderSearch}
              showHeader={this.state.isDetailsOpen && this.props.phoneMode}
            />
          )}
          {this.state.iosFullScreen &&this.state.showRelaeseNote &&(
            <div className={styles.releaseNotesWrapper}>
              <span className={styles.dateReleaseWrapper}><span>{moment(this.relaeseNotes[0].releasedAt).format("LLL")}</span><span className={styles.closeIcon} onClick={this.onClickshowRelaeseNoteHandler}><Icon defination={{ prefix: "fas", iconName: "times" }} /></span></span>
              {this.relaeseNotes.map((item, index) => {
                return <p className={styles.notes} key={index}>{item.description}</p>;
              })}
            </div>
          )}
          <div
            className={
              !this.state.iosFullScreen
                ? styles.openMapMode
                : this.state.isDetailsOpen && this.props.phoneMode
                ? `${styles.googleMapWrapper} ${styles.googleMapWrapperHeight}`
                : styles.googleMapWrapper
            }
          >
            <div
              className={
                this.state.openModel === USERS_OVERLAY
                  ? this.state.openMenuHeader
                    ? this.props.phoneMode
                      ? styles.fullMode
                      : styles.openModel
                    : styles.fullMode
                  : styles.closeModel
              }
            >
              {this.state.openModel === USERS_OVERLAY && (
                <Users
                  openModel={this.state.openModel}
                  phoneMode={this.props.phoneMode}
                  mode={this.state.openModel}
                  refDashboard={this.RefComponentHandler}
                  closeModel={this.openModelHandler}
                />
              )}
            </div>
            <div
              className={
                this.state.openModel === UPDATE_VERSION
                  ? this.state.openMenuHeader
                    ? this.props.phoneMode
                      ? styles.fullMode
                      : styles.openModel
                    : styles.fullMode
                  : styles.closeModel
              }
            >
              {this.state.openModel === UPDATE_VERSION && (
                <VersionUpdate
                  phoneMode={this.props.phoneMode}
                  mode={this.state.openModel}
                  refDashboard={this.RefComponentHandler}
                  closeModel={this.openModelHandler}
                  updateVersion={this.updateVersionHandler}
                />
              )}
            </div>
            <div
              className={
                this.state.openModel === UPDATE_ACCOUNT_MODEL
                  ? this.state.openMenuHeader
                    ? this.props.phoneMode
                      ? styles.fullMode
                      : styles.openModel
                    : styles.fullMode
                  : styles.closeModel
              }
            >
              {this.state.openModel === UPDATE_ACCOUNT_MODEL &&
                !Controller.getIsUser() && (
                  <UpdateAccount
                    {...this.props}
                    phoneMode={this.props.phoneMode}
                    openModel={this.openModelHandler}
                    infoUpdated={this.infoUpdatedHandler}
                  />
                )}
              {Controller.getIsUser() &&
                this.state.openModel === UPDATE_ACCOUNT_MODEL && (
                  <CreateUser
                    mode={EDIT_MODE}
                    phoneMode={this.props.phoneMode}
                    data={Controller.getBasicInfo()}
                    close={this.openModelHandler}
                    controller={true}
                    infoUpdated={this.infoUpdatedHandler}
                  />
                )}
            </div>

            <div
              className={
                this.state.openModel === VEHICLES_OVERLAY
                  ? this.state.openMenuHeader
                    ? this.state.openCrudVechile
                      ? styles.openModel
                      : `${styles.openModel}  ${styles.expandedTables}`
                    : this.state.openCrudVechile
                    ? styles.fullMode
                    : `${styles.fullMode} ${styles.expandedTables} `
                  : styles.closeModel
              }
            >
              {this.state.openModel === VEHICLES_OVERLAY && (
                <Vehicles
                  openModel={this.state.openModel}
                  mode={this.state.openModel}
                  setCrudLayout={this.setCrudLayoutHandler}
                  phoneMode={this.props.phoneMode}
                  closeModel={this.openModelHandler}
                  refDashboard={this.RefComponentHandler}
                />
              )}
            </div>
            <div
              className={
                this.state.openModel === GROUPS_OVERLAY
                  ? this.state.openMenuHeader
                    ? this.props.phoneMode
                      ? styles.fullMode
                      : styles.openModel
                    : styles.fullMode
                  : styles.closeModel
              }
            >
              {this.state.openModel === GROUPS_OVERLAY && (
                <Groups
                  openModel={this.state.openModel}
                  mode={this.state.openModel}
                  phoneMode={this.props.phoneMode}
                  closeModel={this.openModelHandler}
                />
              )}
            </div>
            <div
              className={
                this.state.openGlobleSearch
                  ? styles.resultSearchOpen
                  : styles.resultSearchClose
              }
            >
              <div className={styles.titleWrapper}>
                <p
                  style={{
                    visibility: this.state.openGlobleSearch
                      ? "visible"
                      : "hidden",
                  }}
                  className={styles.title}
                >
                  Search Results
                </p>
                <span
                  className={styles.icon}
                  onClick={this.closeSearchHandler}
                  style={{
                    visibility: this.state.openGlobleSearch
                      ? "visible"
                      : "hidden",
                  }}
                >
                  <Icon defination={{ prefix: "fas", iconName: "times" }} />
                </span>
              </div>
              <div className={styles.breakLine}></div>
              <div className={styles.listResult}>
                {this.state.resultSearch.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className={
                        this.state.searchVehicle.indexOf(item.lookupId) == -1
                          ? styles.cardWrapper
                          : `${styles.cardWrapper} ${styles.selected}`
                      }
                      onClick={() => {
                        this.onClickVechileItemHandler(item);
                      }}
                    >
                      <div className={styles.item}>
                        <span className={styles.label}>
                          <strong>Full name</strong>
                        </span>
                        <span>
                          {item.leadOperator === null
                            ? "N/A"
                            : `${item.leadOperator.firstName} ${item.leadOperator.lastName}`}
                        </span>
                      </div>
                      <div className={styles.item}>
                        <span className={styles.label}>Terminal ID</span>
                        <span>
                          {item.terminal !== null
                            ? item.terminal.externalId
                            : "N/A"}
                        </span>
                      </div>
                      <div className={styles.item}>
                        <span className={styles.label}>License Number</span>
                        <span>{item.licensePlate.number}</span>
                      </div>
                      <div className={styles.breakLine}></div>
                    </div>
                  );
                })}
                {!this.state.resultSearch.length &&
                  !this.state.disabledInterval && (
                    <p className={styles.noSearchResult}>No result found</p>
                  )}
              </div>
              <div
                className={`${styles.iconPlus}`}
                style={{
                  visibility:
                    this.state.total > this.state.resultSearch.length
                      ? "visible"
                      : "hidden",
                }}
                onClick={
                  this.state.total > this.state.resultSearch.length
                    ? () =>
                        this.setSearchKeyHandler(this.state.searchKey, "offset")
                    : () => {}
                }
              >
                <span className={styles.plusLabel}>Load More</span>
                <Icon defination={{ prefix: "fas", iconName: "plus" }} />
              </div>

              <span
                className={styles.spinnerSearchWrapper}
                style={{
                  display: this.state.disabledInterval ? "block" : "none",
                }}
              >
                <SpinnerLoader />
              </span>
            </div>
            <GoogleMapWrapper
              {...this.props}
              mode={this.state.openModel}
              disabledInterval={this.state.disabledInterval}
              searchVehicle={this.state.searchVehicle}
              refSearchControl={this.refSearchControlHandler}
              searchKey={this.state.searchKey}
              openModel={this.openModelHandler}
              toggoleIosScreen={this.toggoleIosScreenHandler}
              clearSelectedVehicle={this.clearSelectedVehicleHandler}
              disableHeaderSearch={this.disableHeaderSearchHandler}
              manageHeaderView={this.manageHeaderHandler}
            />
          </div>
        </div>
      </Fragment>
    );
  }
}

export default Hoc(DashBoard);
