import React, { Component } from "react";
import Icon from "../Icon/Icon";
import styles from "./ImportFile.module.scss";
export default class ImportFile extends Component {
  render() {
    return (
      <>
        <div className={styles.ImportFiles}>
          <span className={styles.toolTip}>Import CSV file</span>
          <input
            className={styles.file}
            id="my-file"
            accept={this.props.acceptedFiles}
            type="file"
            //disabled={this.props.showLoader}
            onChange={this.props.clickAction}
            onClick={(e) => {
              e.target.value = "";
              if (this.props.isImportTrailOn) {
                this.props.clearTrail();
                e.preventDefault();
              }
            }}
          />

          <label
            htmlFor={"my-file"}
            className={styles.label}
            //disabled={this.props.showLoader}
          >
            <span className={styles.icon}>
              <Icon defination={{ prefix: "fas", iconName: "upload" }} />
            </span>
            {this.props.isImportTrailOn && (
              <span className={styles.closeIcon}>
                <Icon defination={{ prefix: "fas", iconName: "times" }} />
              </span>
            )}
          </label>
        </div>
      </>
    );
  }
}
