import React, { Component } from "react";
import Button from "../Button/Button";
import styles from "./PreviewImage.module.scss";
import {DONE} from "../../Util/constants"
export default class PreviewImage extends Component {
  render() {
    return (
      <>
        <div className={styles.titleWrapper}>
          <p className={styles.title}>Preview</p>
          {this.props.showBackLink!=undefined && this.props.showBackLink && (
            <p className={styles.rightTitle} onClick={this.props.onBack}>
              Back
            </p>
          )}
        </div>
        <div className={styles.PreviewImages}>
          <div className={styles.imageWrapper}>
            <img
              alt="Crop"
              style={{ maxWidth: "100%" }}
              src={this.props.previewImage}
            />
          </div>
        </div>
        <div className={styles.btnwrapper}>
          <Button click={this.props.closePreview} text={DONE} />
        </div>
      </>
    );
  }
}
