import React, { Component } from "react";
import styles from "./Vehicles.module.scss";
import Pagination from "../Pagination/Pagination";
import Icon from "../Icon/Icon";
import Button from "../Button/Button";
import {
  PAGE_LIMIT,
  INDEX,
  TYPE,
  MODEL,
  INTITAL_OFFSET,
  VEHICLES_OVERLAY,
  ASSIGN_TO_ZONE,
  LICENSE_NUMBER,
  VIN_NUMBER,
  ASSIGNED_TERMINAL,
  NAME,
  EDIT_MODE,
  CREATE_MODE,
  MANUFACTURER,
  VIEW_MODE,
  UPDATE_VERSION,
  OWNER_NAME,
  EXTERNAL_OWNER_NAME,
  VEHICLE_NAME,
  OTHER_ALIAS_NAME,
} from "../../Util/constants";

import Swal from "sweetalert2/dist/sweetalert2.js";
import CreateVehicle from "../CreateVehicle/CreateVehicle";
import Terminals from "../../Component/Terminals/Terminals";
import { httpRequest } from "../../RequestManager/HttpRequest";
import {
  deleteVehicle,
  getVehicles,
  getTerminals,
  getVehiclesTerminals,
  activateTerminal,
  deactivateTerminal,
  deleteTerminal,
} from "../../RequestManager/GqlBuilderAccount";
import SpinnerLoader from "../SpinnerLoader/SpinnerLoader";
import Controller from "../../Controller";
import { checkVersionApp, trimStringAtStart } from "../../Util/globalFunc";
const COMBINED = "combined";
const VEHICLES = "vehicles";
const TERMINALS = "terminals";
export default class Vehicles extends Component {
  timer = null;
  terminalsTimer = null;
  _ismounted = true;
  state = {
    data: [],
    activeIndex: 0,
    totalRecords: 0,
    pageNo: 1,
    offset: INTITAL_OFFSET,
    limit: PAGE_LIMIT,
    searchKey: "",
    originalData: [],
    loading: false,
    vehicleDetails: {},
    mode: "",
    ///
    terminalsData: [],
    terminalsActiveIndex: 0,
    terminalsTotalRecords: 0,
    terminalsPageNo: 1,
    terminalsOffset: INTITAL_OFFSET,
    terminalsLimit: PAGE_LIMIT,
    terminalsSearchKey: "",
    terminalsOriginalData: [],
    terminalsLoading: true,
  };

  componentDidMount() {
    if (this.props.refDashboard !== undefined) {
      this.props.refDashboard(this.updateStateHandler);
    }
    this.createHttpRequestHandler(COMBINED);
  }
  componentWillUnmount() {
    this._ismounted = false;
  }
  createHttpRequestHandler = (type = VEHICLES, search = "") => {
    if (!this._ismounted) return;
    let params = {
      search: search.trim(),
      offset: this.state.offset,
      limit: this.state.limit,
    };
    let queryType = getVehiclesTerminals;
    if (type == TERMINALS) {
      queryType = getTerminals;
      params = {
        search: search,
        offset: this.state.terminalsOffset,
        limit: this.state.terminalsLimit,
      };
    } else if (type == VEHICLES) {
      queryType = getVehicles;
    }
    let loadingType = "loading";
    if (type == TERMINALS) loadingType = "terminalsLoading";
    this.setState({ [loadingType]: true }, () => {
      httpRequest(queryType, params)
        .then((res) => {
          if (type == VEHICLES) this.setDataHandler(res.data);
          else if (type == TERMINALS) this.setTerminalsDataHandler(res.data);
          else this.combinedSetDataHandler(res.data);
        })
        .catch((err) => {
          this.setState({ loading: false, terminalsLoading: false });
        });
    });
  };
  setDataHandler = (data) => {
    let chunk = [...this.state.data];
    let vehicles = data.vehicles.vehicles;
    /**
     * Store the records by spliting in to two arrays
     * If user requested for next+1 records
     */
    if (vehicles.length > PAGE_LIMIT) {
      chunk[this.state.pageNo - 2] = vehicles.slice(0, PAGE_LIMIT);
      chunk[this.state.pageNo - 1] = vehicles.slice(PAGE_LIMIT);
    } else {
      chunk[this.state.pageNo - 1] = vehicles;
    }
    let originalData = [...this.state.originalData, ...vehicles];
    this.setState(
      {
        totalRecords: data.vehicles.count,
        data: chunk,
        originalData: originalData,
      },
      () => {
        this.setState({
          activeIndex: this.getActiveIndexHandler(vehicles.length),
          loading: false,
        });
      }
    );
  };
  setTerminalsDataHandler = (data) => {
    let chunk = [...this.state.terminalsData];
    let terminals = data.terminals.terminals;
    /**
     * Store the records by spliting in to two arrays
     * If user requested for next+1 records
     */
    if (terminals.length > PAGE_LIMIT) {
      chunk[this.state.terminalsPageNo - 2] = terminals.slice(0, PAGE_LIMIT);
      chunk[this.state.terminalsPageNo - 1] = terminals.slice(PAGE_LIMIT);
    } else {
      chunk[this.state.terminalsPageNo - 1] = terminals;
    }

    let terminalsOriginalData = [
      ...this.state.terminalsOriginalData,
      ...terminals,
    ];
    this.setState(
      {
        loading: false,
        terminalsTotalRecords: data.terminals.count,
        terminalsData: chunk,
        terminalsOriginalData: terminalsOriginalData,
      },
      () => {
        this.setState({
          terminalsActiveIndex: this.getTerminalsActiveIndexHandler(
            terminals.length
          ),
          terminalsLoading: false,
        });
      }
    );
  };
  combinedSetDataHandler = (data) => {
    if (!checkVersionApp(data.systemVersion)) {
      this.props.closeModel(UPDATE_VERSION);
    } else {
      let chunk = [...this.state.data];
      let terminalsChunk = [...this.state.terminalsData];
      let vehicles = data.vehicles.vehicles;
      /**
       * Store the records by spliting in to two arrays
       * If user requested for next+1 records
       */
      if (vehicles.length > PAGE_LIMIT) {
        chunk[this.state.pageNo - 2] = vehicles.slice(0, PAGE_LIMIT);
        chunk[this.state.pageNo - 1] = vehicles.slice(PAGE_LIMIT);
      } else {
        chunk[this.state.pageNo - 1] = vehicles;
      }

      let terminals = data.terminals.terminals;
      /**
       * Store the records by spliting in to two arrays
       * If user requested for next+1 records
       */
      if (terminals.length > PAGE_LIMIT) {
        terminalsChunk[this.state.pageNo - 2] = terminals.slice(0, PAGE_LIMIT);
        terminalsChunk[this.state.pageNo - 1] = terminals.slice(PAGE_LIMIT);
      } else {
        terminalsChunk[this.state.pageNo - 1] = terminals;
      }

      let terminalsOriginalData = [
        ...this.state.terminalsOriginalData,
        ...terminals,
      ];
      let originalData = [...this.state.originalData, ...vehicles];
      this.setState(
        {
          totalRecords: data.vehicles.count,
          data: chunk,
          originalData: originalData,
          terminalsTotalRecords: data.terminals.count,
          terminalsData: terminalsChunk,
          terminalsOriginalData: terminalsOriginalData,
        },
        () => {
          this.setState({
            activeIndex: this.getActiveIndexHandler(vehicles.length),
            terminalsActiveIndex: this.getActiveIndexHandler(terminals.length),
            loading: false,
            terminalsLoading: false,
          });
        }
      );
    }
  };
  onClickPageNumberHandler = (pageNumber) => {
    if (this.state.loading || this.state.terminalsLoading) return;
    if (this.state.data[pageNumber - 1] !== undefined) {
      this.setState({
        activeIndex: pageNumber - 1,
        pageNo: pageNumber,
      });
    } else {
      /**
       * offset: 3 (even if pressed next+1 pageno)
       */
      let nuberOfRecordstoFetch =
        (pageNumber - (this.state.activeIndex + 1)) * PAGE_LIMIT;
      if (pageNumber - 1 > this.state.pageNo) {
        this.setState(
          {
            offset: (this.state.activeIndex + 1) * PAGE_LIMIT + 1,
            limit: nuberOfRecordstoFetch,
            pageNo: pageNumber,
          },
          () => {
            this.createHttpRequestHandler();
          }
        );
      } else {
        this.setState(
          {
            offset: (pageNumber - 1) * PAGE_LIMIT + 1,
            pageNo: pageNumber,
            limit: PAGE_LIMIT,
          },
          () => {
            this.createHttpRequestHandler();
          }
        );
      }
    }
  };
  onClickTerminalsPageNumberHandler = (pageNumber) => {
    if (this.state.loading || this.state.terminalsLoading) return;
    if (this.state.terminalsData[pageNumber - 1] !== undefined) {
      this.setState({
        terminalsActiveIndex: pageNumber - 1,
        terminalsPageNo: pageNumber,
      });
    } else {
      /**
       * offset: 3 (even if pressed next+1 pageno)
       */
      let nuberOfRecordstoFetch =
        (pageNumber - (this.state.terminalsActiveIndex + 1)) * PAGE_LIMIT;
      if (pageNumber - 1 > this.state.terminalsPageNo) {
        this.setState(
          {
            terminalsOffset:
              (this.state.terminalsActiveIndex + 1) * PAGE_LIMIT + 1,
            terminalsLimit: nuberOfRecordstoFetch,
            terminalsPageNo: pageNumber,
          },
          () => {
            this.createHttpRequestHandler(TERMINALS);
          }
        );
      } else {
        this.setState(
          {
            terminalsOffset: (pageNumber - 1) * PAGE_LIMIT + 1,
            terminalsPageNo: pageNumber,
            terminalsLimit: PAGE_LIMIT,
          },
          () => {
            this.createHttpRequestHandler(TERMINALS);
          }
        );
      }
    }
  };
  getActiveIndexHandler = (noOfRecords) => {
    let nextIndex = 0;
    if (noOfRecords > PAGE_LIMIT) {
      nextIndex = this.state.pageNo - 1;
    } else {
      nextIndex =
        this.state.activeIndex + 1 === this.state.data.length
          ? this.state.activeIndex
          : this.state.activeIndex + 1;
    }
    return nextIndex;
  };
  getTerminalsActiveIndexHandler = (noOfRecords) => {
    let nextIndex = 0;
    if (noOfRecords > PAGE_LIMIT) {
      nextIndex = this.state.terminalsPageNo - 1;
    } else {
      nextIndex =
        this.state.terminalsActiveIndex + 1 === this.state.terminalsData.length
          ? this.state.terminalsActiveIndex
          : this.state.terminalsActiveIndex + 1;
    }
    return nextIndex;
  };
  onClickPrevHandler = () => {
    if (this.state.loading || this.state.terminalsLoading) return;
    let nextIndex =
      this.state.activeIndex - 1 < 0 ? 0 : this.state.activeIndex - 1;
    this.setState({
      activeIndex: nextIndex,
      pageNo: nextIndex + 1,
    });
  };
  onClickTerminalsPrevHandler = () => {
    if (this.state.loading || this.state.terminalsLoading) return;
    let nextIndex =
      this.state.terminalsActiveIndex - 1 < 0
        ? 0
        : this.state.terminalsActiveIndex - 1;
    this.setState({
      terminalsActiveIndex: nextIndex,
      terminalsPageNo: nextIndex + 1,
    });
  };
  onClickTerminalsNextHandler = () => {
    if (this.state.loading || this.state.terminalsLoading) return;
    /**
     * pageNo : is the prev page number here
     * ActiveIndex = PageNo-1
     * offset : (currentPAgeNo -1) * PAGE_LIMIT +1
     */
    if (this.state.terminalsData[this.state.terminalsPageNo] !== undefined) {
      this.setState({
        terminalsActiveIndex: this.state.terminalsPageNo,
        terminalsPageNo: this.state.terminalsPageNo + 1,
      });
    } else if (
      this.state.terminalsPageNo + 1 <=
      Math.ceil(this.state.terminalsTotalRecords / PAGE_LIMIT)
    ) {
      this.setState({ terminalsPageNo: this.state.terminalsPageNo + 1 }, () => {
        this.setState(
          {
            terminalsOffset: (this.state.terminalsPageNo - 1) * PAGE_LIMIT + 1,
            terminalsLimit: PAGE_LIMIT,
          },
          () => {
            this.createHttpRequestHandler(TERMINALS);
          }
        );
      });
    }
  };
  onClickNextHandler = () => {
    if (this.state.loading || this.state.terminalsLoading) return;
    /**
     * pageNo : is the prev page number here
     * ActiveIndex = PageNo-1
     * offset : (currentPAgeNo -1) * PAGE_LIMIT +1
     */
    if (this.state.data[this.state.pageNo] !== undefined) {
      this.setState({
        activeIndex: this.state.pageNo,
        pageNo: this.state.pageNo + 1,
      });
    } else if (
      this.state.pageNo + 1 <=
      Math.ceil(this.state.totalRecords / PAGE_LIMIT)
    ) {
      this.setState({ pageNo: this.state.pageNo + 1 }, () => {
        this.setState(
          {
            offset: (this.state.pageNo - 1) * PAGE_LIMIT + 1,
            limit: PAGE_LIMIT,
          },
          () => {
            this.createHttpRequestHandler();
          }
        );
      });
    }
  };
  updateStateHandler = (type) => {
    if (!this._ismounted) return;
    if (type !== VEHICLES_OVERLAY) {
      this.setState({ vehicleDetails: {}, mode: "" });
    }
  };
  getIndexOfPageHandler = (pageIndex) => {
    return 1 + (pageIndex - 1) * PAGE_LIMIT;
  };
  getBackgroundColor = (item) => {
    if (!Object.keys(this.state.vehicleDetails).length) {
      return { backgroundColor: "#fff" };
    } else if (item.lookupId == this.state.vehicleDetails.lookupId) {
      return { backgroundColor: "#ffb637", color: "#fff" };
    } else {
      return { backgroundColor: "#fff" };
    }
  };
  getIconColorHandler = (item) => {
    if (!Object.keys(this.state.vehicleDetails).length) {
      return { color: "#ffb637" };
    } else if (item.lookupId == this.state.vehicleDetails.lookupId) {
      return { color: "#fff" };
    } else {
      return { color: "#ffb637" };
    }
  };

  buildTableHeaderHandler = () => {
    let header = [
      INDEX,
      VEHICLE_NAME,   
       MODEL,
      MANUFACTURER,   
      OWNER_NAME,
      TYPE,
      LICENSE_NUMBER,
      ASSIGNED_TERMINAL,
      ASSIGN_TO_ZONE,
      "",
    ];
    return header.map((item, index) => {
      let item_h = [item];
      if (this.props.phoneMode) item_h = item.split(" ");
      return (
        <div key={index} className={styles.textCell}>
          {item_h.map((k, i) => {
            return (
              <strong key={i}>
                {k}
                {i + 1 < item_h.length && <br />}
              </strong>
            );
          })}
        </div>
      );
    });
  };

  onClickEditHandler = (copyDevice, index) => {
    if (this.state.loading || this.state.terminalsLoading) return;
    this.props.setCrudLayout();
    this.setState({ vehicleDetails: copyDevice, mode: EDIT_MODE });
  };
  onClickDeleteHandler = (item) => {
    if (this.state.loading || this.state.terminalsLoading) return;
    Swal.fire({
      title: "Are you sure?",
      text: `You want to delete ${item.name} from vehicles.`,
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#00a0dc",
      cancelButtonColor: "#ff1a1a",
      confirmButtonText: "Yes",
    }).then((res) => {
      if (res.dismiss !== "cancel" && res.dismiss !== "backdrop") {
        this.deleteRecordHandler(item);
      }
    });
  };
  fireSwalHandler = (title, text, type) => {
    Swal.fire({
      title: title,
      text: text,
      type: type,
    }).then((res) => {
      if (type === "success") {
        this.reloadPageHandler(COMBINED);
      }
    });
  };
  checkDeleteResponseHandler = (res) => {
    if (res.errors !== undefined) {
      this.fireSwalHandler(
        "Warning",
        res.errors[0].message.replace(/_/g, " "),
        "warning"
      );
      this.setState({ loading: false });
    } else {
      this.fireSwalHandler("Success", "Vehicle was deleted", "success");
    }
  };
  deleteRecordHandler = (item) => {
    if (this.state.loading || this.state.terminalsLoading) return;
    this.setState({ loading: true }, () => {
      httpRequest(deleteVehicle, { lookupId: item.lookupId })
        .then((res) => {
          this.checkDeleteResponseHandler(res);
        })
        .catch((err) => {});
    });
  };
  onClickViewHandler = (copyDevice) => {
    if (this.state.loading || this.state.terminalsLoading) return;
    this.props.setCrudLayout();
    this.setState({ vehicleDetails: copyDevice, mode: VIEW_MODE });
  };

  buildTableRowHandler = () => {
    if (
      (this.state.data[this.state.activeIndex] == undefined ||
        this.state.data[this.state.activeIndex].length == 0) &&
      !this.state.loading
    ) {
      return <div className={styles.noDataRow}>No records found</div>;
    } else {
      let listArrayTableRows = null;
      if (this.state.data.length > 0) {
        let indexPage = this.getIndexOfPageHandler(this.state.activeIndex + 1);
        listArrayTableRows = this.state.data[this.state.activeIndex].map(
          (item, index) => {
            return (
              <div
                key={index}
                className={styles.tableRow}
                style={this.getBackgroundColor(item)}
              >
                <div className={styles.textCell}>{index + indexPage}</div>
                <div className={`${styles.textCell} `}>
                  {item.name ? item.name : "N/A"}
                </div>
                 <div className={`${styles.textCell} `}>
                  {item.model ? item.model : "N/A"}
                </div>
                <div className={`${styles.textCell} `}>
                  {item.make ? item.make : "N/A"}
                </div>
                <div className={`${styles.textCell} ${styles.ownerName}`}>
                  {Object.keys(item.ownerAccount).length &&
                  item.ownerAccount.name != null
                    ? item.ownerAccount.name
                    : "N/A"}
                  <span className={styles.toolTip}>
                    {Object.keys(item.ownerAccount).length &&
                    item.ownerAccount.name != null
                      ? item.ownerAccount.name
                      : "N/A"}
                  </span>
                </div>
               
                <div className={styles.textCell}>
                  {item.vClass != null ? item.vClass.displayName.toLowerCase()=='other'?OTHER_ALIAS_NAME:item.vClass.displayName : "N/A"}
                </div>
                <div className={styles.textCell}>
                  {item.licensePlate != null ? item.licensePlate.number : "N/A"}
                </div>   
                {/* <div className={styles.textCell}>
                  {item.terminal != null ? item.terminal.externalOwnerAccountName : "N/A"}
                </div>             */}
                <div className={`${styles.textCell} ${styles.externalId}`}>
                  <span className={styles.toolTip}>
                    {item.terminal != null ? item.terminal.deviceName : "N/A"}
                  </span>
                  {item.terminal != null
                    ? this.props.phoneMode
                      ? trimStringAtStart(item.terminal.externalId, 7)
                      : item.terminal.externalId
                    : "N/A"}
                </div>
                <div className={styles.textCell}>
                  {item.fences.length ? "ON" : "OFF"}
                </div>
                <div className={`${styles.textCell} ${styles.iconwrapper}`}>
                  <span
                    style={{
                      color: this.getIconColorHandler(item),
                    }}
                    className={styles.actionIconDel}
                    onClick={() => this.onClickViewHandler(item)}
                  >
                    <span className={styles.toolTip}>View</span>
                    <Icon
                      defination={{ prefix: "fas", iconName: "info-square" }}
                    />
                  </span>
                  {Controller.isAuthenticated() && (
                    <span
                      className={styles.actionIconEdit}
                      onClick={() => this.onClickEditHandler(item, index)}
                      style={{
                        color: this.getIconColorHandler(item),
                      }}
                    >
                      <span className={styles.toolTip}>Edit</span>
                      <Icon defination={{ prefix: "fas", iconName: "pen" }} />
                    </span>
                  )}
                  {Controller.isAuthenticated() && (
                    <span
                      className={styles.actionIconDel}
                      onClick={() => this.onClickDeleteHandler(item)}
                      style={{
                        color: this.getIconColorHandler(item),
                      }}
                    >
                      <span className={styles.toolTip}>Delete</span>
                      <Icon
                        defination={{ prefix: "fas", iconName: "trash-alt" }}
                      />
                    </span>
                  )}
                </div>
              </div>
            );
          }
        );
      }
      return listArrayTableRows;
    }
  };
  reloadPageHandler = (reloadType) => {
    this.setState(
      {
        loading: true,
        activeIndex: 0,
        totalRecords: 0,
        pageNo: 1,
        offset: INTITAL_OFFSET,
        limit: PAGE_LIMIT,
        mode: "",
        vehicleDetails: {},
        searchKey: "",
        terminalsSearchKey: "",
      },
      () => {
        this.props.setCrudLayout();
        this.createHttpRequestHandler(reloadType);
      }
    );
  };
  reloadTerminalsPageHandler = () => {
    this.setState(
      {
        data: [],
        activeIndex: 0,
        totalRecords: 0,
        pageNo: 1,
        offset: INTITAL_OFFSET,
        limit: PAGE_LIMIT,
        terminalsLoading: true,
        terminalsActiveIndex: 0,
        terminalsTotalRecords: 0,
        terminalsPageNo: 1,
        terminalsOffset: INTITAL_OFFSET,
        terminalsLimit: PAGE_LIMIT,
        mode: "",
        loading: true,
      },
      () => {
        this.createHttpRequestHandler(COMBINED);
      }
    );
  };
  getTableDataHandler = () => {
    return (
      <>
        <div
          className={styles.tableWrapper}
          style={{ opacity: this.state.loading ? 0.6 : 1 }}
          id="form"
        >
          <div className={`${styles.tableRow} `} id="tblHeader">
            {this.buildTableHeaderHandler()}
          </div>
          <div
            className={styles.table}
            style={{
              opacity: this.state.loading || this.props.showLoader ? 0.6 : 1,
            }}
          >
            {this.buildTableRowHandler()}
            <div
              className={styles.spinnerWrapper}
              style={{ display: this.state.loading ? "block" : "none" }}
            >
              <SpinnerLoader msg="" />
            </div>

            <Pagination
              style={styles}
              totalRecordsPerPage={
                this.state.data.length > 0
                  ? this.state.data[this.state.activeIndex].length
                  : ""
              }
              totalRecords={this.state.totalRecords}
              totalPages={Math.ceil(this.state.totalRecords / PAGE_LIMIT)}
              setPage={this.onClickPageNumberHandler}
              prev={this.onClickPrevHandler}
              data={this.state.data}
              next={this.onClickNextHandler}
              disabled={this.state.loading || this.state.data.length === 0}
              pageNumber={this.state.activeIndex + 1}
            />
          </div>
        </div>
      </>
    );
  };

  onChangeInputsHandler = (event) => {
    let copyTermianl = { ...this.state.terminal };
    copyTermianl[event.target.name].value = event.target.value;
    copyTermianl[event.target.name].error = false;
    this.setState({ terminal: copyTermianl });
  };

  onClickCreateHandler = () => {
    if (this.state.loading || this.state.terminalsLoading) return;
    this.props.setCrudLayout();
    this.setState({ mode: CREATE_MODE });
  };
  onChangeTextHandler = (e) => {
    if (this.state.loading || this.state.terminalsLoading) return;
    this.setState({ searchKey: e.target.value }, () => {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.setState(
          {
            offset: INTITAL_OFFSET,
            pageNo: 1,
            activeIndex: 0,
            data: [],
          },
          () => {
            this.createHttpRequestHandler(
              VEHICLES,
              this.state.searchKey.toLowerCase()
            );
          }
        );
      }, 1000);
    });
  };

  onChangeTerminalsTextHandler = (e) => {
    if (this.state.loading || this.state.terminalsLoading) return;
    this.setState({ terminalsSearchKey: e.target.value }, () => {
      clearTimeout(this.terminalsTimer);
      this.terminalsTimer = setTimeout(() => {
        this.setState(
          {
            terminalsOffset: INTITAL_OFFSET,
            terminalsPageNo: 1,
            terminalsActiveIndex: 0,
            terminalsData: [],
          },
          () => {
            this.createHttpRequestHandler(
              TERMINALS,
              this.state.terminalsSearchKey.toLowerCase()
            );
          }
        );
      }, 1000);
    });
  };
  onClickActiveHandler = (item, index) => {
    this.setState({ terminalsLoading: true }, () => {
      let copyTermianl = [...this.state.terminalsData];
      let queryType = activateTerminal;
      if (copyTermianl[this.state.terminalsActiveIndex][index].isActive) {
        queryType = deactivateTerminal;
      }
      httpRequest(queryType, { lookupId: item.lookupId })
        .then((res) => {
          if (res.errors !== undefined) {
            this.fireSwalHandler(
              "Warning",
              res.errors[0].message.replace(/_/g, " "),
              "warning"
            );
            this.setState({ terminalsLoading: false });
          } else {
            copyTermianl[this.state.terminalsActiveIndex][index].isActive =
              !copyTermianl[this.state.terminalsActiveIndex][index].isActive;

            Swal.fire({
              title: "Success",
              text: "Updated terminal",
              type: "success",
            });
            this.setState({
              terminalsData: copyTermianl,
              terminalsLoading: false,
            });
          }
        })
        .catch((err) => {});
    });
  };
  onClickDeleteTerminalHandler = (item, index) => {
    this.setState({ terminalsLoading: true }, () => {
      httpRequest(deleteTerminal, { terminalLookupId: item.lookupId })
        .then((res) => {
          if (res.errors !== undefined) {
            this.fireSwalHandler(
              "Warning",
              res.errors[0].message.replace(/_/g, " "),
              "warning"
            );
            this.setState({ terminalsLoading: false });
          } else {
            this.fireSwalHandler("Success", "Terminal was deleted", "success");
          }
        })
        .catch((err) => {});
    });
  };
  onClickCloseHandler = () => {
    this.props.setCrudLayout();
    this.setState({ mode: "", vehicleDetails: {} });
  };
  editVehicleHandler = (obj) => {};
  render() {
    return (
      <>
        <div
          id="top-table"
          className={
            this.props.openModel === VEHICLES_OVERLAY
              ? styles.vehicles
              : styles.TermainlClose
          }
        >
          <div
            className={
              this.state.mode != "" ? styles.openWrapper : styles.closeWrapper
            }
          >
            {this.state.mode != "" && (
              <CreateVehicle
                data={this.state.vehicleDetails}
                mode={this.state.mode}
                close={this.onClickCloseHandler}
                reloadPage={this.reloadPageHandler}
                phoneMode={this.props.phoneMode}
                edit={this.editVehicleHandler}
              />
            )}
          </div>

          <div className={styles.info}>
            <h3 className={styles.header} id="header">
              <span className={styles.icon}>
                <Icon
                  defination={{ prefix: "fal", iconName: "truck-pickup" }}
                />
              </span>
              <span className={styles.text}>Vehicles</span>
              {Controller.isAuthenticated() && (
                <span className={styles.btnCreateWrapper}>
                  <Button
                    text="Create Vehicle"
                    click={this.onClickCreateHandler}
                    disabled={this.state.loading}
                  />
                </span>
              )}
              <span
                className={styles.timesIcon}
                onClick={this.props.closeModel}
              >
                <Icon defination={{ prefix: "fas", iconName: "times" }} />
              </span>
            </h3>
            <div className={styles.breakLine}></div>
            <div className={styles.dataWrapper}>
              <div className={styles.searchDeviceWrapper}>
                <span className={styles.iconSearch}>
                  <Icon defination={{ prefix: "fas", iconName: "search" }} />
                </span>
                <input
                  onChange={(e) => this.onChangeTextHandler(e)}
                  placeholder="Search Vehicle"
                  value={this.state.searchKey}
                  className={styles.searchInput}
                  type="search"
                />
              </div>
              {this.getTableDataHandler()}
            </div>
          </div>
        </div>
        <Terminals
          data={this.state.terminalsData}
          closeModel={this.props.closeModel}
          totalRecords={this.state.terminalsTotalRecords}
          activeIndex={this.state.terminalsActiveIndex}
          loading={this.state.terminalsLoading}
          vehiclesLoading={this.state.loading}
          onClickPageNumberHandler={this.onClickTerminalsPageNumberHandler}
          onClickPrevHandler={this.onClickTerminalsPrevHandler}
          onClickNextHandler={this.onClickTerminalsNextHandler}
          searchKey={this.state.terminalsSearchKey}
          onChangeTextHandler={this.onChangeTerminalsTextHandler}
          reloadPage={this.reloadTerminalsPageHandler}
          onActive={this.onClickActiveHandler}
          onDelete={this.onClickDeleteTerminalHandler}
          setCrudLayout={this.props.setCrudLayout}
          phoneMode={this.props.phoneMode}
        />
      </>
    );
  }
}
