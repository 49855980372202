import React, { Component } from "react";
import PhoneInput from "react-phone-input-2";
import Icon from "../Icon/Icon";
import styles from "./Telephone.module.scss";
export default class Telephone extends Component {
  render() {
    const {
      type,
      label,
      value,
      name,
      inputWrapper,
      placeholder,
      error,
      errMsg,
      blur,
      disabled,
      id,
      min,
      onFoucs,
      autocomplete,
      step,
      hideError = false,
      isRegularText = false,
      inputStyles = {},
      iconStyles = {},
      iconDefination = {},
      format,
      phoneMode,
      defaultCountry,
    } = this.props;

    return (
      <div className={inputWrapper}>
        <label className={isRegularText ? styles.regularLabel : styles.label}>
          <span className={styles.text}>{label} </span>
          {phoneMode && (
            <span
              className={styles.errorMobile}
              style={{ visibility: error ? "visible" : "hidden" }}
            >
              {" "}
              {errMsg}
            </span>
          )}
        </label>
        <PhoneInput
          inputProps={{
            name: name,
            required: true,
            autoFocus: false,
            autoComplete: "off",
          }}
          autocompleteSearch={false}
          disabled={disabled}
          placeholder={placeholder}
          country={defaultCountry}
          value={value}
          onChange={(val) => this.props.change(val)}
          inputClass={styles.input}
          containerClass={` ${styles.genericInput}`}
          //containerClass={styles.inputParent}
        />
        {!phoneMode && (
          <p
            className={styles.error}
            style={{ visibility: error ? "visible" : "hidden" }}
          >
            {errMsg}
          </p>
        )}
      </div>
    );
  }
}
