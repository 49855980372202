import React, { Component } from "react";
import styles from "./Spinner.module.scss";
import LOGO from "../../assets/hiwayLoader.svg";
export default class Spinner extends Component {
  render() {
    return (
      <div className={styles.Spin}>
      <div className={styles.svgLoaderWrapper}>
        <object type="image/svg+xml" data={LOGO} className={styles.imgSpinner}></object>
      </div>
    </div>
    );
  }
}
