import React, { Component } from "react";
import styles from "./Marker.module.scss";
import { getImageUrl } from "../../../Util/globalFunc";
import SOS from "../../../assets/sos.svg";
import SOSNOTIFICATION from "../../../assets/notification&sos.svg";
import SOS_SEEN from "../../../assets/sos-seen.svg";
import SOSNOTIFICATION_SEEN from "../../../assets/notification&sos-seen.svg";
import NOTIFICATION from "../../../assets/notification.svg";
import {
  DEEP_ZOOM_LEVEL,
  DOT_MARKER,
  MAX_LICENSE_NUMER_LENGTH,
} from "../../../Util/constants";
import Icon from "../../Icon/Icon";
class Marker extends Component {
  checkStatusSOSHandler = () => {
    return (
      this.props.details.sosAlert.alertVehiclealertupdatemessage != null &&
      (this.props.details.sosAlert.alertVehiclealertupdatemessage.status == 1 ||
        this.props.details.sosAlert.alertVehiclealertupdatemessage.status == 6)
    );
  };
  render() {
    const { details } = this.props;
        // let licenseNumber = this.props.licenseNumber;
    // if (
    //   this.props.licenseNumber != undefined &&
    //   this.props.licenseNumber != null &&
    //   this.props.licenseNumber.length >= MAX_LICENSE_NUMER_LENGTH
    // ) {
    //   licenseNumber =
    //     ".." +
    //     this.props.licenseNumber.slice(
    //       this.props.licenseNumber.length - MAX_LICENSE_NUMER_LENGTH
    //     );
    // }
    return this.props.markerType == DOT_MARKER ? (
      <>
        <span
          className={styles.dotIcon}
          onContextMenu={(e) => this.props.onRightClickDot(e, this.props)}
        >
          <Icon defination={{ prefix: "fas", iconName: "circle" }} />
        </span>
      </>
    ) : (
      <>
        <div
          style={{
            display:
              details.showVehicleOutsideFenceAnimation ||
              details.exitFenceAlert !== null
                ? "block"
                : "none",
            transform:
              this.props.heading !== null
                ? `translate(-50%,-50%) rotate(${this.props.heading}deg)`
                : "",
          }}
          className={
            details.showVehicleOutsideFenceAnimation
              ? styles.backgroundColorAlertZoneOn
              : styles.backgroundColorAlertZoneOff
          }
        ></div>
        <>
          <div
            className={styles.custommarker}
            onClick={
              this.props.markerClick != undefined
                ? () => this.props.markerClick(this.props.details)
                : () => {}
            }
          >
            <img
              src={getImageUrl(this.props.type, this.props.isOnline)}
              className={
                this.props.getcurrentZoomLevel != undefined &&
                this.props.getcurrentZoomLevel() <= 5
                  ? styles.imageStyles //3.5
                  : this.props.getcurrentZoomLevel != undefined &&
                    this.props.getcurrentZoomLevel() <= 13
                  ? styles.imageStylesMidRage ////3.2
                  : styles.imageStylesMin ////2.9
              }
              style={{
                transform: `rotate(${
                  this.props.heading != null ? this.props.heading : 0
                }deg)`,
                //left: this.props.type == "" ? "-1.5rem" : "",
              }}
            />
            <img
              style={{
                transform:
                  details.sosAlert == null && details.newMessageAlert !== null
                    ? "translate(2.3rem,0)"
                    : "",
                display:
                  details.sosAlert == null && details.newMessageAlert == null
                    ? "none"
                    : "",
              }}
              className={styles.imgNotification}
              src={
                details.sosAlert !== null && details.newMessageAlert !== null
                  ? this.checkStatusSOSHandler()
                    ? SOSNOTIFICATION_SEEN
                    : SOSNOTIFICATION
                  : details.sosAlert !== null
                  ? this.checkStatusSOSHandler()
                    ? SOS_SEEN
                    : SOS
                  : NOTIFICATION
              }
              alt="sos-icon-and-notification"
            />
          </div>
          {/* {(this.props.showLicense ||
            details.sosAlert !== null ||
            details.newMessageAlert !== null) && (
            <div
              className={styles.vinNumber}
              style={{
                backgroundColor:
                  this.props.viewVehicleLookupId == details.lookupId
                    ? "#00bdd6"
                    : "",
              }}
            >
              <span>{licenseNumber}</span>
            </div>
          )} */}
        </>
      </>
    );
  }
}
export default Marker;
