import React, { Component } from "react";
import styles from "./UploadFile.module.scss";
import Icon from "../Icon/Icon";
export default class UploadFile extends Component {
  state = {
    value: "",
  };
  render() {
    return (
      <>
        <div className={this.props.inputWrapper}>
          <div className={styles.inputInfoWrapper}>
            <>
              <input
                className={styles.file}
                id="my-file"
                accept={this.props.acceptedFiles}
                type="file"
                disabled={this.props.loading}
                onChange={this.props.clickAction}
                value={this.state.value}
                onClick={() => {
                  this.setState({ value: "" });
                }}
              />

              <label
                htmlFor="my-file"
                className={styles.label}
                disabled={this.props.loading}
              >
                <span className={styles.text}>{this.props.label}</span>
                {this.props.phoneMode && (
                  <span
                    style={{
                      visibility: this.props.error ? "visible" : "hidden",
                    }}
                  >
                    {this.props.errMsg}
                  </span>
                )}
              </label>
            </>
            <label htmlFor="my-file" className={styles.placeholderText}>
              {this.props.fileName !== ""
                ? this.props.fileName
                : this.props.placeholder}
            </label>
          </div>
          {!this.props.phoneMode && (
            <p
              className={styles.error}
              style={{ visibility: this.props.error ? "visible" : "hidden" }}
            >
              {this.props.errMsg}
            </p>
          )}
        </div>
      </>
    );
  }
}
