import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const Icon = (props) => {
  return (
    <FontAwesomeIcon
      icon={[props.defination.prefix, props.defination.iconName]}
      spin={props.isSpinner}
      rotate={props.rotate}
      flip={
        props.flip != undefined && props.flip != "" ? this.props.flip : null
      }
    />
  );
};
export default Icon;
