import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import styles from "./Login.module.scss";
import Input from "../../Component/Input/Input";
import Button from "../../Component/Button/Button";
import Icon from "../../Component/Icon/Icon";
import SamllHiway from "../../assets/hisky-logo.svg";
import HiWayMobileLogo from "../../assets/mobile/hiWayLogoGrey.svg";
import HiWayLogo from "../../assets/main-hisky-login.svg";
import { isEmail } from "validator";
import Telephone from "../../Component/Telephone/Telephone";
import { httpRequest } from "../../RequestManager/HttpRequest";
import {
  buildLoginRequest,
  buildCreateAccountRequest,
  buildResetPwdRequest,
  hasFenceCreateWsToken,
} from "../../RequestManager/GqlBuilderAccount";
import Controller from "../../Controller";
import {
  setAuthToken,
  storeInLocalStorage,
} from "../../Store/localStorageManager/localStorageManager";
import LogoBottom from "../../assets/mobile/whiteHiskyLogoMobile.svg";

class Login extends Component {
  state = {
    view: 1,
    login: {
      email: {
        value: "",
        error: false,
        name: "email",
        type: "text",
      },
      password: {
        value: "",
        error: false,
        name: "password",
        type: "password",
      },
    },
    createAccount: {
      email: {
        value: "",
        error: false,
        name: "email",
        type: "text",
      },
      password: {
        value: "",
        error: false,
        name: "password",
        type: "password",
      },
      firstName: {
        value: "",
        error: false,
        name: "firstName",
        type: "text",
      },
      lastName: {
        value: "",
        error: false,
        name: "lastName",
        type: "text",
      },
      company: {
        value: "",
        error: false,
        name: "company",
        type: "text",
      },
      telephone: {
        value: "",
        error: false,
        name: "telephone",
        type: "text",
      },
    },
    forgotPassword: {
      resetEmail: {
        value: "",
        error: false,
        name: "email",
        type: "text",
      },
    },
    loading: false,
    errorMsg: "",
    infoMsg: "",
  };
  componentDidMount() {}
  onChangeLoginHandler = (event) => {
    if (this.state.loading) return;
    let coptLogin = { ...this.state.login };
    coptLogin[event.target.name].value = event.target.value;
    coptLogin[event.target.name].error = false;
    this.setState({ login: coptLogin, errorMsg: "" });
  };
  onChangeCreateAccountHandler = (event) => {
    if (this.state.loading) return;
    let copycreate = { ...this.state.createAccount };
    copycreate[event.target.name].value = event.target.value;
    copycreate[event.target.name].error = false;
    this.setState({ createAccount: copycreate, errorMsg: "" });
  };
  onChangeResetEmailHandler = (event) => {
    if (this.state.loading) return;
    let copyResetEmail = { ...this.state.forgotPassword };
    copyResetEmail[event.target.name].value = event.target.value;
    copyResetEmail[event.target.name].error = false;
    this.setState({
      forgotPassword: copyResetEmail,
      errorMsg: "",
      infoMsg: "",
    });
  };
  onChangePhoneHandler = (value) => {
    if (this.state.loading) return;
    let copycreate = { ...this.state.createAccount };
    copycreate.telephone.value = value;
    copycreate.telephone.error = false;
    this.setState({ createAccount: copycreate, errorMsg: "" });
  };
  checkValidSigninHandler = () => {
    let isValid = true;
    let copyUser = { ...this.state.login };
    if (copyUser.email.value === "") {
      copyUser.email.error = true;
      isValid = false;
    }
    if (copyUser.password.value === "") {
      copyUser.password.error = true;
      isValid = false;
    }

    this.setState({ login: copyUser });
    return isValid;
  };
  createSigninRequestHandler = () => {
    const user = { ...this.state.login };
    httpRequest(buildLoginRequest, {
      email: user.email.value.toLowerCase().trim(),
      password: user.password.value.trim(),
    })
      .then((res) => {
        this.checkSigninResponeHandler(res);
      })
      .catch((err) => {
        this.setState({
          loading: false,
          errorMsg: "Error login",
        });
      });
  };
  checkSigninResponeHandler = (res) => {
    if (res.errors !== undefined) {
      this.setState({
        loading: false,
        errorMsg: res.errors[0].message.replace(/_/g, " "),
      });
    } else {
      const { login } = res.data;
      let apiData = login.account.puapikeyAccount;
      let info = {
        account_id: login.account.lookupId,
        entityTier: login.account.entity.tier,
        name: login.isUser
          ? login.user.firstName + " " + login.user.lastName
          : login.account.name,
        lastName: login.user.lastName,
        firstName: login.user.firstName,
        lookupId: login.user.lookupId,
        email: login.user.email,
        avatar: login.isUser ? login.user.avatar : login.account.logo,
        phone: login.user.phone,
        speedUnit: login.account.speedUnit,
        refreshToken: login.refreshToken,
        location: login.isUser ? login.user.location : login.account.location,
        companyName: login.account.name,
        isUser: login.isUser,
        recommendedTextMsgBytes:
          login.account.recommendedTextMsgBytes != null
            ? login.account.recommendedTextMsgBytes
            : 80,
        apiKey: apiData.length ? apiData[0].apiKey : "",
        userRole: login.user.role.tier,
        groupColor: login.user.defaultColor,
        syncAccountDetailsWithPartner:
          login.account.syncAccountDetailsWithPartner,
        selectedIntegrationPartner:
          login.account.selectedIntegrationPartner != null
            ? login.account.selectedIntegrationPartner.name
            : null,
        isHiskyEntity: login.user.selectedAccount.entity.tier == 1,
      };
      setAuthToken(
        JSON.stringify({ token: login.token, refreshToken: login.refreshToken })
      );
      httpRequest(hasFenceCreateWsToken, {
        excludeRead: false,
        offset: 1,
        limit: 1,
      })
        .then((res) => {
          info.hasFencing = res.data.hasFencing;
          info.generateWsToken = res.data.generateWsToken;
          info.generateWsTokenBroadcast = res.data.generateWsTokenbroadcast;
          info.unreadNotificationsCount =
            res.data.userNotifications.unreadNotificationsCount;
          info.chatUnreadNotificationsCount =
            res.data.userNotifications.chatUnreadNotificationsCount;
          info.fenceUnreadNotificationsCount =
            res.data.userNotifications.fenceUnreadNotificationsCount;
          info.sosUnreadNotificationsCount =
            res.data.userNotifications.sosUnreadNotificationsCount;
          info.systemVersions = res.data.systemVersions.objs;
          Controller.setAccountInfo(info);
          this.props.setAuthuntication(true);
        })
        .catch((err) => {});
    }
  };

  onClickSigninHandler = () => {
    if (this.state.loading) return;
    //login
    if (this.checkValidSigninHandler()) {
      this.setState({ loading: true }, () => {
        this.createSigninRequestHandler();
      });
    } else {
      this.setState({ loading: false });
    }
  };
  checkValidSignupHandler = () => {
    let isValid = true;
    let copyAccount = { ...this.state.createAccount };

    for (let key in copyAccount) {
      if (
        copyAccount[key].value.trim() === "" &&
        key !== "email" &&
        key !== "telephone"
      ) {
        copyAccount[key].error = true;
        isValid = false;
      } else if (
        key === "email" &&
        (!isEmail(copyAccount[key].value) ||
          copyAccount[key].value.length > 254)
      ) {
        copyAccount[key].error = true;
        isValid = false;
      } else if (
        key == "telephone" &&
        (copyAccount[key].value.trim() == "" ||
          (copyAccount[key].value.trim() !== "" &&
            copyAccount[key].value.length < 7))
      ) {
        copyAccount[key].error = true;
        isValid = false;
      }
    }

    this.setState({
      createAccount: copyAccount,
    });
    return isValid;
  };
  onClickSignupHandler = () => {
    if (this.state.loading) return;

    if (this.checkValidSignupHandler()) {
      this.setState({ loading: true }, () => {
        this.createSignupRequestHandler();
      });
    } else {
      this.setState({ loading: false });
    }
  };
  createSignupRequestHandler = () => {
    const accountInfo = { ...this.state.createAccount };
    httpRequest(buildCreateAccountRequest, {
      user: {
        firstName: accountInfo.firstName.value,
        lastName: accountInfo.lastName.value,
        email: accountInfo.email.value,
        password: accountInfo.password.value,
        phone: accountInfo.telephone.value,
        companyName: accountInfo.company.value,
      },
    })
      .then((res) => {
        return this.checkSignupResponeHandler(res);
      })
      .catch((err) => {});
  };
  checkSignupResponeHandler = (res) => {
    if (res.errors !== undefined) {
      this.setState({
        loading: false,
        errorMsg: res.errors[0].message.replace(/_/g, " "),
      });
    } else {
      const { registerUserAccount } = res.data;
      // let apiData = registerUserAccount.account.puapikeyAccount.filter(
      //   (item) => item.name.toLowerCase() == getHostName()
      // );
      let apiData = registerUserAccount.account.puapikeyAccount;
      let info = {
        account_id: registerUserAccount.account.lookupId,
        name: registerUserAccount.account.name,
        lastName: registerUserAccount.user.lastName,
        firstName: registerUserAccount.user.firstName,
        lookupId: registerUserAccount.user.lookupId,
        email: registerUserAccount.user.email,
        avatar: registerUserAccount.account.logo,
        phone: registerUserAccount.user.phone,
        refreshToken: registerUserAccount.refreshToken,
        location: registerUserAccount.account.location,
        companyName: registerUserAccount.account.name,
        isUser: false,
        apiKey: apiData.length ? apiData[0].apiKey : "",
        userRole: registerUserAccount.user.role.tier,
        groupColor: registerUserAccount.user.defaultColor,
        recommendedTextMsgBytes:
          registerUserAccount.account.recommendedTextMsgBytes !== null
            ? registerUserAccount.account.recommendedTextMsgBytes
            : 80,
        syncAccountDetailsWithPartner:
          registerUserAccount.account.syncAccountDetailsWithPartner,
        selectedIntegrationPartner:
          registerUserAccount.account.selectedIntegrationPartner != null
            ? registerUserAccount.account.selectedIntegrationPartner.name
            : null,
        isHiskyEntity:
          registerUserAccount.user.selectedAccount.entity.tier == 1,
      };
      setAuthToken(
        JSON.stringify({
          token: registerUserAccount.token,
          refreshToken: registerUserAccount.refreshToken,
        })
      );
      httpRequest(hasFenceCreateWsToken, { excludeRead: false })
        .then((res) => {
          info.hasFencing = res.data.hasFencing;
          info.generateWsToken = res.data.generateWsToken;
          info.unreadNotificationsCount =
            res.data.userNotifications.unreadNotificationsCount;
          Controller.setAccountInfo(info);
          this.props.setAuthuntication(true);
        })
        .catch((err) => {});
    }
  };
  switchViewHandler = (view) => {
    if (this.state.loading) return;
    this.setState({ view: view, errorMsg: "", infoMsg: "" });
  };
  checkValidResetPwdHandler = () => {
    let isValid = true;
    let copyUser = { ...this.state.forgotPassword };
    if (
      copyUser.resetEmail.value === "" ||
      !isEmail(copyUser.resetEmail.value)
    ) {
      copyUser.resetEmail.error = true;
      isValid = false;
    }
    this.setState({
      forgotPassword: copyUser,
      errorMsg: !isValid ? "Invalid email" : "",
    });
    return isValid;
  };
  onClickResetPwdHandler = () => {
    if (this.state.loading) return;
    if (this.checkValidResetPwdHandler()) {
      this.setState({ loading: true }, () => {
        this.createResetRequestHandler();
      });
    } else {
      this.setState({ loading: false });
    }
  };
  createResetRequestHandler = () => {
    const user = { ...this.state.forgotPassword };
    httpRequest(buildResetPwdRequest, {
      email: user.resetEmail.value.toLowerCase(),
    })
      .then((res) => {
        //check response
        if (res.errors !== undefined) {
          this.setState({
            loading: false,
            errorMsg: res.errors[0].message.replace(/_/g, " "),
          });
        } else {
          this.setState({
            loading: false,
            infoMsg: res.data.requestResetPassword.message,
          });
        }
      })
      .catch((err) => {});
  };
  onClickIconPasswordHandler = () => {
    let login = { ...this.state.login };
    login.password.type =
      login.password.type == "password" ? "text" : "password";
    this.setState({ login: login });
  };
  getHeaderTitle = () => {
    if (this.state.view == 1) return "Sign In";
    else if (this.state.view == 2) return "Create account";
    else if (this.state.view == 3) return "Reset Password";
  };
  onKeyUpHandler = (e) => {
    if (e.key === "Enter") {
      this.onClickSigninHandler();
    }
  };
  render() {
    const { login, createAccount, forgotPassword } = this.state;

    return (
      <div className={styles.Logins}>
        <div className={styles.leftDiv}>
          <img src={HiWayLogo} className={styles.img} alt="logo-hiway" />
          <div className={styles.bottomIcon}>
            <span className={styles.imgPreview}>Powered by</span>
            <img
              className={styles.logoSatSmall}
              src={SamllHiway}
              alt="hiway-iot-logo-small"
            />
          </div>
        </div>
        <div
          className={styles.imgPhoneHeader}
          onClick={() => this.switchViewHandler(1)}
        >
          <img
            src={HiWayMobileLogo}
            alt="hiway-mobile-logo"
            className={styles.hiwayMobileLogo}
          />
        </div>
        <div className={styles.rightDiv}>
          <div
            className={
              this.state.view !== 1
                ? styles.fromWrapperCreate
                : styles.formWrapper
            }
            style={{ opacity: this.state.loading ? ".6" : "1" }}
          >
            <div className={styles.headerwrapper}>
              <h3 className={styles.signInTitle}>
                {this.getHeaderTitle()}
                <span
                  className={styles.loadingIcon}
                  style={{
                    visibility: this.state.loading ? "visible" : "hidden",
                  }}
                >
                  <Icon
                    defination={{ prefix: "fal", iconName: "spinner" }}
                    isSpinner={true}
                  />
                </span>
              </h3>
              <p
                className={styles.text}
                style={{ display: this.state.view == 1 ? "block" : "none" }}
              >
                don't have an account yet?
              </p>
              {this.state.view === 1 ? (
                <p
                  className={
                    this.state.loading
                      ? `${styles.signUp} ${styles.disabledLink}`
                      : styles.signUp
                  }
                  onClick={() => this.switchViewHandler(2)}
                >
                  Sign up now
                </p>
              ) : (
                <p
                  className={
                    this.state.loading
                      ? `${styles.signUp} ${styles.disabledLink}`
                      : styles.signUp
                  }
                  onClick={() => this.switchViewHandler(1)}
                >
                  <Icon
                    defination={{ prefix: "fas", iconName: "arrow-left" }}
                  />
                  <span style={{ marginLeft: ".3rem" }}>Back</span>
                </p>
              )}
              <p
                className={styles.mainError}
                style={{
                  visibility: this.state.errorMsg != "" ? "visible" : "hidden",
                }}
              >
                {this.state.errorMsg}
              </p>
              <p
                className={styles.mainError}
                style={{
                  visibility: this.state.infoMsg != "" ? "visible" : "hidden",
                }}
              >
                {this.state.infoMsg}
              </p>
            </div>
            {this.state.view === 1 && (
              <div
                className={styles.inputWrapperSignIn}
                onKeyUp={this.onKeyUpHandler}
              >
                <Input
                  label="Email"
                  value={login.email.value}
                  error={login.email.error}
                  errMsg="Invalid email"
                  type={login.email.type}
                  isRegularText={false}
                  name={login.email.name}
                  placeholder="Enter email"
                  phoneMode={this.props.phoneMode}
                  inputWrapper={styles.inputWrapper}
                  change={(e) => this.onChangeLoginHandler(e)}
                />
                <Input
                  label="Password"
                  value={login.password.value}
                  error={login.password.error}
                  errMsg="Invalid password"
                  type={login.password.type}
                  name={login.password.name}
                  isRegularText={false}
                  phoneMode={this.props.phoneMode}
                  placeholder="Enter password"
                  iconDefination={{
                    prefix: "far",
                    iconName:
                      login.password.type == "password" ? "eye-slash" : "eye",
                  }}
                  change={(e) => this.onChangeLoginHandler(e)}
                  inputWrapper={styles.inputWrapper}
                  iconAction={this.onClickIconPasswordHandler}
                />
                <div className={styles.btnWrapper}>
                  <Button
                    text="Sign in"
                    click={this.onClickSigninHandler}
                    disabled={this.state.loading}
                    overrideClass={styles.btn}
                  />
                  <p
                    className={
                      this.state.loading
                        ? `${styles.forgotPassword} ${styles.disabledLink}`
                        : styles.forgotPassword
                    }
                    onClick={() => this.switchViewHandler(3)}
                  >
                    forgot password
                  </p>
                </div>
              </div>
            )}
            {this.state.view === 2 && (
              <Fragment>
                <div className={styles.createAccoutnWrapper}>
                  <Input
                    label="First name"
                    value={createAccount.firstName.value}
                    error={createAccount.firstName.error}
                    errMsg="Invalid First name"
                    type={createAccount.firstName.type}
                    isRegularText={false} //this.props.phoneMode ? true :
                    phoneMode={this.props.phoneMode}
                    name={createAccount.firstName.name}
                    placeholder="Enter first Name"
                    inputWrapper={styles.inputWrapperCreate}
                    change={(e) => this.onChangeCreateAccountHandler(e)}
                  />
                  <Input
                    label="Last name"
                    value={createAccount.lastName.value}
                    error={createAccount.lastName.error}
                    errMsg="Invalid last Name"
                    type={createAccount.lastName.type}
                    isRegularText={false}
                    phoneMode={this.props.phoneMode}
                    name={createAccount.lastName.name}
                    placeholder="Enter last name"
                    inputWrapper={styles.inputWrapperCreate}
                    change={(e) => this.onChangeCreateAccountHandler(e)}
                  />
                  <Input
                    label="Email"
                    value={createAccount.email.value}
                    error={createAccount.email.error}
                    errMsg="Invalid email"
                    type={createAccount.email.type}
                    isRegularText={false}
                    phoneMode={this.props.phoneMode}
                    name={createAccount.email.name}
                    placeholder="Enter email"
                    inputWrapper={styles.inputWrapperCreate}
                    change={(e) => this.onChangeCreateAccountHandler(e)}
                  />
                  <Input
                    label="password"
                    value={createAccount.password.value}
                    error={createAccount.password.error}
                    errMsg="Invalid password"
                    type={createAccount.password.type}
                    isRegularText={false}
                    name={createAccount.password.name}
                    placeholder="Enter password"
                    phoneMode={this.props.phoneMode}
                    inputWrapper={styles.inputWrapperCreate}
                    change={(e) => this.onChangeCreateAccountHandler(e)}
                  />
                  <Input
                    label="Company name"
                    value={createAccount.company.value}
                    error={createAccount.company.error}
                    errMsg="Invalid company name"
                    type={createAccount.company.type}
                    isRegularText={false}
                    name={createAccount.company.name}
                    placeholder="Enter company name"
                    phoneMode={this.props.phoneMode}
                    inputWrapper={styles.inputWrapperCreate}
                    change={(e) => this.onChangeCreateAccountHandler(e)}
                  />
                  <Telephone
                    label="Telephone"
                    value={createAccount.telephone.value}
                    error={createAccount.telephone.error}
                    errMsg="Invalid telephone"
                    type={createAccount.telephone.type}
                    isRegularText={false}
                    name={createAccount.telephone.name}
                    placeholder="Enter telephone"
                    phoneMode={this.props.phoneMode}
                    inputWrapper={styles.inputWrapperCreate}
                    change={(e) => this.onChangePhoneHandler(e)}
                    defaultCountry={"us"} //for no value
                  />
                </div>
                <div className={styles.btnCreate}>
                  <Button
                    text="Sign up"
                    click={this.onClickSignupHandler}
                    disabled={this.state.loading}
                    overrideClass={styles.btn}
                  />
                </div>
              </Fragment>
            )}
            {this.state.view == 3 && (
              <>
                <Input
                  name="resetEmail"
                  inputWrapper={styles.inputWrapper}
                  placeholder="Email here..."
                  label="Email"
                  type={forgotPassword.resetEmail.type}
                  error={forgotPassword.resetEmail.error}
                  phoneMode={this.props.phoneMode}
                  errMsg={forgotPassword.resetEmail.errMsg}
                  change={(e) => this.onChangeResetEmailHandler(e)}
                  value={forgotPassword.resetEmail.value}
                  isRegularText={true}
                />
                <div className={styles.btnForgetPwd}>
                  <Button
                    text="Reset Password"
                    click={this.onClickResetPwdHandler}
                    disabled={this.state.loading}
                    overrideClass={styles.btn}
                  />
                </div>
              </>
            )}
          </div>
          {this.state.view == 1 && (
            <div className={styles.logoMobileBottom}>
              <span className={styles.imgPreviewMobileText}>Powered by </span>
              <img
                className={styles.bottomLogoMobile}
                src={LogoBottom}
                alt="one-track-moblie-bottom-logo"
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default withRouter(Login);
