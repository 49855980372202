import React from "react";
import styles from "./MapInfo.module.scss";
import OFFLINE_ICON from "../../../assets/offline-legend.svg";
import ONLINE_ICON from "../../../assets/online-legend.svg";
import { OFFLINE, ONLINE, ALERT } from "../../../Util/constants";
import ALERT_ICON from "../../../assets/alert-legend.svg";
const MapInfo = () => {
  return (
    <div className={styles.InfoWrapper}>
      <div className={styles.itemWrapper}>
        <span className={styles.img}>
          <img alt="alert-icon" src={ALERT_ICON} />
        </span>
        <span>{ALERT}</span>
      </div>
      <div className={styles.itemWrapper}>
        <span className={styles.img}>
          <img alt="online-icon" src={ONLINE_ICON} />
        </span>
        <span>{ONLINE}</span>
      </div>
      <div className={styles.itemWrapper}>
        <span className={styles.img}>
          <img alt="offline-icon" src={OFFLINE_ICON} />
        </span>
        <span>{OFFLINE}</span>
      </div>
    </div>
  );
};
export default MapInfo;
