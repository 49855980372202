import React, { Component } from "react";
import GoogleMapReact from "google-map-react";
import styles from "./GoogleMap.module.scss";
import supercluster from "supercluster";
import ReactDOM from "react-dom";
import GenericMarker from "./GenericMarker/GenericMarker";
import {
  GOOGLE_MAP_API_KEY,
  DEFAULT_MAP_LOCATION,
  DEFAULT_GROUP_COLOR,
  DEFAULT_MAP_TYPE,
  START,
  STOP,
  OVERVIEW,
  DRAW_FENCING,
  DOT_MARKER,
  SHOW_FENCING,
  EDIT_FENCING,
  UPLOAD_KLM,
  RECTANGLE,
  CIRCLE,
  POLYLINE,
  DRAW_FENCING_POINTS,
  DRAW_FENCING_CIRCEL,
  DRAW_FENCING_RECTANGLE,
  RELOAD_FENCING_LIST,
  NOTIFICATION_CONTAINER,
  CLUSTER_LEVEL_ZOOM,
  DEFAULT_ZOOM_LEVEL,
  CLUSTER,
  EDIT_FENCE_DETAILS,
  FENCING,
  HIGHLIGHT_POLYGON_SETTINGS,
  VEHICLE_SOS_COLOR,
  VEHICLE_ONLINE_COLOR,
  CLEAR_ALL_ACTION_BOXES,
  DISABLED_POLYGON_SETTINGS,
  HIDDEN_POLYGON_SETTINGS,
  DEFAULT_ZONE_DRAW_COLOR,
} from "../../Util/constants";
import Controls from "./Controls/Controls";
import ZoomControls from "./ZoomControls/ZoomControls";
import Marker from "./Marker/Marker";
import ClusterMarker from "./ClusterMarker/ClusterMarker";
import MapInfo from "./MapInfo/MapInfo";
import VehicleDetails from "./VehicleDetails/VehicleDetails";
import {
  getCombinedBounds,
  getMapBoundsForBounding,
  randomID,
} from "../../Util/globalFunc";
import RightMarker from "./RightMarker/RightMarker";
import SpinnerLoader from "../SpinnerLoader/SpinnerLoader";
import MapTypes from "./MapTypes/MapTypes";
import ShortcutMenu from "./ShortcutMenu/ShortcutMenu";
import { overLayPolygons } from "../../RequestManager/GqlBuilderAccount";
import { httpRequest } from "../../RequestManager/HttpRequest";
// Return map bounds based on list of places
const getMapBounds = (map, maps, places) => {
  if (maps != null && places.length > 0) {
    const bounds = new maps.LatLngBounds();
    places.forEach((place) => {
      let lat, lng;
      lat =
        place.lastValidPosition == null
          ? place.location.latitude
          : place.lastValidPosition.latitude;
      lng =
        place.lastValidPosition == null
          ? place.location.longitude
          : place.lastValidPosition.longitude;
      bounds.extend(new maps.LatLng(lat, lng));
    });
    return bounds;
  }
};

class GoogleMap extends Component {
  _isScrolling = false;
  _buttonsPlaced = false;
  _allowRecenter = true;
  tempPoligon = null;
  _isFencing = false;
  _disableRightClick = false;
  boundFence = false;
  _infowindow;
  drawingManager;
  _drawingInfowindow;
  _filtersOpen = false;
  _singleMarker = "";
  polylineManager = null;
  polygonManager = [];
  importTrailManager = [];
  _refActionControls = null;
  _drawFencingPolygonId = null;
  _activeFencingUuid = null;
  _activeDrawType = null;
  _selectedShape = [];
  // _refFencingControl = null;
  _refVehiclesInClusterArray = null;
  boundToAccountFencingList = false;
  allowFenceClick = true;
  state = {
    showMap: true,
    mapApiLoaded: false,
    mapInstance: null,
    mapApi: null,
    isFiltersOpen: false,
    isFullScreenMode: false,
    importTrail: [],
    activeMapType: DEFAULT_MAP_TYPE,
    activeActionType: "",
    drawFencingPoints: null,
    dragStarted: false,
    fencingList: {}, //this list holds the upload kml  or edit fencing list with uuid as object key
    showRightMarker: false,
    rightMarker: {
      lat: "",
      lng: "",
      id: "",
    },
    showFencingListOnMap: true,
    mapBounds: [],
    activeShortcutMenu: {},
    fencingMenu: {},
    fencingLoading: false,
  };
  componentWillUnmount() {}
  componentDidUpdate(nextprops) {
    if (this.state.mapInstance != null && !this._buttonsPlaced) {
      //Zoom controls
      var zoomBtns = document.createElement("div");
      zoomBtns.setAttribute("id", "zoomControlsWrapper");
      zoomBtns.setAttribute("class", styles.zoomControls);
      this.state.mapInstance.controls[
        this.state.mapApi.ControlPosition.BOTTOM_RIGHT
      ].clear();
      this.state.mapInstance.controls[
        this.state.mapApi.ControlPosition.BOTTOM_RIGHT
      ].push(zoomBtns);
      if (zoomBtns != null) {
        ReactDOM.render(
          <>
            <MapTypes
              wrapperClass={styles.typesWrapper}
              setMapType={this.setMapTypeHandler}
              phoneMode={this.props.phoneMode}
            />
            {this.props.phoneMode !== undefined && !this.props.phoneMode && (
              <ZoomControls
                zoomin={this.zoominHandler}
                zoomout={this.zoomoutHandler}
              />
            )}
          </>,
          zoomBtns
        );
      }
      /**
       * Map information
       */
      var mapBottomBtns = document.createElement("div");
      mapBottomBtns.setAttribute("id", "mapBotomBtnsWrapper");
      mapBottomBtns.setAttribute("class", styles.mapInfoWrapper);
      this.state.mapInstance.controls[
        this.state.mapApi.ControlPosition.BOTTOM_LEFT
      ].clear();
      this.state.mapInstance.controls[
        this.state.mapApi.ControlPosition.BOTTOM_LEFT
      ].push(mapBottomBtns);
      ReactDOM.render(
        <div className={styles.mapInfo}>
          <MapInfo></MapInfo>
        </div>,
        mapBottomBtns
      );
      /**
       * Map Controls
       */
      var controlsMap = document.createElement("div");
      controlsMap.setAttribute("id", "controlsMapWrapper");
      controlsMap.setAttribute("class", styles.controlsMap);
      this.state.mapInstance.controls[
        this.state.mapApi.ControlPosition.TOP_LEFT
      ].clear();
      this.state.mapInstance.controls[
        this.state.mapApi.ControlPosition.TOP_LEFT
      ].push(controlsMap);
      if (controlsMap != null) {
        ReactDOM.render(
          <Controls
            updateOverView={this.props.updateOverView}
            updateWebSocket={this.props.updateWebSocket}
            phoneMode={this.props.phoneMode}
            btnOptions={this.props.btnOptions}
            recenterMap={this.recenterMapHandler}
            groupFilters={this.props.groupFilters}
            refFiltersControls={this.props.refFiltersControls}
            refDisableControls={this.props.refDisableControls}
            refActionControls={this.refActionControlsHandler}
            triggerFilter={this.props.triggerFilter}
            exludeGroups={this.props.exludeGroups}
            contralHttpInterval={this.props.contralHttpInterval}
            showLicenseClick={this.props.showLicenseClick}
            setActionType={this.setActionTypeHandler}
            updateFencingColorsAndData={this.updateFencingColorsAndDataHandler}
            setDrawType={this.setDrawTypeHandler}
            statusOfFencingPanel={this.statusOfFencingPanelHandler}
            updateDrawFencingPoints={this.updateDrawFencingPointsHandler}
            mapToNotificationAlert={this.mapToNotificationAlertHandler}
            //fencingInfoUpdate={this.fencingInfoUpdateHandler}
            //onClickShowFencingOnMap={this.onClickShowFencingOnMapHandler}
            onClickShowOrHideSingleFencing={
              this.onClickShowOrHideSingleFencingHandler
            }
            updateFencingDataAndClearTempFencing={
              this.updateFencingDataAndClearTempFencing
            }
            getAccountFencingList={this.getAccountFencingListHandler}
            boundToAccountFences={this.boundToAccountFences}
            boundToSingleFence={this.boundToSingleFenceHandler}
            highlightSingleFence={this.highlightSingleFenceHanlder}
            updateVehiclesInCluster={this.updateVehiclesInClusterHandler}
            refFencingDataControl={this.props.refFencingDataControl}
            getVehiclesofFence={this.props.getVehiclesofFence}
          />,
          controlsMap
        );
      }
      /**
       * Vehicle details
       */
      var vehicleDetails = document.createElement("div");
      vehicleDetails.setAttribute("id", "vehicleDetailsWrapper");
      vehicleDetails.setAttribute("class", styles.vehicleDetails);
      this.state.mapInstance.controls[
        this.state.mapApi.ControlPosition.TOP_RIGHT
      ].clear();
      this.state.mapInstance.controls[
        this.state.mapApi.ControlPosition.TOP_RIGHT
      ].push(vehicleDetails);
      if (vehicleDetails != null) {
        ReactDOM.render(
          <VehicleDetails
            refVehicleControl={this.props.refVehicleControl}
            closeVehicleDetails={this.props.markerClick}
            contralHttpInterval={this.props.contralHttpInterval}
            fullScreenMode={this.fullScreenModeHandler}
            isFullScreenMode={this.state.isFullScreenMode}
            handleESCPress={this.handleESCPress}
            phoneMode={this.props.phoneMode}
            toggoleIosScreenDash={this.props.toggoleIosScreen}
            setMapType={this.setMapTypeHandler}
            drawPolyline={this.drawPolylineHandler}
            updateVehicleData={this.props.updateVehicleData}
            clearImportedTrail={this.clearImportedTrailHandler}
            refDisableActions={this.props.refDisableActions}
            activeMapType={this.state.activeMapType}
            refFencingControl={this.refFencingControlHandler}
            clearAllActionsOnMap={this.clearAllActionsOnMap}
          />,
          vehicleDetails
        );
      }

      this._buttonsPlaced = true;
    }
  }
  onRightClickDotHandler = (event, item) => {
    this._disableRightClick = true;
    let copyRight = { ...this.state.rightMarker };
    copyRight.lat = parseFloat(item.lat);
    copyRight.lng = parseFloat(item.lng);
    copyRight.id = item.id;
    this.setState({ showRightMarker: true, rightMarker: copyRight });
  };
  // Fit map to its bounds after the api is loaded
  apiIsLoaded = (map, maps, places) => {
    if (maps == null) return;
    // Bind the resize listener
    this.setState(
      {
        mapApiLoaded: true,
        mapInstance: map,
        mapApi: maps,
      },
      () => {
        if (this.props.apiIsLoadedTrigger != undefined)
          this.props.apiIsLoadedTrigger();
        maps.event.addListener(map, "zoom_changed", this.onZoomHandler);
        const mapDiv = document.getElementById("map_canvas");
        maps.event.addDomListener(mapDiv, "wheel", () => {
          this._isScrolling = true;
        });
        maps.event.addListener(map, "click", (mapsMouseEvent) => {
          this.mapClickHandler(mapsMouseEvent);
        });
      }
    );
  };
  refActionControlsHandler = (ref) => {
    this._refActionControls = ref;
  };
  // refFencingControlHandler = (ref) => {
  //   this._refFencingControl = ref;
  // };
  statusOfFencingPanelHandler = (mode) => {
    this._allowRecenter = !mode;
  };
  updateVehiclesInClusterHandler = (ref) => {
    this._refVehiclesInClusterArray = ref;
  };

  onZoomHandler = () => {};

  /**
   * Custom zoom controls
   *  */
  zoominHandler = () => {
    let currentZoom = this.state.mapInstance.getZoom();
    if (currentZoom == 22) return;
    this.state.mapInstance.setZoom(currentZoom + 1);
    this._isScrolling = true;
    this._allowRecenter = false;
  };
  zoomoutHandler = () => {
    let currentZoom = this.state.mapInstance.getZoom();
    if (currentZoom == 3) return;
    this.state.mapInstance.setZoom(currentZoom - 1);
    this._isScrolling = true;
    this._allowRecenter = false;
  };
  /**
   * building cluster markers
   *
   */
  getpoints = (points) => {
    const geojsonPoints = points.map((pos) => {
      let headingAdjustment =
        pos.headingAdjustment != null ? pos.headingAdjustment : 0;

      let lat =
        pos.lastValidPosition == null
          ? pos.location.latitude
          : pos.lastValidPosition.latitude;
      let lng =
        pos.lastValidPosition == null
          ? pos.location.longitude
          : pos.lastValidPosition.longitude;

      return {
        type: "Feature",
        properties: {
          itemId: pos.lookupId,
          data: {
            details: pos,
            lat:
              pos.lastValidPosition == null
                ? pos.location.latitude
                : pos.lastValidPosition.latitude,
            lng:
              pos.lastValidPosition == null
                ? pos.location.longitude
                : pos.lastValidPosition.longitude,
            id: pos.lookupId,
            heading:
              pos.lastValidHeading == null
                ? 0
                : pos.lastValidHeading.heading + headingAdjustment,
            isOnline:
              pos.terminal != null && pos.terminal.isOnline !== undefined
                ? pos.terminal.isOnline
                : false,
          },
        },
        geometry: {
          type: "Point",
          coordinates: [lng, lat],
        },
      };
    });
    return geojsonPoints;
  };

  getsupercluster = (points, options) => {
    var cl = new supercluster(options);
    this.clRef = cl;
    cl.load(this.getpoints(points));
    return cl.getClusters(
      this.state.mapBounds,
      this.state.mapInstance.getZoom()
    );
  };
  getClusters = () => {
    const clusters = this.getsupercluster(this.props.markers, {
      minZoom: 0,
      maxZoom: CLUSTER_LEVEL_ZOOM,
      radius: 30,
      minPoints: 1,
    });
    return clusters;
  };
  openClusterVehcleListHandler = (vehiclesInCluster = []) => {
    this.allowFenceClick = false;
    setTimeout(() => {
      this.allowFenceClick = true;
    }, 500);
    this._refVehiclesInClusterArray(vehiclesInCluster);
  };
  /** clusters code end */
  getMarkerForTrailHandler = () => {
    if (this.state.importTrail.length == 0) return;    
    let startPoint = this.state.importTrail[this.state.importTrail.length - 1];
    let endPoint = this.state.importTrail[0];
    return [startPoint, endPoint].map((item, index) => {
      return (
        <Marker
          markerType={index == 0 ? DOT_MARKER : ""}
          onRightClickDot={() => {}}
          key={"importtrail" + index}
          lat={parseFloat(item.lat)}
          lng={parseFloat(item.lng)}
          name={"importTrail"}
          type={""}
          heading={item.heading}
          details={{
            exitFenceAlert: null,
            newMessageAlert: null,
            sosAlert: null,
            showVehicleOutsideFenceAnimation: false,
          }}
        />
      );
    });
  };
  getcurrentZoomLevelHandler = () => {
    return this.state.mapInstance.getZoom();
  };
  markerClickHandler = (data) => {
    this.allowFenceClick = false;
    setTimeout(() => {
      this.allowFenceClick = true;
    }, 500);
    this.props.markerClick(data);
  };
  getMarkersHandlers = (pos) => {
    let markerId = pos.id;
    return (
      <GenericMarker
        key={markerId}
        lat={parseFloat(pos.lat)}
        lng={parseFloat(pos.lng)}
        name={pos.title}
        viewVehicleLookupId={this.props.viewVehicleLookupId}
        type={pos.type}
        heading={pos.heading}
        details={pos.details}
        licenseNumber={pos.licensePlate}
        markerClick={this.markerClickHandler}
        isOnline={pos.isOnline}
        getcurrentZoomLevel={this.getcurrentZoomLevelHandler}
      />
    );
  };
  getCustomMarkersHandler = () => {
    if (!this.state.mapApiLoaded) return;
    let markers;
    markers = this.getClusters().map((cluster) => {
      let item = cluster.geometry.coordinates;
      if (!cluster.properties.cluster) {
        if (this.state.mapInstance.getZoom() <= CLUSTER_LEVEL_ZOOM) {
          let fakeCluster = cluster.properties.data;
          let isSosAlert = fakeCluster.details.sosAlert != null;
          let isOnline = fakeCluster.details.terminal.isOnline;
          let markersInCluster = [
            {
              id: fakeCluster.id, //record id
              lookupId: fakeCluster.id, //terminal lookup id
              details: fakeCluster.details,
              isOnline: fakeCluster.isOnline,
            },
          ];
          return (
            <ClusterMarker
              key={fakeCluster.id}
              id={fakeCluster.id}
              lat={fakeCluster.lat}
              lng={fakeCluster.lng}
              count={1}
              click={this.openClusterVehcleListHandler}
              type={CLUSTER}
              markersInCluster={markersInCluster}
              dotColor={
                isSosAlert
                  ? VEHICLE_SOS_COLOR
                  : isOnline
                  ? VEHICLE_ONLINE_COLOR
                  : ""
              }
            />
          );
        } else {
          return this.getMarkersHandlers(cluster.properties.data);
        }
      } else {
        let onlineCount = 0;
        let isSosAlertCount = 0;

        let markersInCluster = this.clRef
          .getLeaves(cluster.id, "Infinity", 0)
          .map((item) => {
            if (item.properties.data.isOnline) onlineCount++;
            if (item.properties.data.details.sosAlert != null)
              isSosAlertCount++;
            return {
              id: item.properties.data.id, //record id
              lookupId: item.properties.data.id, //terminal lookup id
              isOnline: item.properties.data.isOnline,
              details: item.properties.data.details,
            };
          });
        return (
          <ClusterMarker
            key={cluster.id}
            id={cluster.id}
            lat={item[1]}
            lng={item[0]}
            count={cluster.properties.point_count}
            click={this.openClusterVehcleListHandler}
            type={CLUSTER}
            markersInCluster={markersInCluster}
            onlineCount={onlineCount}
            dotColor={
              isSosAlertCount > 0
                ? VEHICLE_SOS_COLOR
                : onlineCount > 0
                ? VEHICLE_ONLINE_COLOR
                : ""
            }
          />
        );
      }
    });
    if (this._allowRecenter && !this._isScrolling) {
      this.recenterMapHandler();
    }
    return markers;
  };
  closeShortMenuForZoneHandler = () => {
    this.setState({ activeShortcutMenu: {}, fencingMenu: {} });
  };
  mapToNotificationAlertHandler = (data, isOverview = null) => {
    this.setState({ activeShortcutMenu: {}, fencingMenu: {} }, () => {
      if (isOverview == null) {
        if (this.state.mapInstance == null) return;
        let dataPostion = [
          {
            lat: parseFloat(data.vehicle.lastValidPosition.latitude),
            lng: parseFloat(data.vehicle.lastValidPosition.longitude),
          },
        ];
        const bounds = new this.state.mapApi.LatLngBounds();
        dataPostion.forEach((place) => {
          bounds.extend(new this.state.mapApi.LatLng(place.lat, place.lng));
        });
        this._allowRecenter = false;
        this.state.mapInstance.fitBounds(bounds);
        this.props.updateVehicleData(data, NOTIFICATION_CONTAINER);
      } else {
        this.props.updateVehicleData(data, OVERVIEW);
      }
    });
  };

  recenterMapHandler = () => {
    let markersList = this.props.markers;
    let bounds;
    if (this.state.importTrail.length) {
      markersList = this.state.importTrail;
      bounds = this.getMapBoundsForTrail(
        this.state.mapInstance,
        this.state.mapApi,
        markersList
      );
    } else {
      bounds = getMapBounds(
        this.state.mapInstance,
        this.state.mapApi,
        markersList
      );
    }
    if (this.state.mapInstance == null || !markersList.length) return;
    this.state.mapInstance.fitBounds(bounds);
    if (this.props.markers.length === 1 || !this.props.markers.length) {
      this.state.mapInstance.setZoom(DEFAULT_ZOOM_LEVEL);
    }
    if (this.state.activeActionType == "") this._allowRecenter = true;
  };
  mapDragHandler = () => {
    this._allowRecenter = false;
  };
  /**
   * Fullscreen btn actions
   *
   */
  fullScreenModeHandler = () => {
    this.setState({ isFullScreenMode: !this.state.isFullScreenMode }, () => {
      var elementToSendFullscreen = this.state.mapInstance.getDiv().firstChild;
      if (this.isFullscreen(elementToSendFullscreen)) {
        this.exitFullscreen();
      } else {
        this.requestFullscreen(elementToSendFullscreen);
      }
    });
  };

  setMapTypeHandler = (type) => {
    this.setState({
      activeMapType: type,
    });
  };

  isFullscreen = (element) => {
    return (
      (document.fullscreenElement ||
        document.webkitFullscreenElement ||
        document.mozFullscreenElement ||
        document.msFullscreenElement) == element
    );
  };

  requestFullscreen = (element) => {
    var userAgent = navigator.userAgent;
    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.webkitRequestFullScreen) {
      element.webkitRequestFullscreen();
    } else if (element.mozRequestFullScreen) {
      element.mozRequestFullscreen();
    } else if (element.msRequestFullScreen) {
      element.msRequestFullscreen();
    } else if (
      this.props.phoneMode &&
      /iPhone/.test(userAgent) &&
      !window.MSStream
    ) {
      this.props.toggoleIosScreen();
    }
  };
  cancelDeletePointHandler = () => {
    this.setState({ showRightMarker: false }, () => {
      setTimeout(() => {
        this._disableRightClick = false;
      }, 200);
    });
  };
  exitFullscreen = () => {
    var userAgent = navigator.userAgent;
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullscreen();
    } else if (document.msCancelFullScreen) {
      document.msCancelFullscreen();
    } else if (
      this.props.phoneMode &&
      /iPhone/.test(userAgent) &&
      !window.MSStream
    ) {
      this.props.toggoleIosScreen();
    }
  };
  handleESCPress = (event) => {
    if (document.fullscreenElement == null && this.state.isFullScreenMode) {
      this.setState(
        { isFullScreenMode: !this.state.isFullScreenMode },
        () => {}
      );
    }
  };

  drawPolylineHandler = (importTrail) => {
    this.setState({ importTrail: importTrail }, () => {
      this.props.clearMapData(STOP);
      if (this.state.mapApi == null) return;
      let mapApi = this.state.mapApi;

      const polyline = new mapApi.Polyline({
        path: importTrail,
        geodesic: true,
        strokeColor: DEFAULT_ZONE_DRAW_COLOR,
        strokeOpacity: 1.0,
        strokeWeight: 1,
      });
      polyline.setMap(this.state.mapInstance);

      this.importTrailManager.push(polyline); //added
      //recenter
      const bounds = this.getMapBoundsForTrail(
        this.state.mapInstance,
        this.state.mapApi,
        importTrail
      );
      this.state.mapInstance.fitBounds(bounds);
      this.props.stopLoader();
      //close all boxes on map
      this._refActionControls({ key: CLEAR_ALL_ACTION_BOXES });
    });
  };
  clearAllActionsOnMap = () => {
    this._refActionControls({ key: CLEAR_ALL_ACTION_BOXES });
  };
  getMapBoundsForTrail = (map, maps, places) => {
    if (maps != null && places.length > 0) {
      const bounds = new maps.LatLngBounds();
      places.forEach((place) => {
        bounds.extend(new maps.LatLng(place.lat, place.lng));
      });
      return bounds;
    }
  };
  onClickDeletePointhandler = () => {
    let copyData = { ...this.state.drawFencingPoints };
    delete copyData[this.state.rightMarker.id];
    this.setState(
      { drawFencingPoints: copyData, showRightMarker: false },
      () => {
        //update controls form
        this._refActionControls({
          key: DRAW_FENCING_POINTS,
          data: this.state.drawFencingPoints,
        }); //controls
        setTimeout(() => {
          this._disableRightClick = false;
        }, 200);
      }
    );
  };
  clearImportedTrailHandler = () => {
    this._allowRecenter = true;
    this._isScrolling = false;
    this.setState({ importTrail: [] }, () => {
      if (
        this.importTrailManager != undefined &&
        this.importTrailManager.length > 0
      ) {
        this.importTrailManager.map((item, index) => {
          item.setMap(null);
        });
      }
      this.props.clearMapData(START);
    });

    //?? is recenter required on clearing import trail.
    if (this.props.markers.length == 0) {
      let markersList = [DEFAULT_MAP_LOCATION];
      const bounds = this.getMapBoundsForTrail(
        this.state.mapInstance,
        this.state.mapApi,
        markersList
      );
      this.state.mapInstance.fitBounds(bounds);
      this.state.mapInstance.setZoom(DEFAULT_ZOOM_LEVEL);
    }
  };
  /**
   *  FEncing realted function
   */
  mapClickHandler = (e) => {
    if (Object.keys(this.state.fencingMenu).length > 0) {
      this.setState({ activeShortcutMenu: {}, fencingMenu: {} });
    }

    if (
      this.state.activeActionType == DRAW_FENCING &&
      this._activeDrawType == POLYLINE
    ) {
      if (this._disableRightClick) return;
      // capture the clicks and strore
      let drawFencingPoints = { ...this.state.drawFencingPoints };
      let latLng = e.latLng.toJSON();
      drawFencingPoints[`point${latLng.lat}-${latLng.lng}`] = e.latLng.toJSON();
      this.setState({ drawFencingPoints }, () => {
        this._refActionControls({
          key: DRAW_FENCING_POINTS,
          data: this.state.drawFencingPoints,
        }); //controls
      });
    }
  };

  /**
   *update the draw fencing points on add or delete
   */
  updateDrawFencingPointsHandler = (data) => {
    let drawFencingPoints = { ...this.state.drawFencingPoints };
    drawFencingPoints[`point${data.lat}-${data.lng}`] = data;
    this.setState({ drawFencingPoints });
  };
  /**
   * update the polygon fill color and border color on change
   * @returns
   */
  updateFencingColorsAndDataHandler = (data) => {
    if (this._activeDrawType != null && this._activeDrawType != POLYLINE) {
      // when the active draw type is circle or rectangle as we are drawing them as shapes
      this._selectedShape[0].setOptions({
        fillColor: data.color,
        strokeColor: data.borderColor,
      });
      return;
    }
    let polygonParams = {
      fillColor: data.color,
      borderColor: data.color,
    };
    if (
      this.state.drawFencingPoints != null &&
      Object.keys(this.state.drawFencingPoints).length == 0 &&
      this._activeDrawType == null
    )
      return;
    if (this.state.activeActionType == DRAW_FENCING) {
      if (
        this._drawFencingPolygonId == null ||
        this.polygonManager[this._drawFencingPolygonId] == undefined
      )
        return;
      this.polygonManager[this._drawFencingPolygonId].setOptions(polygonParams);
    } else {
      //in case of upload kml or edit fencing

      let fencingList = { ...this.state.fencingList };
      if (data.borderColor != undefined) {
        fencingList[data.uuid].borderColor = data.borderColor;
      }

      fencingList[data.uuid].color = data.color;
      this.setState({ fencingList }, () => {
        this.polygonManager[data.uuid].setOptions(polygonParams);
      });
    }
  };
  /**
   * updates the bordercolor and fillclor and name from form to map and to small map
   * @param {*} action
   * @param {*} fencingTobeUpdated
   */
  updateFencingDataAndClearTempFencing = (
    action,
    fencingTobeUpdated = null
  ) => {
    this.setState(
      { fencingList: {}, showFencingListOnMap: true, drawFencingPoints: null },
      () => {
        /**step 1 delete the placed fencing */
        if (action == RELOAD_FENCING_LIST) {
          this.resetShapeTypeFencingHandler();
          this.resetPolygonTypeFencingHandler();
        }
        this._activeFencingUuid = null;
        this._drawFencingPolygonId = null;
        /** step 2 inform the google map wrapper to either update the list or call the api again in case of create */
        this.props.updateFencingData(action, fencingTobeUpdated);
      }
    );
  };
  /**
   * show or hide the account fencing on the map on click of icon
   * @param {*} value
   */
  // onClickShowFencingOnMapHandler = (value = null) => {
  //   this.setState(
  //     {
  //       showFencingListOnMap:
  //         value == null ? !this.state.showFencingListOnMap : value,
  //     },
  //     () => {
  //       this.showorHideAllFencesFromMapHandler(this.state.showFencingListOnMap);
  //     }
  //   );
  // };
  /** hide the list of fencing on the map
   * we need to clear out the polygon manager to remove the added polygons(fencing) from map
   */
  showorHideAllFencesFromMapHandler = (showAll) => {
    let polygonList = this.polygonManager;
    Object.keys(polygonList).map((key) => {
      if (showAll) {
        this.polygonManager[key].setOptions({
          fillOpacity: 0.5,
          strokeOpacity: 1,
        });
      } else polygonList[key].setOptions(HIDDEN_POLYGON_SETTINGS);
    });
  };
  /**
   * show or hide single fence
   * @param {*} hiddenFencingList
   */
  onClickShowOrHideSingleFencingHandler = (hiddenFencingList = []) => {
    let polygonList = this.polygonManager;
    Object.keys(polygonList).map((key) => {
      if (hiddenFencingList.indexOf(key) != -1) {
        this.polygonManager[key].setOptions(HIDDEN_POLYGON_SETTINGS);
      } else {
        let params = {
          fillOpacity: 0.5,
          strokeOpacity: 1,
        };
        if (!this.props.accountFencingList[key].isActive) {
          params = DISABLED_POLYGON_SETTINGS;
        }
        this.polygonManager[key].setOptions(params);
      }
    });
  };
  /**
   * Draw the diffrent types of fencing(polygons) based on the list supplied
   */
  drawFencingOnMapHandler = (list, boundToFencing = false) => {
    let mapApi = this.state.mapApi;
    if (mapApi == null) return; //////ADDED this
    let mapInstance = this.state.mapInstance;
    let bounds,
      coordinatesArr = [];
    list.map((fencingItem) => {
      if (this.polygonManager[fencingItem.uuid] != undefined) return;
      let fencing,
        coor,
        strokeOpacity = 1.0,
        fillOpacity = 0.5;

      if (!fencingItem.isActive) {
        strokeOpacity = 0;
        fillOpacity = 0;
      }

      if (fencingItem.type == 3) {
        fencing = new mapApi.Rectangle({
          strokeColor: fencingItem.color,
          strokeOpacity,
          strokeWeight: 1,
          fillColor: fencingItem.color,
          fillOpacity,
          map: mapInstance,
          bounds: fencingItem.coordinates,
          //icons
        });
        coor = getCombinedBounds(fencing.getBounds());
        coordinatesArr = coor.concat(coordinatesArr);
      } else if (fencingItem.type == 2) {
        fencing = new mapApi.Circle({
          strokeColor: fencingItem.color,
          strokeOpacity,
          strokeWeight: 1,
          fillColor: fencingItem.color,
          fillOpacity,
          map: mapInstance,
          center: fencingItem.coordinates.center,
          radius: fencingItem.coordinates.radius,
        });
        coor = getCombinedBounds(fencing.getBounds());

        coordinatesArr = coor.concat(coordinatesArr);
      } else {
        fencing = new mapApi.Polygon({
          path: fencingItem.coordinates,
          strokeColor: fencingItem.color,
          strokeOpacity,
          strokeWeight: 1,
          fillColor: fencingItem.color,
          fillOpacity,
        });
        fencing.setMap(this.state.mapInstance);
        coordinatesArr = fencingItem.coordinates.concat(coordinatesArr);
      }

      fencing.addListener(
        "click",
        (e) => {
          this.state.activeActionType == ""
            ? this.allowFenceClick &&
              this.showMenuForFenceHanlder(e, fencingItem)
            : this.mapClickHandler(e);
        },
        true
      );
      this.polygonManager[fencingItem.uuid] = fencing;
    });
  };
  /**
   * Show the fecning of type UPLOAD_KML and EDIT_FENCING
   */
  addTempFencingPolygons = () => {
    let list = Object.values(this.state.fencingList);
    if (list.length == 0) return;
    this.drawFencingOnMapHandler(list, true);
  };
  /**
   *
   * @returns list of account fencing list
   */
  getAccountFencingListHandler = () => {
    return this.props.accountFencingList; //obj
  };
  /**
   * bounds to the accountFEncing list supplied from googlempwrapper ????????????
   */
  boundToAccountFences = () => {
    if (
      this.state.activeActionType == DRAW_FENCING ||
      this.state.activeActionType == EDIT_FENCING ||
      this.state.activeActionType == EDIT_FENCE_DETAILS ||
      this.state.activeActionType == UPLOAD_KLM
    )
      return;
    this.boundToAccountFencingList = true;
    this.boundFence = false;
    this.setState({ showFencingListOnMap: true }, () => {});
  };
  boundToSingleFenceHandler = (uuid) => {
    if (
      this.state.activeActionType == DRAW_FENCING ||
      this.state.activeActionType == EDIT_FENCING ||
      this.state.activeActionType == EDIT_FENCE_DETAILS ||
      this.state.activeActionType == UPLOAD_KLM
    )
      return;
    let polygonList = this.polygonManager;
    Object.keys(polygonList).map((key) => {
      if (key == uuid) {
        let fencing = this.polygonManager[uuid];
        let fencingItem = this.props.accountFencingList[uuid];
        this.polygonManager[uuid].setOptions(HIGHLIGHT_POLYGON_SETTINGS);

        let tempCoor = [];
        if (fencingItem.type == 1) {
          fencing.getPath().forEach(function (xy, i) {
            tempCoor.push({
              lat: xy.lat(),
              lng: xy.lng(),
            });
          });
        } else if (fencingItem.type == 2) {
          tempCoor = getCombinedBounds(fencing.getBounds());
        } else if (fencingItem.type == 3) {
          tempCoor = getCombinedBounds(fencing.getBounds());
        }

        let bounds = getMapBoundsForBounding(
          this.state.mapInstance,
          this.state.mapApi,
          tempCoor
        );
        this.state.mapInstance.fitBounds(bounds);
        this._allowRecenter = false;
      } else {
        this.polygonManager[key].setOptions({
          strokeColor: this.props.accountFencingList[uuid].borderColor,
          zIndex: 1,
          strokeWeight: 1,
        });
      }
    });
  };
  /**
   * highlight the fence color with yellow when opened
   * @param {*} uuid
   * @returns
   */
  highlightSingleFenceHanlder = (uuid) => {
    if (
      this.state.activeActionType == DRAW_FENCING ||
      this.state.activeActionType == EDIT_FENCING ||
      this.state.activeActionType == EDIT_FENCE_DETAILS ||
      this.state.activeActionType == UPLOAD_KLM
    )
      return;
    let polygonList = this.polygonManager;
    Object.keys(polygonList).map((key) => {
      if (uuid == key) {
        this.polygonManager[key].setOptions(HIGHLIGHT_POLYGON_SETTINGS);
      } else {
        this.polygonManager[key].setOptions({
          strokeColor: this.props.accountFencingList[key].color,
          zIndex: 1,
          strokeWeight: 1,
        });
      }
    });
  };
  /**
   * used to draw the dots incase of draw type POLYLINE, also draws polyline if points <=2 and polygon if points>=3
   * @returns points and polygon or polyline based on number of points
   */
  drawFencingPointsHandler = () => {
    if (this.state.drawFencingPoints == null || this.state.mapApi == null) {
      return;
    }
    let drawFencingPoints = Object.values({ ...this.state.drawFencingPoints });

    let mapApi = this.state.mapApi;
    if (drawFencingPoints.length == 2) {
      //draws polyline
      if (this.polygonManager[this._drawFencingPolygonId] != undefined) {
        this.polygonManager[this._drawFencingPolygonId].setMap(null);
        delete this.polygonManager[this._drawFencingPolygonId];
      }
      if (
        this.polylineManager != null &&
        drawFencingPoints.length ==
          this.polylineManager.getPath().getArray().length
      ) {
        //do nothing
      } else {
        this.polylineManager = new mapApi.Polyline({
          path: drawFencingPoints,
          geodesic: true,
          strokeColor: DEFAULT_ZONE_DRAW_COLOR,
          strokeOpacity: 1.0,
          strokeWeight: 1,
          zIndex: 999,
        });
        this.polylineManager.setMap(this.state.mapInstance);
      }
    } else if (drawFencingPoints.length > 2) {
      //DEletes drawen polyline as poits are more than 2
      if (this.polylineManager != null) {
        this.polylineManager.setMap(null);
        this.polylineManager = null;
      }
      let coordinates = drawFencingPoints;
      coordinates.push(coordinates[0]);

      if (
        this._drawFencingPolygonId != null &&
        this.polygonManager[this._drawFencingPolygonId] != undefined &&
        Object.values({ ...this.state.drawFencingPoints }).length
      ) {
        this.polygonManager[this._drawFencingPolygonId].setPath(coordinates);
      } else {
        const polygon = new mapApi.Polygon({
          path: coordinates,
          strokeColor: DEFAULT_ZONE_DRAW_COLOR,
          strokeOpacity: 1.0,
          strokeWeight: 1,
          fillColor: DEFAULT_ZONE_DRAW_COLOR,
          fillOpacity: 0.09,
          geodesic: true,
          draggable: true,
          zIndex: 999,
        });
        polygon.setMap(this.state.mapInstance);
        polygon.addListener(
          "dragstart",
          (e) => {
            this.setState({ dragStarted: true });
          },
          true
        );

        polygon.addListener(
          "dragend",
          (e) => {
            let drawFencingPoints = {};
            this.polygonManager[this._drawFencingPolygonId]
              .getPath()
              .forEach(function (xy, i) {
                drawFencingPoints[`point${xy.lat()}-${xy.lng()}`] = {
                  lat: xy.lat(),
                  lng: xy.lng(),
                };
              });
            this.setState({ drawFencingPoints, dragStarted: false }, () => {
              this._refActionControls({
                key: DRAW_FENCING_POINTS,
                data: this.state.drawFencingPoints,
              }); //controls
            });
          },
          true
        );

        this.polygonManager[this._drawFencingPolygonId] = polygon;
      }
    } else {
      if (this.polylineManager != null) {
        this.polylineManager.setMap(null);
        this.polylineManager = null;
      }
    }
    if (!this.state.dragStarted && this.state.drawFencingPoints != null) {
      return Object.values(this.state.drawFencingPoints).map((point) => {
        return (
          <Marker
            key={`point${point.lat}-${point.lng}`}
            id={`point${point.lat}-${point.lng}`}
            lat={parseFloat(point.lat)}
            lng={parseFloat(point.lng)}
            name={"drawFencingPoint"}
            type={""}
            markerType={DOT_MARKER}
            onRightClickDot={this.onRightClickDotHandler}
          />
        );
      });
    }
  };
  /**
   * show the list of fences having that coordinates
   * @param {*} e
   * @param {*} uuid
   */
  showMenuForFenceHanlder = (e, fencingItem) => {
    let uuid = fencingItem.uuid;
    this.setState(
      {
        fencingLoading: true,
        fencingMenu: {
          latLng: e.latLng.toJSON(),
          fencingList: [],
          uuid,
        },
      },
      () => {
        httpRequest(overLayPolygons, {
          latitude: e.latLng.toJSON().lat,
          longitude: e.latLng.toJSON().lng,
        })
          .then((res) => {
            let fencingMenu = {},
              activeShortcutMenu = {};

            if (res.data.coordinateClickedDashboard.count) {
              fencingMenu = {
                latLng: e.latLng.toJSON(),
                fencingList: res.data.coordinateClickedDashboard.objs, //converStringToJson(data.coordinates),
                uuid,
              };
              activeShortcutMenu = { id: uuid };
            }
            this.setState({
              fencingMenu,
              activeShortcutMenu,
              fencingLoading: false,
            });
          })
          .catch((err) => {});
      }
    );
  };

  /**
   * shows the list of all fencing on the main map
   * @returns
   */
  addAllFencingPolygons = () => {
    let list = Object.values(this.props.accountFencingList);
    if (list.length == 0 || !this.state.showFencingListOnMap) return;
    this.drawFencingOnMapHandler(list, this.boundToAccountFencingList);
  };
  /**
   * used in case of action type is EDIT_FENCING, UPLOAD_KLM, ""
   * @param {*} actionType
   * @param {*} data
   */
  setActionTypeHandler = (actionType, data = null) => {
    let fencingList = {};
    if (actionType == EDIT_FENCING || actionType == UPLOAD_KLM) {
      if (actionType == UPLOAD_KLM) {
        fencingList = {};
        this.resetPolygonTypeFencingHandler();
        this.resetShapeTypeFencingHandler();
      }
      this.boundFence = false;
      if (data != null) {
        data.map((item) => {
          fencingList[item.uuid] = item;
          this._activeFencingUuid = item.uuid;
        });
      }
    } else if (actionType == "" || actionType == DRAW_FENCING) {
      //delete the polyline or polygon or circle or rectangle if exsists
      fencingList = {};
      this.resetPolygonTypeFencingHandler();
      this.resetShapeTypeFencingHandler();
    }
    this.setState(
      {
        activeActionType: actionType,
        drawFencingPoints: null,
        fencingList,
        // showFencingListOnMap: !(
        //   actionType == EDIT_FENCING ||
        //   actionType == DRAW_FENCING ||
        //   actionType == EDIT_FENCE_DETAILS ||
        //   actionType == UPLOAD_KLM
        // ),
      },
      () => {
        if (actionType == "") {
          this.boundFence = false;
          this._activeDrawType = null;
        }
      }
    );
  };
  /**
   * Set the draw mode to either polyline, circle, or rectangle
   * @param {*} drawingMode
   * @param {*} data
   */
  setDrawTypeHandler = (drawingMode, data) => {
    let mapDrawingMode;
    this._allowRecenter = false;
    let mapApi = this.state.mapApi;

    this.resetShapeTypeFencingHandler();
    if (this._activeDrawType != drawingMode) {
      this.resetPolygonTypeFencingHandler();
    }
    this._activeDrawType = drawingMode;
    this.setState(
      {
        drawFencingPoints: null,
        activeActionType: drawingMode != "" ? DRAW_FENCING : "",
      },
      () => {}
    );
    if (drawingMode != POLYLINE) {
      if (drawingMode == RECTANGLE)
        mapDrawingMode = mapApi.drawing.OverlayType.RECTANGLE;
      else if (drawingMode == CIRCLE)
        mapDrawingMode = mapApi.drawing.OverlayType.CIRCLE;

      this.drawingManager = new mapApi.drawing.DrawingManager({
        drawingMode: mapDrawingMode,
        drawingControl: false,
        map: this.state.mapInstance,
        rectangleOptions: {
          strokeWeight: 1,
          fillOpacity: 0.5,
          editable: true,
          draggable: true,
          fillColor: data.fillColor,
          strokeColor: data.fillColor,
        },
        circleOptions: {
          strokeWeight: 1,
          fillOpacity: 0.5,
          clickable: true,
          editable: true,
          draggable: true,
          fillColor: data.fillColor,
          strokeColor: data.fillColor,
        },
      });
      this.state.mapApi.event.addListener(
        this.drawingManager,
        "overlaycomplete",
        this.onDrawCompleteHandler
      );
    } else if (drawingMode == POLYLINE) {
      this._drawFencingPolygonId = `drawFencing${randomID()}`;
      this._refActionControls({
        key: DRAW_FENCING_POINTS,
        data: this.state.drawFencingPoints,
      }); //controls
      this._activeDrawType = drawingMode;
    } else {
      this.resetPolygonTypeFencingHandler();
      this._activeDrawType = null;
    }
  };

  updateBoundstoFormHandler = (event) => {
    if (event.type == "rectangle") {
      var bounds = event.overlay.bounds.toJSON();
      this._refActionControls({
        key: DRAW_FENCING_RECTANGLE,
        data: {
          north: bounds.north,
          south: bounds.south,
          east: bounds.east,
          west: bounds.west,
        },
      });
    } else if (event.type == "circle") {
      this._refActionControls({
        key: DRAW_FENCING_CIRCEL,
        data: {
          center: {
            lat: event.overlay.center.lat(),
            lng: event.overlay.center.lng(),
          },
          radius: event.overlay.getRadius(),
        },
      });
    }
  };
  onDrawCompleteHandler = (event) => {
    var newShape = event.overlay;
    if (event.type !== this.state.mapApi.drawing.OverlayType.MARKER) {
      this.drawingManager.setDrawingMode(null);
      this._selectedShape.push(newShape);
      this.updateBoundstoFormHandler(event);
      this.state.mapApi.event.addListener(
        event.overlay,
        "bounds_changed",
        () => {
          this.updateBoundstoFormHandler(event);
        }
      );
    }
  };
  /**
   * // used for removing  polyline, polygon if any
   */
  resetPolygonTypeFencingHandler = () => {
    if (
      this.polylineManager != null &&
      this.polylineManager.getPath().getArray().length
    ) {
      ///added
      this.polylineManager.setMap(null);
      this.polylineManager = null;
    }
    let polygonList = this.polygonManager;
    if (polygonList != null) {
      Object.keys(polygonList).map((key) => {
        polygonList[key].setMap(null);
        delete this.polygonManager[key];
      });
    }

    this._drawFencingPolygonId = null;
  };
  /**
   * //for removing circle and rectangle shapes if any
   */
  resetShapeTypeFencingHandler = () => {
    if (this.drawingManager != null) {
      this.drawingManager.setMap(null);
    }
    if (this._selectedShape.length) {
      this._selectedShape.map((shape) => {
        shape.setMap(null);
      });
      this._selectedShape = [];
    }
    this._activeFencingUuid = null;
  };
  render() {
    return (
      <>
        <div
          className={styles.mapWrapper}
          id="map_canvas"
          style={{
            opacity: this.props.importTrailLoader ? 0.6 : 1,
            visibility: this.props.mode !== "" ? "hidden" : "visible",
          }}
        >
          {this.state.showMap && (
            <GoogleMapReact
              bootstrapURLKeys={{
                key: GOOGLE_MAP_API_KEY,
                libraries: ["drawing", "places"].join(","),
                language: "en",
              }}
              yesIWantToUseGoogleMapApiInternals
              defaultZoom={DEFAULT_ZOOM_LEVEL}
              defaultCenter={DEFAULT_MAP_LOCATION}
              onGoogleApiLoaded={({ map, maps }) => {
                this.apiIsLoaded(map, maps, this.props.markers);
              }}
              options={{
                fullscreenControl: false,
                zoomControl: false,
                gestureHandling: "greedy",
                mapTypeId: this.state.activeMapType,
              }}
              onDragEnd={this.mapDragHandler}
              onChange={({ center, zoom, bounds }) => {
                this.setState({
                  mapBounds: [
                    bounds.nw.lng,
                    bounds.se.lat,
                    bounds.se.lng,
                    bounds.nw.lat,
                  ],
                });
              }}
            >
              {this.getCustomMarkersHandler()}
              {this.getMarkerForTrailHandler()}
              {this.drawFencingPointsHandler()}
              {this.addAllFencingPolygons()}
              {this.addTempFencingPolygons()}
              {this.state.showRightMarker && (
                <RightMarker
                  lng={this.state.rightMarker.lng}
                  lat={this.state.rightMarker.lat}
                  cancel={this.cancelDeletePointHandler}
                  click={this.onClickDeletePointhandler}
                />
              )}
              {(Object.keys(this.state.fencingMenu).length > 0 ||
                this.state.fencingLoading) &&
                this.state.fencingMenu.uuid != undefined &&
                this.state.fencingMenu.uuid ==
                  this.state.activeShortcutMenu.id && (
                  <ShortcutMenu
                    data={{
                      type: FENCING,
                      fencingList: this.state.fencingMenu.fencingList,
                    }}
                    lat={this.state.fencingMenu.latLng.lat}
                    lng={this.state.fencingMenu.latLng.lng}
                    uuid={this.state.fencingMenu.uuid}
                    fencingLoading={this.state.fencingLoading}
                    onClickFencingItem={(uuid) => {
                      this.closeShortMenuForZoneHandler();
                      this.highlightSingleFenceHanlder(uuid);
                      this.props.onClickFencingItem(uuid);
                    }}
                    closeShortMenuForZone={this.closeShortMenuForZoneHandler}
                  />
                )}
            </GoogleMapReact>
          )}
          <div
            className={styles.spinnerWrapper}
            style={{ display: this.props.importTrailLoader ? "block" : "none" }}
          >
            <SpinnerLoader />
          </div>
        </div>
      </>
    );
  }
}

export default GoogleMap;
