import React, { Component } from "react";
import GoogleMapReact from "google-map-react";
import styles from "./GoogleMap.module.scss";
import Speedmeter from "./Controls/Speedmeter/Speedmeter";
import ReactDOM from "react-dom";
import SocketLoader from "./SocketLoader/SocketLoader";
import {
  GOOGLE_MAP_API_KEY,
  DEFAULT_MAP_LOCATION,
  CAR,
  TRAIL,
  OFF,
  DEFAULT_MAP_TYPE,
} from "../../Util/constants";
import ZoomControls from "./ZoomControls/ZoomControls";
import Marker from "./Marker/Marker";
import FollowVehicle from "./FollowVehicle/FollowVehicle";
import {
  getCombinedBounds,
  getInclinationVehicle,
  getMapBoundsForBounding,
} from "../../Util/globalFunc";
import FullScreenControl from "./FullScreenControl/FullScreenControl";
import MapButton from "./MapButton/MapButton";
import MapTypes from "./MapTypes/MapTypes";
import LICENSE_PLACE_ICON from "../../assets/LicensePlate.svg";
import RotateCar from "./RotateCar/RotateCar";
// Return map bounds based on list of places
const getMapBounds = (map, maps, places) => {
  if (maps != null && places.length > 0) {
    const bounds = new maps.LatLngBounds();
    places.forEach((place) => {
      bounds.extend(new maps.LatLng(place.latitude, place.longitude));
    });
    return bounds;
  }
};
class VehicleMap extends Component {
  _isScrolling = false;
  _buttonsPlaced = false;
  _allowRecenter = true;
  _lastZoom = "";
  polylineManager = [];
  polygonManager = {};
  _isMounted = true;
  _boundType = CAR;
  _currentZoom = 11;
  boundFence = false;
  state = {
    mapApiLoaded: false,
    mapInstance: null,
    mapApi: null,
    isFullScreenMode: false,
    activeMapType: DEFAULT_MAP_TYPE,
    showLicense: true,
  };
  componentDidMount() {
   
    if (this.props.refActiveMapTypeControl !== undefined) {
      this.props.refActiveMapTypeControl(this.setMapDefaultsHandler);
    }
  }
  componentWillUnmount() {
    this._isMounted = false;
    document.removeEventListener("fullscreenchange", () => {});
  }

  setOptionBoundHandler = (type) => {
    if (type !== this._boundType) {
      this._allowRecenter = true;
    }
    this._boundType = type;
    this.recenterMapHandler();
  };
  resetAllowRecenter = () => {
    this._allowRecenter = true;
  };
  setMapDefaultsHandler = (type) => {
    let polygonList = this.polygonManager;
    Object.keys(polygonList).map((key) => {
      polygonList[key].setMap(null);
      delete this.polygonManager[key];
    });
    this.setMapTypeHandler(type);
    if(this.state.mapInstance!=null)
    this.state.mapInstance.setZoom(5);
    this._lastZoom = "";
    this.boundFence = false;
  };

  componentDidUpdate() {
    if (this.state.mapInstance != null && !this._buttonsPlaced) {
      //Zoom controls

      var zoomBtns = document.createElement("div");
      zoomBtns.setAttribute("id", "zoomControlsWrapper");
      zoomBtns.setAttribute("class", styles.zoomControls);
      this.state.mapInstance.controls[
        this.state.mapApi.ControlPosition.BOTTOM_RIGHT
      ].clear();
      this.state.mapInstance.controls[
        this.state.mapApi.ControlPosition.BOTTOM_RIGHT
      ].push(zoomBtns);
      if (zoomBtns != null) {
        ReactDOM.render(
          <>
            <MapTypes
              wrapperClass={styles.typesWrapper}
              setMapType={this.setMapTypeHandler}
              phoneMode={this.props.phoneMode}
              refSetMapTypeControl={this.props.refSetMapTypeControl}
            />
            {!this.props.phoneMode && (
              <ZoomControls
                zoomin={this.zoominHandler}
                zoomout={this.zoomoutHandler}
              />
            )}
          </>,
          zoomBtns
        );
      }
      ////map socket loader
      var socketLoader = document.createElement("div");
      socketLoader.setAttribute("id", "socket-loader-wrapper");

      this.state.mapInstance.controls[
        this.state.mapApi.ControlPosition.CENTER
      ].clear();
      this.state.mapInstance.controls[
        this.state.mapApi.ControlPosition.CENTER
      ].push(socketLoader);
      if (socketLoader != null) {
        <>{ReactDOM.render(<SocketLoader />, socketLoader)}</>;
      }
      /**
       * Map Controls
       */
      var controlsMap = document.createElement("div");
      controlsMap.setAttribute("id", "controlsMapWrapper");
      controlsMap.setAttribute("class", styles.controlsMap);
      this.state.mapInstance.controls[
        this.state.mapApi.ControlPosition.TOP_LEFT
      ].clear();
      this.state.mapInstance.controls[
        this.state.mapApi.ControlPosition.TOP_LEFT
      ].push(controlsMap);
      if (controlsMap != null) {
        ReactDOM.render(
          <div className={styles.leftBtnsWrapper}>
            <FollowVehicle
              click={this.setOptionBoundHandler}
              refVehicleMapControl={this.props.refVehicleMapControl}
              removeTrail={this.props.removeTrail}
              phoneMode={this.props.phoneMode}
              resetAllowRecenter={this.resetAllowRecenter}
            />

            <MapButton
              id="zoom"
              btnAction={this.zoomtoStreetLevelHandler}
              customStyles={{ width: "6rem" }}
              customClass={styles.btn}
              {...(this.props.phoneMode && {
                icon: {
                  prefix: "fas",
                  iconName: "search-plus",
                },
                iconClass: styles.iconClassZoom,
              })}
              {...(!this.props.phoneMode && {
                label: "Zoom",
              })}
            />

            {/* <MapButton
              tool={styles.licenseImg}
              id="showLicense"
              {...(this.props.phoneMode && {
                imageType: LICENSE_PLACE_ICON,
                imageClass: styles.imageClass,
              })}
              {...(!this.props.phoneMode && {
                label: "License No.",
              })}
              btnAction={this.showLicenseClick}
              //showClose={this.state.showLicense}
              customClass={styles.viewLicense}
            /> */}

            {!this.props.phoneMode && (
              <RotateCar
                onClickDismissAlarm={this.props.onClickDismissAlarm}
                saveHeadingAdjustment={this.props.saveHeadingAdjustment}
                refHeadingControl={this.props.refHeadingControl}
                phoneMode={this.props.phoneMode}
              />
            )}
          </div>,
          controlsMap
        );
      }
      /**
       * Fullscreen component
       */
      ////////////////Speedmeter
      var speedmeter = document.createElement("div");
      speedmeter.setAttribute("id", "speedmeter");
      this.state.mapInstance.controls[
        this.state.mapApi.ControlPosition.BOTTOM_LEFT
      ].clear();
      this.state.mapInstance.controls[
        this.state.mapApi.ControlPosition.BOTTOM_LEFT
      ].push(speedmeter);
      if (speedmeter !== null) {
        ReactDOM.render(
          <Speedmeter refSpeedUdpate={this.props.refSpeedUdpate} />,
          speedmeter
        );
      }
      ////////////////////////////////////
      var fullscreenActions = document.createElement("div");
      fullscreenActions.setAttribute("id", "fullscreenWrapper");

      this.state.mapInstance.controls[
        this.state.mapApi.ControlPosition.TOP_RIGHT
      ].clear();
      this.state.mapInstance.controls[
        this.state.mapApi.ControlPosition.TOP_RIGHT
      ].push(fullscreenActions);
      if (fullscreenActions != null) {
        ReactDOM.render(
          <FullScreenControl
            createDetailsElement={this.props.createDetailsElement}
            fullScreenMode={this.fullScreenModeHandler}
            isFullScreenMode={this.state.isFullScreenMode}
            refTelemetryFullscreen={this.props.refTelemetryFullscreen}
            handleESCPress={this.handleESCPress}
            onClickToggleBtn={this.props.onClickToggleBtn}
            getStatusToggleBtn={this.getStatusToggleBtnHandler}
            getInclinationVehicle={this.getInclinationVehicleHandler}
            onClickCheckBox={this.props.onClickCheckBox}
            phoneMode={this.props.phoneMode}
            onClickDismissAlarm={this.props.onClickDismissAlarm}
            setMapType={this.setMapTypeHandler}
            canUseRoads={this.props.canUseRoads}
            saveHeadingAdjustment={this.props.saveHeadingAdjustment}
            activeMapType={this.state.activeMapType}
            refHeadingControl={this.props.refHeadingControl}
            onClickFencing={this.props.onClickFencing}
          />,
          fullscreenActions
        );
      }
      this._buttonsPlaced = true;
    }
  }
  // Fit map to its bounds after the api is loaded
  apiIsLoaded = (map, maps) => {
    if (maps == null || !this._isMounted) return;
    // Bind the resize listener
    this.setState(
      {
        mapApiLoaded: true,
        mapInstance: map,
        mapApi: maps,
      },
      () => {
        if (this.props.apiIsLoadedTrigger != undefined)
          this.props.apiIsLoadedTrigger();

        const mapDiv = document.getElementById("map_canvas");
        maps.event.addDomListener(mapDiv, "wheel", () => {
          this._isScrolling = true;
        });
      }
    );
  };
  getInclinationVehicleHandler = () => {
    return getInclinationVehicle(
      this.props.markers.length ? this.props.markers[0].type : ""
    );
  };
  setMapTypeHandler = (type) => {
    this.setState({ activeMapType: type }, () => {});
  };
  zoomtoStreetLevelHandler = () => {
    let marker = [
      {
        latitude: this.props.lastValidPosition.latitude,
        longitude: this.props.lastValidPosition.longitude,
      },
    ];
    let saveZoomBeforeBound = this.state.mapInstance.getZoom();
    let bounds = getMapBounds(
      this.state.mapInstance,
      this.state.mapApi,
      marker
    );
    this.state.mapInstance.fitBounds(bounds);
    if (this._lastZoom === "") {
      this._lastZoom = saveZoomBeforeBound;
      this.state.mapInstance.setZoom(20);
    } else {
      this.state.mapInstance.setZoom(this._lastZoom);
      this._lastZoom = "";
    }

    this._allowRecenter = false;
  };
  showLicenseClick = () => {
    this.setState({ showLicense: !this.state.showLicense }, () => {
      //this.props.showLicenseClick();
    });
  };
  drawPolylineHandler = () => {
    if (this.state.mapApi == null) return;
    if (this.props.terminalLookupId == "" || this.props.hidePolytrail) {
      this.polylineManager.length && this.deletePolylineHandler();
      return;
    }
    this.deletePolylineHandler();
    let mapApi = this.state.mapApi;
    let points = [];
    // this constructs an array of arrays, starting a new array each time it encounters a change to the transparent field
    for (var index = 0; index < this.props.polytrail.length - 1; index++) {
      if (points.length == 0){
        let newPoints = {
          transparent: this.props.polytrail[index].transparent,
          path:[
            {
              lat: parseFloat(this.props.polytrail[index].latitude),
              lng: parseFloat(this.props.polytrail[index].longitude)
            }
          ]
        }
        points.push(newPoints);
      }else{
        if (this.props.polytrail[index].transparent==points[points.length-1].transparent){
          points[points.length-1].path.push(
            {
              lat: parseFloat(this.props.polytrail[index].latitude),
              lng: parseFloat(this.props.polytrail[index].longitude)
            }
          )
        }else{
          let newPoints = {
            transparent: this.props.polytrail[index].transparent,
            path:[
              {
                lat: parseFloat(this.props.polytrail[index].latitude),
                lng: parseFloat(this.props.polytrail[index].longitude)
              }
            ]
          }
          points.push(newPoints);
        }
      }
    }
    
 
    
    for (const pointPath of points){
      if (pointPath.transparent == false){
        const polyline = new mapApi.Polyline({
          path: pointPath.path,
          geodesic: true,
          strokeColor: "#1a8092",
          strokeOpacity: 1.0,
          strokeWeight: 7,
        });
        polyline.setMap(this.state.mapInstance);
  
        this.polylineManager.push(polyline);
      }
    }

    if (this._allowRecenter && !this._isScrolling) {
      this.recenterMapHandler();
    }
  }
  
  deletePolylineHandler = () => {
    if (this.polylineManager != undefined && this.polylineManager.length > 0) {
      this.polylineManager.map((item, index) => {
        item.setMap(null);
      });
    }
  };

  /**
   * Custom zoom controls
   *  */
  zoominHandler = () => {
    let currentZoom = this.state.mapInstance.getZoom();
    if (currentZoom == 22) return;
    this.state.mapInstance.setZoom(currentZoom + 1);
    this._isScrolling = true;
    this._allowRecenter = false;
  };
  zoomoutHandler = () => {
    let currentZoom = this.state.mapInstance.getZoom();
    if (currentZoom == 3) return;
    this.state.mapInstance.setZoom(currentZoom - 1);
    this._isScrolling = true;
    this._allowRecenter = false;
  };
  getMarkerstoBoundHandler = () => {
    if (this._boundType == CAR) {
      return [
        {
          latitude: this.props.lastValidPosition.latitude,
          longitude: this.props.lastValidPosition.longitude,
        },
      ];
    } else if (this._boundType == TRAIL) {
      if (this.props.polytrail.length) {
        return this.props.polytrail;
      } else {
        return [
          {
            latitude: this.props.lastValidPosition.latitude,
            longitude: this.props.lastValidPosition.longitude,
          },
        ];
      }
    } else return [];
  };
  recenterMapHandler = () => {
    let markers = this.getMarkerstoBoundHandler();
    if (
      this.state.mapInstance == null ||
      this._boundType == OFF ||
      markers.length == 0
    )
      return;
    this._currentZoom = this.state.mapInstance.getZoom();
    const bounds = getMapBounds(
      this.state.mapInstance,
      this.state.mapApi,
      markers
    );
    this.state.mapInstance.fitBounds(bounds);
    //chnage the zoom level when poline coordinates are closer //TODO
    this.state.mapInstance.setZoom(this._currentZoom);
  };
  mapDragHandler = () => {
    this._allowRecenter = false;
  };
  getCustomMarkersHandler = () => {
    if (!this.state.mapApiLoaded || this.props.loading) return;

    let markers = this.props.markers.map((marker) => {
      let lat,
        lng,
        heading = 0,
        direction;
      if (this.props.polytrail.length > 0) {
        lat = parseFloat(this.props.polytrail[0].latitude);
        lng = parseFloat(this.props.polytrail[0].longitude);
        heading = this.props.lastValidHeading + this.props.rotateVehicle;
      } else {
        lat = this.props.lastValidPosition.latitude;
        lng = this.props.lastValidPosition.longitude;
        heading = this.props.lastValidHeading + this.props.rotateVehicle;
      }

      //elseif to marker pins TODO

      return (
        <Marker
          key={marker.id}
          lat={lat}
          lng={lng}
          viewVehicleLookupId={marker.details.lookupId}
          details={marker.details}
          name={marker.name}
          type={marker.type}
          vinNumber={marker.vin}
          heading={heading}
          // direction={direction}
          isOnline={
            !marker.isOnline || (!this.props.loading && this.props.timeOutReset)
              ? false
              : true
          }
          showVin={true}
          showLicense={this.state.showLicense}
          licenseNumber={
            marker.licensePlate != null ? marker.licensePlate.number : "N/A"
          }
          getcurrentZoomLevel={this.getcurrentZoomLevelHandler}
        />
      );
    });

    if (this.props.markers.length && this._boundType !== OFF) {
      this.recenterMapHandler();
    }

    return markers;
  };
  handleESCPress = (event) => {
    if (document.fullscreenElement == null && this.state.isFullScreenMode) {
      this.setState(
        { isFullScreenMode: !this.state.isFullScreenMode },
        () => {}
      );
    }
  };
  /**
   * Fullscreen btn actions
   *
   */

  fullScreenModeHandler = (callback) => {
    this.setState({ isFullScreenMode: !this.state.isFullScreenMode }, () => {
      var elementToSendFullscreen = this.state.mapInstance.getDiv().firstChild;
      if (this.isFullscreen(elementToSendFullscreen)) {
        this.exitFullscreen();
      } else {
        this.requestFullscreen(elementToSendFullscreen);
      }
      callback();
    });
  };
  getStatusToggleBtnHandler = (callback) => {
    callback({
      toggle: this.props.toggleBtn,
      checkbox: this.props.checkboxTelemetry,
    });
  };
  isFullscreen = (element) => {
    return (
      (document.fullscreenElement ||
        document.webkitFullscreenElement ||
        document.mozFullScreenElement ||
        document.msFullscreenElement) == element
    );
  };

  requestFullscreen = (element) => {
    var userAgent = navigator.userAgent;
    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.webkitRequestFullScreen) {
      element.webkitRequestFullscreen();
    } else if (element.mozRequestFullScreen) {
      element.mozRequestFullScreen();
    } else if (element.msRequestFullScreen) {
      element.msRequestFullScreen();
    } else if (
      this.props.phoneMode &&
      /iPhone/.test(userAgent) &&
      !window.MSStream
    ) {
      this.props.toggoleIosScreen();
    }
  };
  exitFullscreen = () => {
    var userAgent = navigator.userAgent;
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.msCancelFullScreen) {
      document.msCancelFullScreen();
    } else if (
      this.props.phoneMode &&
      /iPhone/.test(userAgent) &&
      !window.MSStream
    ) {
      this.props.toggoleIosScreen();
    }
  };
  getcurrentZoomLevelHandler = () => {
    return this.state.mapInstance.getZoom();
  };
  addAllFencingPolygons = () => {
    if (!this.props.showFencing) { 
      let polygonList = this.polygonManager;
      Object.keys(polygonList).map((key) => {
        polygonList[key].setMap(null);
        delete this.polygonManager[key];
      });
      return;
    }
    let list = Object.values(this.props.fencingList);
    if (list.length == 0) return;
    let mapApi = this.state.mapApi;
    let mapInstance = this.state.mapInstance;
    let bounds,
      coordinatesArr = [];
     
    list.map((fencingItem) => {
      if (this.polygonManager[fencingItem.uuid] != undefined || !fencingItem.isActive) {
        return;
      }
      let fencing, coor;

      if (fencingItem.type == 3) {
        fencing = new mapApi.Rectangle({
          strokeColor: fencingItem.color,
          strokeOpacity: 1.0,
          strokeWeight: 1,
          fillColor: fencingItem.color,
          fillOpacity: 0.5,
          map: mapInstance,
          bounds: fencingItem.coordinates,
        });
        coor = getCombinedBounds(fencing.getBounds());
        coordinatesArr = coor.concat(coordinatesArr);
      } else if (fencingItem.type == 2) {
        fencing = new mapApi.Circle({
          strokeColor: fencingItem.color,
          strokeOpacity: 1.0,
          strokeWeight: 1,
          fillColor: fencingItem.color,
          fillOpacity: 0.5,
          map: mapInstance,
          center: fencingItem.coordinates.center,
          radius: fencingItem.coordinates.radius,
        });
        coor = getCombinedBounds(fencing.getBounds());

        coordinatesArr = coor.concat(coordinatesArr);
      } else {
        fencing = new mapApi.Polygon({
          path: fencingItem.coordinates,
          strokeColor: fencingItem.color,
          strokeOpacity: 1.0,
          strokeWeight: 1,
          fillColor: fencingItem.color,
          fillOpacity: 0.5,
        });
        fencing.setMap(this.state.mapInstance);
        coordinatesArr = fencingItem.coordinates.concat(coordinatesArr);
      }
      bounds = getMapBoundsForBounding(
        this.state.mapInstance,
        this.state.mapApi,
        coordinatesArr
      );
      this.polygonManager[fencingItem.uuid] = fencing;
    });

    //bound to the active mode polygon
    if (this.boundFence && coordinatesArr.length) {
      this.state.mapInstance.fitBounds(bounds);
      this._allowRecenter = false;
    }
    this.boundFence = true;
  };
  render() {
    return (
      <>
        <div className={styles.mapWrapper} id="vehicle_map_canvas">
          <GoogleMapReact
            bootstrapURLKeys={{
              key: GOOGLE_MAP_API_KEY,
              libraries: ["drawing", "places"].join(","),
              language: "en",
            }}
            yesIWantToUseGoogleMapApiInternals
            defaultZoom={11}
            defaultCenter={DEFAULT_MAP_LOCATION}
            onGoogleApiLoaded={({ map, maps }) => {
              this.apiIsLoaded(map, maps);
            }}
            options={{
              fullscreenControl: false,
              zoomControl: false,
              gestureHandling: !this.state.isFullScreenMode
                ? "cooperative"
                : "greedy",
              mapTypeId: this.state.activeMapType,
            }}
            onDragEnd={this.mapDragHandler}
          >
            {this.getCustomMarkersHandler()}
            {this.drawPolylineHandler()}
            {this.addAllFencingPolygons()}
          </GoogleMapReact>
        </div>
      </>
    );
  }
}

export default VehicleMap;
