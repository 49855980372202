import React, { Component } from "react";
import styles from "./Input.module.scss";
import Icon from "../Icon/Icon";
export default class Input extends Component {
  render() {
    const {
      type,
      label,
      value,
      name,
      inputWrapper,
      placeholder,
      error,
      errMsg,
      blur,
      max,
      disabled,
      id,
      min,
      onFoucs,
      autocomplete,
      step,
      hideError = false,
      isRegularText = false,
      inputStyles = {},
      iconStyles = {},
      iconDefination = {},
      format,
      phoneMode,
    } = this.props;

    return (
      <div className={inputWrapper}>
        <label className={styles.label}>
          <span className={isRegularText ?styles.lableWhite :styles.text}>{label}</span>
          {phoneMode && (
            <span
              style={{ visibility: error ? "visible" : "hidden" ,color:isRegularText ?"#fff":""}}
              className={styles.errorMobile}
            >              
              {errMsg}
            </span>
          )}
        </label>
        <input
          name={name}
          className={`${styles.input} ${styles.genericInput} `}
          type={type}
          onChange={this.props.change}
          value={value}
          id={id === undefined ? label : id}
          min={min}
          max={max}
          step={step}
          autoComplete={!autocomplete ? "on" : "off"}
          disabled={disabled}
          placeholder={placeholder}
          onBlur={blur}
          onFocus={onFoucs}
          style={inputStyles}
        />
        {Object.keys(iconDefination).length > 0 && (
          <span className={styles.iconStyles} onClick={this.props.iconAction}>
            <Icon defination={iconDefination} />
          </span>
        )}
        {!phoneMode && (
          <p
            className={styles.error}
            style={{ visibility: error ? "visible" : "hidden" }}
          >
            {errMsg}
          </p>
        )}
      </div>
    );
  }
}
