import React, { Component } from "react";
import {
  DEFAULT_MAP_TYPE,
  GET_MAP_TYPE_ICON,
  HYBRID_MAP_TYPE,
  SATELLITE_MAP_TYPE,
  TERRAIN_MAP_TYPE,
} from "../../../Util/constants";
import styles from "./MapTypes.module.scss";
import AERIAL from "../../../assets/Aerial.png";
import HYBRID from "../../../assets/Hybrid.png";
import DefaultMapIcon from "../../../assets/DefaultMapIcon.png";
import TERRIAN from "../../../assets/Terrain.png";
export default class MapTypes extends Component {
  state = {
    activeMapType: DEFAULT_MAP_TYPE,
    isMapTypeOpen: false,
  };
  componentDidMount() {
    if (this.props.refSetMapTypeControl !== undefined) {
      this.props.refSetMapTypeControl(this.setMapDefaultsHandler);
    }
  }
  setMapDefaultsHandler = (type) => {
    this.setState({
      activeMapType: DEFAULT_MAP_TYPE,
    });
  };
  setMapTypeHandler = (type) => {
    this.setState(
      {
        activeMapType: type,
        isMapTypeOpen: !this.state.isMapTypeOpen,
      },
      () => {
        //update respective maps
        this.props.setMapType(type);
      }
    );
  };
  openMapTypeHandler = () => {
    this.setState(
      {
        isMapTypeOpen: !this.state.isMapTypeOpen,
      },
      () => {
        //update respective maps
      }
    );
  };
  getSelectedMapViewIcon = () => {
    return (
      <div
        className={styles.selectedMapButton}
        onClick={this.openMapTypeHandler}
      >
        <div className={styles.mapIcon}>
          <img
            className={styles.imageIcon}
            src={GET_MAP_TYPE_ICON[this.state.activeMapType]}
            alt={this.state.activeMapType}
          />
          {!this.props.phoneMode && (
            <span className={styles.iconTitle}>
              {this.state.activeMapType.replace("map", "")}
            </span>
          )}
        </div>
      </div>
    );
  };
  render() {
    return (
      <div className={styles.MapType}>
        <div
          className={
            this.props.mainWrapperStyles != undefined
              ? `${styles.mainWrapper} ${this.props.mainWrapperStyles}`
              : styles.mainWrapper
          }
          style={{
            visibility: this.state.isMapTypeOpen ? "visible" : "hidden",
            padding: !this.state.isMapTypeOpen ? "0rem" : "",
            width: !this.state.isMapTypeOpen ? "0rem" : "",
            margin: !this.state.isMapTypeOpen ? "0rem" : "",
          }}
        >
          <div className={this.props.wrapperClass}>
            <div
              className={styles.mapIcon}
              onClick={() => this.setMapTypeHandler(SATELLITE_MAP_TYPE)}
            >
              <img
                className={
                  this.state.activeMapType == SATELLITE_MAP_TYPE
                    ? `${styles.imageIcon} ${styles.selected}`
                    : styles.imageIcon
                }
                src={AERIAL}
                alt="AERIAL"
              />
              {!this.props.phoneMode && (
                <span className={styles.iconTitle}>{SATELLITE_MAP_TYPE}</span>
              )}
            </div>

            <div
              className={styles.mapIcon}
              onClick={() => this.setMapTypeHandler(HYBRID_MAP_TYPE)}
            >
              <img
                className={
                  this.state.activeMapType == HYBRID_MAP_TYPE
                    ? `${styles.imageIcon} ${styles.selected}`
                    : styles.imageIcon
                }
                src={HYBRID}
                alt="HYBRID"
              />
              {!this.props.phoneMode && (
                <span className={styles.iconTitle}>{HYBRID_MAP_TYPE}</span>
              )}
            </div>

            <div
              className={styles.mapIcon}
              onClick={() => this.setMapTypeHandler(TERRAIN_MAP_TYPE)}
            >
              <img
                className={
                  this.state.activeMapType == TERRAIN_MAP_TYPE
                    ? `${styles.imageIcon} ${styles.selected}`
                    : styles.imageIcon
                }
                src={TERRIAN}
                alt="TERRIAN"
              />
              {!this.props.phoneMode && (
                <span className={styles.iconTitle}>{TERRAIN_MAP_TYPE}</span>
              )}
            </div>
            <div
              className={styles.mapIcon}
              onClick={() => this.setMapTypeHandler(DEFAULT_MAP_TYPE)}
            >
              <img
                className={
                  this.state.activeMapType == DEFAULT_MAP_TYPE
                    ? `${styles.imageIcon} ${styles.selected}`
                    : styles.imageIcon
                }
                src={DefaultMapIcon}
                alt="default map"
              />

              {!this.props.phoneMode && (
                <span className={styles.iconTitle}>
                  {DEFAULT_MAP_TYPE.replace("map", "")}
                </span>
              )}
            </div>
          </div>
        </div>
        {this.getSelectedMapViewIcon()}
      </div>
    );
  }
}
